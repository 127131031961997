import React, { Component } from "react";
import PropTypes from "prop-types";
import { routerActions } from "react-router-redux";
import { connect } from "react-redux";
import { login, hideSnackBar } from "../actions/user";
import { loginWrapper } from "../styles/login.css";
import Snackbar from "../components/Snackbar"
import { confirmationError } from "../styles/common.css";

function select(state, ownProps) {
    const isAuthenticated = state.user.userID || false;
    const redirect = ownProps.location.query.redirect || "/";
    const showSnackbar = state.user.showSnackBar;
    const response = state.user.response;
    return {
        isAuthenticated,
        redirect,
        showSnackbar,
        response,
    };
}

class LoginContainer extends Component {
    componentWillMount() {
        const { isAuthenticated, replace, redirect } = this.props;
        if (isAuthenticated) {
            replace(redirect);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { isAuthenticated, replace, redirect } = nextProps;
        const { isAuthenticated: wasAuthenticated } = this.props;

        if (!wasAuthenticated && isAuthenticated) {
            replace(redirect);
        }
    }

    onClick = (e) => {
        e.preventDefault();
        this.props.login({
            email: this.email.value,
            password: this.password.value,
        });
    };

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.props.hideSnackBar();
    };

    render() {
        return (
            <React.Fragment>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.props.showSnackbar}
                    onClose={this.handleClose}
                    className={confirmationError}
                    message={this.props.response}
                />
                <div className={loginWrapper}>

                    <h2>Login</h2>
                    <div>
                        <label htmlFor="email">
                            {"Email:"}
                            <input
                                type="text"
                                ref={(node) => {
                                    this.email = node;
                                }}
                                id="email"
                            />
                        </label>
                    </div>
                    <div>
                        <label htmlFor="password">
                            {"Password:"}
                            <input
                                type="password"
                                ref={(node) => {
                                    this.password = node;
                                }}
                                id="password"
                            />
                        </label>
                    </div>
                    <button
                        className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
                        onClick={this.onClick}
                    >
                        {"Login"}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

LoginContainer.propTypes = {
    isAuthenticated: PropTypes.bool,
    redirect: PropTypes.string,
    login: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    response: PropTypes.string,
    showSnackbar: PropTypes.bool,
    hideSnackBar: PropTypes.func.isRequired,
};

LoginContainer.defaultProps = {
    isAuthenticated: false,
    redirect: "/",
    showSnackBar: false,
    response: "",
};

export default connect(select, {
    login,
    hideSnackBar,
    replace: routerActions.replace,
})(LoginContainer);
