import React from "react";
import { connect } from "react-redux";
import Payment from "../components/Payment";
import {
    requestEligibleStatements,
    updateEligibleStatements,
    clearErrorMessage,
    setSelectedStartDateForFetch,
    setSelectedEndDateForFetch,
    fetchChargeList,
    fetchUnallocatedPayments,
} from "../actions/payment";
import selectPaymentsContainer from "../selectors/payment";

const PaymentContainer = (props) => <Payment {...props} />;

const mapStateToProps = (state) => ({
  ...selectPaymentsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchEligibleStatements: () => {
    dispatch(requestEligibleStatements());
  },
  doUpdateEligibleStatements: ({ statements }) => {
    dispatch(updateEligibleStatements({ statements }));
  },
  setSelectedStartDateForFetch: (date) => {
    dispatch(setSelectedStartDateForFetch(date));
  },
  setSelectedEndDateForFetch: (date) => {
    dispatch(setSelectedEndDateForFetch(date));
  },
  closeSnackbar: () => {
    dispatch(clearErrorMessage());
  },
  onFetchChargeList: (member, startDate, endDate) => {
    dispatch(fetchChargeList(member, startDate, endDate))
  },
  doFetchUnallocatedPayments: () => {
    dispatch(fetchUnallocatedPayments())
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
