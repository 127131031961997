import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  Tooltip,
} from "@material-ui/core";
import {
  FileUpload,
  OpenInNew,
  Delete,
  Edit
} from "@material-ui/icons";
import Button from "../../Button"
import ReactTable from "react-table";
import StatusOverlay from "../../StatusOverlay";
import UploadFileDialog from "./UploadFileDialog";
import DeleteFileDialog from "./DeleteFileDialog";
import UpdateFileDialog from "./UpdateFileDialog";
import { FILE_CATEGORIES } from "./UploadFileDialog";

const MemberDocuments = props => {
  const {
    member,
    memberDocuments: { documents },
    doFetchMemberDocuments,
    doUploadDocument,
    doDeleteDocument,
    doUpdateDocument,
    doOpenDocument,
  } = props;

  const [upload_dialog_open, setUploadDialogOpen] = useState(false);
  const [selected_file, setSelectedFile] = useState(null);
  const [delete_dialog_open, setDeleteDialogOpen] = useState(false);
  const [update_dialog_open, setUpdateDialogOpen] = useState(false);

  useEffect(() => {
    doFetchMemberDocuments(member.get("memberID"));
  }, [member.get("memberID")]);

  function handleOpenDeleteDialog(file) {
    setSelectedFile(file);
    setDeleteDialogOpen(true);
  }

  function handleCloseDeleteDialog() {
    setSelectedFile(null);
    doFetchMemberDocuments(member.get("memberID"));
  }

  function handleOpenDocument(file) {
    doOpenDocument(member.get("memberID"), file.fileID);
  }

  function handleOpenUpdateDialog(file) {
    setSelectedFile(file);
    setUpdateDialogOpen(true);
  }

  function handleCloseUpdateDialog() {
    setSelectedFile(null);
    doFetchMemberDocuments(member.get("memberID"));
  }

  const columns = [
    {
      Header: "File Name",
      accessor: "file_name",
    },
    {
      Header: "File Category",
      accessor: "file_category",
      Cell: (row) => {
        return FILE_CATEGORIES[row.value.toLowerCase()];
      },
    },
    {
      Header: "Date",
      id: "date",
      accessor: "created",
      Cell: (row) => {
        return moment(row.value).format("YYYY-MM-DD HH:mm:ss")
      },
    },
    {
      Header: "",
      id: "commands",
      filterable: false,
      sortable: false,
      width: 250,
      resizable: false,
      accessor: (row) => (
        <>
          <Tooltip placement="top" title="Open File"><span>
            <Button
              onClick={() => handleOpenDocument(row)}
              variant="contained"
              color="primary"
              disabled={row.deleted}
              style={{ marginRight: "5px" }}
            >
              <OpenInNew />
            </Button>
          </span></Tooltip>
          <Tooltip placement="top" title="Edit File"><span>
            <Button
              onClick={() => handleOpenUpdateDialog(row)}
              variant="contained"
              color="danger"
              disabled={row.deleted}
              style={{ marginRight: "5px" }}
            >
              <Edit />
            </Button>
          </span></Tooltip>
          <Tooltip placement="top" title="Delete File"><span>
            <Button
              onClick={() => handleOpenDeleteDialog(row)}
              variant="contained"
              color="secondary"
              disabled={row.deleted}
            >
              <Delete />
            </Button>
          </span></Tooltip>
        </>
      ),
    },
  ];

  return <div>
    <div>
      <Button
        style={{ float: "right" }}
        variant="contained"
        color="primary"
        onClick={() => setUploadDialogOpen(true)}
      >
        Add files <FileUpload />
      </Button>

      <h4>Documents</h4>
    </div>
    <ReactTable
      data={documents}
      columns={columns}
      style={{ textAlign: 'center' }}
      className="-striped -highlight"
      defaultPageSize={10}
      defaultSortDesc
      defaultSorted={[
        { id: "date", desc: true },
      ]}
      filterable
      default
    />
    <UploadFileDialog
      open={upload_dialog_open}
      setOpen={setUploadDialogOpen}
      onClose={() => doFetchMemberDocuments(member.get("memberID"))}
      doUploadFile={(file, category) => doUploadDocument(member.get("memberID"), file, category)}
    />
    {selected_file &&
      <DeleteFileDialog
        open={delete_dialog_open}
        setOpen={setDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        file={selected_file}
        doDeleteFile={(fileID) => doDeleteDocument(member.get("memberID"), fileID)}
      />
    }
    {update_dialog_open &&
      <UpdateFileDialog
        open={update_dialog_open}
        setOpen={setUpdateDialogOpen}
        onClose={handleCloseUpdateDialog}
        file={selected_file}
        doUpdateFile={(parameters) => doUpdateDocument(parameters)}
      />
    }
    <StatusOverlay />
  </div>
}

export default MemberDocuments;
