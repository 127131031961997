import * as constants from "../../constants";

const reportInitState = {
  users: [],
  payload: [],
  roles: [],
};

export function userManagement(state = reportInitState, action) {
  switch (action.type) {
    case constants.USER_MANAGEMENT_GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users
      }
    case constants.USER_MANAGEMENT_GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles
      }
    default:
      return state;
  }
}
