import React from "react";
import { connect } from "react-redux";
import Batch from "../components/Batch";
import selectBatchContainer from "../selectors/batch";

const BatchContainer = (props) => <Batch {...props} />;

const mapStateToProps = (state) => ({
  ...selectBatchContainer(state)(state),
  adminUser: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchContainer);
