import { createSelector } from "reselect";

const userManagement = () => (state) => state.userManagement;

const selectUserManagementContainer = () =>
  createSelector(userManagement(), (userManagementData) => {
    return userManagementData;
  });

export default selectUserManagementContainer;
