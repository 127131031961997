import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { enrollmentUpdatePayload } from "../selectors/transferOfService";
import moment from "moment";

export const createErrorMessage = (errorMessage) => ({
  type: constants.TRANSFER_OF_SERVICE_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.TRANSFER_OF_SERVICE_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => {
  return (dispatch) =>
    dispatch({ type: constants.TRANSFER_OF_SERVICE_CLOSE_SNACKBAR });
};

function isProcessing(payload) {
  return {
    type: constants.SET_TRANSFER_OF_SERVICE_IS_PROCESSING,
    payload: payload,
  };
}

function requestEnrollmentUpdate() {
  return {
    type: constants.ENROLLMENT_UPDATE_REQUEST,
  };
}

function enrollmentUpdateSuccess() {
  return {
    type: constants.ENROLLMENT_UPDATE_SUCCESS,
  };
}
function enrollmentUpdateFailure() {
  return {
    type: constants.ENROLLMENT_UPDATE_FAILURE,
  };
}
export const updateEnrollment =
  ({
    meterUID,
    moveOutDate,
    requestedStartDate,
    updateBillingAddress,
    serviceAddress: s,
  }) =>
  (dispatch, getState) => {
    dispatch(isProcessing(true));
    dispatch(requestEnrollmentUpdate());

    const payload = enrollmentUpdatePayload()(getState());

    // Add additional required formData
    payload.formData = {
      ...payload.formData,
      meterUID,
      enrollMode: "movein",
      moveOutDate,
      requestedStartDate,
      address: s,
    };

    if (updateBillingAddress) {
      // if selected, update billing address to service address
      payload.formData.billingAddress = {
        line1: s.line1,
        line2: s.line2,
        city: s.city,
        state: s.state,
        postal_code: s.postal_code,
        country: s.country,
      };
    }

    payload.formData.enrollmentCompletionDate = moment().utc().format();

    fetch(constants.ENROLLMENT_UPDATE, payload)
      .then(() => {
        dispatch(isProcessing(false));
        dispatch(setSuccessMessage("Enrollment update successful"));
        dispatch(enrollmentUpdateSuccess());
      })
      .catch((err) => {
        dispatch(isProcessing(false));
        dispatch(setErrorMessage(`Unable to update enrollment`));
        dispatch(enrollmentUpdateFailure());
      });
  };
