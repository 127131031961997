import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "../Button"
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import NotificationsView from "../NotificationsView";

const useStyles = makeStyles({
  root: {},
  card: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },

  label: {
    marginBottom: "10px"
  }
});

const EnrollmentNotifications = (props) => {
  const classes = useStyles();
  const { details, closePopup } = props;

  const handleClose = () => {
    closePopup();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card} variant="outlined">
        <div className={`${classes.cardTitle} ${noselect}`}>
          <span>Enrollment Notifications</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <Grid container style={{ padding: '10px' }}>
          <Grid item md={12} className={classes.label}>
            <Typography color="primary" variant="h6" display="inline">
              Enrollment ID: &nbsp;
            </Typography>
            <Typography color="secondary" variant="h6" display="inline">
              {details.id}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <NotificationsView searchTerms={{ to_email: details.email, search: details.id }} />
          </Grid>
        </Grid>
        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
            paddingBottom: '10px'
          }}
        >
          <Button
            color="secondary"
            onClick={() => handleClose()}
          >
            Close
          </Button>
        </div>
      </Card>
    </div>
  );
};


export default EnrollmentNotifications;
