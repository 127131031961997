import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button";
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import moment from "moment";
import JSONInput from "react-json-ide";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const getJsonData = (json) => {
  let copyJson = JSON.parse(json);
  Object.values(copyJson).forEach((o) =>
    Object.entries(o).forEach(([k, v]) => {
      if (v === "") {
        delete o[k];
      }
    })
  );
  return copyJson;
};

const ConfirmRenewalOffer = (props) => {
  const classes = useStyles();
  const {
    closeManualAdjustmentPopup,
    enrollment,
    doSubmitEnrollment,
    enrollmentType,
    ctBillingCycles,
  } = props;

  const [editorJsonData, setEditorJsonData] = useState({});
  const [docs, setDocs] = useState({});
  const [docsLoaded, setDocsLoaded] = useState(false);
  const [TOSYRACEFLConfirm, setTOSYRACEFLConfirm] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [formError, setFormError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [requestedStartDate, setRequestedStartDate] = useState(
    moment(enrollment.renewalDate)
  );
  const [isDateEditable, setIsDateEditable] = useState(true);

  const showSuccessModal = () => {
    setSuccessModal(true);
  };

  const closeAllModals = () => {
    closeManualAdjustmentPopup();
    setEditorJsonData({});
  };

  useEffect(() => {
    if (TOSYRACEFLConfirm) {
      setFormError(false);
    } else {
      setFormError(true);
    }
  }, [TOSYRACEFLConfirm]);
  const isProductChange = enrollmentType === "product_change";
  const title = isProductChange
    ? "Confirm Product Change"
    : "Confirm Offer Renewal";
  const enrollmenTypeText = isProductChange
    ? "Product Change"
    : "Offer Renewal";

  useEffect(() => {
    if (typeof enrollment.formData.product["documents"] !== "undefined") {
      const productDocs = JSON.parse(enrollment.formData.product.documents);
      const tempDocs = {};
      for (const pd of productDocs) {
        tempDocs[pd.name] = pd;
      }
      setDocs(tempDocs);
      setDocsLoaded(true);
    } else {
      setDocsLoaded(false);
    }
  }, [enrollment.formData.product]);

  useEffect(() => {
    if (enrollment?.formData?.product?.billing) {
      setEditorJsonData(getJsonData(enrollment.formData.product.billing));
    }
    if (
      isProductChange &&
      enrollment.formData.address.get("state") === "CT" &&
      enrollment.formData.meterReadCycle
    ) {
      const utilityCycles = ctBillingCycles[enrollment.formData.tdspName];
      if (utilityCycles && utilityCycles.length) {
        //find the read cyccl that 33 days in to the future
        const readCycle = utilityCycles.find(
          (r) =>
            parseInt(r.billingcycle) ===
              parseInt(enrollment.formData.meterReadCycle) &&
            moment().diff(moment(r.date).startOf("day"), "days") <= -33
        );
        if (readCycle) {
          setRequestedStartDate(moment(readCycle.date));
          setIsDateEditable(false);
        }
      }
    }
  }, [enrollment]);

  const submitForm = () => {
    if (!disableButton) {
      if (TOSYRACEFLConfirm) {
        setDisableButton(true);
        doSubmitEnrollment({
          TOSYRACEFLConfirm,
          setters: {
            showSuccessModal,
          },
          requestedStartDate,
          enrollmentType,
        });
        closeManualAdjustmentPopup();
      } else {
        setFormError(true);
        setOpenPopup(true);
      }
    }
  };

  const handleCloseErrorModal = () => {
    setOpenPopup(false);
  };

  return (
    docsLoaded && (
      <div className={classes.root}>
        <Card className={classes.container} variant="outlined">
          <div className={`${classes.menuBar} ${noselect}`}>
            <span>{title}</span>
            <CloseIcon onClick={() => closeManualAdjustmentPopup()} />
          </div>
          <div className={classes.content}>
            <div>
              Selected Plan:{" "}
              <span className="label">{enrollment.formData.product.name}</span>
            </div>
            {enrollmentType === "renewal" ? (
              <div>
                Renewal Date:{" "}
                <span>
                  {moment.utc(enrollment.renewalDate).format("MMMM Do YYYY")}
                </span>
              </div>
            ) : (
              <div>
                Start Date:{" "}
                <KeyboardDatePicker
                  style={{
                    marginTop: -5,
                    width: "180px",
                  }}
                  format="MMMM do yyyy"
                  placeholder="mm/dd/yyyy"
                  value={moment.utc(requestedStartDate).format("l LT")}
                  onChange={(v) => setRequestedStartDate(moment(v))}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disabled={!isDateEditable}
                />
              </div>
            )}
            <span>Billing Details:</span>
            <JSONInput
              id="enrollmentBillingData"
              placeholder={editorJsonData}
              height="300px"
              width="100%"
              viewOnly
              reset
            />
            <div>{enrollment.formData.name}</div>
            <div>
              <Checkbox
                type="checkbox"
                className="checkbox"
                checked={TOSYRACEFLConfirm}
                onChange={() => setTOSYRACEFLConfirm(!TOSYRACEFLConfirm)}
              />
              <span>
                {" "}
                Customer agrees to the{" "}
                <a
                  href={docs["TOS"] ? docs["TOS"].location : "/"}
                  target="_blank"
                >
                  Terms of Service,{" "}
                </a>
                {"  "}
                <a
                  href={docs["YRAC"] ? docs["YRAC"].location : "/"}
                  target="_blank"
                >
                  YRAC{" "}
                </a>
                and{" "}
                <a
                  href={docs["EFL"] ? docs["EFL"].location : "/"}
                  target="_blank"
                >
                  EFL
                </a>
              </span>
            </div>
          </div>
          <div className={classes.optionContainer}>
            <Button
              color="secondary"
              varian="raised"
              onClick={async () => await closeManualAdjustmentPopup()}
            >
              Cancel
            </Button>
            <Button
              className={classes.enableButton}
              color="primary"
              varian="raised"
              onClick={async () => await submitForm()}
              disabled={disableButton}
            >
              Apply
            </Button>
          </div>
        </Card>
        <Dialog
          onClose={handleCloseErrorModal}
          aria-labelledby="customized-dialog-title"
          open={openPopup}
        >
          <DialogTitle
            className={classes.confirmDialog}
            id="customized-dialog-title"
            onClose={handleCloseErrorModal}
          >
            <span>Please Confirm Customer Agrees to the Terms</span>
          </DialogTitle>
          <DialogActions>
            <Button
              className={classes.enableButton}
              autoFocus
              onClick={() => handleCloseErrorModal()}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          onClose={closeAllModals}
          aria-labelledby="customized-dialog-title"
          open={successModal}
        >
          <DialogTitle
            className={classes.confirmDialog}
            id="customized-dialog-title"
            onClose={closeAllModals}
          >
            <span>
              {enrollmenTypeText} accepted. It may take up to five minutes for{" "}
              {enrollmenTypeText} to appear, please wait and refresh the page in
              order to verify.
            </span>
          </DialogTitle>
          <DialogActions>
            <Button
              className={classes.enableButton}
              autoFocus
              onClick={() => closeAllModals()}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  );
};

ConfirmRenewalOffer.propTypes = {
  closeManualAdjustmentPopup: PropTypes.func.isRequired,
  doSubmitEnrollment: PropTypes.func.isRequired,
  enrollment: PropTypes.object.isRequired,
};

export default ConfirmRenewalOffer;
