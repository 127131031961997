import React from "react";
import Grid from "@material-ui/core/Grid";
import NotificationsView from "../NotificationsView";

const Notifications = props => {
  return (
    <div>
      <Grid container>
        <Grid item sm={6}>
          <h4>Notifications</h4>
        </Grid>
      </Grid>
      <br />
      <NotificationsView searchTerms={{ memberID: props.params.memberId }} />
    </div>
  )
}

export default Notifications