import React from "react";
import { useStyles } from "./styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import UsageView from "./UsageView";
import Generation from "./Generation";
import MonthlyUsage from "./MonthlyUsage";
import NetMeteringBank from "./NetMeteringBank";
import Usage from "./Usage";

const UsageStatement = (props) => {
  const classes = useStyles();
  const {
    isLoading,
    doFetchUsage,
    doFetchGeneration,
    doFetchMonthlyUsage,
    doFetchNetMeteringBank,
    params,
    guest,
    usage,
    generation,
    monthlyUsage,
    netMeteringBank,
  } = props;

  return (
    <div className={classes.root}>
      <h1 style={{ marginBottom: "10px" }} id="page-title">Usage/Generation</h1>
      <UsageView>
        <Usage
          usage={usage}
          guest={guest}
          params={params}
          doGetUsage={doFetchUsage}
        />
        <Generation
          generation={generation}
          guest={guest}
          params={params}
          doGetGeneration={doFetchGeneration}
        />
        <MonthlyUsage
          monthlyUsage={monthlyUsage}
          guest={guest}
          params={params}
          doGetMonthlyUsage={doFetchMonthlyUsage}
        />
        <NetMeteringBank
          netMeteringBank={netMeteringBank}
          guest={guest}
          params={params}
          doGetNetMeteringBank={doFetchNetMeteringBank}
        />
      </UsageView>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

export default UsageStatement;
