import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "../../Button"
import Dialog from "@material-ui/core/Dialog"
import { useTableStyles as useStyles } from "../styles";
import AllocatePayment from "./AllocatePayment";

const UnallocatedPayment = (props) => {
  const {
    unallocatedPayments,
    doFetchUnallocatedPayments,
    isLoading,
  } = props;
  const columnStyle = {
    textAlign: "center",
    marginTop: "5px"
  };
  const columnButtonStyle = {
    margin: "0 auto",
    display: "block"
  };
  const columnButtonContainerStyle = {
    flex: "0",
  };
  const headerStyle = {
    fontWeight: "700",
  };

  const getUnallocatedTableData = () => {
    const unallocatedArray = [];
    unallocatedPayments.forEach((item) => {
      const metadata = item.meta_data;
      unallocatedArray.push({
        id: item.id,
        payment_batch_date: metadata.meta_data.payment_batch_date,
        client_id: metadata.meta_data.client_id,
        account_number: metadata.meta_data.account_number,
        charge_id: metadata.charge_id,
        amount: metadata.amount,
        date: item.created,
      });
    });
    return unallocatedArray;
  }

  const classes = useStyles();

  const unallocatedDataArray = getUnallocatedTableData();
  const [openAllocatePayment, setOpenAllocatePayment] = useState(false);
  const [unallocated, setUnallocated] = useState({});

  const unallocatedColumns = [
    {
      Header: "Payment Batch Date",
      accessor: "payment_batch_date",
      style: columnStyle,
      headerStyle,
    },
    {
      Header: "Client ID",
      accessor: "client_id",
      style: columnStyle,
      headerStyle,
    },
    {
      Header: "Account Number",
      accessor: "account_number",
      style: columnStyle,
      headerStyle,
    },
    {
      Header: "Charge ID",
      accessor: "charge_id",
      style: columnStyle,
      headerStyle,
    },
    {
      Header: "Amount",
      accessor: "amount",
      style: columnStyle,
      headerStyle,
    },
    {
      Header: "Date",
      accessor: "date",
      style: columnStyle,
      headerStyle,
    },
    {
      Header: "",
      accessor: "id",
      filterable: false,
      style: columnButtonContainerStyle,
      Cell: (i) => (
        <Button
          style={columnButtonStyle}
          onClick={() => {
            setUnallocated(i.original)
            setOpenAllocatePayment(true)
          }}
          variant="contained"
          color="secondary"
          size="small"
          title="Allocate"
        >
          Allocate
        </Button>
      ),
    }
  ];

  useEffect(() => {
    doFetchUnallocatedPayments()
  }, []);

  return (
    <>
      <div>
        <Grid container>
          <Grid item md={6}>
            <h4>Unallocated Payments</h4>
          </Grid>
        </Grid>
        <Dialog
          className={classes.dialog}
          onClose={() => setOpenAllocatePayment(false)}
          aria-labelledby="customized-dialog-title"
          open={openAllocatePayment}
        >
          <AllocatePayment
            closePopup={() => setOpenAllocatePayment(false)}
            unallocated={unallocated}
          />
        </Dialog>
        <ReactTable
          data={unallocatedDataArray}
          columns={unallocatedColumns}
          loading={isLoading}
          defaultPageSize={10}
          className="-striped -highlight"
          filterable
        />
      </div>
    </>
  );
};

UnallocatedPayment.propTypes = {
};

const mapStateToProps = (state) => ({
  unallocatedPayments: state.payments.unallocatedPayments,
  errorMessage: state.payments.errorMessage,
  successMessage: state.payments.successMessage,
  showSnackBar: state.payments.showSnackBar,
  isLoading: state.payments.isLoading,
});

export default connect(mapStateToProps)(UnallocatedPayment);
