import React from "react";
import { connect } from "react-redux";
import Usage from "../components/Usage";
import {
  getUsage,
  getGeneration,
  getMonthlyUsage,
  getNetMeteringBank,
} from "../actions/usage";
import selectUsageContainer from "../selectors/usage";

const UsageContainer = (props) => <Usage {...props} />;

const mapStateToProps = (state) => ({
  ...selectUsageContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchUsage: (memberID, guestUserID = null) => { 
    dispatch(getUsage(memberID, guestUserID))
  },
  doFetchGeneration: (memberID, aggregation) => {
    dispatch(getGeneration(memberID, aggregation))
  },
  doFetchMonthlyUsage: (memberID) => {
    dispatch(getMonthlyUsage(memberID))
  },
  doFetchNetMeteringBank: (memberID) => {
    dispatch(getNetMeteringBank(memberID))
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UsageContainer);
