import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { useStyles } from "./styles";

import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";

import PaymentViews from "./PaymentViews";
import AutoPayStatementTable from "./AutoPayStatementTable.js";
import PaymentHistory from "./PaymentHistory";
import UnallocatedPayment from "./UnallocatedPayment";

const Payment = (props) => {
  const classes = useStyles();
  const {
    doUpdateEligibleStatements,
    setSelectedStartDateForFetch,
    setSelectedEndDateForFetch,
    selectedStartDate,
    selectedEndDate,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isLoading,
    isFetching,
    doFetchEligibleStatements,
    doFetchUnallocatedPayments,
    statements,
    onFetchChargeList,
  } = props;

  useEffect(() => {
    doFetchEligibleStatements({});
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">Payments</h1>
      <PaymentViews doFetchEligibleStatements={doFetchEligibleStatements}>
        <UnallocatedPayment
          onFetchChargeList={onFetchChargeList}
          isLoading={isFetching}
          doFetchUnallocatedPayments={doFetchUnallocatedPayments}
          showMemberID
        />
        <AutoPayStatementTable
          statements={statements}
          doFetchEligibleStatements={doFetchEligibleStatements}
          doUpdateEligibleStatements={doUpdateEligibleStatements}
          setSelectedStartDateForFetch={setSelectedStartDateForFetch}
          setSelectedEndDateForFetch={setSelectedEndDateForFetch}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
        <PaymentHistory
          onFetchChargeList={onFetchChargeList}
          isLoading={isFetching}
          showMemberID
          showDateFilter
        />
      </PaymentViews>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

Payment.propTypes = {
  statements: PropTypes.array.isRequired,
  doFetchEligibleStatements: PropTypes.func.isRequired,
  doUpdateEligibleStatements: PropTypes.func.isRequired,
  setSelectedStartDateForFetch: PropTypes.func.isRequired,
  setSelectedEndDateForFetch: PropTypes.func.isRequired,
  selectedStartDate: PropTypes.node.isRequired,
  selectedEndDate: PropTypes.node.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  onFetchChargeList: PropTypes.func.isRequired,
};

export default Payment;
