import timestamp from "internet-timestamp";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import sha256 from "sha256";

export function isProcessing(payload) {
  return {
    type: constants.PAYMENTS_INITIALIZED,
    isLoading: payload,
  };
}

export function requestEligibleStatements() {
  return {
    type: constants.REQUEST_BILLING_ELIGIBLE_STATEMENTS,
  };
}

export function receiveEligibleStatements(payload) {
  return {
    type: constants.RECEIVE_BILLING_ELIGIBLE_STATEMENTS,
    payload: payload,
  };
}

export const setSelectedStartDateForFetch = (payload) => {
  return {
    type: constants.PAYMENTS_SET_START_DATE_FOR_FETCH,
    payload: payload,
  };
};

export const setSelectedEndDateForFetch = (payload) => {
  return {
    type: constants.PAYMENTS_SET_END_DATE_FOR_FETCH,
    payload: payload,
  };
};

export const updateEligibleStatements =
  ({ statements }) =>
    async (dispatch) => {
      dispatch(isProcessing(true));
      for await (const statement of statements) {
        const { autopay_amount, member_id } = statement;
        const payload = {
          start: new Date().toISOString(),
          member_id: member_id,
          amount: autopay_amount.toString(),
          initial_payment: false,
          paymentSubType: "auto_payment",
        };
        payload.hashID = sha256(JSON.stringify(payload));
        await fetch(constants.BILLING_ADDFUNDS, payload).catch((err) => {
          dispatch({
            type: constants.PAYMENTS_SET_ERROR,
            errorMessage: `${statement.member_id} add funds failed. \n${err.message}`,
          });
        });
      }
      dispatch(isProcessing(false));
      dispatch(requestEligibleStatements());
    };

function requestChargeList() {
  return {
    type: constants.REQUEST_PAYMENT_CHARGES,
    isFetching: true,
  };
}
function receiveChargeList(data) {
  return {
    type: constants.RECEIVE_PAYMENT_CHARGES,
    isFetching: false,
    data,
  };
}
function requestCardList() {
  return {
    type: constants.REQUEST_PAYMENT_CARDS,
    isFetching: true,
  };
}
function receiveCardList(data) {
  return {
    type: constants.RECEIVE_PAYMENT_CARDS,
    isFetching: false,
    data,
  };
}

function requestApplyRefund() {
  return {
    type: constants.REQUEST_PAYMENT_REFUND,
    isFetching: true,
  };
}
function receiveApplyRefund(data) {
  return {
    type: constants.RECEIVE_PAYMENT_REFUND,
    isFetching: false,
    data,
  };
}

function startRefund() {
  return {
    type: constants.START_PAYMENT_REFUND,
    isLoading: true,
  };
}

function endRefund() {
  return {
    type: constants.END_PAYMENT_REFUND,
    isLoading: false,
  };
}

export function fetchChargeList(member, startDate, endDate) {
  return (dispatch) => {
    dispatch(requestChargeList());
    return fetch(constants.PAYMENT_LIST_CHARGES, {
      memberID: member,
      start_date: startDate,
      end_date: endDate,
      queryAllMembers: !member,
    }).then((data) => {
      dispatch(receiveChargeList(data.payments));
    });
  };
}

export function fetchCardList(userId, member_id) {
  return (dispatch) => {
    dispatch(requestCardList);
    dispatch({ type: constants.PAYMENTS_INITIALIZED, isLoading: true });
    return fetch(constants.PAYMENT_LIST_CARDS, { userId, member_id }).then((data) => {
      dispatch(receiveCardList(data.card));
      dispatch({
        type: constants.PAYMENTS_INITIALIZED,
        isLoading: false,
      });
    });
  };
}

export function toggleCharge(chargeIndex) {
  return {
    type: constants.TOGGLE_CHARGE,
    index: chargeIndex,
  };
}

export function applyRefund(chargeObj, member) {
  return (dispatch) => {
    dispatch(requestApplyRefund);
    return fetch(constants.PAYMENT_REFUND, {
      memberID: member.get("memberID"),
      chargeID: chargeObj.get("chargeID"),
      amount: chargeObj.get("Amount"),
    }).then((data) => {
      dispatch(receiveApplyRefund(data));
    });
  };
}

export function processRefund(
  paymentID,
  amount,
  message,
  memberID,
  startDate,
  endDate,
) {
  return (dispatch) => {
    dispatch(startRefund());
    return fetch(constants.PAYMENT_REFUND_URL, {
      payment_id: paymentID,
      amount_dollars: amount,
      message,
    })
      .then(() => {
        dispatch(fetchChargeList(memberID, startDate, endDate));
      })
      .finally(() => dispatch(endRefund()));
  };
}

export function processChargeBack(paymentID, message, memberID, startDate, endDate) {
  return (dispatch) => {
    dispatch(startRefund());
    return fetch(constants.PAYMENT_CHARGEBACK_URL, {
      payment_id: paymentID,
      message,
    })
      .then(() => {
        dispatch(fetchChargeList(memberID, startDate, endDate));
      })
      .finally(() => dispatch(endRefund()));
  };
}

export function clearConfMsg() {
  return {
    type: constants.CLEAR_MEMBER_CONF_MSG,
  };
}

export const removePayment = (memberID, userID) => {
  return (dispatch, getState) => {
    const { cardID } = getState().payments;
    dispatch({ type: constants.PAYMENTS_TOGGLE_CONFIRM_DELETE });
    return fetch(constants.PAYMENT_REMOVE_CARD, {
      memberID,
      cardID,
      paymentMethodID: cardID,
    })
      .then(() => {
        fetchCardList(userID, memberID)(dispatch);
      })
      .catch((err) => {
        dispatch({
          type: constants.PAYMENTS_SET_ERROR,
          errorMessage: err.message,
        });
      });
  };
};

export const toggleConfirmCardDelete = () => {
  return (dispatch) => {
    dispatch({ type: constants.PAYMENTS_TOGGLE_CONFIRM_DELETE });
  };
};
export const confirmPaymentDelete = (cardID, cardLastFour) => {
  return (dispatch) => {
    dispatch({
      type: constants.PAYMENTS_SET_CARD_DELETE,
      cardID,
      cardLastFour,
    });
  };
};

export const clearErrorMessage = () => {
  return (dispatch) => {
    dispatch({ type: constants.PAYMENTS_CLEAR_ERROR });
  };
};

export const createErrorMessage = (errorMessage) => ({
  type: constants.PAYMENTS_SET_ERROR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

export function fetchUnallocatedPayments() {
  return (dispatch) => {
    dispatch({ type: constants.UNALLOCATED_PAYMENT_INITIALIZE, isLoading: true });
    return fetch(constants.UNALLOCATED_PAYMENT_LIST, {}).then((data) => {
      dispatch({
        type: constants.RECEIVE_UNALLOCATED_PAYMENT,
        isLoading: false,
        payload: data
      });
    });
  };
}

export function fetchAllocatePayment(id, accountNumber) {
  return (dispatch) => {
    dispatch({ type: constants.UNALLOCATED_PAYMENT_INITIALIZE, isLoading: true });
    return fetch(constants.ALLOCATE_PAYMENT, {
      id: id,
      account_number: accountNumber
    }).then((data) => {
      dispatch(fetchUnallocatedPayments())
      dispatch({
        type: constants.UNALLOCATED_PAYMENT_SUCCESS,
        successMessage: "Unallocated payment processed",
      });
    }).catch(err => {
      dispatch({
        type: constants.UNALLOCATED_PAYMENT_ERROR,
        errorMessage: err.message,
      });
    })
  };
}

export function setDefaultCard(userID, memberID, cardID, paymentMethodID) {
  return async (dispatch) => {
    dispatch(isProcessing(true));
    try {
      dispatch({ type: constants.PAYMENTS_TOGGLE_CONFIRM_DEFAULT_CARD });
      await fetch(constants.PAYMENT_SET_DEFAULT_CARD, {
        memberID,
        cardID,
        paymentMethodID,
      });
      dispatch(fetchCardList(userID, memberID));
    } catch (err) {
      dispatch(setErrorMessage(err.message));
    } finally {
      dispatch(isProcessing(false));
    }
  };
}

export const toggleConfirmDefaultCard = () => {
  return (dispatch) => {
    dispatch({ type: constants.PAYMENTS_TOGGLE_CONFIRM_DEFAULT_CARD });
  };
}

export const confirmDefaultCard = (cardID, cardLastFour, paymentMethodID) => {
  return (dispatch) => {
    dispatch({
      type: constants.PAYMENTS_SET_DEFAULT_CARD,
      cardID,
      cardLastFour,
      paymentMethodID,
    });
  };
}
