import React, { useState } from "react";
import { connect } from "react-redux";
import Button from "../../Button"
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import MaskedInput from "react-text-mask";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, FormLabel, FormControlLabel } from "@material-ui/core/";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { Helmet } from "react-helmet";
import styled from 'styled-components'
import Select from "@material-ui/core/Select";
import { PAYMENT_TYPE, ACCOUNT_TYPE } from '../../../../constants'
import  selectAccountFlagsContainer  from "../../../selectors/accountFlags";
import {
  RootContainer,
  Container,
  Header,
  OptionsContainer,
  CreditInformationForm,
  TwoColumnsContainer,
  TextFieldInput,
  ButtonInput,
  TextFieldLabel,
  MakePaymentContainer,
  PaymentMethodHeader,
  SaveCardContainer,
} from "./OneTimeCardPayment.style";

const CreditCardMaskComponent = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,

        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

const CVCMaskComponent = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

const  ExpiryMaskComponent = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

const ZipMaskComponent = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

export const ChasePaymentScripts = ({ getKeysUrl }) => (
  <>
    <Helmet>
      <script src={getKeysUrl} />
      <script src={process.env.REACT_APP_CHASE_ENRCRYPTION_URL} />
    </Helmet>
  </>
);

const PaymentButtonContainer = styled.div`
  margin-left: 25px;  
  margin-top: 25px;
`

const PaymentTypeButton = styled.div`
  margin-right: 10px;
  border: 1px solid lightgrey;
  border-radius: 30px;
  padding: 15px;
  font-size: 1rem;
  font-weight: 500;
  color: ${props => (props.selected ? "primary" : "secondary")};
`

const detectCardType = (number) => {
  const re = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MasterCard:
      /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    Discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  };

  for (const key in re) {
    if (re[key].test(number)) {
      return key;
    }
  }
};

const PaymentMethodSelector = ({ isCCSelected, setIsCCSelected, disableACHPayment }) => {  
  return (
    <PaymentButtonContainer>    
      <RadioGroup        
        defaultValue={isCCSelected}
        name="paymentType"
        onChange={(e) => {            
          setIsCCSelected(e.target.value === "true")            
        }}
        value={isCCSelected}
        row
      >
        <FormControlLabel
          value={true}
          control={<Radio inline/>}
          label="Credit Card"
        />
        <FormControlLabel
          value={false}
          control={<Radio inline/>}
          label="Bank Account"
          disabled={disableACHPayment}
        />
      </RadioGroup>        
    </PaymentButtonContainer>
    
  )
}

const OneTimeCardPayment = ({
  userID,
  memberID,
  market,
  open,
  onClose,
  onMakeOneTimePayment,
  onFetchMemberAccountBalance,  
  selectedAccountFlags,
  creditCardFee,
}) => {  
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [savePaymentCard, setSavePaymentCard] = useState(false);
  const [savePaymentCardAsDefault, setSavePaymentCardAsDefault] = useState(true);
  const [isCCSelected, setIsCCSelected] = useState(true)
  const [accountType, setAccountType] = useState(ACCOUNT_TYPE.checking)
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");  
  const removeMask = (number) => number && number.replace(/[^\d]/g, "");
  const CHASE_GET_KEYS_URL = market==='TX' ? process.env.REACT_APP_CHASE_GETKEYS_URL : process.env.REACT_APP_CHASE_GETKEYS_URL_NTX;  
  const disableACHPayment = selectedAccountFlags.some(
    (f) => f.flag_type === "DISABLE_ACH"
  );
  if (disableACHPayment && !isCCSelected) {
    setIsCCSelected(true);
  }


  const validateInput = () => {
    const amountValid = Boolean(amount && parseFloat(amount));
    const nameValid = Boolean(name);
    const cardNumberScrubbed = removeMask(cardNumber);
    const cardNumberValid = cardNumberScrubbed.length === 16;
    const cvcValid = cvc.length === 3;
    const zipCodeValid = zipCode.length === 5;
    const expiryValid = expiry.length === 7;    
    const accountNumberValid = accountNumber.length > 7;
    const routingNumberValid = accountNumber.length > 7;
    return (
      (amountValid || savePaymentCard) &&
      (isCCSelected &&
      nameValid &&
      cardNumberValid &&
      cvcValid &&
      zipCodeValid &&
      expiryValid
      ) || (
        !isCCSelected &&
        nameValid &&
        accountNumberValid &&
        routingNumberValid &&
        zipCodeValid
      )
    );
  };

  const clearForm = () =>{
    setAmount("");
    setName("");
    setCardNumber("");
    setCvc("");
    setExpiry("");
    setZipCode("");
  }

  const processPayment = () => {
    const CHASE_SUBSCRIBER_ID = market === 'TX' ? process.env.REACT_APP_CHASE_SUBSCRIBER_ID : process.env.REACT_APP_CHASE_SUBSCRIBER_ID_NTX;    
    const timestamp = new Date().getTime();
    const reqBody = {
      memberID,
      market,
      order_id: `MP${userID}_${timestamp}`,
      name,
      zip: zipCode,
      amount: amount || "0",
      is_default: savePaymentCardAsDefault,
      skip_saving_profile: !savePaymentCard,
    };    
    
    if(isCCSelected){
      const cardNumberScrubbed = removeMask(cardNumber);
      const exp = expiry.split("/");
      reqBody.payment_type = detectCardType(cardNumber);

      // encrypt card

      let encryptedCard = [];
      encryptedCard = window.ProtectPANandCVV(cardNumberScrubbed, cvc, true);
      reqBody.payment_type = detectCardType(cardNumberScrubbed);
      const pageEncryption = {
        pie_format_id: `${window.PIE.L}${window.PIE.E}`,
        pie_integrity_check: encryptedCard[2],
        pie_key_id: window.PIE.key_id,
        pie_phase_id: `${window.PIE.phase}`,
        pie_mode: "FPE",
        pie_subscriber_id: CHASE_SUBSCRIBER_ID,
      };
      reqBody.credit_card_info = {
        cardNumber: encryptedCard[0],
        cvc: encryptedCard[1],
        expiration_month: exp[0],
        expiration_year: exp[1],
      };
      reqBody.page_encryption = pageEncryption;
    }else{
      reqBody.payment_type = PAYMENT_TYPE.ECP
      reqBody.bank_info = {
        account_type: accountType,
        account_number: accountNumber,
        routing_number: routingNumber,
      }
    }
    
    onClose();
    onMakeOneTimePayment(memberID, "amount", amount, reqBody).then(() => {
      clearForm();      
      onFetchMemberAccountBalance(memberID);
    });
     
  };
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="on time payment dialog"
      open={open}
    >
      <ChasePaymentScripts getKeysUrl={CHASE_GET_KEYS_URL} />
      <RootContainer>
        <Container variant="outlined">
          <Header>
            <span>One Time Payment</span>
            <CloseIcon onClick={() => onClose()} />
          </Header>
          <input type="hidden" value="_" />
          <MakePaymentContainer>            
            <h4>Make Payment</h4>
            <TextFieldInput
              label={`Amount`}
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              type="money"
              required
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            </MakePaymentContainer>
          <PaymentMethodSelector
            isCCSelected={isCCSelected}
            setIsCCSelected={setIsCCSelected}
            disableACHPayment={disableACHPayment}
          />
          { isCCSelected && <CreditInformationForm autoComplete="off">
            
            <PaymentMethodHeader>Credit Card Information</PaymentMethodHeader>
            <TextFieldInput
              label={`Full Name`}
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              required
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />
            <TextFieldLabel>Card number *</TextFieldLabel>
            <TextFieldInput
              label=""
              onChange={(e) => setCardNumber(e.target.value)}
              value={cardNumber}
              type="text"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: CreditCardMaskComponent,
              }}
              autoComplete="off"
            />
            <TwoColumnsContainer>
              <TextFieldInput
                label={`CVC`}
                onChange={(e) => setCvc(e.target.value)}
                value={cvc}
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: CVCMaskComponent,
                }}
                style={{ width: "48%" }}
                autoComplete="off"
              />
              <TextFieldInput
                label={`Expiry Date`}
                onChange={(e) => setExpiry(e.target.value)}
                value={expiry}
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputComponent: ExpiryMaskComponent,
                }}
                style={{ width: "48%" }}
                autoComplete="off"
              />
            </TwoColumnsContainer>
            <TextFieldInput
              label={`Zip Code`}
              onChange={(e) => setZipCode(e.target.value)}
              value={zipCode}
              type="text"
              required
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputComponent: ZipMaskComponent,
              }}
              style={{ width: "48%" }}
              autoComplete="off"
            />            
            {creditCardFee && <span style={{ color: "red", marginTop:"10px" }}>
              {creditCardFee}% processing fee will apply to credit card payments
            </span>}
          </CreditInformationForm>
          }
          { !isCCSelected && <CreditInformationForm>
            <PaymentMethodHeader>Bank Account Information</PaymentMethodHeader>
            <TextFieldInput
                select
                label={'Account Type'}
                name="accountType"
                type="tel"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                guide={false}
                autoComplete="postal-code"
                defaultValue={ACCOUNT_TYPE.checking}
                onChange={(e) => setAccountType(e.target.value)}
              >
                <MenuItem value={ACCOUNT_TYPE.checking} >
                  {ACCOUNT_TYPE.checking}
                </MenuItem>
                <MenuItem value={ACCOUNT_TYPE.savings} >
                  {ACCOUNT_TYPE.savings}
                </MenuItem>
                <MenuItem value={ACCOUNT_TYPE.commercialChecking} >
                  {ACCOUNT_TYPE.commercialChecking}
                </MenuItem>
              </TextFieldInput> 

            <TextFieldInput
                label={'Full Name'}
                name="name"
                autoComplete="name"
                maxLength={30}            
                onChange={(e) => setName(e.target.value)}    
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextFieldInput
                label={'Account Number'}
                name="accountNumber"
                autoComplete="accountNumber"
                type="tel"
                maxLength={17}
                onChange={(e) => setAccountNumber(e.target.value)}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                InputLabelProps={{
                  shrink: true,
                }}
              />              
             <TwoColumnsContainer>
              <TextFieldInput
                label={'Routing Number'}
                name="routingNumber"
                type="tel"
                autoComplete="routingNumber"
                maxLength={9}
                onChange={(e) => setRoutingNumber(e.target.value)}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                InputLabelProps={{
                  shrink: true,
                }}
                // prettier-ignore
              />                                                                 

              <TextFieldInput
                label={'Zip Code'}
                name="zipcode"
                type="tel"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                onChange={(e) => setZipCode(e.target.value)}
                guide={false}
                autoComplete="postal-code"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </TwoColumnsContainer>
            </CreditInformationForm>}            
            <SaveCardContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={savePaymentCard}
                    onChange={(e) => setSavePaymentCard(e.target.checked)}                  
                  />
                }
                label={`Save payment card`} 
              />
            <FormControlLabel
              control={
                <Checkbox
                  checked={savePaymentCard && savePaymentCardAsDefault}
                  onChange={(e) => setSavePaymentCardAsDefault(e.target.checked)}    
                  disabled={!savePaymentCard}              
                />
              }
              label={`Make default payment card`} 
              style={{ marginTop: "15px" }}
            />            
          </SaveCardContainer>
          <OptionsContainer>
            <Button color="secondary" varian="raised" onClick={() => onClose()}>
              Cancel
            </Button>
            <ButtonInput
              disabled={!validateInput()}
              color="primary"
              varian="raised"
              onClick={async () => await processPayment()}
            >
              Process
            </ButtonInput>
          </OptionsContainer>
        </Container>
      </RootContainer>
    </Dialog>
  );
};

const mapStateToProps = selectAccountFlagsContainer();

const ConnectedOneTimeCardPayment = connect(
    mapStateToProps,
    {},
)(OneTimeCardPayment);

export default ConnectedOneTimeCardPayment;