import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { useStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Card from "@material-ui/core/Card";
import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import { debounce } from "/src/util/utilities";
import CreateTransferService from "./CreateTransferService";
import Checkbox from "@material-ui/core/Checkbox";

const TransferOfService = (props) => {
  const classes = useStyles();
  const {
    doRequestMeterInfo,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
    doFetchMeters,
    memberID,
    doUpdateEnrollment,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [meterIdentifier, setMeterIdentifier] = useState("");
  const [moveOutDate, setMoveOutDate] = useState(moment());
  const [moveInDate, setMoveInDate] = useState(moment());
  const [updateBillingAddress, setUpdateBillingAddress] = useState(false);

  useEffect(() => {
    if (typeof memberID != "undefined") doRequestMeterInfo(memberID);
  }, [memberID]);

  useEffect(() => {
    if (props.meter != null) {
      setOpenPopup(true);
    }
  }, [props.meter]);
  const pressEnter = (callback, values) => (ev) => {
    if (ev.key === "Enter") {
      callback({ ...values });
      ev.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">Transfer of Service</h1>
      <Card className={classes.card}>
        {" "}
        <div>
          <div className={classes.inputWrapper}>
            <label htmlFor="meterIdentifier">
              {"New Meter Identifier:"}
              <input
                type="text"
                value={meterIdentifier}
                onChange={(e) => setMeterIdentifier(e.target.value)}
                onKeyPress={debounce(
                  pressEnter(doFetchMeters, {
                    esiID: meterIdentifier,
                  }),
                  500
                )}
              />
            </label>
          </div>
          <div className={`${classes.dateSelector} ${classes.inputWrapper}`}>
            <label htmlFor="move_out_date">
              {"Move Out Date:"}
              <KeyboardDatePicker
                style={{
                  margin: "15px 0px",
                  width: "280px",
                }}
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={moment.utc(moveOutDate).format("l LT")}
                onChange={(v) => setMoveOutDate(moment.parseZone(v).format('l LT'))}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onKeyPress={pressEnter(doFetchMeters, {
                  esiID: meterIdentifier,
                })}
              />
            </label>
          </div>
          <div className={classes.inputWrapper}>
            <label htmlFor="move_in_date">
              {"Move In Date:"}
              <KeyboardDatePicker
                style={{
                  margin: "15px 0px",
                  width: "280px",
                }}
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={moment.utc(moveInDate).format("l LT")}
                onChange={(v) => setMoveInDate(moment.parseZone(v).format('l LT'))}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </label>
          </div>
          <div className={`${classes.inputWrapper} ${classes.wrapper}`}>
            <label htmlFor="update_billing_address">
              {"Use service address as billing address:"}
            </label>
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={updateBillingAddress}
              onChange={() => setUpdateBillingAddress(!updateBillingAddress)}
            />
          </div>
          <button
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
            onClick={() =>
              doFetchMeters({
                esiID: meterIdentifier,
              })
            }
          >
            {"Search"}
          </button>
        </div>
        {props.meter && (
          <CreateTransferService
            classes={classes}
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            meter={props.meter}
            moveOutDate={moveOutDate}
            moveInDate={moveInDate}
            setMoveInDate={setMoveInDate}
            setMoveOutDate={setMoveOutDate}
            updateBillingAddress={updateBillingAddress}
            setUpdateBillingAddress={setUpdateBillingAddress}
            doUpdateEnrollment={doUpdateEnrollment}
          />
        )}
      </Card>

      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

TransferOfService.propTypes = {
  member: PropTypes.object,
  memberID: PropTypes.string,
  doRequestMeterInfo: PropTypes.func.isRequired,
  doFetchMeters: PropTypes.func.isRequired,
  doUpdateEnrollment: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  showSnackBar: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export default TransferOfService;
