import React from "react";
import { connect } from "react-redux";
import Referrals from "../components/Referrals";
import {
  fetchReferralCredits,
  fetchReferralCreditSummaries,
  approveReferralCredits,
  closeSnackbar,
} from "../actions/referrals";
import selectReferralsContainer from "../selectors/referrals";

const ReferralsContainer = (props) => <Referrals {...props} />;

const mapStateToProps = (state) => ({
  ...selectReferralsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchReferralCredits: ({ user_id }, callback) => {
    dispatch(fetchReferralCredits({ user_id }, callback));
  },
  doFetchReferralCreditSummaries: ({ isInitialLoad }) => {
    dispatch(fetchReferralCreditSummaries({ isInitialLoad }));
  },
  doApproveReferralCredits: ({ user_ids }) => {
    dispatch(approveReferralCredits({ user_ids }));
  },
  closeSnackbar: () => {
    dispatch(closeSnackbar());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralsContainer);
