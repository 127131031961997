import React from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "./Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import { closeSnackbar } from "../actions/pageStatus";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

function toList(messages) {
    return <ul style={{ paddingInlineStart: '0px' }}>
        {messages.map((message) => <li key={message}>{message}</li>)}
    </ul>
}

function StatusOverlay(props) {
    const classes = useStyles();
    const {
        pageStatus: {
            showSnackBar,
            successMessages,
            errorMessages,
            actionsProcessing
        },
    } = props;
    function onCloseSnackbar() {
        props.dispatch(closeSnackbar());
    }

    function snackbarFormat() {
        if (successMessages.length > 0 && errorMessages.length > 0) {
            return [<>
                <div className={confirmationSuccess}>{toList(successMessages)}</div>
                <div className={confirmationError}>{toList(errorMessages)}</div>
            </>, undefined]
        }
        else if (successMessages.length > 0) {
            return [toList(successMessages), confirmationSuccess];
        }
        return [toList(errorMessages), confirmationError];
    }

    const [snackbarMessage, snackbarClass] = snackbarFormat();

    // Use CreatePortal to render the snackbar outside of the current component
    // so that it can be displayed over dialogs.
    return <div>
        {createPortal(
            <Snackbar
                id="message-id"
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={showSnackBar}
                onClose={onCloseSnackbar}
                className={snackbarClass}
                message={snackbarMessage}
            />,
            document.body
        )}
        <Backdrop className={classes.backdrop} open={actionsProcessing > 0}>
            <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
    </div>;
}

const mapStateToProps = (state) => ({
    pageStatus: state.pageStatus,
});

const mapDispatchToProps = (dispatch) => ({
    onCloseSnackBar: () => dispatch(closeSnackbar()),
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusOverlay);
