import React from 'react';
import { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import StatusOverlay from '../StatusOverlay';

const ConfirmDeleteDialog = (props) => {
  const { open, name, onClose, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete "{name}"?</DialogTitle>
      <DialogContent>
        Are you sure you want to delete "{name}"?
        This action will permanently delete this tag and remove this tag from any notes.
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          Confirm
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const EditTagDialog = (props) => {
  const {
    open,
    onClose,
    tag,
    doUpdateNoteTag,
    doDeleteNoteTag,
  } = props;

  const [tagName, setTagName] = useState(tag.name);
  const [confirmDelete, setConfirmDelete] = useState(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Tag "{tag.name}"</DialogTitle>
      <DialogContent>
        <TextField
          label="Tag Name"
          value={tagName}
          variant="outlined"
          size="small"
          style={{ marginRight: "10px" }}
          onChange={(e) => setTagName(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            doUpdateNoteTag({ ...tag, name: tagName.trim() });
            onClose();
          }}
          disabled={tagName.trim() === '' || tagName.trim() === tag.name}
        >
          Update
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setConfirmDelete(true);
          }}
        >
          Delete Tag
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
      <ConfirmDeleteDialog
        open={confirmDelete}
        name={tag.name}
        onClose={() => setConfirmDelete(false)}
        onConfirm={() => {
          doDeleteNoteTag(tag);
          onClose();
        }}
      />
    </Dialog>
  );
}


const NoteTags = (props) => {
  const {
    noteTags,
    doGetNoteTags,
    doCreateNoteTag,
    doUpdateNoteTag,
    doDeleteNoteTag,
  } = props;

  const availableTags = noteTags?.tags;
  const [selectedTag, setSelectedTag] = useState(null);
  const [newTagName, setNewTagName] = useState('');

  useEffect(() => {
    doGetNoteTags();
  }, []);


  return (
    <div>
      <h4>Note Tags</h4>
      <div>
        <TextField
          style={{ marginRight: "10px" }}
          label="New Tag Name"
          value={newTagName}
          variant="outlined"
          size="small"
          onChange={(e) => setNewTagName(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => doCreateNoteTag({ name: newTagName.trim() })}
          disabled={newTagName.trim() === ''}
        >
          Create Tag
        </Button>
      </div>

      <h5>Available Tags</h5>
      <Card
        variant="outlined"
        style={{ padding: "10px" }}
      >
        {Object.values(availableTags).map((tag) => (
          <Chip
            style={{ margin: "5px" }}
            key={tag.ID}
            label={tag.name}
            color="primary"
            onClick={() => setSelectedTag(tag)}
          />
        ))}
      </Card>

      {
        selectedTag && <EditTagDialog
          open={selectedTag !== null}
          onClose={() => setSelectedTag(null)}
          tag={selectedTag}
          doUpdateNoteTag={doUpdateNoteTag}
          doDeleteNoteTag={doDeleteNoteTag}
        />
      }
      <StatusOverlay />

    </div >
  );
}

export default NoteTags;