import styled from "styled-components";
import {
  fontSize,
  space,
  color,
  textAlign,
  flex,
  alignSelf,
  lineHeight,
  width,
  maxWidth,
} from "styled-system";

export const Text = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
  font-weight: ${(props) => (props.medium ? 500 : 400)};
  line-height: 1 !important;
  cursor: ${(props) => (props.cursor ? props.cursor : null)};
  ${(props) =>
    props.ellipsis &&
    `
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    `};
  ${fontSize};
  ${space};
  ${color};
  ${textAlign};
  ${flex};
  ${alignSelf};
  ${lineHeight};
  ${width};
  ${maxWidth};

  & strong {
    font-weight: 600;
  }
`;
