import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import Button from "../../Button"

function DeleteFileDialog(props) {
  const {
    onClose, // callback to update state in parent
    open,
    setOpen,
    file: {
      fileID,
      file_name,
    },
    doDeleteFile,
  } = props;

  function handleClose() {
    setOpen(false);
    onClose();
  }

  function handleDeleteFile() {
    doDeleteFile(fileID);
    handleClose();
  }


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to delete `{file_name}`?</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleDeleteFile}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteFileDialog;
