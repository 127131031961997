import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Enrollments from "../components/Enrollments";
import selectEnrollmentssContainer from "../selectors/enrollments";
import * as actions from "../actions/enrollments";

const EnrollmentsContainer = (props) => <Enrollments {...props} />;

const mapStateToProps = selectEnrollmentssContainer();

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators(actions, dispatch),
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnrollmentsContainer);
