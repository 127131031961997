import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../Button";
import { DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const CreateStatement = (props) => {
  const { open, memberId, accountID, onClose, doCreateStatement } = props;

  const [dateRange, setDateRange] = useState(null);
  const [isFinal, setIsFinal] = useState(false);

  const handleCreate = () => {
    const { from, to } = dateRange;
    doCreateStatement({
      spsd: from.toISOString(),
      sped: to.toISOString(),
      isFinal,
      memberId,
      accountID,
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Statement</DialogTitle>
      <DialogContent>
        Generate a new statement for the selected member.
        If an unsent statement already exists, the date range will be expanded to include the newer dates.
        <div>
          <DayPicker
            mode="range"
            label="Start Date"
            selected={dateRange}
            onSelect={setDateRange}
            showOutsideDays
            footer={dateRange && dateRange.from &&
              `${dateRange.from.toLocaleDateString()} - ${dateRange.to ? dateRange.to.toLocaleDateString() : ""}`
            }
          />
        </div>
        <FormControlLabel
          label="Final Statement"
          control={
            <Checkbox
              checked={isFinal}
              onChange={() => setIsFinal(!isFinal)}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleCreate}
          disabled={!dateRange || !dateRange.from || !dateRange.to || !memberId || !accountID}
        >
          Create
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog >
  );
}

export default CreateStatement;
