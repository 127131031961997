import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import ReactTable from "react-table";
import Button from "../Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "../Snackbar";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FileDownloadIcon from "@material-ui/icons/FileDownload";
import MeterTransactionDetail from "./MeterTransactionDetail";
import MeterTransactionCreate from "./MeterTransactionCreate";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { FormControl } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  icons: {
    float: "right",
    "& > *": {
      margin: theme.spacing(1),
    },
    height: 25,
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  assignMeterDialog: {
    minHeight: "400px",
    minWidth: "1200px",
  },
  searchMeterResult: {
    marginLeft: 15,
  },
  createTransaction: {
    "&  *": {
      marginRight: "20px",
      width: "200px",
    },
  },
  input175: {
    marginRight: "20px",
    width: "100%",
  },
  input200: {
    marginRight: "20px",
    width: "175px",
  },
  transactionDetailRoot: {
    width: "100%",
    height: 420,

    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      width: "100%",
      margin: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  transactionDetailHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
    display: "inline-block",
    fontWeight: theme.typography.fontWeightRegular,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    display: "inline-block",
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    marginLeft: 10,
  },
  divider: {
    margin: "20px 0",
  },
  editTransactionView: {
    height: 550,
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  label: {
    fontSize: ".75rem",
    fontWeight: 400,
    lineHeight: 1,
  },
  formControl: {
    width: "100%",
    paddingLeft: 10,
  },
  dialog: {
    position: "absolute",
    scroll: "none",
    top: 0,
  },
  transactionData: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    justifyContent: "space-between",
  },
  transactionInfo: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    fontSize: "18px",
    justifyContent: "space-between",
  },
  transactionBody: {
    height: "100%",
    marginTop: "20px",
    border: "1px solid #ddd",
  },
  transactionHistoryDialog: {
    position: "absolute",
    scroll: "none",
    top: "10vh",
  },
  transactionProcess: {
    fontSize: "12px",
    marginBottom: "5px"
  },
  transactionHistory: {
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    padding: "15px",
    marginBottom: "20px",
    border: "1px solid #ddd",
    height: "400px",
    overflowY: "scroll",
    "& h1": {
      fontSize: "19px",
      textDecoration: "underline",
      paddingBottom: "15px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        color: "#3f51b5",
        transition: "0.15s all linear",
        "&:hover": {
          transform: "scale(1.05)",
        },
      },
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "15px",
      color: "white",
    },
  },
  warning: { 
    display: 'flex',
    alignItems: 'center',  
    backgroundColor: 'orange',
    height: '50px',
    color: 'black',
    width: '100%', 
    fontSize: '16px',
    fontWeight:'bolder'
  }
}));

const MeterTransactions = ({
  transactions,
  showSnackBar,
  successMessage,
  errorMessage,
  selectedIndex,
  selectedTransaction,
  transactionIsModified,
  params,
  total,
  has_next,
  allTransactionTypes,
  fetchMeterTransactions,
  fetchAllMeterTransactions,
  fetchMeterTransactionTypes,
  updateTransaction,
  closeSnackbar,
  setSelectedTransaction,
  processTransaction,
  postUpdateTransaction,
  updateState,
  openAssignMeter,
  openConfirmTransactionProcessing,
  searchMeters,
  searchMeterIdentifier,
  searchMeterResult,
  isProcessing,
  openCreateTransaction,
  newTransactionData,
  updateNewTransaction,
  updateNewTransactionDetail,
  createNewTransaction,
  sendTransaction,
  validateTransactionField,
  location,
  adminUser,
  transactionTypes,
  fetchUserTransactions,
  users,
}) => {
  const [memberId] = useState(params.memberId);
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const fetchData = (memberId, page) => {
    if (memberId) {
      fetchMeterTransactions(memberId, page);
    } else {
      if (location.pathname.includes("/metertransactions/pending")) {
        fetchAllMeterTransactions(page, [], "I", {}, ["No"]);
      } else if (location.pathname.includes("/metertransactions/error")) {
        fetchAllMeterTransactions(page, [], "I", {}, ["Error"]);
      } else if (location.pathname.includes("/metertransactions/held")) {
        fetchAllMeterTransactions(page, [], "", {}, ["Hold"]);
      } else {
        fetchAllMeterTransactions(page, [], "");
      }
    }
  };

  const getDate = (value) => {
    if (!value) {
      return "";
    }
    const d = value.split("T")[0];
    return moment(d).format("YYYY-MM-DD");
  };

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    updateTransaction({
      [`${name}`]: value,
      [`${name}IsSet`]: true,
    });
  };

  const handleNewTransactionChange = (event) => {
    const { target } = event;
    const { name, value } = target;

    updateNewTransaction({
      [`${name}`]: value,
      [`${name}IsSet`]: true,
    });
  };

  const handleNewTransactionDetailChange = (record, index, updatedInfo) => {
    updateNewTransactionDetail({
      record,
      index,
      updatedInfo,
    });
  };

  const handleValidateTransactionField = (
    recordKey,
    record,
    index,
    fieldName,
    fieldValue
  ) => {
    validateTransactionField(recordKey, record, index, fieldName, fieldValue);
  };

  const handleEditorChange = (event) => {
    updateTransaction({
      transactionDataEdited: event.plainText,
      transactionDataIsSet: true,
    });
  };

  const handelConfirmProcessTransaction = () => {
    updateState({
      openConfirmTransactionProcessing: false,
    });
    processTransaction(selectedTransaction.id);
  };

  const assignMeterToTransaction = async (meterID, memberID, reassignTransaction) => {    
    const payload = {
      meterID,
      memberID,
      meterIDIsSet: true,
      memberIDIsSet: true,
    }
    if (reassignTransaction) {
      payload.processingStatus = "No";
      payload.processingStatusIsSet = true;
    }

    updateTransaction(payload);
    await postUpdateTransaction();
    updateState({
      searchMeterIdentifier: "",
      searchMeterResult: [],
      openAssignMeter: false,
      openConfirmTransactionProcessing: true,
    });
  };

  useEffect(() => {
    const START_PAGE = 1;
    fetchData(memberId, START_PAGE);
    if (location.pathname.includes("/metertransactions/history")) {
      fetchMeterTransactionTypes();
    }
  }, [memberId]);

  const wildcardFilter = (filter, row) =>
    row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;

  const columns = [
    {
      Header: "ID",
      accessor: "intId",
      show: false,
    },
    {
      Header: "Date",
      accessor: "transactionDate",
      Cell: (row) => getDate(row.value),
    },
    {
      Header: "Member ID",
      accessor: "memberID",
      show: !Boolean(memberId),
      Cell: (row) =>
        row?.value === "" ? (
          "Unassigned"
        ) : (
          <Link to={`/members/${row.value}/details`}>{row.value}</Link>
        ),
    },
    {
      Header: "Utility No",
      accessor: "utilityAccountNumber",
    },
    {
      Header: "Direction",
      accessor: "direction",
      show:
        Boolean(memberId) || location.pathname === "/metertransactions/history",
      Cell: (row) => (row.value === "I" ? "Inbound" : "Outbound"),
    },
    {
      Header: "Type",
      accessor: "transactionTypeName",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Status",
      accessor: "transactionSubType",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Reason",
      accessor: "reason",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Sender Transaction ID",
      accessor: "senderTransactionID",
    },
    {
      Header: "Originating Transaction ID",
      accessor: "originatingTransactionID",
    },
    {
      Header: "Processed",
      accessor: "processingStatus",
      filterMethod: wildcardFilter,
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <Button
          onClick={async () => await setSelectedTransaction(row.value)}
          variant="contained"
          color="secondary"
        >
          View Data
        </Button>
      ),
    },
  ];

  const transactionDataJson = JSON.parse(
    (selectedTransaction && selectedTransaction.transactionData) || "{}"
  );

  const filterTransactions = (transactions) =>
    (location.pathname.includes("/metertransactions/pending")
      ? transactions.filter((t) => t.processingStatus !== "Yes")
      : transactions
    ).map((tran) => ({ ...tran, intId: parseInt(tran.id) }));

  const getHeaderText = (memberId, path) => {
    if (memberId) {
      return "Meter Transactions";
    } else {
      if (path.includes("/metertransactions/pending")) {
        return "Unprocessed Transactions";
      } else if (path.includes("/metertransactions/held")) {
        return "Held Transactions";
      } else {
        return "Trasaction History";
      }
    }
  };

  const downloadCSV = (data) => {
    const header = columns.map((x) => x.Header).join(",");
    const values = data.map((x) => columns.map((y) => x[y.accessor]).join(","));
    const csv = [header, ...values].join("\n");
    const csvContent = `data:text/csv;charset=utf-8,${csv}`;
    const encodedURI = encodeURI(csvContent);
    window.open(encodedURI);
  };

  const handleSelect = (e, type) => {
    if (e.target.value) setFilter({ ...filter, [type]: e.target.value });
    else setFilter({ ...filter, [type]: null });
  };

  const handlePicker = (e, type) => {
    if (e) setFilter({ ...filter, [type]: moment(e).format("YYYY-MM-DD") });
    else setFilter({ ...filter, [type]: null });
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const cleanFilter = {};
    Object.keys(filter).map((x) => {
      if (filter[x]) cleanFilter[x] = filter[x];
    });

    const START_PAGE = 1;
    fetchAllMeterTransactions(START_PAGE, [], "", cleanFilter);
  };

  const handlePageChange = (pageIndex) => {
    setPageIndex(pageIndex);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    const trx = filterTransactions(transactions).slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
    const createdUsers = trx.map(obj => obj.createdByUserID)
    const modifiedUsers = trx.map(obj => obj.modifiedByUserID)
    const processedUsers = trx.map(obj => obj.processedByUserID)
    const listUsers = [...new Set(createdUsers.concat(modifiedUsers).concat(processedUsers))].filter(x => users[x] === undefined)
    if (listUsers.length > 0) {
      fetchUserTransactions(listUsers)
    }
  }, [pageIndex, pageSize, transactions.length, location.pathname]);

  const classes = useStyles();
  const { isTransactionManager } = adminUser.permissions;
  return (
    <div>
      <Grid container>
        <Grid item md={6}>
          <h4>{getHeaderText(memberId, location.pathname)}</h4>
        </Grid>
        <Grid item md={6} style={{ alignSelf: "flex-end" }}>
          {!!memberId && isTransactionManager && (
            <div style={{ float: "right", marginBottom: "10px" }}>
              <IconButton
                aria-label="Create Transaction"
                size="medium"
                title="Create Transaction"
                classes={{ label: classes.iconButtonLabel }}
                color="primary"
                onClick={() =>
                  updateState({
                    openCreateTransaction: true,
                  })
                }
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </div>
          )}
          <div style={{ float: "right", marginBottom: "10px" }}>
            <IconButton
              aria-label="Export Report"
              size="medium"
              title="Export Report"
              classes={{ label: classes.iconButtonLabel }}
              color="primary"
              onClick={() => {
                downloadCSV(filterTransactions(transactions));
              }}
            >
              <FileDownloadIcon fontSize="large" />
            </IconButton>
          </div>
        </Grid>
        {location.pathname.includes("/metertransactions/history") && (
          <Grid item md={12}>
            <FormControl
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-5px" }}>
                    <label>From:</label>
                  </div>
                  <KeyboardDatePicker
                    onChange={(e) => handlePicker(e, "startTransactionDate")}
                    dateformat="YYYY-MM-DD"
                    value={
                      filter.startTransactionDate
                        ? filter.startTransactionDate
                        : null
                    }
                    views={["year", "month", "date"]}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-5px" }}>
                    <label>To:</label>
                  </div>
                  <KeyboardDatePicker
                    onChange={(e) => handlePicker(e, "endTransactionDate")}
                    dateformat="YYYY-MM-DD"
                    value={
                      filter.endTransactionDate
                        ? filter.endTransactionDate
                        : null
                    }
                    views={["year", "month", "date"]}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-5px" }}>
                    <label>Utility N°</label>
                  </div>
                  <TextField
                    value={
                      filter.utilityAccountNumber
                        ? filter.utilityAccountNumber
                        : null
                    }
                    type="text"
                    onChange={(e) => handleSelect(e, "utilityAccountNumber")}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-1px" }}>
                    <label>Transaction Type</label>
                  </div>
                  <Select
                    id="transaction_type"
                    style={{ width: "100%", marginTop: "-20px" }}
                    onChange={(e) => handleSelect(e, "transactionTypeName")}
                    value={
                      filter.transactionTypeName
                        ? filter.transactionTypeName
                        : null
                    }
                  >
                    {transactionTypes &&
                      transactionTypes.map((a) => (
                        <MenuItem value={a.transaction_type_name}>
                          {a.transaction_type_name}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-1px" }}>
                    <label>Transaction SubType</label>
                  </div>
                  <Select
                    id="transaction_subtype"
                    style={{ width: "100%", marginTop: "-20px" }}
                    onChange={(e) => handleSelect(e, "transactionSubType")}
                    value={
                      filter.transactionSubType
                        ? filter.transactionSubType
                        : null
                    }
                  >
                    <MenuItem value={"Request"}>Request</MenuItem>
                    <MenuItem value={"Accept"}>Accept</MenuItem>
                    <MenuItem value={"Reject"}>Reject</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-1px" }}>
                    <label>Direction</label>
                  </div>
                  <Select
                    id="direction"
                    style={{ width: "100%", marginTop: "-20px" }}
                    onChange={(e) => handleSelect(e, "direction")}
                    value={filter.direction ? filter.direction : null}
                  >
                    <MenuItem value={"I"}>Inbound</MenuItem>
                    <MenuItem value={"O"}>Outbound</MenuItem>
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div style={{ marginBottom: "-5px" }}>
                    <label>Reason</label>
                  </div>
                  <TextField
                    value={filter.reason ? filter.reason : null}
                    type="text"
                    onChange={(e) => handleSelect(e, "reason")}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ marginRight: "30px", marginBottom: "20px" }}
                >
                  <div>
                    <Button
                      autoFocus
                      onClick={handleConfirm}
                      color="primary"
                      variant="contained"
                      style={{ marginBottom: "30px" }}
                    >
                      Search
                    </Button>
                    <Button
                      autoFocus
                      onClick={(e) => {
                        setFilter({});
                        fetchAllMeterTransactions(1, [], "", {});
                      }}
                      color="success"
                      variant="contained"
                      style={{ marginBottom: "30px", marginLeft: "10px" }}
                    >
                      Clear
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <ReactTable
        data={filterTransactions(transactions)}
        columns={columns}
        className="-striped -highlight"
        defaultPageSize={10}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        filterable
        defaultSorted={[
          {
            id: "intId",
            desc: true,
          },
        ]}
      />
      {selectedTransaction && selectedTransaction.id > 0 && (
        <MeterTransactionDetail
          classes={classes}
          selectedTran={selectedTransaction}
          selectedIndex={selectedIndex}
          transactionData={transactionDataJson}
          handleChange={handleChange}
          handleEditorChange={handleEditorChange}
          setSelectedTransaction={setSelectedTransaction}
          transactionIsModified={transactionIsModified}
          processTransaction={processTransaction}
          searchMeters={searchMeters}
          searchMeterIdentifier={searchMeterIdentifier}
          searchMeterResult={searchMeterResult}
          openAssignMeter={openAssignMeter}
          updateState={updateState}
          postUpdateTransaction={postUpdateTransaction}
          isProcessing={isProcessing}
          assignMeterToTransaction={assignMeterToTransaction}
          openConfirmTransactionProcessing={openConfirmTransactionProcessing}
          handelConfirmProcessTransaction={handelConfirmProcessTransaction}
          isTransactionManager={isTransactionManager}
          fetchUserTransactions={fetchUserTransactions}
          users={users}
        />
      )}

      {openCreateTransaction && (
        <MeterTransactionCreate
          classes={classes}
          openCreateTransaction={openCreateTransaction}
          updateState={updateState}
          updateNewTransaction={updateNewTransaction}
          updateNewTransactionDetail={handleNewTransactionDetailChange}
          isProcessing={isProcessing}
          newTransactionData={newTransactionData}
          handleChange={handleNewTransactionChange}
          createNewTransaction={createNewTransaction}
          sendTransaction={sendTransaction}
          validateTransactionField={handleValidateTransactionField}
          allTransactionTypes={allTransactionTypes}
        />
      )}

      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      {has_next && (
        <Button
          onClick={async () => {
            await fetchData(memberId);
          }}
          color="primary"
          variant="contained"
          style={{
            marginRight: 20,
            marginTop: 10,
          }}
        >
          Load More
        </Button>
      )}

      <div style={{ float: "right", marginTop: "10px" }}>
        Downloaded {transactions.length} of {total} transactions
      </div>
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

MeterTransactions.propTypes = {
  closeSnackbar: PropTypes.any,
  errorMessage: PropTypes.any,
  fetchAllMeterTransactions: PropTypes.func,
  fetchMeterTransactions: PropTypes.func,
  params: PropTypes.shape({
    memberId: PropTypes.any,
  }),
  processTransaction: PropTypes.func,
  selectedIndex: PropTypes.any,
  setSelectedTransaction: PropTypes.func,
  showSnackBar: PropTypes.any,
  successMessage: PropTypes.any,
  transactionIsModified: PropTypes.any,
  transactions: PropTypes.array,
  updateTransaction: PropTypes.func,
};

export default MeterTransactions;
