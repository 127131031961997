import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { browserHistory } from "react-router";
import { routerMiddleware } from "react-router-redux";
import rootReducer from "../reducers";
import rootSaga from "../sagas";
import createSagaMiddleware from "redux-saga";
import { hydrate } from "../actions/user.js";

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState) {
  const routingMiddleware = routerMiddleware(browserHistory);

  let enhancers = applyMiddleware(
    routingMiddleware,
    thunkMiddleware,
    sagaMiddleware
  );
  if (process.env.NODE_ENV !== "production") {
    if (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers = compose(enhancers, window.__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const store = createStore(rootReducer, initialState, enhancers);
  sagaMiddleware.run(rootSaga);

  store.dispatch(hydrate());

  return store;
}
