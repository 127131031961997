import { createSelector } from "reselect";

const enrollmentsDomain = () => (state) => state.enrollments;

const adminUser = () => (state) => state.user;

const selectEnrollmentsContainer = () =>
    createSelector(
        enrollmentsDomain(),
        adminUser(),
        (
            subState,
            adminUser
        ) =>
            Object.assign({}, subState, {
                adminUser,
            })
    );

export default selectEnrollmentsContainer;
