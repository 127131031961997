import { makeStyles, styled } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "25px",
    marginBottom: "25px",
    border: "1px solid lightgrey",
    borderRadius: 0,
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiTableCell-root": {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  productDetail: {
    flexDirection: "column",
    alignItems: "start",
  },
  productDetailList: {
    display: "list-item",
    listStyle: "disc",
    marginLeft: 25,
  },
}));
