import React, { useState, useEffect, Fragment } from "react";
import { useTableStyles as useStyles } from "./styles";
import fetch from "../../util/api-ajax";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { FormControl } from "@material-ui/core/";
import Button from "../Button"
import InputLabel from "@material-ui/core/InputLabel";
import XLSX from "xlsx";
import * as constants from "../../../constants";
import Tooltip from "@material-ui/core/Tooltip";

const ProcessTag = ({ type, error }) => {
  const tagStyle = {
    padding: '0px 12px',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: type === 'pending' ? 'gray' : type === 'done' ? 'green' : type === 'error' ? 'red' : 'orange',
    display: 'inline-block',
  };
  if (error === undefined || error === null) {
    return (<span style={tagStyle}>
      {type === 'pending' ? 'Pending' : type === 'done' ? 'Done' : type === 'error' ? 'Error' : 'Running'}
    </span>)
  } else {
    return (
      <Tooltip placement='right' title={error} >
        <span style={tagStyle}>
          {type === 'pending' ? 'Pending' : type === 'done' ? 'Done' : type === 'error' ? 'Error' : 'Running'}
        </span>
      </Tooltip>
    );
  }
};

const FileImport = ({ adminUser }) => {
  const [headers, setHeaders] = useState([])
  const [rows, setRows] = useState([])
  const [rowsStatus, setRowsStatus] = useState({})
  const [errorStatus, setErrorStatus] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [importType, setImportType] = useState("note")

  useEffect(() => {
    if (importType === "note") {
      setHeaders(['Brand', 'Account Number', 'Note'])
    } else if (importType === "remove_ach") {
      setHeaders(['brand', 'account_number'])
    } else {
      setHeaders([])
    }
  }, [importType])

  useEffect(() => {
    if (rows) {
      let dict = {}
      rows.map(value => dict[value.internal_id] = 'pending')
      setRowsStatus(dict)
    }
  }, [rows])

  function exportXLSX(data, name) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data, { header: Object.keys(data[0]) });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    const fileName = `${name}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  }

  const readFileAsync = (file) => {
    setIsLoading(true);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        setRowsStatus({})
        setErrorStatus({})
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_row_object_array(sheet);
        resolve(jsonData.map((x, idx) => ({ ...x, internal_id: idx })));
      };
      reader.onerror = (error) => {
        setIsLoading(false);
        if (file.current) {
          file.current.value = '';
        }
        reject(error);
      };
      reader.onloadend = () => {
        setIsLoading(false);
        if (file.current) {
          file.current.value = '';
        }
      };
      reader.readAsBinaryString(file);
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    try {
      const jsonData = await readFileAsync(file);
      setRows(jsonData);
    } catch (error) {
      console.error('An error has ocurred:', error);
    }
  };

  const setAccountFlag = async (value) => {
    const account = await fetch(constants.BILLING_ACCOUNT, value)
    if (!account.accountID) {
      throw new Error('Account not found')
    }
    const vars = {
      account_id: account.accountID,
      account_flag_type_id: value.account_flag_type_id,
      expiration_date: value.expiration_date,
    }
    
    return await fetch(`${constants.ACCOUNT_FLAGS_MAIN_URL}/${vars.account_id}/accountflags/create`, vars)
  }

  const delay = (value) => new Promise((resolve, reject) => {
    if (importType === "note") {
      fetch(constants.MEMBER_NOTE_CREATE_URL, value)
        .then((res) => {
          resolve(null);
        })
        .catch((error) => {
          reject(error.message);
        });
    } else if (importType === "remove_ach") {
      setAccountFlag(value)
      .then((res) => {
        resolve(null);
      })
      .catch((error) => {
        reject(error.message);
      });
    } else {
      reject('Invalid import type');
    }
  });

  const getParams = value => {
    if (importType === "note") {
      return {
        authorID: Number(adminUser.userID),
        authorEmail: adminUser.email,
        body: value['Note'],
        accountNumber: value['Account Number'],
      }
    } else if (importType === "remove_ach") {
      return {
        accountNumber: value['account_number'],
        account_flag_type_id: 'DISABLE_ACH',
        // Setting the flag to expire on the current date will remove it
        expiration_date: new Date().toISOString(),
      }
    }
    // fallback for unimplemented options
    return value
  }

  const checkBrand = (value) => {
    const brand = value['Brand'] ? value['Brand'] : value['brand']
    
    return (brand && process.env.REACT_APP_BRAND_INSTANCE_NAME && process.env.REACT_APP_BRAND_INSTANCE_NAME.toUpperCase() === brand.toUpperCase())
  }

  const handleProcessFile = async () => {
    setDisabled(true)
    const requests = rows.map(value => {
      if (checkBrand(value)) {
        setRowsStatus(prevRowsStatus => {
          const update = { [value.internal_id]: 'running' };
          return { ...prevRowsStatus, ...update };
        });
        return delay(getParams(value))
          .then(() => {
            setRowsStatus(prevRowsStatus => {
              const update = { [value.internal_id]: 'done' };
              return { ...prevRowsStatus, ...update };
            });
            return { data: true };
          })
          .catch(error => {
            setRowsStatus(prevRowsStatus => {
              const update = { [value.internal_id]: 'error' };
              return { ...prevRowsStatus, ...update };
            });
            setErrorStatus(prevRowsStatus => {
              const update = { [value.internal_id]: error };
              return { ...prevRowsStatus, ...update };
            });
            return null;
          });
      } else {
        setRowsStatus(prevRowsStatus => {
          const update = { [value.internal_id]: 'error' };
          return { ...prevRowsStatus, ...update };
        });
        setErrorStatus(prevRowsStatus => {
          const update = { [value.internal_id]: 'Invalid brand selected' };
          return { ...prevRowsStatus, ...update };
        });
      }
    });

    Promise.all(requests).then(results => { setDisabled(false) })
  };

  const handleDownload = () => {
    const result = []
    rows.forEach(x => {
      const value = { ...x }
      value['Process Status'] = rowsStatus[value.internal_id]
      value['Error Details'] = errorStatus[value.internal_id]
      delete value.internal_id
      result.push(value)
    })
    exportXLSX(result, "response")
  }

  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.tableOptionsContainer}>
        <Grid container style={{ marginTop: "10px", marginBottom: "20px" }}>
          <Grid item md={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="enroll-type">Select Format</InputLabel>
              <Select
                value={importType}
                onChange={(e) => setImportType(e.target.value)}
              >
                <MenuItem value="note">Import Note</MenuItem>
                <MenuItem value="remove_ach">Remove Disable ACH flag</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={1} style={{ marginLeft: "10px", marginTop: "5px" }}>
            <Button
              style={{ margin: "10px", width: "100%", backgroundColor: "purple" }}
              variant="contained"
              color="primary"
              onClick={() => {
                let dict = {}
                headers.map(x => { dict[x] = null })
                exportXLSX([dict], "template")
              }}
            >
              Template
            </Button>
          </Grid>
          <Grid item md={1} style={{ marginLeft: "10px", marginTop: "5px" }}>
            <input
              type="file"
              id="fileInput"
              accept=".xlsx"
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
            <Button
              style={{ margin: "10px", width: "100%" }}
              variant="contained"
              color="primary"
              disabled={isLoading}
              onClick={() => {
                const fileInput = document.getElementById('fileInput');
                fileInput.click();
              }}
            >
              Import
            </Button>
          </Grid>
          <Grid item md={1} style={{ marginLeft: "10px", marginTop: "5px" }}>
            <Button
              style={{ margin: "10px", width: "100%" }}
              variant="contained"
              color="secondary"
              disabled={!((headers.length > 0 && rows.length > 0)) || disabled}
              onClick={handleProcessFile}
            >
              Process
            </Button>
          </Grid>
          <Grid item md={1} style={{ marginLeft: "10px", marginTop: "5px" }}>
            <Button
              style={{ margin: "10px", width: "100%", backgroundColor: "green" }}
              variant="contained"
              color="primary"
              onClick={handleDownload}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      </div>
      <ReactTable
        className={"-striped -highlight " + classes.table}
        data={rows}
        loading={isLoading}
        columns={headers.map(column => {
          return {
            Header: column,
            accessor: column,
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
          }
        }).concat([{
          Header: 'Process Status',
          accessor: 'internal_id',
          Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>
            <ProcessTag type={rowsStatus[value]} error={errorStatus[value]} />
          </div>)
        }])}
        defaultPageSize={20}
      />
    </Fragment>
  );
};

export default FileImport;
