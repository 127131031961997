import numeral from "numeral";

export const formatCentData = (cent) => {
  const centNumber = Number(cent);

  if (centNumber >= 99.95) {
    const dollarValue = centNumber.toFixed() / 100;
    return numeral(dollarValue).format("$0,0.00");
  }

  return `${centNumber.toFixed(1)}¢`;
};

export const currencyWithNoDecimal = (value) => numeral(value).format("$0,0");

export const currencyWithNoSignWithDecimal = (value) =>
  numeral(value).format("0,0.00");
export const currencyWithNoSign = (value) => numeral(value).format("0,0");

export default (value) => numeral(value).format("$0,0.00");
