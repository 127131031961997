import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { requestMonthlyCharges } from "../../actions/billing";

import { noselect } from "../../styles/common.css";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        fontSize: "18px",
        padding: "15px",
        marginTop: "20px",
        marginBottom: "80px",
        border: "1px solid #ddd",
        height: "600px",
        "& h1": {
            fontSize: "19px",
            textDecoration: "underline",
            paddingBottom: "15px",
            margin: 0,
            display: "flex",
            justifyContent: "space-between",
        },
    },
    tableHeaderRow: {
        backgroundColor: "#333",
        "& .MuiTableCell-root": {
            fontSize: "15px",
            color: "white",
            backgroundColor: "#333",
            whiteSpace: "pre",
        },
    },
    dateSelector: {
        display: "flex",
        alignItems: "center",
        margin: "15px",
        "& #date-selector-label": {
            fontSize: "15px",
            marginRight: "15px",
        },
    },
});

const MonthlyChargesTable = (props) => {
    const classes = useStyles();
    const { chargeItems, money } = props;

    return (
        <Table className={classes.tableContainer}>
            <TableHead className={`${classes.tableHeaderRow} ${noselect}`}>
                <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Billed</TableCell>
                    <TableCell>Unbilled</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {chargeItems &&
                    chargeItems.map((item) => {
                        return (
                            <TableRow>
                                <TableCell>{moment(item.date).utc().format("YYYY/MM")}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{money(item.billed)}</TableCell>
                                <TableCell>{money(item.unbilled)}</TableCell>
                            </TableRow>
                        );
                    })
                }
            </TableBody>
        </Table>
    );
};

const mapStateToProps = (state) => ({
    billingMonthlyCharges: state.billingMonthlyCharges,
});

const mapDispatchToProps = (dispatch) => ({
    doFetchMonthlyCharges: (memberID, month, year) =>
        dispatch(requestMonthlyCharges(memberID, month, year)),
    dispatch,
});

const MonthlyChargesPanel = (props) => {
    const classes = useStyles();
    const {
        memberID,
        money,
        billingMonthlyCharges: { chargeItems },
        doFetchMonthlyCharges,
    } = props;
    const [currentDate, setCurrentDate] = useState(moment());


    useEffect(() => {
        doFetchMonthlyCharges(memberID, currentDate.month() + 1, currentDate.year());
    }, []);

    const handleChangeForDate = (date) => {
        const selectedDate = moment(date);
        doFetchMonthlyCharges(memberID, selectedDate.month() + 1, selectedDate.year());
        setCurrentDate(selectedDate);
    };

    return (
        <Card className={classes.root}>
            <h1 id="panel-title">
                Monthly Charges
            </h1>
            <div className={classes.dateSelector}>
                <span id="date-selector-label">Select Month</span>
                <KeyboardDatePicker
                    dateformat="YYYY/MM"
                    value={currentDate}
                    onChange={handleChangeForDate}
                    views={["month"]}
                    style={{ margin: "0px 15px", width: "200px" }}
                    openTo="month"
                />
            </div>
            <MonthlyChargesTable
                chargeItems={chargeItems}
                money={money}
            />
        </Card>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MonthlyChargesPanel);
