import { createSelector } from "reselect";

const usage = () => (state) => state.usage;

const selectUsageContainer = () =>
  createSelector(usage(), (usageData) => {
    return usageData;
  });

export default selectUsageContainer;
