import { createSelector } from "reselect";

const reports = () => (state) => state.reports;

const selectReportsContainer = () =>
  createSelector(reports(), (reportsData) => {
    return reportsData;
  });

export default selectReportsContainer;
