import PropTypes from "prop-types";
import React from "react";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import currency from "../../util/currency-formatter";

const StatementDetailsRow = (props) => {
  const { summary } = props;
  const { charge_summary } = summary;

  const chargeSummaryLabels = {
    previous_balance: "Previous Balance",
    current_charges: "Current Charges",
    payments_adjustments: "Payments & Adjustment",
    prior_period_charges: "Prior Period Charges",
    taxes_fees: "Taxes Fees",
    total_due: "Total Due",
  };
  const createDetailRow = (key, i) => {
    // Charge item - show total only if a breakdown is not available
    let chargeItem = [
      <TableRow key={i}>
        <TableCell />
        <TableCell align="left" style={{ fontWeight: "bold" }}>
          {chargeSummaryLabels[key]}
        </TableCell>
        <TableCell colSpan={9} align="left">
          {!summary[key] && currency(charge_summary[key])}
        </TableCell>
      </TableRow>,
    ];

    // Display charge item breakdown if available
    if (summary[key]) {
      let chargeItemBreakdown = summary[key].map((detail, index) => {
        return (
          <TableRow key={index}>
            <TableCell />
            <TableCell style={{ paddingLeft: 25 }} align="left">
              {detail?.item}
            </TableCell>
            <TableCell colSpan={9} align="left">
              {currency(detail?.amount)}
            </TableCell>
          </TableRow>
        );
      });
      // Charge item total
      let chargeItemTotal = [
        <TableRow key={i}>
          <TableCell />
          <TableCell align="left">
            {`Total ${chargeSummaryLabels[key]}`}
          </TableCell>
          <TableCell colSpan={9} align="left">
            {currency(charge_summary[key])}
          </TableCell>
        </TableRow>,
      ];
      return [chargeItem, ...chargeItemBreakdown, chargeItemTotal];
    } else {
      return chargeItem;
    }
  };

  return summary.hasOwnProperty("charge_summary") ? (
    Object.keys(chargeSummaryLabels).map((charge, index) => {
      return createDetailRow(charge, index);
    })
  ) : (
    <TableRow>
      <TableCell></TableCell>
      <TableCell colSpan={9}>
        Summary Breakdown Not Avaialble For This Statement
      </TableCell>
    </TableRow>
  );
};

StatementDetailsRow.propTypes = {
  summary: PropTypes.object.isRequired,
};

export default StatementDetailsRow;
