import PropTypes from "prop-types";
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button"
import { Link } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { noselect } from "../../styles/common.css";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  statementInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px 10px 0px",
    margin: "0px 10px",
    "& span": {
      fontWeight: "bold",
    },
  },
  adjustmentForm: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
    borderTop: "2px solid #ddd",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const UpdateStatement = (props) => {
  const classes = useStyles();
  const {
    doUpdateStatementStatus,
    closeManualAdjustmentPopup,
    selectedStatements,
    selectedStatus,
    hasErrorReason,
    inReview
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [formError, setFormError] = useState(false);
  const [errorReason, setErrorReason] = useState("");

  const submitForm = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = async () => {
    if (!formError) {
      let checkedStatementIDs = selectedStatements
        .filter((s) => {
          if (s.checked) return true;
          return false;
        })
        .map((s) => s.id);

      await doUpdateStatementStatus({
        statementIDs: checkedStatementIDs,
        status: selectedStatus,
        errorReason: errorReason,
        inReview: inReview
      });

      setOpenPopup(false);
      closeManualAdjustmentPopup(false);
    }
  };
  return (
    <div className={classes.root}>
      <Card className={classes.container} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Update Statement Status</span>
          <CloseIcon onClick={() => closeManualAdjustmentPopup()} />
        </div>
        <div className={classes.statementInfo}>
          {selectedStatements.map((selectedStatement, index) => {
            if (selectedStatement.checked)
              return (
                <div key={index}>
                  <span>
                    Member ID:{" "}
                    <Link
                      style={{ color: "#3f51b5" }}
                      to={`/members/${selectedStatement.id}/details`}
                    >
                      {selectedStatement.member_id}
                    </Link>{" "}
                  </span>
                  <span>
                    Due Date:{" "}
                    {moment
                      .utc(selectedStatement.due_date)
                      .format("MMMM Do YYYY")}
                  </span>
                </div>
              );
          })}
          {
            selectedStatus === "error" &&
            <div>
              <TextField
                style={{ width: "100%", marginTop: "5px" }}
                label="Error Reason"
                id="error-reason"
                onChange={(e) => setErrorReason(e.target.value)}
                value={errorReason}
                type="text"
              />
            </div>
          }
        </div>
        <div className={classes.optionContainer}>
          <Button
            color="secondary"
            varian="raised"
            onClick={async () => await closeManualAdjustmentPopup()}
          >
            Cancel
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
          >
            Apply
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {formError && (
            <WarningIcon style={{ color: "gold", marginRight: "10px" }} />
          )}
          {!formError ? (
            <span>Please confirm the following transactions</span>
          ) : (
            <span>Warning</span>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.contentFullView}>
            {!formError ? (
              <div>
                <span>
                  Updating statement(s) status from to{" "}
                  <span style={{ fontWeight: "bold", fontSize: 16 }}>
                    {selectedStatus}
                  </span>{" "}
                  for selected member(s)
                </span>
                <br />
                {selectedStatus === "error" && (
                  <span>
                    Note: only statements that are new or pending approval can
                    be set to {selectedStatus} status
                  </span>
                )}
              </div>
            ) : (
              <div>Please complete every field of the form.</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="secondary">
            Go Back
          </Button>
          <Button
            className={
              !formError ? classes.enableButton : classes.disableButton
            }
            autoFocus
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UpdateStatement.propTypes = {
  doUpdateStatementStatus: PropTypes.func.isRequired,
  closeManualAdjustmentPopup: PropTypes.func.isRequired,
  selectedStatements: PropTypes.array.isRequired,
  selectedStatus: PropTypes.string.isRequired,
};

export default UpdateStatement;
