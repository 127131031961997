import { createSelector } from "reselect";

const meterTransactionDomain = () => (state) => state.meterTransactions;
const adminUserDomain = () => (state) => state.user;

const meterNewTransactionType = () => (state) =>
    state.meterTransactions.newTransactionData.transactionTypeName;

const meterNewTransactionSchema = () => (state) =>
    state.meterTransactions.newTransactionData?.schema;

const meterNewTransactionSchemaOverride = () => {
    return createSelector(
        meterNewTransactionType(),
        meterNewTransactionSchema(),
        (transactionTypeName, newTransactionSchema) => {
            if (!newTransactionSchema) {
                return {};
            }

            const { transaction_types } = newTransactionSchema;

            const transactionOverride = transaction_types.find(
                (t) => t.transaction_type === transactionTypeName
            );

            return transactionOverride;
        }
    );
};

const selectMeterTransactionContainer = () =>
    createSelector(
        meterTransactionDomain(),
        meterNewTransactionSchemaOverride(),
        adminUserDomain(),
        (subState, newTransactionSchema, adminUser) =>
            Object.assign(
                {},
                {
                    ...subState,
                    transactions: subState.transactions.filter(
                        (t) =>
                            (!subState.meterID &&
                                t.processingStatus !== "Ignore") ||
                            subState.meterID
                    ),
                    newTransactionSchema,
                    adminUser
                }
            )
    );

export default selectMeterTransactionContainer;
