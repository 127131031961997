import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import moment from "moment";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../Button"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import JSONInput from "react-json-ide";
import locale from "react-json-ide/locale/en";
import IconButton from "@material-ui/core/IconButton";
import PlayArrow from "@material-ui/icons/PlayArrow";
import History from "@material-ui/icons/History";
import LinkIcon from "@material-ui/icons/Link";
import FindReplace from "@material-ui/icons/FindReplace";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import AssignMeterTransaction from "./AssignMeterTransaction";
import ConfirmTransactionProcessing from "./ConfirmTransactionProcessing";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import { styles } from "styled-system";

const TransactionHistory = ({
  classes,
  transactionID,
  transactions,
  onClose,
  users,
}) => (
  <Dialog
    open
    contentlabel="Transaction History"
    ariaHideApp={false}
    fullWidth={true}
    maxWidth={"md"}
    classes={{
      paper: classes.transactionHistoryDialog,
    }}
    onClose={() => onClose(false)}
  >
    <DialogTitle>Transaction History (ID: {transactionID} )</DialogTitle>
    <DialogContent style={{ overflow: "hidden" }}>
      <Card className={classes.transactionHistory}>
        <Table>
          <TableHead className={`${classes.tableHeaderRow} `}>
            <TableRow>
              <TableCell align="left" width="150px">
                Date
              </TableCell>
              <TableCell align="left" width="75px">
                Meter ID
              </TableCell>
              <TableCell align="left" width="50px">
                Status
              </TableCell>
              <TableCell align="left" width="100px">
                Processed By
              </TableCell>
              <TableCell align="left" width="100px">
                Created By
              </TableCell>
              <TableCell align="left" width="100px">
                Modified By
              </TableCell>
              <TableCell align="left">Error</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions?.map((item, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    {moment(item.modified || item.created || item.date).format(
                      "MM-DD-yy hh:mm:ss"
                    )}
                  </TableCell>
                  <TableCell>{item.meterID}</TableCell>
                  <TableCell>{item.processingStatus}</TableCell>
                  <TableCell>{users[item.processedByUserID] !== null ? users[item.processedByUserID] : '-'}</TableCell>
                  <TableCell>{users[item.createdByUserID] !== null ? users[item.createdByUserID] : '-'}</TableCell>
                  <TableCell>{users[item.modifiedByUserID] !== null ? users[item.modifiedByUserID] : '-'}</TableCell>
                  <TableCell>{item.processingError}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Card>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          onClose(false);
        }}
        color="secondary"
        variant="contained"
        style={{
          float: "right",
          marginRight: 10,
          marginBottom: 10,
        }}
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const TextFieldData = ({
  name,
  label,
  value,
  readonly,
  rows,
  multiline,
  disabled,
  onChange,
}) => (
  <TextField
    name={name}
    label={label}
    disabled={disabled}
    size="small"
    style={{
      width: "100%",
    }}
    rows={rows}
    multiline={multiline}
    fontSize="10"
    value={value}
    onChange={onChange}
    inputProps={{
      name: { name },
    }}
    InputLabelProps={{ shrink: true }}
    readonly={readonly}
  />
);

const MeterTransactionDetail = ({
  selectedTran,
  selectedIndex,
  transactionData,
  handleChange,
  handleEditorChange,
  setSelectedTransaction,
  postUpdateTransaction,
  transactionIsModified,
  processTransaction,
  openAssignMeter,
  searchESI,
  searchMeterIdentifier,
  searchMeterResult,
  searchMeters,
  updateState,
  isProcessing,
  openConfirmTransactionProcessing,
  assignMeterToTransaction,
  isTransactionManager,
  handelConfirmProcessTransaction,
  classes,
  users
}) => {
  console.log("selectedTran", selectedTran);
  const [showAllFields] = useState(false);
  const [reassignTransaction, setReassignTransaction] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [editorJsonData, setEditorJsonData] = useState({});
  const [processedTransaction, setProcessedTransaction] = useState(
    selectedTran.processingStatus
  );
  const getJsonData = (json) => {
    let copyJson = JSON.parse(JSON.stringify(json));
    if (!showAllFields) {
      Object.values(copyJson).forEach((o) =>
        Object.entries(o).forEach(([k, v]) => {
          if (v === "") {
            delete o[k];
          }
        })
      );
    }
    return copyJson;
  };

  useEffect(() => {
    setEditorJsonData(getJsonData(transactionData));
  }, [showAllFields]);

  //create hook to re-render when editordata is changed when showAllFields is toggled
  useEffect(() => { }, [editorJsonData]);
  return (
    <Dialog
      open={selectedIndex !== -1}
      contentlabel="Transaction Raw Data"
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"lg"}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle>
        Transaction Information:{" "}
        {selectedTran.direction === "I" ? "Inbound" : "Outbound"}
        {" - "}
        {selectedTran.transactionTypeName} {selectedTran.transactionSubType}
        {selectedTran.meterID === "0" ? " (unassigned) " : ""}
        <div className={classes.icons}>
          {isTransactionManager && selectedTran.meterID !== "0" && 
            <IconButton
              aria-label="Re-assign"
              size="large"
              title="Reassign transaction"
              classes={{ label: classes.iconButtonLabel }}
              color="secondary"
              onClick={() => {
                updateState({
                  openAssignMeter: true,
                  searchESI: editorJsonData.utility_account_number,
                }),
                setReassignTransaction(true)
              }}
            >
              <FindReplace fontSize="large" />
            </IconButton>
          }
          {selectedTran.meterID === "0" &&
            selectedTran.processingStatus !== "Yes" &&
            isTransactionManager && (
              <IconButton
                aria-label="Link Meter"
                size="large"
                title="Link Meter"
                classes={{ label: classes.iconButtonLabel }}
                color="primary"
                onClick={() => {
                  updateState({
                    openAssignMeter: true,
                    searchESI: editorJsonData.utility_account_number,
                  }),
                    searchMeters(editorJsonData.utility_account_number);
                }}
              >
                <LinkIcon fontSize="large" />
              </IconButton>
            )}
          {selectedTran.processingStatus !== "Yes" &&
            selectedTran.direction === "I" &&
            isTransactionManager && (
              <IconButton
                aria-label="process transaction"
                size="large"
                title="Process"
                classes={{ label: classes.iconButtonLabel }}
                color="primary"
                onClick={() => processTransaction(selectedTran.id)}
              >
                <PlayArrow fontSize="large" />
              </IconButton>
            )}
          <IconButton
            aria-label="View History"
            size="large"
            title="View History"
            style={{ border: "none" }}
            color="primary"
            onClick={() => {
              setShowTransactionHistory(!showTransactionHistory);
            }}
          >
            <History fontSize="large" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container className={classes.transactionBody}>
            <Grid item md={4}>
              <Card className={classes.transactionInfo}>
                <List>
                  <ListItem>
                    <TextFieldData
                      label="Transaction Date"
                      value={selectedTran.transactionDate}
                    />
                  </ListItem>
                  <ListItem>
                    <TextFieldData
                      label="Utility Account No"
                      value={selectedTran.utilityAccountNumber}
                    />
                  </ListItem>
                  <ListItem>
                    <TextFieldData
                      label="Type"
                      value={selectedTran.transactionTypeName}
                    />
                  </ListItem>
                  <ListItem>
                    <TextFieldData
                      label="Sub-Type"
                      value={selectedTran.transactionSubType}
                    />
                  </ListItem>
                  <ListItem>
                    <TextFieldData
                      label="Direction"
                      value={
                        selectedTran.direction === "I" ? "Inbound" : "Outbound"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      select
                      label="Processing Status"
                      size="small"
                      style={{
                        width: "100%",
                      }}
                      value={selectedTran.processingStatus}
                      onChange={handleChange}
                      inputProps={{
                        name: "processingStatus",
                      }}
                      disabled={
                        processedTransaction === "Yes" ||
                        processedTransaction === "Sent" ||
                        !isTransactionManager
                      }
                    >
                      {
                        !["No", "Hold"].includes(selectedTran.processingStatus) &&
                        <MenuItem
                          value={selectedTran.processingStatus}
                          disabled
                        >
                          {selectedTran.processingStatus}
                        </MenuItem>
                      }
                      <MenuItem value="No">No</MenuItem>
                      <MenuItem value="Hold">Hold</MenuItem>
                      {!["Closed"].includes(selectedTran.processingStatus) && <MenuItem value="Closed">Closed</MenuItem>}
                    </TextField>
                  </ListItem>
                  <ListItem>
                    <TextFieldData
                      label="Processing Error"
                      value={selectedTran.processingError}
                      type="string"
                      multiline
                      rows={3}
                      readonly
                    />
                  </ListItem>
                </List>
              </Card>
            </Grid>
            <Grid item md={8}>
              <Card className={classes.transactionData}>
                <Typography variant="subtitle1" gutterBottom>
                  Transaction Data
                </Typography>
                <JSONInput
                  id="transactionData"
                  placeholder={editorJsonData}
                  locale={locale}
                  height="429px"
                  width="100%"
                  viewOnly
                  reset
                  onChange={handleEditorChange}
                />
                <div style={{ flex: "1 0 0" }} />
                <Grid container style={{marginTop: '20px', marginBottom: '10px'}}>
                  {
                    users[selectedTran.createdByUserID] !== null && (
                      <Grid item md={4}>
                        <div className={classes.transactionProcess}>
                          <b style={{marginRight: '5px'}}>Created By:</b>
                          {users[selectedTran.createdByUserID]}
                        </div>
                      </Grid>
                    )
                  }
                  {
                    users[selectedTran.modifiedByUserID] !== null && (
                      <Grid item md={4}>
                        <div className={classes.transactionProcess}>
                          <b style={{marginRight: '5px'}}>Modified By:</b>
                          {users[selectedTran.modifiedByUserID]}
                        </div>
                      </Grid>
                    )
                  }
                  {
                    users[selectedTran.processedByUserID] !== null && (
                      <Grid item md={4}>
                        <div className={classes.transactionProcess}>
                          <b style={{marginRight: '5px'}}>Processed By:</b>
                          {users[selectedTran.processedByUserID]}
                        </div>
                      </Grid>
                    )
                  }
                </Grid>
              </Card>
            </Grid>
          </Grid>
          {showTransactionHistory && (
            <TransactionHistory
              transactions={[
                selectedTran,
                ...(selectedTran.transactionHistory || {}),
              ]}
              transactionID={selectedTran.id}
              classes={classes}
              onClose={setShowTransactionHistory}
              users={users}
            />
          )}
        </DialogContentText>
        {openAssignMeter && (
          <AssignMeterTransaction
            open={openAssignMeter}
            searchESI={searchESI}
            searchMeterIdentifier={searchMeterIdentifier}
            searchMeters={searchMeters}
            searchMeterResult={searchMeterResult}
            handleChange={updateState}
            handleAssignMeter={assignMeterToTransaction}
            classes={classes}
            isProcessing={isProcessing}
            reassignTransaction={reassignTransaction}
            meterID={selectedTran.meterID}

          />
        )}
        {openConfirmTransactionProcessing && (
          <ConfirmTransactionProcessing
            handleProcessTransaction={handelConfirmProcessTransaction}
            classes={classes}
            handleClose={() => {
              updateState({
                openConfirmTransactionProcessing: false,
              });
            }}
          />
        )}
        <Backdrop className={classes.backdrop} open={isProcessing}>
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={async () => {
            await postUpdateTransaction();
            await setProcessedTransaction(selectedTran.processingStatus);
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 10,
            marginBottom: 10,
          }}
          disabled={!transactionIsModified || !isTransactionManager}
        >
          Save
        </Button>
        <Button
          onClick={async () => {
            await setSelectedTransaction(-1);
          }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

MeterTransactionDetail.propTypes = {
  handleChange: PropTypes.func,
  handleEditorChange: PropTypes.func,
  processTransaction: PropTypes.func,
  selectedIndex: PropTypes.number,
  selectedTran: PropTypes.shape({
    direction: PropTypes.any,
    id: PropTypes.any,
    processingError: PropTypes.any,
    processingStatus: PropTypes.string,
    reason: PropTypes.any,
    transactionDate: PropTypes.any,
    transactionSubType: PropTypes.any,
    transactionTypeName: PropTypes.any,
  }),
  setSelectedTransaction: PropTypes.func,
  transactionData: PropTypes.any,
  transactionIsModified: PropTypes.any,
};

export default MeterTransactionDetail;
