import React, { useState } from "react";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import { noselect } from "../../styles/common.css";

import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "../Button"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import ManualAdjustment from "./ManualAdjustmentPanel";

const useStyles = makeStyles({
  transactionPanel: {
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "80px",
    border: "1px solid #ddd",
    "& h1": {
      fontSize: "19px",
      textDecoration: "underline",
      paddingBottom: "15px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        color: "#3f51b5",
        transition: "0.15s all linear",
        "&:hover": {
          transform: "scale(1.05)",
        },
      },
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "15px",
      color: "white",
      backgroundColor: "#333",
      whiteSpace: "pre",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
      border: "none",
    },
  },
});

const TransactionPanel = (props) => {
  const classes = useStyles();
  const {
    transactions,
    hasNext,
    page,
    authData,
    params,
    onNoteCreate,
    doApplyAdjustment,
    acceptedPairs,
    money,
    doRequestAllBillingTransactions,
  } = props;
  const [openPopup, setOpenPopup] = useState(false);

  const handlePagination = (memberId, page, hasNext) => {
    if (hasNext) {
      doRequestAllBillingTransactions(memberId, page + 1);
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <Card className={classes.transactionPanel}>
      <h1 id="panel-title">
        Payments &amp; Adjustments
        <Tooltip placement="left" title="Add Adjustment">
          <AddCircleOutlineIcon
            fontSize="large"
            onClick={() => setOpenPopup(true)}
          />
        </Tooltip>
      </h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "600px", overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead className={`${classes.tableHeaderRow} ${noselect}`}>
              <TableRow>
                <TableCell style={{ width: 250 }} align="left">
                  Created Date
                </TableCell>
                <TableCell style={{ width: 200 }} align="left">
                  Type
                </TableCell>
                <TableCell style={{ width: 200 }} align="left">
                  Sub Type
                </TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Ending Balance</TableCell>
                <TableCell align="left">User</TableCell>
                <TableCell style={{ widows: 300 }} align="left">
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.length > 0 ? (
                transactions.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {moment(item.created).format("MMMM Do YYYY, h:mm:ss a")}
                      </TableCell>
                      <TableCell>{item.type_description}</TableCell>
                      <TableCell>{item.subtype_description}</TableCell>
                      <TableCell>{money(item.amount)}</TableCell>
                      <TableCell>{money(item.ending_balance)}</TableCell>
                      <TableCell>{item.user_email}</TableCell>
                      <TableCell style={{ width: "300px" }}>
                        {item.notes}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={9}>No Records Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {hasNext && (
          <Button
            variant="contained"
            color="primary"
            style={{
              marginTop: "20px",
              width: "150px",
              alignSelf: "flex-end",
            }}
            onClick={async () => await handlePagination(params.memberId, page, hasNext)}
          >
            {"Load more"}
          </Button>
        )}
      </div>
      <Dialog
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <ManualAdjustment
          authData={authData}
          params={params}
          doApplyAdjustment={doApplyAdjustment}
          onNoteCreate={onNoteCreate}
          acceptedPairs={acceptedPairs}
          closeManualAdjustmentPopup={handleClose}
        />
      </Dialog>
    </Card>
  );
};

export default TransactionPanel;
