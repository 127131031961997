import React, { useState, useEffect, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Button"
import ReactTable from "react-table";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField";
import RefreshIcon from "@material-ui/icons/Refresh"
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Box from '@material-ui/core/Box';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as constants from "../../../constants";
import fetch from "../../util/api-ajax";

// Filter to remove some roles from all lists/dropdowns
const isAllowedRole = (role_id) => !role_id.includes('partner_') && role_id !== 'admin' && role_id !== 'csa_owner'

const ShowPassword = ({ openDialog, setOpenDialog, password }) => {
  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Reset password
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={12} style={{
              color: "black",
              textAlign: "center"
            }}>
              Password updated: {password}
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => { setOpenDialog(false) }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ResetPassword = ({ openDialog, setOpenDialog, user, setShowPassworDialog, setPassword }) => {
  const [disable, setDisable] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleResetPassword = async data => {
    setDisable(true)
    await fetch(constants.USERS_MANAGEMENT_RESET_PASSWORD, data)
      .then(async (res) => {
        if (res.error) {
          setErrorMessage(res.error)
          setDisable(false)
        } else {
          setPassword(res.password)
          setShowPassworDialog(true)
          setOpenDialog(false)
          setDisable(false)
        }
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setDisable(false)
      });
  }

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Reset password
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={12} style={{
              color: "black",
              textAlign: "center"
            }}>
              Are you sure you want to reset the password email of this user?
            </Grid>
            {
              errorMessage &&
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                <div style={{ color: "red" }}>{errorMessage}</div>
              </Grid>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await handleResetPassword({
              "email": user.email,
              "skip_notification": true,
              "return_password": true
            })
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginBottom: 10,
          }}
          disabled={disable}
        >
          Confirm
        </Button>
        <Button
          onClick={async () => { setOpenDialog(false) }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const EditRoles = ({ openDialog, setOpenDialog, user, roles, getUsers }) => {
  const [selectedRoles, setSelectedRoles] = useState([])
  const [incognitoRoles, setIncognitoRoles] = useState([])
  const [disable, setDisable] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [roleList, setRoleList] = useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    setIncognitoRoles(user.roles ? user.roles.split(';').filter(x => x.includes('partner_')) : [])
    setSelectedRoles(user.roles ? user.roles.split(';').filter(x => isAllowedRole(x)) : []);
  }, [user])

  useEffect(() => {
    setRoleList(roles.filter((role) => !selectedRoles.includes(role.role_id) && isAllowedRole(role.role_id)));
  }, [roles, selectedRoles])

  const handleDelete = (value, event) => {
    event.stopPropagation();
    setSelectedRoles((prevSelectedRoles) => prevSelectedRoles.filter((role) => role !== value));
  };

  const validateRoles = roles => {
    if (roles.includes('read_only_admin')) {
      return roles.filter(x => x !== 'admin').concat(incognitoRoles)
    } else {
      const newList = roles.filter(x => x !== 'admin');
      newList.push('admin')
      return newList.concat(incognitoRoles)
    }
  }

  const handleEditRoles = async data => {
    if (selectedRoles.length > 0) {
      setDisable(true)
      await fetch(constants.USERS_MANAGEMENT_UPDATE_ROLES, data)
        .then(async (res) => {
          if (res.error) {
            setErrorMessage(res.error)
            setDisable(false)
          } else {
            setOpenDialog(false)
            setDisable(false)
            await getUsers()
          }
        })
        .catch((error) => {
          setErrorMessage(error.message)
          setDisable(false)
        });
    } else {
      setErrorMessage("Please select at least one role")
    }
  }

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Edit roles
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={12}>
              <label>Roles</label>
              <Select
                multiple
                value={selectedRoles}
                onChange={handleChange}
                input={<OutlinedInput label="Chip" />}
                style={{ width: "100%" }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        style={{ marginRight: "3px" }}
                        key={value}
                        label={value}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={(event) => handleDelete(value, event)}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                }}
              >
                {roleList.map(z => z.role_id).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {
              errorMessage &&
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                <div style={{ color: "red" }}>{errorMessage}</div>
              </Grid>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await handleEditRoles({
              "user_id": user.id,
              "roles": validateRoles(selectedRoles)
            })
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginBottom: 10,
          }}
          disabled={!(selectedRoles.filter(x => x !== 'admin').length > 0) || disable}
        >
          Save
        </Button>
        <Button
          onClick={async () => { setOpenDialog(false) }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const RemoveUser = ({ openDialog, setOpenDialog, user, getUsers }) => {
  const [disable, setDisable] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const handleResetPassword = async data => {
    setDisable(true)
    await fetch(constants.USERS_MANAGEMENT_UPDATE_ROLES, data)
      .then(async (res) => {
        if (res.error) {
          setErrorMessage(res.error)
          setDisable(false)
        } else {
          setOpenDialog(false)
          setDisable(false)
          await getUsers()
        }
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setDisable(false)
      });
  }

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Remove user
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={12} style={{
              color: "black",
              textAlign: "center"
            }}>
              Are you sure you want to remove the roles of this user?
            </Grid>
            {
              errorMessage &&
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                <div style={{ color: "red" }}>{errorMessage}</div>
              </Grid>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await handleResetPassword({
              "user_id": user.id,
              "roles": []
            })
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginBottom: 10,
          }}
          disabled={disable}
        >
          Confirm
        </Button>
        <Button
          onClick={async () => { setOpenDialog(false) }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CreateUser = ({ openDialog, setOpenDialog, getUsers, roles }) => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [disable, setDisable] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roleList, setRoleList] = useState([])

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const validateRoles = roles => {
    if (roles.includes('read_only_admin')) {
      return roles.filter(x => x !== 'admin')
    } else {
      const newList = roles.filter(x => x !== 'admin');
      newList.push('admin')
      return newList
    }
  }

  const handleCreateUser = async data => {
    setDisable(true)
    await fetch(constants.USERS_MANAGEMENT_CREATE_USER, data)
      .then(async (res) => {
        if (res.error) {
          setErrorMessage(res.error)
          setDisable(false)
        } else {
          // Add roles
          await fetch(constants.USERS_MANAGEMENT_UPDATE_ROLES, {
            "user_id": res.userID,
            "roles": validateRoles(selectedRoles)
          })
            .then(async (res) => {
              if (res.error) {
                setErrorMessage(res.error)
                setDisable(false)
              } else {
                // Add roles
                setOpenDialog(false)
                setDisable(false)
                await getUsers()
              }
            })
            .catch((error) => {
              setErrorMessage(error.message)
              setDisable(false)
            });
        }
      })
      .catch((error) => {
        setErrorMessage(error.message)
        setDisable(false)
      });
  }

  useEffect(() => {
    setErrorMessage("")
    setFirstName("")
    setLastName("")
    setEmail("")
    setPassword("")
    setSelectedRoles([])
  }, [])

  useEffect(() => {
    setRoleList(roles.filter((role) => !selectedRoles.includes(role.role_id) && isAllowedRole(role.role_id)));
  }, [roles, selectedRoles])

  const handleDelete = (value, event) => {
    event.stopPropagation();
    setSelectedRoles((prevSelectedRoles) => prevSelectedRoles.filter((role) => role !== value));
  };

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Create user
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={12}>
              <TextField
                label="First Name"
                type="text"
                size="small"
                color="primary"
                width="100%"
                value={firstName}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setFirstName(e.target.value)}
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12} style={{ marginTop: "20px" }}>
              <TextField
                label="Last Name"
                type="text"
                size="small"
                color="primary"
                width="100%"
                value={lastName}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setLastName(e.target.value)}
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12} style={{ marginTop: "20px" }}>
              <TextField
                label="Email"
                type="email"
                size="small"
                color="primary"
                width="100%"
                value={email}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={12} style={{ marginTop: "20px" }}>
              <TextField
                label="Password"
                type="password"
                size="small"
                color="primary"
                width="100%"
                value={password}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: "100%" }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogTitle style={{ paddingTop: '0px', paddingBottom: '5px' }}>
        Add roles
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item sm={12}>
              <Select
                multiple
                value={selectedRoles}
                onChange={handleChange}
                input={<OutlinedInput label="Chip" />}
                style={{ width: "100%" }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        onDelete={(event) => handleDelete(value, event)}
                        style={{ marginRight: "3px" }}
                        key={value}
                        label={value}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                }}
              >
                {roleList.map(z => z.role_id).map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {
              errorMessage &&
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                <div style={{ color: "red" }}>{errorMessage}</div>
              </Grid>
            }
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await handleCreateUser({
              "email": email,
              "password": password,
              "first_name": firstName,
              "last_name": lastName,
              "agreed": true,
              "billing_address": "{}"
            })
          }}
          color="secondary"
          variant="contained"
          style={{
            float: "right",
            marginBottom: 10,
          }}
          disabled={!(firstName && lastName && email && password && selectedRoles.length > 0) || disable}
        >
          Create
        </Button>
        <Button
          onClick={async () => { setOpenDialog(false) }}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const UserManagement = props => {
  const {
    users,
    payload,
    roles,
    doFetchGetUsers,
    doFetchGetRoles,
    createUser,
  } = props
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedPreview, setSelectedPreview] = useState(payload);
  const [search, setSearch] = useState("");
  const [password, setPassword] = useState("");
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [editUserDialog, setEditUserDialog] = useState(false);
  const [removeUserDialog, setRemoveUserDialog] = useState(false);
  const [showPasswordDialog, setShowPassworDialog] = useState(false);
  const [roleList, setRoleList] = useState([])

  const shouldDisplay = (u) => {
    // ignore any users that are only csa owners (non-admins)
    return u.roles.split(";").filter(z => z !== 'csa_owner').length > 0
  }
  const filteredUsers = users ? users.filter(shouldDisplay) : []

  useEffect(() => {
    setSelectedPreview(payload)
  }, [payload, selectedPreview])

  useEffect(() => {
    doFetchGetUsers({
      search: "",
    });
    doFetchGetRoles();
  }, [])

  useEffect(() => {
    if (roles) {
      setRoleList(roles)
    }
  }, [roles])

  const handleFetchUsers = async () => {
    await doFetchGetUsers({
      search: search,
    });
  }

  const columns = [
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => (<div style={{ textAlign: "center" }}>{value}</div>)
    },
    {
      Header: "First Name",
      accessor: "first_name",
      Cell: ({ value }) => (<div style={{ textAlign: "center" }}>{value}</div>)
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      Cell: ({ value }) => (<div style={{ textAlign: "center" }}>{value}</div>)
    },
    {
      Header: "Roles",
      accessor: "roles",
      Cell: ({ value }) => (<div style={{ textAlign: "center" }}>
        {value.split(";").filter(z => z.length > 0 && isAllowedRole(z)).map((z, i) => <Chip style={{ marginRight: "3px" }} label={z} key={i} />)}
      </div>)
    },
    {
      Header: "",
      accessor: "id",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              marginRight: "2px",
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "15px",
              minHeight: "15px",
            }}
            onClick={async () => {
              await setSelectedUser(row.original);
              await setResetPasswordDialog(true);
            }}
            variant="contained"
            color="primary"
            size="small"
            title="Reset password"
          >
            <RefreshIcon />
          </Button>
          <Button
            style={{
              marginRight: "2px",
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "15px",
              minHeight: "15px",
            }}
            onClick={async () => {
              await setSelectedUser(row.original);
              await setEditUserDialog(true);
            }}
            variant="contained"
            color="secondary"
            size="small"
            title="Edit roles"
          >
            <EditIcon />
          </Button>
          <Button
            style={{
              marginRight: "2px",
              maxWidth: "30px",
              maxHeight: "30px",
              minWidth: "15px",
              minHeight: "15px",
            }}
            onClick={async () => {
              await setSelectedUser(row.original);
              await setRemoveUserDialog(true);
            }}
            variant="contained"
            color="default"
            size="small"
            title="Remove user"
          >
            <CloseIcon />
          </Button>
        </div>
      )
    }
  ]

  return (
    <div>
      <Grid container>
        <Grid item sm={6}>
          <h4>User Management</h4>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <TextField
            label="Search"
            type="text"
            size="small"
            color="primary"
            width="100%"
            InputLabelProps={{
              shrink: true,
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: "100%" }}
            variant="outlined"
          />
        </Grid>
        <Grid item sm={3}>
          <Button
            onClick={async () => {
              await handleFetchUsers();
            }}
            color="primary"
            variant="contained"
            style={{ float: "left", marginBottom: "5px" }}
          >
            Search
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            onClick={async () => {
              await setCreateUserDialog(true)
            }}
            color="secondary"
            variant="contained"
            style={{ float: "right", marginBottom: "5px" }}
          >
            Create user
          </Button>
        </Grid>
      </Grid>
      <CreateUser
        openDialog={createUserDialog}
        setOpenDialog={setCreateUserDialog}
        createUser={createUser}
        getUsers={doFetchGetUsers}
        roles={roleList}
      />
      <ResetPassword
        openDialog={resetPasswordDialog}
        setOpenDialog={setResetPasswordDialog}
        user={selectedUser}
        getUsers={doFetchGetUsers}
        setShowPassworDialog={setShowPassworDialog}
        setPassword={setPassword}
      />
      <EditRoles
        openDialog={editUserDialog}
        setOpenDialog={setEditUserDialog}
        user={selectedUser}
        roles={roleList}
        getUsers={handleFetchUsers}
      />
      <RemoveUser
        openDialog={removeUserDialog}
        setOpenDialog={setRemoveUserDialog}
        user={selectedUser}
        getUsers={doFetchGetUsers}
      />
      <ShowPassword
        openDialog={showPasswordDialog}
        setOpenDialog={setShowPassworDialog}
        password={password}
      />
      <br />
      {
        filteredUsers && filteredUsers.length > 0 ?
          <Fragment>
            <ReactTable
              data={filteredUsers}
              columns={columns}
              className="-striped -highlight"
              defaultPageSize={10}
              defaultSortDesc
              filterable
            />
          </Fragment> :
          <div>
            There are no users to show
          </div>
      }
    </div>
  )
}

export default UserManagement