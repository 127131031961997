import React from "react";
import MaskedInput from "react-text-mask";

const phoneMask = ["+", /[1-9]/, " ", "(", /\d/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
export function PhoneNumberMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null) }}
      mask={phoneMask}
      placeholderChar={"\u2000"}
      guide={false}
    />
  );
}

export const phoneUnmaskedValue = (maskedPhone) => {
  return maskedPhone.replace(/\D/g, "");
}
