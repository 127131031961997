import React, { useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table";

const NetMeteringBank = props => {
  const { doGetNetMeteringBank, params, guest, netMeteringBank } = props;
  const memberId = params.guestUserId ? guest.get("memberID") : params.memberId;

  useEffect(() => {
    if (memberId) {
      doGetNetMeteringBank(memberId);
    }
  }, [memberId]);

  const columns = [
    {
      Header: "ID",
      accessor: "id",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: "Amount KWH",
      accessor: "amount_kwh",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value} kW/h</div>,
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {moment
            .tz(row.value, "America/Chicago")
            .format("MMM DD, YYYY, h:mm a")}
        </div>
      ),
    },
  ];

  return (
    <div>
      {
        netMeteringBank &&
        <h6>
          <b>Net Metering Bank Balance (kWh): </b>
          <>{netMeteringBank.reduce((val, a) => val + (a.amount_kwh ? a.amount_kwh : 0), 0)}</>
        </h6>
      }
      <ReactTable
        data={netMeteringBank}
        columns={columns}
        style={{ height: "600px", marginTop: 15 }}
        className="-striped -highlight"
        filterable
        noDataText=""
        defaultPageSize={100}
      />
    </div>
  );
}

export default NetMeteringBank