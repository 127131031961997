import * as constants from "../../constants";
import fetch from "../util/api-ajax";

const createErrorMessage = (errorMessage) => ({
  type: constants.TAX_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.TAX_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

const setTaxExcemptions = (payload) => {
  return {
    type: constants.TAX_EXCEMPTION_SUCCESS,
    payload,
  };
};

export const requestTaxExcemptions = () => (dispatch, getState) => {  
  return fetch(constants.TAX_EXCEMPTIONS)
    .then((res) => {
      dispatch(setTaxExcemptions(res));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
    });
};

export const hideTaxSnackBar = () => ({
  type: constants.TAX_HIDE_SNACKBAR,
});
