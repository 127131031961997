import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../Button"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControlLabel } from "@material-ui/core/";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/core/styles";
import styled from 'styled-components'
import { PAYMENT_TYPE, ACCOUNT_TYPE } from '../../../../constants'
import  selectAccountFlagsContainer  from "../../../selectors/accountFlags";
import {
  RootContainer,
  Container,
  Header,
  OptionsContainer,
  CreditInformationForm,
  TwoColumnsContainer,
  TextFieldInput,
  ButtonInput,
  MakePaymentContainer,
  PaymentMethodHeader,
  SaveCardContainer,
} from "./OneTimeCardPayment.style";
import './icheck-style.css';

const PaymentButtonContainer = styled.div`
  margin-left: 25px;  
  margin-top: 25px;
`

const styles = (theme) => ({
  backdropStyle: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  header: {
    "& a:hover": {
      transition: "all 0.3s linear",
      backgroundColor: "#eee",
      borderRadius: "10px",
    },
    "& button:hover": {
      transition: "all 0.3s linear",
      backgroundColor: "#aaa",
      borderRadius: "10px",
      color: "white",
    },
  },
});

const PaymentMethodSelector = ({ isCCSelected, setIsCCSelected, disableACHPayment }) => {  
  return (
    <PaymentButtonContainer>    
      <RadioGroup        
        defaultValue={isCCSelected}
        name="paymentType"
        onChange={(e) => {            
          setIsCCSelected(e.target.value === "true")            
        }}
        value={isCCSelected}
        row
      >
        <FormControlLabel
          value={true}
          control={<Radio inline/>}
          label="Credit Card"
        />
        <FormControlLabel
          value={false}
          control={<Radio inline/>}
          label="Bank Account"
          disabled={disableACHPayment}
        />
      </RadioGroup>        
    </PaymentButtonContainer>
    
  )
}

const OneTimeCardPaymentICheck = ({
  userID,
  memberID,
  market,
  open,
  onClose,
  onMakeOneTimePayment,
  onFetchMemberAccountBalance,  
  selectedAccountFlags,
  classes,
}) => {  
  const [amount, setAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [savePaymentCard, setSavePaymentCard] = useState(false);
  const [savePaymentCardAsDefault, setSavePaymentCardAsDefault] = useState(true);
  const [isCCSelected, setIsCCSelected] = useState(true)
  const [accountType, setAccountType] = useState(ACCOUNT_TYPE.checking)
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");  
  const [form, setVGSForm] = useState({});
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [isVGSFormLoaded, setIsVGSFormLoaded] = useState(false);
  const disableACHPayment = selectedAccountFlags.some(
    (f) => f.flag_type === "DISABLE_ACH"
  );
  if (disableACHPayment && !isCCSelected) {
    setIsCCSelected(true);
  }

  // script loading
  useEffect(() => {
    const script = document.createElement("script");
    script.src = 'https://js.verygoodvault.com/vgs-collect/2.20.2/vgs-collect.js';
    script.async = true;
    script.onload = () => setTimeout(()=>setScriptLoaded(true),5000);
    document.body.appendChild(script);    
  },[]);

  // VGS Collect initialization
  useEffect(() => {    
    if(!isScriptLoaded){
      setIsVGSFormLoaded(false)        
    }
    if (isScriptLoaded && open && isCCSelected && !isVGSFormLoaded ) {      
      try{
        const vgsForm = window.VGSCollect.create(
          process.env.REACT_APP_VGS_VAULT_ID,
          process.env.REACT_APP_VGS_ENVIRONMENT,
          () => { })
        setVGSForm(vgsForm)
        const css = {
          boxSizing: 'border-box',
          fontSize: '1.6rem',
          color: '#000000',
          '&::placeholder': {
            color: '#bcbcbc'
          }
        };
  
        const firstName = vgsForm.field('#cc-firstname', {
          type: 'text',
          name: 'firstName',
          placeholder: '',
          validations: ['required'],
          css: css,
        });
  
        const lastName = vgsForm.field('#cc-lastname', {
          type: 'text',
          name: 'lastName',
          placeholder: '',
          validations: ['required'],
          css: css,
        });
  
        const cardNumber = vgsForm.field('#cc-number', {
          type: 'card-number',
          name: 'cardNumber',
          placeholder: '____ ____ ____ ____',
          showCardIcon: true,
          validations: ['required', 'validCardNumber'],
          css: css,
        });
  
        const cvc = vgsForm.field('#cc-cvc', {
          type: 'card-security-code',
          name: 'cvc',
          placeholder: '123',
          showCardIcon: true,
          validations: ['required', 'validCardSecurityCode'],
          css: css,
        });
  
        const expiry = vgsForm.field('#cc-expiration-date', {
          type: 'card-expiration-date',
          name: 'expiration',
          placeholder: 'MM / YY',
          validations: ['required', 'validCardExpirationDate'],
          css: css,
        });
  
        const zip = vgsForm.field('#cc-zip', {
          type: 'text',
          name: 'zip',
          placeholder: '',
          validations: ['required'],
          css: css,
        });        
        Promise.all([firstName.promise, lastName.promise, cardNumber.promise, cvc.promise, expiry.promise, zip.promise]).then(function () {
          setIsVGSFormLoaded(Boolean(vgsForm.formId))          
        })
      } catch (error) {   
        setIsVGSFormLoaded(false)        
      }      
    }
  }, [isScriptLoaded, open, isCCSelected, isVGSFormLoaded]);

  useEffect(() => {
    if (!isCCSelected ) {
      setVGSForm({})
      setIsVGSFormLoaded(false)
    }
  }, [isCCSelected]);


  const callVGS = async (form) => new Promise((resolve) => form.submit('/billing/echo_vgs_data', {}, (res, data) => {    
    resolve({ res, data })
  }, (validationError) => {
    resolve({ validationError })
  }));

  const getCardType = ({ state }) => {
    const mapping = {
      'amex': 'Amex',
      'discover': 'Discover',
      'mastercard': 'MasterCard',
      'visa': 'Visa',
      'jcb': 'JCB',
    }
    return mapping[state.cardNumber.cardType]
  
  }
  
  
  const clearForm = () =>{
    setAmount("");
    setFirstName("");
    setLastName("");
    setCompanyName("");
    setZipCode("");
    setVGSForm({})
    setIsVGSFormLoaded(false)
    setIsCCSelected(false)
  }

  const  processPayment = async () => {    
    const timestamp = new Date().getTime();
    const reqBody = {
      memberID,
      firstName,
      lastName,
      companyName,
      market,
      order_id: `MP${userID}_${timestamp}`,
      zip: zipCode,
      amount: amount || "0",
      is_default: savePaymentCardAsDefault,
      skip_saving_profile: !savePaymentCard,
      credit_card_info: {},
    };    
    
    if(isCCSelected){
      const { data, validationError } = await callVGS( form, '/post')
  
      if (validationError) {
        return
      }      

      const { firstName, lastName, cardNumber, cvc, zip, expiration } = data
      // update payload with tokenized data
      reqBody.firstName = firstName
      reqBody.lastName = lastName
      reqBody.zip = zip
      reqBody.credit_card_info.cardNumber = cardNumber
      reqBody.credit_card_info.cvc = cvc
      const exp = expiration.split('/')

      reqBody.payment_type = getCardType(form)
      reqBody.credit_card_info.expiration_month = exp[0].trim()
      reqBody.credit_card_info.expiration_year = exp[1].trim()
    }else{
      reqBody.payment_type = PAYMENT_TYPE.ECP
      reqBody.bank_info = {
        account_type: accountType,
        account_number: accountNumber,
        routing_number: routingNumber,
      }
    }
    
    onClose();
    onMakeOneTimePayment(memberID, "amount", amount, reqBody).then(() => {
      clearForm();      
      onFetchMemberAccountBalance(memberID);
    });
     
  };  
  
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="on time payment dialog"
      open={open}
    >      
      <RootContainer>
        <Backdrop className={classes.backdropStyle} open={isCCSelected && (!isScriptLoaded || !isVGSFormLoaded)}>
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
        <Container variant="outlined">
          <Header>
            <span>One Time Payment</span>
            <CloseIcon onClick={() => onClose()} />
          </Header>
          <input type="hidden" value="_" />
          <MakePaymentContainer>            
            <h4>Make Payments</h4>
            <TextFieldInput
              label={`Amount`}
              onChange={(e) => setAmount(e.target.value)}
              value={amount}
              type="money"
              required
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
            />            
            </MakePaymentContainer>
          <PaymentMethodSelector
            isCCSelected={isCCSelected}
            setIsCCSelected={setIsCCSelected}
            disableACHPayment={disableACHPayment}
          />
          { isCCSelected && <CreditInformationForm autoComplete="off">
            
            <PaymentMethodHeader>Credit Card Information</PaymentMethodHeader>
            <form id="vgs-collect-form">
                  <label>First Name</label>
                  <div id="cc-firstname" class="form-field"></div>
                  <label>Last Name</label>
                  <div id="cc-lastname" class="form-field"></div>
                  <label>Card Number</label>
                  <div id="cc-number" class="form-field"></div>
                  <div class="form-field-group">
                    <div>
                      <label>CVC</label>
                      <div id="cc-cvc" class="form-field"></div>
                    </div>
                    <div>
                      <label>Expiry Date</label>
                      <div id="cc-expiration-date" class="form-field"></div>
                    </div>
                  </div>
                  <label>Zip</label>
                  <div id="cc-zip" class="form-field"></div>
                </form>
          </CreditInformationForm>
          }
          { !isCCSelected && <CreditInformationForm>
            <PaymentMethodHeader>Bank Account Information</PaymentMethodHeader>
            <TextFieldInput
                select
                label={'Account Type'}
                name="accountType"
                type="tel"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                guide={false}
                autoComplete="postal-code"
                defaultValue={ACCOUNT_TYPE.checking}
                onChange={(e) => setAccountType(e.target.value)}
              >
                <MenuItem value={ACCOUNT_TYPE.checking} >
                  {ACCOUNT_TYPE.checking}
                </MenuItem>
                <MenuItem value={ACCOUNT_TYPE.savings} >
                  {ACCOUNT_TYPE.savings}
                </MenuItem>
                <MenuItem value={ACCOUNT_TYPE.commercialChecking} >
                  {ACCOUNT_TYPE.commercialChecking}
                </MenuItem>
              </TextFieldInput> 

              { accountType == ACCOUNT_TYPE.commercialChecking ?
            
              <TextFieldInput
                  label={'Company Name'}
                  name="companyName"
                  autoComplete="companyName"
                  maxLength={30}            
                  onChange={(e) => setCompanyName(e.target.value)}    
                  InputLabelProps={{
                    shrink: true,
                  }}
                /> :
                <>
                  <TextFieldInput
                      label={'First Name'}
                      name="firstName"
                      autoComplete="firstName"
                      maxLength={30}            
                      onChange={(e) => setFirstName(e.target.value)}    
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    <TextFieldInput
                      label={'Last Name'}
                      name="lastName"
                      autoComplete="lastName"
                      maxLength={30}            
                      onChange={(e) => setLastName(e.target.value)}    
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                </>
              }

              <TextFieldInput
                label={'Account Number'}
                name="accountNumber"
                autoComplete="accountNumber"
                type="tel"
                maxLength={17}
                onChange={(e) => setAccountNumber(e.target.value)}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                InputLabelProps={{
                  shrink: true,
                }}
              />              
             <TwoColumnsContainer>
              <TextFieldInput
                label={'Routing Number'}
                name="routingNumber"
                type="tel"
                autoComplete="routingNumber"
                maxLength={9}
                onChange={(e) => setRoutingNumber(e.target.value)}
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                guide={false}
                InputLabelProps={{
                  shrink: true,
                }}
                // prettier-ignore
              />                                                                 

              <TextFieldInput
                label={'Zip Code'}
                name="zipcode"
                type="tel"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                onChange={(e) => setZipCode(e.target.value)}
                guide={false}
                autoComplete="postal-code"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </TwoColumnsContainer>
            </CreditInformationForm>
          }  
                  
            <SaveCardContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={savePaymentCard}
                    onChange={(e) => setSavePaymentCard(e.target.checked)}                  
                  />
                }
                label={`Save payment card`} 
              />
            <FormControlLabel
              control={
                <Checkbox
                  checked={savePaymentCard && savePaymentCardAsDefault}
                  onChange={(e) => setSavePaymentCardAsDefault(e.target.checked)}    
                  disabled={!savePaymentCard}              
                />
              }
              label={`Make default payment card`} 
              style={{ marginTop: "15px" }}
            />
            </SaveCardContainer>
          <OptionsContainer>
            <Button color="secondary" varian="raised" onClick={() => onClose()}>
              Cancel
            </Button>
            <ButtonInput
              disabled={isCCSelected && !isVGSFormLoaded}
              color="primary"
              varian="raised"
              onClick={async () => await processPayment()}
            >
              Process
            </ButtonInput>
          </OptionsContainer>
        </Container>
      </RootContainer>
    </Dialog>
  );
};

const mapStateToProps = selectAccountFlagsContainer();

const ConnectedOneTimeCardPaymentICheck = connect(
    mapStateToProps,
    {},
)(OneTimeCardPaymentICheck);

export default withStyles(styles)(ConnectedOneTimeCardPaymentICheck);