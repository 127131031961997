import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button"
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import { noselect } from "../../styles/common.css";

import { updateAccount } from "../../actions/members";

const useStyles = makeStyles({
  root: {
    width: "300px",
  },
  adjustmentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const UpdateBillingAddress = (props) => {
  const classes = useStyles();
  const [commentFocus, setCommentFocus] = useState(false);

  const { member, onAccountUpdate, closePopup } = props;
  const [accountID, setAccountID] = useState({});
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [postal_code, setPostalCode] = useState("");

  const [openPopup, setOpenPopup] = useState(false);
  const [formError, setFormError] = useState(false);

  useEffect(() => {
    if (member.get("billing_address")) {
      try {
        const tmp = member.get("billing_address");        
        tmp.get("line1") && setLine1(tmp.get("line1"));
        tmp.get("line2") && setLine2(tmp.get("line2"));
        tmp.get("city") && setCity(tmp.get("city"));
        tmp.get("state") && setState(tmp.get("state"));
        tmp.get("country") && setCountry(tmp.get("country"));
        tmp.get("postal_code") && setPostalCode(tmp.get("postal_code"));
      } catch (err) {
        setBillingAddress({});
      }
    }
    if (member.get("accountID")) {
      setAccountID(member.get("accountID"));
      
    }
  }, [member]);
  useEffect(() => {
    if (openPopup) {
      if (line1 || line2 || city || state || country || postal_code !== "") {
        setFormError(false);
      } else {
        setFormError(true);
      }
    }
  }, [openPopup]);

  const handleChange = (e) => {
    // e.target.value
  };
  const clearForm = () => {
    setLine1("");
    setCity("");
    setState("");
    setCountry("");
    setPostalCode("");
  };
  const submitForm = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = () => {
    if (!formError) {
      // Construt json string to update
      const tmp = {};
      tmp.line1 = line1;
      tmp.line2 = line2;
      tmp.city = city;
      tmp.state = state;
      tmp.country = country;
      tmp.postal_code = postal_code;
      const billingAddress = {
        billing_address: tmp,
      };
      onAccountUpdate(accountID, "billing_address", billingAddress);
      setOpenPopup(false);
      closePopup(false);
      clearForm();
    }
  };
  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Update Billing Address</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <div className={classes.adjustmentForm}>
          <TextField
            label="Address"
            id="address"
            onChange={(e) => setLine1(e.target.value)}
            value={line1}
            type="text"
          />
          <TextField
            label="APT #"
            id="address-line-2"
            onChange={(e) => setLine2(e.target.value)}
            value={line2}
            type="text"
          />
          <TextField
            label="City"
            id="city"
            onChange={(e) => setCity(e.target.value)}
            value={city}
            type="text"
          />
          <TextField
            label="State"
            id="state"
            onChange={(e) => setState(e.target.value)}
            value={state}
            type="text"
          />
          <TextField
            label="Country"
            id="country"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            type="text"
          />
          <TextField
            label="Zipcode"
            id="zipcode"
            onChange={(e) => setPostalCode(e.target.value)}
            value={postal_code}
            type="text"
          />
        </div>
        <div
          className={classes.optionContainer}
          style={{
            borderTop: commentFocus ? "2px solid #3f51b5" : "2px solid #ddd",
          }}
        >
          <Button color="secondary" varian="raised" onClick={() => clearForm()}>
            Clear
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async() => await submitForm()}
          >
            Apply
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {formError && (
            <WarningIcon style={{ color: "gold", marginRight: "10px" }} />
          )}
          {!formError ? (
            <span>Please Confirm Transaction</span>
          ) : (
            <span>Warning</span>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.contentFullView}>
            {!formError ? (
              <div>{"Add update success message here or something"}</div>
            ) : (
              <div>
                Please complete atleast one field to update the billing
                address.
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="secondary">
            Close
          </Button>
          <Button
            className={
              !formError ? classes.enableButton : classes.disableButton
            }
            autoFocus
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onAccountUpdate: (accountID, key, updateDated) =>
    dispatch(updateAccount(accountID, key, updateDated)),
  dispatch,
});

const ConnectedUpdateBillingAddress = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateBillingAddress);

export default ConnectedUpdateBillingAddress;
