import React, { useState, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import { FILE_CATEGORIES } from "./UploadFileDialog";
import Button from "../../Button"

function UpdateFileDialog(props) {
  const { onClose, open, setOpen, file, doUpdateFile } = props;
  const [documentType, setDocumentType] = useState(null)

  function handleClose() {
    setOpen(false);
    onClose();
  }

  function handleUpdateFile() {
    doUpdateFile({
      id: file.fileID,
      document_type: documentType,
    });
    handleClose();
  }

  useEffect(() => {
    if (documentType === null && file.file_category !== null) {
      setDocumentType(file.file_category.toLowerCase());
    }
  }, [file]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Update Document</DialogTitle>
      {file !== undefined && <div style={{ paddingLeft: '24px' }}>{file.file_name}</div>}
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <List>
            <ListItem style={{ width: "400px", padding: "0" }}>
              <TextField
                select
                label="Document Type"
                size="small"
                style={{ width: "100%" }}
                value={documentType}
                onChange={(e) => setDocumentType(e.target.value)}
              >
                {Object.entries(FILE_CATEGORIES).map(([key, value]) => { return <MenuItem key={key} value={key}>{value}</MenuItem> })}
              </TextField>
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdateFile}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateFileDialog;
