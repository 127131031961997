import { createSelector } from "reselect";

const jobNotifications = () => (state) => state.jobNotifications;

const selectNotificationsContainer = () =>
  createSelector(jobNotifications(), (notificationsData) => {
    return notificationsData;
  });

export default selectNotificationsContainer;
