import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "./styles"

const MonthlyUsage = props => {
  const classes = useStyles();
  const [filter, setFilter] = useState("is_not_hu");
  const [data, setData] = useState([]);
  const { doGetMonthlyUsage, params, guest, monthlyUsage } = props;
  const memberId = params.guestUserId ? guest.get("memberID") : params.memberId;

  const onSelectChange = e => {
    setFilter(e.target.value)
  }

  useEffect(() => {
    if (memberId) {
      doGetMonthlyUsage(memberId);
    }
  }, [memberId]);

  useEffect(() => {
    if (monthlyUsage) {
      if (filter === "is_not_hu")
        setData(monthlyUsage.filter(x => x.is_hu === false));
      else
        setData(monthlyUsage.filter(x => x.is_hu === true));
    }
  }, [monthlyUsage, filter]);

  const columns = [
    {
      Header: "Read Date",
      accessor: "read_date",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {moment
            .tz(row.value, "America/Chicago")
            .format("MM/DD/YYYY h:mm")}
        </div>
      ),
    },
    {
      Header: "SPSD",
      accessor: "spsd",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {moment
            .tz(row.value, "America/Chicago")
            .format("MM/DD/YYYY h:mm")}
        </div>
      ),
    },
    {
      Header: "SPED",
      accessor: "sped",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {moment
            .tz(row.value, "America/Chicago")
            .format("MM/DD/YYYY h:mm")}
        </div>
      ),
    },
    {
      Header: "Total KWH",
      accessor: "total_kwh",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value} kW/h</div>,
    },
    {
      Header: "Total Cost",
      accessor: "total_cost",
      Cell: (row) => <div style={{ textAlign: "center" }}>${row.value}</div>,
    },
    {
      Header: "Usage Purpose",
      accessor: "usage_purpose",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: "Is Final",
      accessor: "is_final",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value ? 'Yes' : 'No'}</div>,
    },
    {
      Header: "Sender Transaction ID",
      accessor: "sender_transaction_id",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
    },
    {
      Header: "Is HU",
      accessor: "is_hu",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value ? 'Yes' : 'No'}</div>,
    },
  ];

  return (
    <div>
      <div className={classes.inputSelector}>
        <span>Select is HU</span>
        <Select
          value={filter}
          onChange={onSelectChange}
          inputProps={{ name: "Interval" }}
          style={{
            width: '200px',
          }}
        >
          <MenuItem value={"is_not_hu"}>No</MenuItem>
          <MenuItem value={"is_hu"}>Yes</MenuItem>
        </Select>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        style={{ height: "600px", marginTop: 15 }}
        className="-striped -highlight"
        filterable
        noDataText=""
        defaultPageSize={100}
      />
    </div>
  );
}

export default MonthlyUsage