import { createSelector } from "reselect";

const referrals = () => (state) => state.referrals;

const adminUserDomain = () => (state) => state.user;

const selectReferralsContainer = () =>
  createSelector(referrals(), adminUserDomain(), (referrals, adminUser) => {
    return Object.assign(
      {},
      {
        ...referrals,
        adminUser,
      }
    );
  });

export default selectReferralsContainer;
