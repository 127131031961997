import PropTypes from "prop-types";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { noselect } from "../../styles/common.css";

import Card from "@material-ui/core/Card";
import Button from "../Button"
import { Link } from "react-router";
import CloseIcon from "@material-ui/icons/Close";
import currency from "../../util/currency-formatter";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  statementInfo: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px 10px 0px",
    margin: "0px 10px",
    "& span": {
      fontWeight: "bold",
    },
  },
  adjustmentForm: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
    borderTop: "2px solid #ddd",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const UpdateStatement = (props) => {
  const classes = useStyles();
  const {
    closeManualAdjustmentPopup,
    selectedStatements,
    doUpdateEligibleStatements,
  } = props;
  const [formError, setFormError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const handleConfirm = async () => {
    setDisableButton(true);
    if (!formError) {
      let checkedStatements = selectedStatements
        .filter((s) => {
          if (s.checked) return true;
          return false;
        })
        .map((s) => s);

      await doUpdateEligibleStatements({
        statements: checkedStatements,
      });

      closeManualAdjustmentPopup(false);
    }
  };
  return (
    <div className={classes.root}>
      <Card className={classes.container} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Update Statement Status</span>
          <CloseIcon onClick={() => closeManualAdjustmentPopup()} />
        </div>
        <div className={classes.statementInfo}>
          {selectedStatements.map((selectedStatement, index) => {
            if (selectedStatement.checked)
              return (
                <div key={index}>
                  <span>
                    Member ID:{" "}
                    <Link
                      style={{ color: "#3f51b5" }}
                      to={`/members/${selectedStatement.id}/details`}
                    >
                      {selectedStatement.member_id}
                    </Link>{" "}
                  </span>
                  <span>
                    Current Balance:{" "}
                    {currency(selectedStatement.autopay_amount)}
                  </span>
                </div>
              );
          })}
        </div>
        <div className={classes.optionContainer}>
          <Button
            color="secondary"
            varian="raised"
            onClick={async () => await closeManualAdjustmentPopup()}
          >
            Cancel
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await handleConfirm()}
            disabled={disableButton}
          >
            Apply
          </Button>
        </div>
      </Card>
    </div>
  );
};

UpdateStatement.propTypes = {
  closeManualAdjustmentPopup: PropTypes.func.isRequired,
  selectedStatements: PropTypes.array.isRequired,
};

export default UpdateStatement;
