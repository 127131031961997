import moment from "moment";
import React, { useEffect } from "react";
import ReactTable from "react-table";

const CSAMemberTable = ({ selectedCsa, csaMembers, doListCSAMembers }) => {
  useEffect(() => {
    if (selectedCsa?.csa?.id) {
      doListCSAMembers({ csa_id: selectedCsa.csa.id, email: selectedCsa.csa_user.email });
    }
  }, [selectedCsa?.csa?.id]);

  return <ReactTable
    data={csaMembers}
    columns={[
      {
        Header: "Member ID",
        accessor: "member_id",
        Cell: (row) => {
          return (row.value) ? <a href={`/members/${row.value}/details`}>{row.value}</a> : "pending";
        },
      },
      {
        Header: "Meter ID",
        accessor: "meter.meter_identifier",
      },
      {
        Header: "Status",
        accessor: "meter.status",
      },
      {
        Header: "Meter Type",
        accessor: "meter_type",
      },
      {
        Header: "On CSA",
        accessor: "meter.on_csa",
        Cell: (row) => {
          return (row.value) ? "Yes" : "No";
        },
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: (row) => {
          return (row.value) ? moment(row.value).local().format("YYYY/MM/DD") : "pending";
        }
      }
    ]}
    defaultPageSize={5}
  />;
}

export default CSAMemberTable;
