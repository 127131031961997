import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { all, omit, mergeDeepRight } from "ramda";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Snackbar from "../components/Snackbar"
import InputLabel from "@material-ui/core/InputLabel";
import { FormControl } from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../components/Button"
import { withStyles } from "@material-ui/core/styles";
import { ClipLoader } from "react-spinners";
import Modal from "react-modal";
import modalStyle from "../styles/modal";
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import {
  requestMeterLookup,
  meterUpdated,
  hideSnackBar,
} from "../actions/meterLookup";
import {
  selectMeterLookupSnackbarStatus,
  selectMeterLookupDetails,
} from "../selectors/meterLookup";

const styles = {
  esi: {
    padding: "5px 0",
  },
  address: {
    padding: "5px 0",
    display: "flex",
  },
  title: {
    paddingRight: "5px",
  },
  switchHold: {
    padding: "3px 0 5px",
    display: "flex",
    alignItems: "center",
  },
  formActions: {
    marginTop: "10px",
  },
  actionButtons: {
    margin: "5px",
  },
};

class MeterLookupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meter: {
        esi: null,
        switch_hold: null,
      },
      prePopulate: true,
      modelOpen: false,
    };
  }

  componentDidMount() {
    const {
      params: { meterIdentifier },
    } = this.props;
    this.props.requestMeterLookup({ meterIdentifier });
  }

  componentDidUpdate() {
    if (this.state.prePopulate) {
      this.setState({ prePopulate: false, meter: this.props.meter });
    }
  }

  handleChange = (name) => (event) => {
    const meter = { meter: { [name]: event.target.value } };
    const mergedState = mergeDeepRight(this.state, meter);
    this.setState(mergedState);
  };

  handleModalOpen = () => {
    this.setState({ modelOpen: true });
  };

  handleModalClose = () => {
    this.setState({ modelOpen: false });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.hideSnackBar();
  };

  handleSubmit = (e) => {
    const {
      meter: { esi, switch_hold },
    } = this.state;
    const update = {
      switch_hold,
      esi,
    };
    this.props.meterUpdated(update);
    this.setState({ modelOpen: false });
  };

  render() {
    const {
      meter: { esi: meterIdentifier, switch_hold: switchHold, address },
      modelOpen,
    } = this.state;

    const {
      snackbar: { showSnackbar, actionResponse, actionMessage },
      classes,
      meter: { switch_hold: propsSwitchHold },
    } = this.props;

    const edited = propsSwitchHold !== switchHold;

    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showSnackbar}
          autoHideDuration={actionResponse === "failure" ? 6000 : 3000}
          onClose={() => this.handleClose()}
          className={actionResponse === "failure" ? confirmationError : confirmationSuccess}
          message={actionMessage}
        />
        <h3>Meter lookup details</h3>
        {meterIdentifier && (
          <div>
            <div className={classes.esi}>
              <strong>Meter Identifier: </strong>
              {meterIdentifier}
            </div>
            <div className={classes.address}>
              <div className={classes.title}>
                <strong>Address: </strong>
              </div>
              <div>
                <div>{`${address.line1}`}</div>
                <div>
                  {`${address.city}, ${address.state} ${address.postal_code}`}
                </div>
              </div>
            </div>
            <form autoComplete="off">
              <Modal isOpen={modelOpen} style={modalStyle.modal}>
                <div>
                  Are you sure you want to update the switch hold status to{" "}
                  <b>{switchHold === "y" ? "Yes" : "No"}</b>
                </div>
                <div className={classes.formActions}>
                  <Button
                    className={classes.actionButtons}
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Yes
                  </Button>
                  <Button
                    className={classes.actionButtons}
                    variant="contained"
                    color="secondary"
                    onClick={this.handleModalClose}
                  >
                    No
                  </Button>
                </div>
              </Modal>
              <div className={classes.switchHold}>
                <div className={classes.title}>
                  <strong>Switch hold status: </strong>
                </div>
                <FormControl>
                  <Select
                    value={switchHold}
                    onChange={this.handleChange("switch_hold")}
                    inputProps={{
                      name: "swtich hold",
                      id: "switch-hold",
                    }}
                  >
                    <MenuItem value="y">Yes</MenuItem>
                    <MenuItem value="n">No</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className={classes.formActions}>
                {edited && (
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.handleModalOpen();
                    }}
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

MeterLookupDetails.propTypes = {
  hideSnackBar: PropTypes.func.isRequired,
  showSnackbar: PropTypes.bool.isRequired,
};

const mapState = createStructuredSelector({
  snackbar: selectMeterLookupSnackbarStatus,
  meter: selectMeterLookupDetails,
});

const mapDispatch = {
  hideSnackBar,
  requestMeterLookup,
  meterUpdated,
};
export default withStyles(styles)(
  connect(mapState, mapDispatch)(MeterLookupDetails)
);
