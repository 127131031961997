import * as constants from "../../constants";

const reportInitState = {
  reports: [],
  payload: [],
  columns: []
};

export function reports(state = reportInitState, action) {
  switch (action.type) {
    case constants.REPORTS_GET_REPORTS_SUCCESS:
      return {
        ...state,
        reports: action.payload.reports
      }
    case constants.REPORTS_RUN_REPORT_SUCCESS:
      return {
        ...state,
        payload: action.payload.result,
        columns: action.payload.columns
      }
    default:
      return state;
  }
}
