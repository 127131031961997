import { createSelector } from "reselect";

const payments = () => (state) => state.payments;

const selectPaymentsContainer = () =>
  createSelector(payments(), (paymentsData) => {
    return paymentsData;
  });

export default selectPaymentsContainer;
