import * as constants from "../../constants";

const reportInitState = {
  jobNotifications: [],
  payload: [],
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
  isProcessing: false,
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

export function jobNotifications(state = reportInitState, action) {
  switch (action.type) {
    case constants.NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        jobNotifications: action.payload.list
      }
    case constants.NOTIFICATIONS_RUN_NOTIFICATION_SUCCESS:
      return {
        ...state,
        payload: action.payload.data
      }
    case constants.NOTIFICATIONS_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case constants.NOTIFICATIONS_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case constants.NOTIFICATIONS_IS_FETCHING:
      return {
        ...state,
        ...action,
      }
    default:
      return state;
  }
}
