import React from "react";
import { connect } from "react-redux";
import StatementApproval from "../components/StatementApproval";
import {
  fetchBillingStatements,
  updateStatementStatus,
  closeSnackbar,
  setSelectedStatusForFetch,
} from "../actions/statements";
import selectStatementsContainer from "../selectors/statements";

const MemberStatementContainer = (props) => <StatementApproval {...props} />;

const mapStateToProps = (state) => ({
  ...selectStatementsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedStatusForFetch: (status) => {
    dispatch(setSelectedStatusForFetch(status));
  },
  doFetchBillingStatements: ({ accountID, status, inReview }) => {
    dispatch(fetchBillingStatements({ accountID, status, inReview }));
  },
  doUpdateStatementStatus: ({ statementIDs, status, errorReason, inReview = false }) => {
    dispatch(updateStatementStatus({ statementIDs, status, errorReason, inReview }));
  },
  closeSnackbar: () => {
    dispatch(closeSnackbar());
  },
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberStatementContainer);
