import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

import ViewSelector from "../common/ViewSelector";
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      "& #iterable-logo": {
        cursor: "pointer",
        width: "25px",
        margin: "0px 10px",
        padding: "5px",
        borderRadius: "99999px",
        border: "2px solid #ddd",
        transition: "0.2s all ease",
        "&:hover": {
          transform: "scale(1.1)",
        },
      },
    },
  },
  viewContainer: {
    marginTop: "20px",
  },
  selectedViewHeader: {
    fontSize: "17px",
    padding: "20px 0px",
    paddingLeft: "15px",
    margin: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const BatchView = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeView, setActiveView] = useState(0);
  const viewNames = ["Customer Tab"];

  return (
    <div className={classes.viewContainer}>
      <ViewSelector
        viewNames={viewNames}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        {viewNames.map((option, index) => {
          return (
            <Tab
              key={index}
              className={activeView === index ? `view activeView` : `view`}
              label={option}
            ></Tab>
          );
        })}
      </ViewSelector>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeView}
      >
        {props.children}
      </SwipeableViews>
    </div>
  );
};

BatchView.propTypes = {};

export default BatchView;
