import React, { useState } from "react";
import { injectStripe } from "react-stripe-elements";
import Button from "../../Button"
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel } from "@material-ui/core/";
import {
  CardNumberElement,
  CardCVCElement,
  CardExpiryElement,
  PostalCodeElement,
} from 'react-stripe-elements'
import {
  RootContainer,
  Container,
  Header,
  OptionsContainer,
  CreditInformationForm,
  TwoColumnsContainer,
  TextFieldInput,
  ButtonInput,
  TextFieldLabel,
  MakePaymentContainer,
  PaymentMethodHeader,
  SaveCardContainer,
} from "./OneTimeCardPayment.style";

var elementStyles = {
  base: {    
    fontWeight: 600,
    fontSize: '16px',
    fontSmoothing: 'antialiased',
    ':focus': {
      color: '#424770',
    },

    '::placeholder': {
      color: '#9BACC8',
    },

    ':focus::placeholder': {
      color: '#CFD7DF',
    },
  },
  invalid: {
    color: 'red',
    ':focus': {
      color: '#FA755A',
    },
    '::placeholder': {
      color: '#FFCCA5',
    },
  },
};

var elementClasses = {
  focus: 'focus',
  empty: 'empty',
  invalid: 'invalid',
};

const OneTimeCardPaymentStripe = ({
  memberID,
  market,
  open,
  onClose,
  onMakeOneTimePayment,
  onFetchMemberAccountBalance,  
  creditCardFee,
  stripe,
}) => {  
  const [amount, setAmount] = useState("");
  const [name, setName] = useState("");
  const [savePaymentCard, setSavePaymentCard] = useState(false);
  const [savePaymentCardAsDefault, setSavePaymentCardAsDefault] = useState(true);
  const [cardElementState, setCardElementStates] = useState({
    cardNumber: { complete: false, errorMessage: "" },
    cardExpiry: { complete: false, errorMessage: "" },
    cardCvc: { complete: false, errorMessage: "" },
    postalCode: { complete: false, errorMessage: "" },
  });

  const updateCardElementState = (element, e) => {    
    setCardElementStates({
      ...cardElementState,
      [element]: { complete: e.complete, errorMessage: e.error?.message },
    });
  };
  
  const validateInput = () => {
    if ( cardElementState.cardNumber.complete && cardElementState.cardExpiry.complete 
      && cardElementState.cardCvc.complete && cardElementState.postalCode.complete 
      && name!="" &&  (amount > 0 || savePaymentCard)) {
      return true
    }
    return false
  };

  const clearForm = () =>{
    setAmount("");
    setName("");
  }

  const processPayment = () => {    
    stripe.createToken({ name }).then((res) => {    
      const card = res.token.card;
      const metaData = {      
        tokenID: res.token.id,
        cardID: card.id,
        name: card.name,
        type: card.brand,
        lastFour: card.last4,
        expirationYear: `${card.exp_year}`,
        expirationMonth: `${card.exp_month}`,
        zip: card.address_zip,
      
    }
      const reqBody = {
        memberID,
        market,
        name,
        amount: amount || 0,
        is_default: savePaymentCardAsDefault,
        skip_saving_profile: !savePaymentCard,
        tokenID: res.token.id,
        payment_account_meta_data: metaData
      };          
      onClose();
      onMakeOneTimePayment(memberID, "amount", amount, reqBody).then(() => {
        clearForm();      
        onFetchMemberAccountBalance(memberID);
      });
    });    
     
  };
  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby="one time payment dialog"
      open={open}
    >      
      <RootContainer>
        <Container variant="outlined">
          <Header>
            <span>One Time Payment</span>
            <CloseIcon onClick={() => onClose()} />
          </Header>
          <input type="hidden" value="_" />
          <MakePaymentContainer>            
            <h4>Make Payment</h4>              
              <TextFieldInput
                label={`Amount`}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                type="money"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
              />
          </MakePaymentContainer> 
          <CreditInformationForm>
              <PaymentMethodHeader>Credit Card Information</PaymentMethodHeader>
              <TextFieldInput
                label={`Full Name`}
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                  fontSize: "18px"
                }}
                autoComplete="off"
            />
              <TextFieldLabel style={{marginBottom:"5px"}}>Card number *</TextFieldLabel>
              <CardNumberElement
                style={elementStyles}
                classes={elementClasses}
                onChange={(e) => updateCardElementState("cardNumber", e)}  
              /> 
              {cardElementState.cardNumber.errorMessage && <span style={{ color: "red" }}>{cardElementState.cardNumber.errorMessage}</span>}
              <TwoColumnsContainer>
                <div style={{width:"50%"}}>
                  <TextFieldLabel  style={{marginBottom:"5px"}}>CVC</TextFieldLabel>
                  <CardCVCElement 
                    style={elementStyles}
                    classes={elementClasses}
                    onChange={(e) => updateCardElementState("cardCvc", e)}  
                  />       
                  {cardElementState.cardCvc.errorMessage && <span style={{ color: "red" }}>{cardElementState.cardCvc.errorMessage}</span>}                         
                </div>
                <div style={{width:"50%"}}>
                  <TextFieldLabel style={{marginBottom:"5px"}}>Expiry</TextFieldLabel>
                  <CardExpiryElement
                    style={elementStyles}
                    classes={elementClasses}
                    onChange={(e) => updateCardElementState("cardExpiry", e)}  
                    />
                    {cardElementState.cardExpiry.errorMessage && <span style={{ color: "red" }}>{cardElementState.cardExpiry.errorMessage}</span>}                         
                </div>
              </TwoColumnsContainer>
              <TextFieldLabel style={{marginBottom:"5px"}}>Zip Code *</TextFieldLabel>
              <PostalCodeElement 
                style={elementStyles}
                classes={elementClasses}
                onChange={(e) => updateCardElementState("postalCode", e)}  
                />            
              {cardElementState.postalCode.errorMessage && <span style={{ color: "red" }}>{cardElementState.postalCode.errorMessage}</span>}                         
              {Boolean(creditCardFee) && <span style={{ color: "red", marginTop:"10px" }}>
                {creditCardFee}% processing fee will apply to credit card payments
              </span>}
            </CreditInformationForm>
          
            <SaveCardContainer>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={savePaymentCard}
                    onChange={(e) => setSavePaymentCard(e.target.checked)}                  
                  />
                }
                label={`Save payment card`} 
              />
            <FormControlLabel
              control={
                <Checkbox
                  checked={savePaymentCard && savePaymentCardAsDefault}
                  onChange={(e) => setSavePaymentCardAsDefault(e.target.checked)}    
                  disabled={!savePaymentCard}              
                />
              }
              label={`Make default payment card`} 
              style={{ marginTop: "15px" }}
            />            
          </SaveCardContainer>
          <OptionsContainer>
            <Button color="secondary" varian="raised" onClick={() => onClose()}>
              Cancel
            </Button>
            <ButtonInput
              disabled={!validateInput()}
              color="primary"
              varian="raised"
              onClick={async () => await processPayment()}
            >
              Process
            </ButtonInput>
          </OptionsContainer>
        </Container>
      </RootContainer>
    </Dialog>
  );
};


export default injectStripe(OneTimeCardPaymentStripe);