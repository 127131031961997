import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link } from "react-router";

import moment from "moment";
import currency, {
  currencyWithNoSignWithDecimal,
} from "../../util/currency-formatter";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LaunchIcon from "@material-ui/icons/Launch";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";

import StatementDetailsRow from "./StatementDetailsRow";

const StatementRow = (props) => {
  const { statement, handleCheckedBox, allowedStatus, hideMember, showExceptions } = props;
  const [openRow, setOpenRow] = useState(false);
  const summary = statement?.summary ? JSON.parse(statement.summary) : {};

  const getStatusComponent = (status, statement) => {
    if (allowedStatus[status]) {
      return (
        <Tooltip placement="bottom" title="Edit Status">
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleCheckedBox(statement.id, true);
            }}
          >
            <span style={{ marginRight: "5px" }} id="status-label">
              {status}
            </span>
            <Edit fontSize="small" />
          </div>
        </Tooltip>
      );
    } else {
      return status;
    }
  };

  return [
    <TableRow
      style={{
        cursor: "pointer",
        backgroundColor: openRow ? "lemonchiffon" : null,
      }}
      key={statement.id}
      onClick={(e) => {
        setOpenRow(!openRow);
      }}
    >
      <TableCell align="left">
        {statement.status in allowedStatus && (
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={statement.checked ? statement.checked : false}
            onChange={(e) => {
              handleCheckedBox(statement.id);
            }}
            name="checkedA"
          />
        )}
      </TableCell>
      {!hideMember && (
        <TableCell align="left">
          <Link
            style={{ color: "#3f51b5" }}
            to={`/members/${statement.member_id}/details`}
          >
            {statement.member_id}
          </Link>
        </TableCell>
      )}
      <TableCell>
        {new Date(statement.statement_date) > new Date(1) && moment.utc(statement.statement_date).format("YYYY-MM-DD")}
      </TableCell>
      <TableCell>{moment.utc(statement.spsd).format("YYYY-MM-DD")}</TableCell>
      <TableCell>{moment.utc(statement.sped).format("YYYY-MM-DD")}</TableCell>
      <TableCell>{currency(statement.amount_due)}</TableCell>
      <TableCell>
        {summary.average_rate && (
          <>
            {`${currencyWithNoSignWithDecimal(summary.average_rate * 100)} `}
            &#162;{"/kWh"}
          </>
        )}
      </TableCell>
      <TableCell>
        {new Date(statement.due_date) > new Date(1) && moment.utc(statement.due_date).format("MMMM Do YYYY")}
      </TableCell>
      <TableCell>
        {statement.is_final ? 'FINAL' : ''}
      </TableCell>
      {
        showExceptions &&
        <TableCell align="center">
          <ul style={{ paddingLeft: '10px' }}>
            {
              statement.exceptions.map(x => <li style={{ textAlign: 'left' }}>{x}</li>)
            }
          </ul>
        </TableCell>
      }
      <TableCell align="center">
        {getStatusComponent(statement.status, statement)}
      </TableCell>
      <TableCell align="center">
        {statement?.pdf_url && (
          <LaunchIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              window.open(statement.pdf_url, "_blank");
            }}
          />
        )}
      </TableCell>
      <TableCell align="center">
        {!openRow ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
      </TableCell>
    </TableRow>,
    openRow && [
      <StatementDetailsRow
        key={`second-panel-${statement.id}`}
        summary={summary}
      />,
    ],
  ];
};

StatementRow.propTypes = {
  handleCheckedBox: PropTypes.func.isRequired,
  statement: PropTypes.object.isRequired,
};

export default StatementRow;
