import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import Table from "./Table";
import CreateStatement from "./CreateStatement";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const Statements = (props) => {
  const classes = useStyles();
  const {
    setSelectedStatusForFetch,
    doFetchBillingStatements,
    doUpdateStatementStatus,
    doCreateStatement,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
    statements,
  } = props;

  const accountID = props.member.get("accountID");
  const [openNewStatementPopup, setOpenNewStatementPopup] = useState(false);

  useEffect(() => {
    setSelectedStatusForFetch("");
    if (accountID) doFetchBillingStatements({ accountID });
  }, [accountID]);

  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">
        Statements
        <IconButton
          style={{ float: "right", padding: "0" }}
          title="Create New Statement"
          color="primary"
          onClick={() => {
            setOpenNewStatementPopup(true);
          }}
        >
          <AddCircleOutlineIcon fontSize="large" />
        </IconButton>
      </h1>
      <Table
        statements={statements}
        doUpdateStatementStatus={doUpdateStatementStatus}
        doCreateStatement={doCreateStatement}
      />

      {openNewStatementPopup && <CreateStatement
        doCreateStatement={doCreateStatement}
        onClose={() => setOpenNewStatementPopup(false)}
        open={true}
        memberId={props.member.get("memberID")}
        accountID={props.member.get("accountID")}
      />}
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

Statements.propTypes = {
  member: PropTypes.object.isRequired,
  setSelectedStatusForFetch: PropTypes.func.isRequired,
  doFetchBillingStatements: PropTypes.func.isRequired,
  doUpdateStatementStatus: PropTypes.func.isRequired,
};

export default Statements;
