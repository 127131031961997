import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import ReactTable from "react-table";
import Button from "../Button"

import UpdateEnrollmentDetails from "./UpdateEnrollmentDetails";
import CancelEnrollment from "./CancelEnrollment";
import Tooltip from "@material-ui/core/Tooltip";
import Notifications from '@material-ui/icons/ContactMail';
import Visibility from '@material-ui/icons/Visibility';
import EnrollmentNotifications from "./EnrollmentNotifications";


const EnrollmentTable = ({
  searchCriteria,
  enrollments,
  fetchEnrollments,
  fetchCancelEnrollments,
  resubmitEnrollment,
  fetchUpdateEnrollmentName,
  fetchAddNote,
  fetchSendVerification,
  isHold,
  adminUser,
}) => {
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [notficationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [cancelStatus, setCancelStatus] = useState("canceled");
  const [selectedEnrollment, setSelectedEnrollment] = useState({});

  const getDate = (value) => {
    if (!value) {
      return "";
    }
    const d = value.split("T")[0];
    return moment(d).format("YYYY-MM-DD");
  };

  useEffect(() => {
    if (selectedEnrollment.id) {
      const en = enrollments.find(e => e.id === selectedEnrollment.id);

      if (en) {
        setSelectedEnrollment(en);
      } else {
        handleCloseUpdateDialog();
        setSelectedEnrollment({});
      }
    }
  }, [enrollments]);

  const wildcardFilter = (filter, row) => row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1;
  const handleCloseUpdateDialog = () => setUpdateDialogOpen(false);
  const handleCloseCancelDialog = () => setCancelDialogOpen(false);
  const handleCloseNotficationDialog = () => setNotificationDialogOpen(false);
  const handleResubmit = (
    id,
    isWaivedDeposit,
    isCompleted,
    isVerified,
    isIDVerified,
    isReleased,
    releaseNote,
  ) => {
    resubmitEnrollment(
      searchCriteria,
      id,
      isWaivedDeposit,
      isCompleted,
      isVerified,
      isIDVerified,
      isReleased,
      releaseNote,
      isHold,
    );
  };

  const handleCancel = (id, notes, status) => {
    fetchCancelEnrollments({
      enrollment_id: id,
      searchCriteria: searchCriteria,
      notes,
      status,
    }, isHold);
  }

  const handleEdit = obj => {
    fetchUpdateEnrollmentName({
      update_enrollment: obj,
      searchCriteria: searchCriteria,
    }, isHold);
  }

  const handleAddNote = ({ id, note }) => {
    fetchAddNote({
      enrollment_id: id,
      note: note,
      searchCriteria: searchCriteria,
    });
  }

  const handleVerification = obj => {
    fetchSendVerification({
      enrollment: obj,
      searchCriteria: searchCriteria,
    }, isHold);
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Type",
      accessor: "enrollment_type",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Name",
      id: "first_name",
      filterMethod: wildcardFilter,
      accessor: (row) => row.first_name + " " + row.last_name,
    },
    {
      Header: "Meter Identifier",
      accessor: "meter_identifier",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Start Date",
      accessor: "requested_start_date",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Verification",
      accessor: "verification_status",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Status",
      accessor: "enrollment_status",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Created",
      id: "created_date",
      accessor: (row) => getDate(row.created_date),
      filterMethod: wildcardFilter,
    },
    {
      Header: "Completed",
      id: "completed_date",
      accessor: (row) => getDate(row.completed_date),
      filterMethod: wildcardFilter,
    },
    {
      Header: "Service",
      accessor: "service_type",
      filterMethod: wildcardFilter,
    },
    {
      Header: "State",
      id: "state",
      filterMethod: wildcardFilter,
      accessor: (row) => row?.address?.state || '-',
    },
    {
      Header: "Premise",
      accessor: "premise_type",
      filterMethod: wildcardFilter,
    },
    {
      Header: "Company",
      id: "company",
      accessor: (row) => row.premise_type && row.premise_type.toLowerCase() === 'commercial' ? row.company : '',
      filterMethod: wildcardFilter,
    },
    {
      Header: "",
      resizable: false,
      filterable: false,
      width: 150,
      Cell: (row) => (
        <div>
          <Tooltip placement="top" title="View/Edit Data">
            <Button
              style={{ marginRight: '5px' }}
              onClick={async () => {
                await setSelectedEnrollment(row.original);
                await setUpdateDialogOpen(true);
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              <Visibility />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="View Notifications">
            <Button
              style={{ marginRight: '5px' }}
              onClick={async () => {
                await setSelectedEnrollment(row.original);
                await setNotificationDialogOpen(true);
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              <Notifications />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <ReactTable
        data={enrollments}
        columns={columns}
        style={{ textAlign: 'center' }}
        className="-striped -highlight"
        defaultPageSize={10}
        defaultSortOrder="created_date"
        defaultSortDesc
        filterable
        defaultSorted={[
          {
            id: "created_date",
            desc: true,
          },
        ]}
      />
      <Dialog
        maxWidth={"lg"}
        onClose={handleCloseUpdateDialog}
        aria-labelledby="customized-dialog-title"
        open={updateDialogOpen}
      >
        <UpdateEnrollmentDetails
          details={selectedEnrollment}
          onStatusChange={() => { }}
          closePopup={handleCloseUpdateDialog}
          onResubmit={handleResubmit}
          onEdit={handleEdit}
          onAddNote={handleAddNote}
          onVerification={handleVerification}
          adminUser={adminUser}
          setCancelDialogOpen={setCancelDialogOpen}
          setCancelStatus={setCancelStatus}
        />
      </Dialog>
      <Dialog
        onClose={handleCloseCancelDialog}
        aria-labelledby="customized-dialog-title"
        open={cancelDialogOpen}
      >
        <CancelEnrollment
          details={selectedEnrollment}
          onCancel={handleCancel}
          handleCloseCancelDialog={handleCloseCancelDialog}
          cancelStatus={cancelStatus}
        />
      </Dialog>
      <Dialog
        maxWidth="md"
        onClose={handleCloseNotficationDialog}
        aria-labelledby="customized-dialog-title"
        open={notficationDialogOpen}
      >
        <EnrollmentNotifications
          details={selectedEnrollment}
          closePopup={handleCloseNotficationDialog}
        />
      </Dialog>

    </div>
  );
};

EnrollmentTable.propTypes = {
  enrollments: PropTypes.array,
};

export default EnrollmentTable;
