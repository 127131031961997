import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  FormControl,
  TextField,
} from "@material-ui/core/";
import Button from "../../Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { useStyles } from "./styles";
import currency from "../../../util/currency-formatter";
import { fetchRefundMeterDeposit } from "../../../actions/meters";
import { noselect } from "../../../styles/common.css";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

const MemberDeposit = (props) => {
  const classes = useStyles();
  const { user, member, onfetchRefundMeterDeposit, onCaptureDeposit } = props;
  const [deposits, setDeposits] = useState(null);
  const [meters, setMeters] = useState(null);
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [openCaptureDeposit, setOpenCaptureDeposit] = useState(false);
  const [amount, setAmount] = useState("");
  const validateInput = () => {
    return amount && parseFloat(amount);
  };

  const closeCaptureDepositDialog = () => {
    setAmount("");
    setOpenCaptureDeposit(false);
  };

  useEffect(() => {
    // set default amount to latest deposit amount
    if (deposits && deposits.size > 0) {
      let latest = deposits
        .toJS()
        .reduce((prev, current) => (current.id > prev.id ? current : prev));

      if (latest.depositAmount) {
        setAmount(latest.depositAmount);
      }
    } else {
      setAmount("");
    }
  }, [deposits, openCaptureDeposit]);

  useEffect(() => {
    if (member.get("deposits")) {
      setDeposits(member.get("deposits"));
    }
    if (member.get("meters")) {
      setMeters(member.get("meters"));
    }
  }, [member]);

  const closeRefundDialog = () => {
    setOpenRefundDialog(false);
  };

  function Row({ row }) {
    return [
      <TableRow style={{ cursor: "pointer" }}>
        <TableCell align="center">
          {moment.utc(row.get("createdDate")).format("YYYY-MM-DD")}
        </TableCell>
        <TableCell align="center">
          {currency(row.get("depositAmount"))}
        </TableCell>
        <TableCell align="center">{row.get("depositStatus")}</TableCell>
        <TableCell align="center">
          {row.get("depositStatus") === "captured" && (
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "3px", padding: "3px" }}
              onClick={(e) => setOpenRefundDialog(true)}
            >
              Refund
            </Button>
          )}
        </TableCell>
        <Dialog
          onClose={() => closeRefundDialog()}
          aria-labelledby="customized-dialog-title"
          open={openRefundDialog}
        >
          <div className={classes.refundRoot}>
            <Card className={classes.createNoteContainer} variant="outlined">
              <div className={`${classes.menuBar} ${noselect}`}>
                <span>Refund Deposit</span>
                <CloseIcon onClick={() => closeRefundDialog()} />
              </div>
              <div style={{ margin: "20px" }}>
                Are you sure you want to refund this deposit?
              </div>
              <div
                className={classes.optionContainer}
                style={{
                  borderTop: "2px solid #ddd",
                }}
              >
                <Button
                  color="secondary"
                  varian="raised"
                  onClick={async () => await closeRefundDialog()}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.enableButton}
                  color="primary"
                  varian="raised"
                  onClick={async () => {
                    closeRefundDialog();
                    await onfetchRefundMeterDeposit(
                      row.get("meterID"),
                      member.get("memberID"),
                      meters && meters.get(0)
                        ? meters.get(0).get("meterIdentifier")
                        : ""
                    );
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Card>
          </div>
        </Dialog>
      </TableRow>,
    ];
  }

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item md={6}>
          <h4 style={{ marginLeft: "25px" }}>Deposits</h4>
        </Grid>
        <Grid item md={6}>
          <div style={{ float: "right", marginBottom: "10px" }}>
            <IconButton
              aria-label="Make Payment"
              size="medium"
              title="Make Payment"
              color="primary"
              onClick={() => setOpenCaptureDeposit(true)}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
          </div>
        </Grid>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="collapsible table"
            style={{ fontSize: 14 }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Created Date</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deposits &&
                deposits.map((p, index) => {
                  return <Row key={index} row={p} />;
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog
        onClose={() => closeCaptureDepositDialog()}
        aria-labelledby="customized-dialog-title"
        open={openCaptureDeposit}
      >
        <div className={classes.paymentRoot}>
          <Card className={classes.createNoteContainer} variant="outlined">
            <div className={`${classes.menuBar} ${noselect}`}>
              <span>Capture Deposit</span>
              <CloseIcon onClick={() => closeCaptureDepositDialog()} />
            </div>
            <FormControl className={classes.paymentForm}>
              <TextField
                label={`Enter payment amount`}
                placeholder="0.00"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                type="money"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.paymentText}
              />
            </FormControl>
            <div
              className={classes.optionContainer}
              style={{
                borderTop: "2px solid #ddd",
              }}
            >
              <Button
                color="secondary"
                varian="raised"
                onClick={async () => await closeCaptureDepositDialog()}
              >
                Cancel
              </Button>
              <Button
                className={
                  validateInput() ? classes.enableButton : classes.disableButton
                }
                color="primary"
                varian="raised"
                onClick={async () => {
                  // use current user's email as enrollmentID for history purposes
                  let enrollmentID = user.email;
                  let meterIdentifier =
                    meters && meters.get(0)
                      ? meters.get(0).get("meterIdentifier")
                      : "";
                  let meterID =
                    meters && meters.get(0) ? meters.get(0).get("meterID") : "";

                  await closeCaptureDepositDialog();
                  await onCaptureDeposit(
                    member.get("memberID"),
                    meterID,
                    meterIdentifier,
                    amount.toString(),
                    enrollmentID
                  );
                }}
              >
                Save
              </Button>
            </div>
          </Card>
        </div>
      </Dialog>
    </Card>
  );
};

MemberDeposit.propTypes = {
  member: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  member: state.selectedMember,
  onfetchRefundMeterDeposit: PropTypes.func.isRequired,
});

const mapDispatchToProps = (dispatch) => ({
  onfetchRefundMeterDeposit: (meterID, memberId, meterIdentifier) =>
    dispatch(fetchRefundMeterDeposit(meterID, memberId, meterIdentifier)),
  dispatch,
});

const ConnectedMemberDeposit = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDeposit);

export default ConnectedMemberDeposit;
