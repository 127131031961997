import React, { useEffect, useState } from "react";
import { Link } from "react-router";
import moment from "moment";
import ReactTable from "react-table";
import { connect } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import Button from "../../Button"
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Dialog from "@material-ui/core/Dialog"
import { capitalizeFirstLetter } from "/src/util/utilities";
import { useTableStyles as useStyles } from "../styles";
import AddPaymentInfo from "./AddPaymentInfo"
import RefundForm from "./RefundForm";
import ChargeBackForm from "./ChargeBackForm"
import currency from "../../../util/currency-formatter";

const PaymentHistory = (props) => {
  const {
    chargeList,
    onFetchChargeList,
    showDateFilter,
    memberID,
    showMemberID,
    isLoading,
  } = props;
    const columnStyle = {
        textAlign: "center",
    };
    const columnStyleRed = {
      textAlign: "center",      
      color: "red"
    };
    const columnButtonStyle = {
      marginRight: "5px",
      maxWidth: "50px",
      maxHeight: "50px",
      minWidth: "30px",
      minHeight: "30px",
    };
    const columnButtonContainerStyle = {
      flex:"0",
    };
    const headerStyle = {
        fontWeight: "700",
    };
    
    const isRefund = ( type) => type === "charge_back" || type === "balance_refund" ;

    const classes = useStyles();
     const getChargeTableData = () => {
        // constructing array of charges from chargeList which can be used in ReactTable component
        const chargeArray = [];
        chargeList.forEach((charge) => {       
            let status = capitalizeFirstLetter(charge.get("status")) ?? "";
            status = charge.get("authorized") ? `Authorized ${status}` : status;
            status = charge.get("refunded") ? `Refunded ${status}` : status;
            status = charge.get("faild_code") ? `Failed ${status}` : status;   
            const amount = charge.get("amount");         
            chargeArray.push({
              id: charge.get("ID"),
              created: charge.get("created"),
              Amount: !isRefund(charge.get("payment_type")) ? amount : "",
              amount_refunded: isRefund(charge.get("payment_type"))
                ? Math.abs(amount)
                : "",
              payment_method: charge.get("payment_method"),
              status,
              fee: charge.get("fee"),
              cardType: charge.get("cardType"),
              cardLastFour: charge.get("lastFour"),
              failureReason: charge.get("faild_code"),
              chargeID: charge.get("charge_id"),
              paymentType: charge.get("payment_type"),
              memberID: charge.get("member_id"),
              message: charge.get("message"),
              isRefundable: charge.get("isRefundable"),
              source: charge.get("source"),
              reference_id: charge.get("reference_id"),
              cardType: charge.get("cardType"),
              lastFour: charge.get("lastFour"),
            });
        });
        return chargeArray;
    }
    const chargeDataArray = getChargeTableData();
    
    const [openAddPayment, setOpenAddPayment] = useState(false);
    const [openRefund, setOpenRefund] = useState(false);
    const [openChargeback, setOpenChargeback] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState({})

    const isReverisble = (amount, type) =>
      type !== "charge_back" && type !== "balance_refund" && parseFloat(amount) > 0;

    const chargeColumns = [
      {
        id: "date",
        Header: "Date",
        accessor: (charge) =>
          moment(charge.created.substring(0, 10)).format("MMM D, yyyy"),
        style: columnStyle,
        headerStyle,
        sortMethod: (a, b) => {
          if (new Date(a).getTime() > new Date(b).getTime()) {
            return 1;
          }
          return -1;
        },
      },
      {
        accessor: "paymentType",
        Header: "Payment Type",
        style: columnStyle,
        headerStyle,
      },
      {
        id: "amount",
        Header: "Amount",
        accessor: (row) =>
          (row.Amount && currency(row.Amount)) ||
          (row.amount_refunded && (
            <div style={columnStyleRed}>{currency(row.amount_refunded)}</div>
          )),
        style: columnStyle,
        color: "red",
        maxWidth: 75,
        headerStyle,
      },
      {
        id: "source",
        accessor: "source",
        Header: "Source",
        accessor: (row) => `${row.source} ${row.cardType} ${row.lastFour}`,
        style: columnStyle,
        headerStyle,
      },
      {
        Header: "Status",
        accessor: "status",
        style: columnStyle,
        maxWidth: 75,
        headerStyle,
      },
      {
        accessor: "message",
        Header: "Message",
        style: columnStyle,
        headerStyle,
      },
      {
        accessor: "reference_id",
        Header: "Ref#",
        style: columnStyle,
        headerStyle,
      },
      {
        accessor: "chargeID",
        Header: "Charge ID",
        style: columnStyle,
        headerStyle,
      },
    ];

    if(showMemberID) {
      chargeColumns.push({
        accessor: "memberID",
        Header: "Member ID",
        style: columnStyle,
        show: showMemberID,        
        Cell: (row) =>
          row?.value === "" ? (
            "Unassigned"
          ) : (
            <Link to={`/members/${row.value}/details`}>{row.value}</Link>
          ),
      });
    }
    chargeColumns.push({
      Header: "",
      accessor: "id",
      filterable: false,
      style: columnButtonContainerStyle,
      maxWidth: 75,
      Cell: (i) => (
        <div>
          <Button
            style={columnButtonStyle}
            onClick={() => {
              setSelectedPayment(i.original);
              setOpenRefund(true);
            }}
            variant="contained"
            color="secondary"
            disabled={
              !i.original.isRefundable ||
              !isReverisble(i.original.Amount, i.original.paymentType)
            }
            size="small"
            title="Refund"
          >
            R
          </Button>

          <Button
            style={columnButtonStyle}
            onClick={() => {
              setSelectedPayment(i.original);
              setOpenChargeback(true);
            }}
            variant="contained"
            color="secondary"
            disabled={!isReverisble(i.original.Amount, i.original.paymentType)}
            size="small"
            title="Charge Back"
          >
            C
          </Button>
        </div>
      ),
    });
    
    const time = moment.tz();
    const localtz = moment.tz.guess();
    const date = time.clone().tz(localtz);
    const [startDate, updateStartDate] = useState(
      moment(date).format("YYYY-MM-DD 00:00:00")
    );
    const [endDate, updateEndDate] = useState(
      moment(date).format("YYYY-MM-DD 23:59:59")
    );

    

    useEffect(() => {
      onFetchChargeList(memberID, startDate, endDate);
    }, []);    

    return (
      <>
        {showDateFilter && (
          <div className={classes.tableOptionsContainer}>
            <div className={classes.dateSelectors} border="1px red solid">
              <label>Start Date: </label>
              <KeyboardDatePicker
                dateformat="YYYY-MM-DD"
                value={moment(startDate).tz(localtz)}
                maxDate={moment(endDate)}
                onChange={() => null}
                onAccept={(value) =>
                  updateStartDate(moment(value).format("YYYY-MM-DD"))
                }
                views={["year", "month", "date"]}
                style={{ margin: "15px 0px", width: "200px" }}
              />
              <label>End Date: </label>
              <KeyboardDatePicker
                dateformat="YYYY-MM-DD"
                value={moment(endDate).tz(localtz)}
                onChange={() => null}
                maxDate={moment()}
                onAccept={(value) => {
                  updateEndDate(moment(value).format("YYYY-MM-DD 23:59:59"));
                }}
                views={["year", "month", "date"]}
                style={{ margin: "15px 0px", width: "200px" }}
              />
            </div>
            <Button
              color="primary"
              varian="raised"
              onClick={async () => {
                await onFetchChargeList(memberID, startDate, endDate);
              }}
            >
              Refresh
            </Button>
          </div>
        )}
        <div>
          <Grid container>
            <Grid item md={6}>
              <h4>Payment History</h4>
            </Grid>

            {memberID && <Grid item md={6} style={{ alignSelf: "flex-end" }}>
              <div style={{ float: "right", marginBottom: "10px" }}>
                <IconButton
                  aria-label="Record Payment"
                  size="medium"
                  title="Record Payment"
                  classes={{ label: classes.iconButtonLabel }}
                  color="primary"
                  onClick={() => setOpenAddPayment(true)}
                >
                  <AddCircleOutlineIcon fontSize="large" />
                </IconButton>
                <Dialog
                  className={classes.dialog}
                  onClose={() => setOpenAddPayment(false)}
                  aria-labelledby="customized-dialog-title"
                  open={openAddPayment}
                >
                  <AddPaymentInfo
                    closePopup={() => setOpenAddPayment(false)}
                    memberId={memberID}
                  />
                </Dialog>
              </div>
            </Grid>}
          </Grid>
          <ReactTable
            data={chargeDataArray}
            columns={chargeColumns}
            loading={isLoading}
            defaultPageSize={10}
            className="-striped -highlight"
            filterable
          />
          <Dialog
            className={classes.dialog}
            onClose={setOpenRefund}
            aria-labelledby="customized-dialog-title"
            open={openRefund}
          >
            <RefundForm
              closePopup={() => setOpenRefund(false)}
              paymentForRefund={selectedPayment}
              memberID={memberID}
              startDate={showDateFilter && startDate}
              endDate={showDateFilter && endDate}
            />
          </Dialog>
          <Dialog
            className={classes.dialog}
            onClose={setOpenChargeback}
            aria-labelledby="customized-dialog-title"
            open={openChargeback}
          >
            <ChargeBackForm
              closePopup={() => setOpenChargeback(false)}
              paymentForChargeback={selectedPayment}
              memberID={memberID}
              startDate={showDateFilter && startDate}
              endDate={showDateFilter && endDate}
            />
          </Dialog>
        </div>
      </>
    );
};

PaymentHistory.propTypes = {
};



const mapStateToProps = (state) => ({
    cardList: state.paymentCardList,
    chargeList: state.paymentChargeList,
    confirmDeleteModalOpen: state.payments.confirmDeleteModalOpen,
    cardLastFour: state.payments.cardLastFour,
    errorMessage: state.payments.errorMessage,
    successMessage: state.payments.successMessage,
    showSnackBar: state.payments.showSnackBar,
    isLoading: state.payments.isLoading,
    userID: state.selectedMember.get("userID"),
    memberID: state.selectedMember.get("memberID"),
});



export default connect(mapStateToProps)(PaymentHistory);
