import * as constants from "../../constants";
import selectDnpRnpHistoryContainer from "../selectors/dnpRnpHistory";
import fetch from "../util/api-ajax";

const createErrorMessage = (message) => ({
    type: constants.DNP_SET_ERROR_MESSAGE,
    message,
});

const createSuccessMessage = (message) => ({
    type: constants.DNP_SET_SUCCESS_MESSAGE,
    message,
});

export const setErrorMessage = (message) => (dispatch) => {
    dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
    dispatch(createSuccessMessage(message));
};

const getMeterDnpRnpTransactions = (
    dispatch,
    meterRecordID,
    status,
    page,
    limit,
) => {
    const payload = {
        meterRecordID,
        page,
        limit,
    };
    if (status !== "all") {
        payload.status = status;
    }
    return fetch(constants.DNP_FETCH_CANDIDATES_URL, payload)
        .then((res) => {
            const data = {
                ...res,
                transactions: res.list.sort((a, b) => a.ID > b.ID),
            };

            dispatch({
                type: constants.DNP_RNP_LOAD_TRANSACTIONS,
                ...data,
            });
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

export const doFetchMemberDnpTransactions = () => (
    dispatch,
    getState,
) => {
    const { status, page, limit } = selectDnpRnpHistoryContainer()(getState());
    const store = getState();    
    const accountID = store.selectedMember.get("accountID"); 
    const payload = {
        page,
        limit,      
        accountID,
    };
    if (status !== "all") {
        payload.status = status;
    }
    return fetch(constants.DNP_FETCH_DISCONNECT_NOTICE_URL, payload)
        .then((res) => {
            const data = {
                ...res,
                transactions: res.list.sort((a, b) => a.ID > b.ID),
            };

            // extract meta_data values
            data.transactions.forEach((d, i) => {
                data.transactions[i] = { ...d, ...JSON.parse(d.meta_data) };
            });

            dispatch({
                type: constants.DNP_RNP_LOAD_TRANSACTIONS,
                ...data,
            });
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

export const doFetchAllDnpTransactions = () => (dispatch, getState) => {
    const { status, page, limit } = selectDnpRnpHistoryContainer()(getState());
    const payload = {
        page,
        limit,
    };
    if (status !== "all") {
        payload.status = status;
    }
    return fetch(constants.DNP_FETCH_DISCONNECT_NOTICE_URL, payload)
        .then((res) => {
            const data = {
                ...res,
                transactions: res.list.sort((a, b) => a.ID > b.ID),
            };
            
            // extract meta_data values
            data.transactions.forEach(
                (d,i) => { data.transactions[i] = { ...d, ...JSON.parse(d.meta_data) } }
            ); 
            
            dispatch({
                type: constants.DNP_RNP_LOAD_TRANSACTIONS,
                ...data,
            });
        })
        .catch((err) => {
            dispatch(setErrorMessage(err.message));
        });
};

const sendDnpRequest = (dispatch, meterRecordID) => {
    return fetch(constants.DNP_DISCONNECT_URL, {
        meterID: meterRecordID,
    })
        .then(() => {
            getMeterDnpRnpTransactions(dispatch, meterRecordID);
            dispatch({
                type: constants.DNP_RNP_OPEN_SNACKBAR,
                successMessage: "DNP request sent!",
            });
        })
        .catch((err) => {
            dispatch({
                type: constants.DNP_RNP_OPEN_SNACKBAR,
                errorMessage: err.message,
            });
        });
};

export const doSendDnpRequest = (memberID) => (dispatch) => {
    return fetch(constants.METER_SEARCH_URL, {
        memberID,
    }).then((data) => {
        if (data.meters.length) {
            const meter = data.meters[0];
            sendDnpRequest(dispatch, meter.ID).then(() => {
                doFetchMemberDnpTransactions(memberID)(dispatch);
            });
        }
    });
};

const sendRnpRequest = (dispatch, meterRecordID) => {
    return fetch(constants.DNP_RECONNECT_URL, {
        meterID: meterRecordID,
    })
        .then(() => {
            getMeterDnpRnpTransactions(dispatch, meterRecordID);
            dispatch({
                type: constants.DNP_RNP_OPEN_SNACKBAR,
                successMessage: "RNP request sent!",
            });
        })
        .catch((err) => {
            dispatch({
                type: constants.DNP_RNP_OPEN_SNACKBAR,
                errorMessage: err.message,
            });
        });
};

export const doSendRnpRequest = (memberID) => (dispatch) => {
    return fetch(constants.METER_SEARCH_URL, {
        memberID,
    }).then((data) => {
        if (data.meters.length) {
            const meter = data.meters[0];

            sendRnpRequest(dispatch, meter.ID).then(() => {
                doFetchMemberDnpTransactions(memberID)(dispatch);
            });
        }
    });
};

export const closeSnackbar = () => {
    return (dispatch) => dispatch({ type: constants.DNP_RNP_CLOSE_SNACKBAR });
};

export const updateFilters = (updatedInfo) => {
    return (dispatch) =>
        dispatch({ type: constants.DNP_RNP_UPDATE_FILTERS, updatedInfo });
};

export const updateDisconnectNoticeStatus = (id, status) => (dispatch) => {    
  return fetch(constants.BILLING_UPDATE_DISCONNECT_NOTICE_URL, {
    id,
    status,
  }).then((data) => {       
    if (data.success) {      
      const updatedInfo = { id, status };
      dispatch({ type: constants.DISCONNECT_NOTICE_UPDATE, updatedInfo });
    }
  });
};