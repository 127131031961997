import * as constants from "../../constants";

export function startProcessing() {
  return {
    type: constants.STATUS_INCREMENT_PROCESSING,
  }
}

export function finishProcessing() {
  return {
    type: constants.STATUS_DECREMENT_PROCESSING,
  }
}

function createErrorMessage(errorMessage) {
  return {
    type: constants.STATUS_ERROR_MESSAGE,
    payload: errorMessage
  }
}

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

function createSuccessMessage(successMessage) {
  return {
    type: constants.STATUS_SUCCESS_MESSAGE,
    payload: successMessage,
  }
}

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => (dispatch) => {
  dispatch({ type: constants.STATUS_CLOSE_SNACKBAR });
};
