import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  topPanel: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dateSelector: {
    display: "flex",
    alignItems: "center",
    margin: "0px 15px",
    "& #date-selector-label": {
      fontSize: "15px",
      marginRight: "15px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const useTableStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    padding: "15px",
    marginTop: "20px",
    marginBottom: "80px",
    border: "1px solid #ddd",
    "& h1": {
      fontSize: "19px",
      textDecoration: "underline",
      paddingBottom: "15px",
      margin: 0,
      display: "flex",
      justifyContent: "space-between",
      "& .MuiSvgIcon-root": {
        color: "#3f51b5",
        transition: "0.15s all linear",
        "&:hover": {
          transform: "scale(1.05)",
        },
      },
    },
    "& .MuiTableCell-root": {
      padding: "5px",
    },
    "& .MuiTableRow-root": {
      "&:hover": { backgroundColor: "lemonchiffon" },
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "15px",
      color: "white",
      backgroundColor: "#333",
      whiteSpace: "pre",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
      border: "none",
    },
  },
  status: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& #status-label:hover": {
      textDecoration: "underline",
    },
  },
  sentButton: {
    color: "purple",
  },
});
