import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import ViewSelector from "../common/ViewSelector";
import SwipeableViews from "react-swipeable-views";
import { useStyles } from "./styles";

const EnrollmentTabs = ({ children, setSelectedView }) => {
  const classes = useStyles();
  const theme = useTheme();
  const viewNames = ["Search Enrollments", "Held Enrollments"];
  const [activeView, setActiveView] = useState(0);

  useEffect(() => {
    setSelectedView(viewNames[activeView])
  }, [activeView]);

  return (
    <div className={classes.viewContainer}>
      <ViewSelector
        viewNames={viewNames}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        {viewNames.map((option, index) => {
          return (
            <Tab
              key={index}
              className={activeView === index ? `view activeView` : `view`}
              label={option}
            ></Tab>
          );
        })}
      </ViewSelector>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeView}
      >
        {children}
      </SwipeableViews>
    </div>
  );
};

export default EnrollmentTabs;
