import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import Snackbar from "../Snackbar"
import Tab from "@material-ui/core/Tab";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

import EventsTable from "./EventsTable";
import ChannelPreferencesTable from "./ChannelPreferencesTable";
import ViewSelector from "../common/ViewSelector";
import SwipeableViews from "react-swipeable-views";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      "& #iterable-logo": {
        cursor: "pointer",
        width: "25px",
        margin: "0px 10px",
        padding: "5px",
        borderRadius: "99999px",
        border: "2px solid #ddd",
        transition: "0.2s all ease",
        "&:hover": {
          transform: "scale(1.1)",
        },
      },
    },
  },
  selectedViewHeader: {
    fontSize: "17px",
    padding: "20px 0px",
    paddingLeft: "15px",
    margin: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Iterable = (props) => {
  const {
    closeSnackbar,
    isProcessing,
    requestIterableUserInfo,
    requestIterableUserEvents,
    requestIterableEmailView,
    requestIterableChannels,
    requestIterableCampaigns,
    requestIterableUserData,
    member,
    showSnackBar,
    successMessage,
    errorMessage,
    createErrorMessage,
    events,
    dispatch,
    user,
    channels,
    channelCollection,
    campaignCollection,
    filteredEvents,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const viewNames = ["Event History", "Channel Preferences"];
  const [activeView, setActiveView] = useState(0);
  const [toggleEventFilter, setToggleEventFilter] = useState(true);

  useEffect(() => {
    if (member.email) {
      requestIterableUserData(member.email);
    }
  }, [member.email]);

  const createMarkup = (htmlData) => {
    var myWindow = window.open("", "_blank");
    myWindow.document.write(htmlData);
  };

  const openEmailView = async (memberId) => {
    try {
      const htmlData = await requestIterableEmailView(member.email, memberId);
      createMarkup(htmlData);
    } catch (err) {
      dispatch(createErrorMessage(err.message));
    }
  };

  const selecteViewName = viewNames[activeView];
  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">
        Iterable
        <Tooltip placement="start-bottom" title="view user">
          <img
            id="iterable-logo"
            src={
              "https://assets.iterable.com/assets/images/logos/updated/logo_only.png"
            }
            onClick={() => {
              window.open(
                `https://app.iterable.com/users/profiles/${member.email}`,
                "_blank"
              );
            }}
          />
        </Tooltip>
      </h1>
      <ViewSelector
        viewNames={viewNames}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        {viewNames.map((option, index) => {
          return (
            <Tab
              key={index}
              className={activeView === index ? `view activeView` : `view`}
              label={option}
              Î
            ></Tab>
          );
        })}
      </ViewSelector>
      <h1 className={classes.selectedViewHeader}>{selecteViewName}</h1>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeView}
      >
        <EventsTable
          email={member.email}
          totalEvents={events.length}
          events={toggleEventFilter ? filteredEvents : events}
          toggleEventFilter={toggleEventFilter}
          setToggleEventFilter={setToggleEventFilter}
          channelCollection={channelCollection}
          campaignCollection={campaignCollection}
          openEmailView={openEmailView}
        />
        <ChannelPreferencesTable user={user} channels={channels} />
      </SwipeableViews>
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

Iterable.propTypes = {};

export default Iterable;
