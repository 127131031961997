import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { fetchMemberDeposit } from "./search";

function setIsFetching(isFetching) {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching,
  };
}

export const createErrorMessage = (errorMessage) => ({
  type: constants.METER_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};


export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.METER_CLOSE_SNACKBAR });
};

function isProcessing(payload) {
  return {
    type: constants.SET_METER_IS_PROCESSING,
    payload: payload,
  };
}

function requestMeters() {
  return {
    type: constants.REQUEST_METERS,
    isFetching: true,
  };
}

function recieveMeters(data) {
  return {
    type: constants.RECEIVE_METERS,
    isFetching: false,
    data,
  };
}

const dunsTable = {
  "007924772": "AEP Central",
  "007923311": "AEP North",
  957877905: "Centerpoint",
  1039940674000: "Oncor",
  1052623364500: "Sharyland",
  "007929441": "TNMP",
};

export function fetchMeters(searchObj) {
  return (dispatch) => {
    dispatch(isProcessing(true));
    dispatch(requestMeters());
    let path = constants.METER_SEARCH;
    //   TODO: MeterLook up should change ESI namespace to MeterIdentifier
    if (searchObj.esiID) {
      path = constants.METER_LOOKUP;
    }
    let metersResult = [];
    return fetch(path, searchObj)
      .then((data) => {
        if (data && data.esi) {
          metersResult.push(data);
        } else if (data && data.meters) {
          const { meters } = data;
          metersResult = meters;
        } else {
          metersResult.push({ no_results: true });
        }

        const newMeters = metersResult.map((m) => {
          const newMeter = m;
          newMeter.duns = dunsTable[m.duns];
          return newMeter;
        });
        dispatch(recieveMeters(newMeters));
        dispatch(isProcessing(false));
      })
      .catch((error) => {
        dispatch(isProcessing(false));
        dispatch(setErrorMessage(error.message));
      });
  };
}

export function fetchRefundMeterDeposit(meterId, memberId, meterIdentifier) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.METER_REFUND_DEPOSIT, {
      meter_id: meterId,
      member_id: memberId,
      meter_identifier: meterIdentifier,
    })
      .then((data) => {
        dispatch(fetchMemberDeposit(memberId));
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}
