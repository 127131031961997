import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  topPanel: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dateSelector: {
    display: "flex",
    alignItems: "center",
    margin: "0px 15px",
    "& #date-selector-label": {
      fontSize: "15px",
      marginRight: "15px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  subRowContainer: {
    display: "flex",
  },
}));

export const useTableStyles = makeStyles({
  tableOptionsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  dateSelectors: {
    "& label": {
      fontWeight: "bold",
    },
  },
  subTable: {
    minWidth: "350px",
    maxWidth: "400px",
  },
  table: {
    "& .rt-th:nth-child(2), .rt-td:nth-child(2)": {
      width: "100px !important",
      flex: "unset !important",
    },
    "& .rt-tbody": {
      minWidth: "unset !important",
    },
    "& .MuiTableCell-root": {
      padding: "5px",
    },

    "& .MuiTableRow-root": {
      "&:hover": { backgroundColor: "lemonchiffon !important" },
    },
    "& .rt-tr.-odd + .MuiTable-root": {
      "& .MuiTableRow-root:nth-child(even)": {
        backgroundColor: "rgba(0,0,0,0.03) !important",
        "&:hover": { backgroundColor: "lemonchiffon !important" },
      },
    },
    "& .rt-tr.-even + .MuiTable-root": {
      "& .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: "rgba(0,0,0,0.03) !important",
        "&:hover": { backgroundColor: "lemonchiffon !important" },
      },
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
      border: "none",
    },
  },
  sentButton: {
    color: "purple",
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
});
