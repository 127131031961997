import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useStyles, useTableStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";
import ReferralTables from "./ReferralTables";
import UpdateReferrals from "./UpdateReferrals";
import Button from "../Button";
import Snackbar from "../Snackbar";

import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import ReactTable from "react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Link } from "react-router";

import currency from "../../util/currency-formatter";
import moment from "moment";

const ReferralCreditsApproval = (props) => {
  const classes = useStyles();
  const tableStyles = useTableStyles();

  const {
    doApproveReferralCredits,
    doFetchReferralCredits,
    doFetchReferralCreditSummaries,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
    referralCreditsSummaries: summaries,
    referralCreditsSummariesIndex,
    total,
    hasNext,
  } = props;

  const [expandedRows, setExpandedRows] = useState([]);
  const [referralCreditsSummaries, setReferralCreditsSummaries] = useState([]);
  const [updateReferralCreditsPopup, setUpdateReferralCreditsPopup] =
    useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    doFetchReferralCreditSummaries({ isInitialLoad: true });
  }, []);

  useEffect(() => {
    setReferralCreditsSummaries(summaries);
  }, [summaries]);

  useEffect(() => {
    // Set all referral to be checked based on master select
    let newArr = referralCreditsSummaries.map((item) => {
      return { ...item, checked: selectAll };
    });
    setReferralCreditsSummaries(newArr);
  }, [selectAll]);

  const handleCheckedBox = (user_id, value) => {
    let newArr = referralCreditsSummaries.map((item) =>
      item.user_id === user_id
        ? { ...item, checked: value ? value : !item.checked }
        : item
    );
    setReferralCreditsSummaries(newArr);
  };

  const handleCloseUpdateReferralCreditsPopup = () => {
    setUpdateReferralCreditsPopup(false);
  };

  // closure to manage table row id for expand
  const tableExpandCallBack = (index) => {
    return (collapse) => {
      expandedRows[index] = collapse;
      setExpandedRows([...expandedRows]);
    };
  };

  const handleToggleExpand = (row) => {
    const user_id = row.row.user_id;

    // nestingPath maintains original table index for expanding
    let index = row.nestingPath;
    let prevExpandState = expandedRows[index];

    // fetch referral_credits details and expand if it does not exist
    if (!referralCreditsSummariesIndex[user_id].referral_credits) {
      expandedRows[index] = false;
      setExpandedRows([...expandedRows]);

      const captureIndex = tableExpandCallBack(index);
      const expandRow = () => captureIndex(true);

      doFetchReferralCredits({ user_id }, expandRow);
    } else {
      // maintain row expand state
      expandedRows[index] = !prevExpandState;
      setExpandedRows([...expandedRows]);
    }
  };

  const resetRowExpandState = (filtered) => {
    // Clear the expanded rows when filters change
    setExpandedRows([]);
  };

  const createCell = (value) => {
    return <div style={{ textAlign: "center" }}>{value}</div>;
  };

  const subComponent = (row) => {
    const referralCredits =
      referralCreditsSummariesIndex[row.row.user_id].referral_credits;

    return referralCredits && referralCredits.length > 0 ? (
      <TableContainer key={row.row.user_id}>
        <Table className={classes.table} aria-label="Slim Table">
          <TableHead className={tableStyles.tableHeaderRow}>
            <TableCell style={{ maxWidth: "150px" }} align="center">
              Member ID
            </TableCell>
            <TableCell align="center">Payment Date</TableCell>
            <TableCell align="center">Payment Amount</TableCell>
            <TableCell align="center">Qualified Count</TableCell>
            <TableCell align="center">Rebate Amount</TableCell>
          </TableHead>
          <TableBody className={tableStyles.tableBody}>
            {referralCredits.map((referralCredit, index) => {
              const metaData = referralCredit.meta_data;
              return (
                <TableRow key={index}>
                  <TableCell align="center">
                    <Link to={`/members/${referralCredit.member_id}/details`}>
                      {referralCredit.member_id}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {metaData?.payment_date
                      ? moment.utc(metaData?.payment_date).format("YYYY-MM-DD")
                      : "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {currency(metaData?.payment_amount)}
                  </TableCell>
                  <TableCell align="center">
                    {metaData?.qualified_count
                      ? metaData?.qualified_count
                      : "N/A"}
                  </TableCell>
                  <TableCell align="center">
                    {currency(metaData?.rebate_amount)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div>no records</div>
    );
  };

  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">Referral Credits</h1>
      <ReferralTables
        doFetchReferralCreditSummaries={doFetchReferralCreditSummaries}
        setUpdateReferralCreditsPopup={setUpdateReferralCreditsPopup}
      >
        <ReactTable
          data={referralCreditsSummaries}
          columns={[
            {
              id: "checkbox",
              accessor: "user_id",
              width: 129,
              resizable: false,
              sortable: false,
              style: {
                textAlign: "center",
              },
              Header: (
                <Tooltip placement="bottom" title="Select All">
                  <Checkbox
                    className={tableStyles.checkbox}
                    checked={selectAll}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectAll(!selectAll);
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </Tooltip>
              ),
              Cell: (row) => {
                return (
                  <Checkbox
                    className={tableStyles.checkbox}
                    checked={row.original.checked}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckedBox(row.value, !row.original.checked);
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                    }}
                  />
                );
              },
            },
            {
              // DO NOT REMOVE - needed to maintain table index for expanding
              Header: "User ID",
              accessor: "user_id",
              Cell: (row) => createCell(row.value),
              filterable: true,
              maxWidth: 255,
              show: false,
            },
            {
              Header: "Email",
              accessor: "email",
              Cell: (row) => createCell(row.value),
              filterable: true,
              maxWidth: 255,
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: (row) => createCell(row.value),
              filterable: true,
              maxWidth: 150,
            },
            {
              Header: "Total Rebate Amount",
              accessor: "total_credit_amount",
              Cell: (row) => createCell(currency(row.value)),
              filterable: true,
              maxWidth: 150,
            },
          ]}
          SubComponent={subComponent}
          className="-striped -highlight"
          defaultPageSize={10}
          getTrProps={(_, rowInfo) => ({
            onClick: () => handleToggleExpand(rowInfo),
          })}
          getTrGroupProps={() => ({
            style: {
              border: "1px solid rgba(0,0,0,0.1)",
              borderBottom: null,
            },
          })}
          expanded={expandedRows}
          onSortedChange={() => {
            resetRowExpandState();
          }}
          onFilteredChange={(a, c) => {
            resetRowExpandState(a);
          }}
        />
      </ReferralTables>
      <Dialog
        className={classes.dialog}
        onClose={handleCloseUpdateReferralCreditsPopup}
        aria-labelledby="customized-dialog-title"
        open={updateReferralCreditsPopup}
      >
        {referralCreditsSummaries.filter((s) => s.checked).length > 0 ? (
          <UpdateReferrals
            doApproveReferralCredits={doApproveReferralCredits}
            referralCreditsSummaries={referralCreditsSummaries}
            closeUpdatePopup={handleCloseUpdateReferralCreditsPopup}
            isProcessing={isProcessing}
          />
        ) : (
          <Dialog
            onClose={handleCloseUpdateReferralCreditsPopup}
            aria-labelledby="customized-dialog-title"
            open={updateReferralCreditsPopup}
          >
            <DialogContent dividers>
              Please select statements to adjust
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={handleCloseUpdateReferralCreditsPopup}
                color="secondary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Dialog>
      <div style={{ width: "100%" }}>
        {hasNext && (
          <Button
            onClick={() => {
              doFetchReferralCreditSummaries({ isInitialLoad: false });
            }}
            color="primary"
            variant="contained"
            style={{
              marginRight: 20,
              marginTop: 10,
              width: "150px",
            }}
            disabled={isProcessing}
          >
            Load More
          </Button>
        )}
        <div style={{ float: "right", marginTop: "10px" }}>
          Downloaded {referralCreditsSummaries.length} of {total} transactions
        </div>
      </div>
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

ReferralCreditsApproval.propTypes = {
  doFetchReferralCreditSummaries: PropTypes.func.isRequired,
  doApproveReferralCredits: PropTypes.func.isRequired,
  doFetchReferralCredits: PropTypes.func.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  showSnackBar: PropTypes.bool.isRequired,
  successMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  referralCreditsSummaries: PropTypes.array.isRequired,
  referralCreditsSummariesIndex: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  hasNext: PropTypes.bool.isRequired,
};

export default ReferralCreditsApproval;
