import { createSelector } from "reselect";

const renewal = () => (state) => state.renewal;

const selectRenewalContainer = () =>
  createSelector(renewal(), (data) => {
    return data;
  });

export default selectRenewalContainer;
