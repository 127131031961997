import React from "react";
import { connect } from "react-redux";
import MemberTOS from "../components/TransferOfService";
import { fetchMeters, closeSnackbar } from "../actions/meters";
import { requestMeterInfo } from "../actions/search";
import { updateEnrollment } from "../actions/transferOfService";

import selectTosContainer from "../selectors/transferOfService";

const MemberTOSContainer = (props) => <MemberTOS {...props} />;

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  memberID: state.selectedMember.get("memberID"),
  meter:
    typeof state.meterResult.first() !== "undefined"
      ? state.meterResult.first()
      : null,
  ...selectTosContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  doFetchMeters: (s, e) => {
    dispatch(fetchMeters(s, e));
  },
  doRequestMeterInfo: (memberID) => {
    dispatch(requestMeterInfo(memberID));
  },
  doUpdateEnrollment: ({
    meterUID,
    moveOutDate,
    requestedStartDate,
    serviceAddress,
    updateBillingAddress,
  }) => {
    dispatch(
      updateEnrollment({
        meterUID,
        moveOutDate,
        requestedStartDate,
        serviceAddress,
        updateBillingAddress,
      })
    );
  },
  closeSnackbar: () => {
    dispatch(closeSnackbar());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberTOSContainer);
