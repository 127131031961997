import React, { useState } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button"
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { FormControl, FormLabel, FormControlLabel } from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";

import { meterUpdate } from "../../actions/members";

const useStyles = makeStyles({
  root: {
    width: "300px",
  },
  disconnectTypeForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const SetDiconnectType = (props) => {
  const classes = useStyles();  

  const { meterID, onMeterUpdate, closePopup, handleDisconnectTypeChange } = props;
  const { endDateMeter, startDateMeter, skipNotification } = props;
  const [disconnectType, setDisconnectType] = useState("switch");
  
   const handleChange = (event) => {
    setDisconnectType(event.target.value);    
  };

  const submitForm = () => {
    onMeterUpdate(meterID, { 
      start_date: startDateMeter,
      end_date: endDateMeter,
    }, endDateMeter, { 
      disconnect_type: disconnectType,
      start_date: startDateMeter,
      null_start_date: startDateMeter === null,
      end_date: endDateMeter,
      null_end_date: endDateMeter === null,
      skip_meter_status_notification: skipNotification,
    });
    handleDisconnectTypeChange(disconnectType);
    closePopup(true);
  };

  const handleClose = () => {
    closePopup(false);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Set Disconnect Type</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>

        <FormControl className={classes.disconnectTypeForm}>
          <FormLabel id="demo-radio-buttons-group-label">
            Select Disconnect Type
          </FormLabel>
          <RadioGroup
            aria-labelledby="select disconnect type"
            defaultValue="switch"
            name="disconnect-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="switch"
              control={<Radio />}
              label="Switch"
            />
            <FormControlLabel value="move" control={<Radio />} label="Move" />
          </RadioGroup>
        </FormControl>

        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
          }}
        >
          <Button
            color="secondary"
            varian="raised"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onMeterUpdate: (meterID, property, value, moreProps) =>
    dispatch(meterUpdate(meterID, property, value, moreProps)),
  dispatch,
});

const ConnectedSetDiconnectType = connect(
  mapStateToProps,
  mapDispatchToProps
)(SetDiconnectType);

export default ConnectedSetDiconnectType;
