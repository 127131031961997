import sha256 from "sha256";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import moment from "moment";

function requestOnlyBillingTransactions() {
  return {
    type: constants.REQUEST_BILLING_TRANSACTIONS,
  };
}

function requestAdjustment() {
  return {
    type: constants.REQUEST_BILLING_ADJUSTMENT,
    isFetching: true,
  };
}

function recieveAdjustment(response, property, value) {
  if (response.success === true) {
    response.msg = `Success: ${property} updated to ${value}`;
  } else {
    response.msg = response.error || `Error - Updating ${property}`;
  }
  return {
    type: constants.RECEIVE_BILLING_ADJUSTMENT,
    isFetching: false,
    response,
  };
}

export function initializeTransactionSummary(obj) {
  return (dispatch) => {
    dispatch(setFetchingState(true));
    try {
      dispatch(requestAcceptedTypeSubtypePairs());
      dispatch(requestAllBillingTransactions(obj.memberID));
    } finally {
      dispatch(setFetchingState(false));
    }
  }
}

function setFetchingState(isFetching) {
  return {
    type: constants.SET_BILLING_FETCHING_STATE,
    isFetching,
  };
}

function receivedAcceptedTypeSubtypePairs(payload) {
  return {
    type: constants.RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS,
    payload,
  };
}

function requestAcceptedTypeSubtypePairs() {
  return (dispatch) =>
    fetch(constants.GET_ACCEPTED_TYPE_SUBTYPE_PAIRS).then((res) => {
      dispatch(receivedAcceptedTypeSubtypePairs(res));
    });
}

function receivedAllBillingTransactions(payload) {
  return {
    type: constants.RECEIVED_ALL_BILLING_TRANSACTIONS,
    payload,
  };
}

export function requestAllBillingTransactions(memberID, page = 1) {
  return (dispatch) =>
    fetch(constants.BILLING_TRANSACTIONS, {
      memberID,
      page,
      limit: 10,
    }).then((res) => {
      dispatch(receivedAllBillingTransactions(res));
    });
}

export const loadMoreBillingTransactions =
  (memberID, page = 1) =>
  (dispatch) => {
    dispatch(requestOnlyBillingTransactions());
    fetch(constants.BILLING_TRANSACTIONS, {
      memberID,
      page,
      limit: 10,
    }).then((res) => {
      dispatch(receivedAllBillingTransactions(res));
    });
  };

export function applyAdjustment(
  userId,
  memberId,
  type,
  subType,
  applyType,
  amount,
  comment,
  date
) {
  return (dispatch) => {
    dispatch(requestAdjustment());
    const metaData = {
      notes: comment,
      userID: String(userId),
    };
    const multiplier = applyType === "debit" ? -1 : 1;
    const payload = {
      date: date.toISOString(),
      memberID: memberId,
      amount: `${Math.abs(amount) * multiplier}`,
      meta_data: metaData,
    };
    payload.hashID = sha256(JSON.stringify(payload));
    try {
      payload.credit_type = type;
      payload.credit_subtype = subType;
      fetch(constants.BILLING_CREDIT, payload)
        .then((response) => dispatch(recieveAdjustment(response, type, amount)))
        .then(() => {
          const date = moment();
          dispatch(
            initializeTransactionSummary({
              memberID: memberId,
            })
          );
        });
    } catch (err) {
      const response = {
        success: false,
        msg: "not credit or debit",
      };
      return dispatch(recieveAdjustment(response, type, amount));
    }
  };
}

export function hideSnackBar() {
  return {
    type: constants.HIDE_SNACKBAR,
  };
}

export function requestMonthlyCharges(memberID, month, year) {
  return (dispatch) => {
    dispatch(setFetchingState(true));

    return fetch(constants.BILLING_MONTHLY_CHARGES, { memberID, month, year })
      .then((res) => {
        dispatch(receiveBillingMonthlyCharges(res));
      })
      .finally(() => {
        dispatch(setFetchingState(false));
      });
  };
}

function receiveBillingMonthlyCharges(payload) {
  return {
    type: constants.RECIEVE_BILLING_MONTHLY_CHARGES,
    payload: payload,
  };
}
