import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../../Button"
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, FormLabel } from "@material-ui/core/";
import { noselect } from "../../../styles/common.css";
import { addPayment } from "../../../actions/members";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  addPaymentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  addPaymentText: {    
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",    
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    pointerEvents: "none",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const AddPaymentInfo = (props) => {
  const classes = useStyles();
  const [amount, setAmount] = useState();
  const [chargeID, setChargeID] = useState();
  const [fundingSource, setFundingSource] = useState();
  const [referenceID, setReferenceID] = useState();
  const [paymentType, setPaymentType] = useState();
  const { closePopup, onAddPayment } = props;
  const {memberId} = props;
  

  const submitForm = () => {     
    onAddPayment(memberId, amount, chargeID, fundingSource, referenceID, paymentType);      
    closePopup();
  };

  const validateInput = () => {
      return amount && chargeID && fundingSource && amount > 0;
  }

  const handleClose = () => {
    closePopup();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Record Payment</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>

        <FormControl className={classes.addPaymentForm}>
          <FormLabel id="demo-radio-buttons-group-label">
            <h4>Payment Information</h4>
          </FormLabel>

          <TextField
            label="Amount"
            placeholder="0.00"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            type="number"
            required
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.addPaymentText}
          />
          <TextField
            label="Charge ID"
            onChange={(e) => setChargeID(e.target.value)}
            value={chargeID}
            type="text"
            required
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.addPaymentText}
          />

          <TextField
            select
            label="Funding Source"
            size="small"
            required
            value={fundingSource}
            onChange={(e) => setFundingSource(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.addPaymentText}
          >
            <MenuItem value="chase">Chase</MenuItem>
            <MenuItem value="iCheckIVR">iCheck IVR</MenuItem>
            <MenuItem value="lockbox">Lockbox</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>

          <TextField
            select
            label="Type"
            size="small"
            required
            value={paymentType}
            onChange={(e) => setPaymentType(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.addPaymentText}
          >
            <MenuItem value="Amex">Amex</MenuItem>
            <MenuItem value="Discover">Discover</MenuItem>
            <MenuItem value="MasterCard">MasterCard</MenuItem>
            <MenuItem value="Visa">Visa</MenuItem>
            <MenuItem value="ACH">ACH</MenuItem>
            <MenuItem value="Diners">Diners</MenuItem>
            <MenuItem value="Cheque">Cheque</MenuItem>
          </TextField>

          <TextField
            label="Reference ID"
            onChange={(e) => setReferenceID(e.target.value)}
            value={referenceID}
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.addPaymentText}
          />
        </FormControl>

        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
          }}
        >
          <Button
            color="secondary"
            varian="raised"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            className={
              validateInput() ? classes.enableButton : classes.disableButton
            }
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
            disable={true}
          >
            Save
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onAddPayment: (
    memberId,
    amount,
    chargeID,
    fundingSource,
    referenceID,
    paymentType,
  ) =>
    dispatch(
      addPayment(
        memberId,
        amount,
        chargeID,
        fundingSource,
        referenceID,
        paymentType,
      )
    ),
  dispatch,
});

const ConnectedAddPaymentInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPaymentInfo);

export default ConnectedAddPaymentInfo;
