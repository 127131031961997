import * as constants from "../../constants";
import fetch from "../util/api-ajax";

const createErrorMessage = (message) => ({
  type: constants.ENROLLMENT_SET_ERROR_MESSAGE,
  message,
});

const createSuccessMessage = (message) => ({
  type: constants.ENROLLMENT_SET_SUCCESS_MESSAGE,
  message,
});

export const closeSnackbar = () => (dispatch) => {
  dispatch({ type: constants.ENROLLMENT_CLOSE_SNACKBAR });
};

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const fetchEnrollments = (searchCriteria, isHold = false) => (dispatch, getState) => {
  dispatch({
    type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
  });
  return fetch(constants.ENROLLMENT_SEARCH_URL, searchCriteria).then((data) => {
    if (isHold) {
      dispatch({
        type: constants.ENROLLMENT_SEARCH_SUCCESS,
        heldEnrollments: data.list,
      });
    } else {
      dispatch({
        type: constants.ENROLLMENT_SEARCH_SUCCESS,
        enrollments: data.list,
      });
    }
  }).finally(() => {
    dispatch({
      type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
    })
  });
};

export const fetchCancelEnrollments = (obj, isHold = false) => (dispatch, getState) => {
  dispatch({
    type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
  });

  const cancelPayload = { enrollmentID: obj.enrollment_id, note: obj.notes, status: obj.status };

  return fetch(constants.ENROLLMENT_CANCEL_URL, cancelPayload).then(async (data) => {
    dispatch(fetchEnrollments(obj.searchCriteria, isHold))
  }).catch(async err => {
    dispatch(setErrorMessage(err.message));
    dispatch(fetchEnrollments(obj.searchCriteria, isHold))
  }).finally(() => {
    dispatch({
      type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
    })
  });
};

export const fetchUpdateEnrollmentName = (obj, isHold = false) => (dispatch, getState) => {
  dispatch({
    type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
  });
  return fetch(constants.ENROLLMENT_UPDATE_NAME_URL, obj.update_enrollment).then(async (data) => {
    dispatch(fetchEnrollments(obj.searchCriteria, isHold))
  }).catch(async err => {
    dispatch(setErrorMessage(err.message));
    dispatch(fetchEnrollments(obj.searchCriteria, isHold))
  }).finally(() => {
    dispatch({
      type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
    })
  });
};

export const fetchSendVerification = (obj, isHold = false) => (dispatch, getState) => {
  dispatch({
    type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
  });
  return fetch(constants.ENROLLMENT_HANDLE_ACTIONS_URL, {
    enrollmentID: obj.enrollment.id
  }).then(async (data) => {
    dispatch(fetchEnrollments(obj.searchCriteria, isHold))
  }).catch(async err => {
    dispatch(setErrorMessage(err.message));
    dispatch(fetchEnrollments(obj.searchCriteria, isHold))
  }).finally(() => {
    dispatch({
      type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
    })
  });
};

export const resubmitEnrollment =
  (
    searchCriteria,
    enrollmentID,
    is_waived_deposit,
    is_completed,
    is_verified,
    is_identity_verified,
    is_release_exception,
    note,
    isHold = false
  ) =>
    (dispatch) => {
      dispatch({
        type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
      });
      return fetch(constants.ENROLLMENT_RESUBMIT_URL, {
        enrollmentID,
        is_waived_deposit,
        is_completed,
        is_verified,
        is_identity_verified,
        is_release_exception,
        note
      })
        .then((data) => {
          dispatch(fetchEnrollments(searchCriteria, isHold))
        })
        .finally(() => {
          dispatch({
            type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
          });
        })
        .catch(err => {
          dispatch(setErrorMessage(err.message));
        });
    };

export const fetchAddNote = ({ enrollment_id, note, searchCriteria }) =>
  (dispatch) => {
    dispatch({
      type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
    });
    return fetch(constants.ENROLLMENT_ADD_NOTE_URL, { enrollment_id, note }).then(async () => {
      dispatch(fetchEnrollments(searchCriteria))
    }).catch(async err => {
      dispatch(setErrorMessage(err.message));
      dispatch(fetchEnrollments(searchCriteria))
    }).finally(() => {
      dispatch({
        type: constants.ENROLLMENT_SEARCH_TOGGLE_PROCESSING,
      })
    });
  }
