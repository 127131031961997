import React, { Component } from "react";
import { connect } from "react-redux";
import ConnectedMemberPayment from "./MemberPayment";

class MemberPaymentContainer extends Component {
    render() {
        return (this.props.userID && <ConnectedMemberPayment />) || <div />;
    }
}

const mapStateToProps = (state) => ({
    userID: state.selectedMember.get("userID"),
});

export default connect(
    mapStateToProps,
    null,
)(MemberPaymentContainer);
