import React, { Component } from "react";
import ReactTable from "react-table";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import CreditCard from "@material-ui/icons/CreditCard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "../components/Button"
import Modal from "react-modal";
import Snackbar from "../components/Snackbar"
import { confirmationError, confirmationSuccess } from "../styles/common.css";
import {
    fetchCardList,
    fetchChargeList,
    toggleCharge,
    removePayment,
    toggleConfirmCardDelete,
    confirmPaymentDelete,
    clearErrorMessage,
    setDefaultCard,
    toggleConfirmDefaultCard,
    confirmDefaultCard,
} from "../actions/payment";
import PaymentHistory from "../components/Payment/PaymentHistory";
import modalStyle from "../styles/modal";

const columnStyle = {
    textAlign: "center",
};
const headerStyle = {
    fontWeight: "700",
};

const classes = {
    formActions: {
        marginTop: "15px",
    },
    actionButtons: {
        marginRight: "5px",
    },
};

class MemberPayment extends Component {
    componentDidMount() {
        const { onFetchCardList } = this.props;
        onFetchCardList(this.props.userID, this.props.memberID);
    }

    getCardTableData() {
        const cardArray = [];
        this.props.cardList.forEach((card) => {
            cardArray.push({
                default: card.get("default"),
                cardType: card.get("cardType"),
                lastFour: card.get("lastFour"),
                billingZip: card.get("address").get("postalCode"),
                expiry: `${card.get("expirationMonth")} / ${card.get(
                    "expirationYear"
                )}`,
                cardID: card.get("CardID"),
                paymentMethodID: card.get("paymentMethodID"),
            });
        });
        return cardArray;
    }
    render() {
        // defining columns for charge ReactTable component

        const {
            memberID,
            isLoading,
            showSnackBar,
            confirmDeleteModalOpen,
            onToggleConfirmCardDelete,
            onConfirmPaymentDelete,
            onRemovePayment,
            onClearErrorMessage,
            successMessage,
            errorMessage,
            cardLastFour,
            onFetchChargeList,
            confirmDefaultCardModalOpen,
            onToggleConfirmDefaultCard,
            onConfirmDefaultCard,
            onSetDefaultCard,
        } = this.props;
        // defining columns for card ReactTable component
        const cardColumns = [
            {
                Header: "Actions",
                accessor: "default",
                style: columnStyle,
                headerStyle,
                Cell: (row) => {
                    return (
                        <div>
                            {row.original.default ?
                                <strong>Default</strong>
                                : <IconButton
                                    title="Set as Default Card"
                                    onClick={() => {
                                        onConfirmDefaultCard(
                                            row.original.cardID,
                                            row.original.lastFour,
                                            row.original.paymentMethodID,
                                        )
                                    }}
                                    style={{
                                        marginLeft: 10,
                                        height: 16,
                                        width: 16,
                                    }}
                                >
                                    <CreditCard />
                                </IconButton>
                            }
                            <IconButton
                                title="Delete Card"
                                onClick={() =>
                                    onConfirmPaymentDelete(
                                        row.original.cardID,
                                        row.original.lastFour
                                    )
                                }
                                style={{
                                    marginLeft: 10,
                                    height: 16,
                                    width: 16,
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    );
                },
            },
            {
                Header: "Type",
                accessor: "cardType",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Last Four",
                accessor: "lastFour",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Expiry (Month / Year)",
                accessor: "expiry",
                style: columnStyle,
                headerStyle,
            },
            {
                Header: "Billing Zipcode",
                accessor: "billingZip",
                style: columnStyle,
                headerStyle,
            },
        ];

        const cardDataArray = this.getCardTableData();
        return (
            <div>
                <PaymentHistory
                    onFetchChargeList={onFetchChargeList}
                    memberID={memberID}
                />
                <h4>Payment Methods</h4>
                <ReactTable
                    data={cardDataArray}
                    columns={cardColumns}
                    loading={isLoading}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
                <Modal isOpen={confirmDeleteModalOpen} style={modalStyle.modal}>
                    <div>
                        Are you sure you want to delete this card ending in{" "}
                        {cardLastFour}?
                    </div>
                    <div style={classes.formActions}>
                        <Button
                            style={classes.actionButtons}
                            variant="contained"
                            color="primary"
                            onClick={async () => await onRemovePayment(this.props.memberID, this.props.userID)}
                        >
                            Yes
                        </Button>
                        <Button
                            style={classes.actionButtons}
                            variant="contained"
                            color="secondary"
                            onClick={onToggleConfirmCardDelete}
                        >
                            No
                        </Button>
                    </div>
                </Modal>
                <Modal isOpen={confirmDefaultCardModalOpen} style={modalStyle.modal}>
                    <div>
                        Are you sure you want set this card ending in{" "}
                        {cardLastFour} as the default payment method?
                    </div>
                    <div style={classes.formActions}>
                        <Button
                            style={classes.actionButtons}
                            variant="contained"
                            color="primary"
                            onClick={async () => await onSetDefaultCard(
                                this.props.userID,
                                this.props.memberID,
                                this.props.cardID,
                                this.props.paymentMethodID,
                            )}
                        >
                            Yes
                        </Button>
                        <Button
                            style={classes.actionButtons}
                            variant="contained"
                            color="secondary"
                            onClick={onToggleConfirmDefaultCard}
                        >
                            No
                        </Button>
                    </div>
                </Modal>
                <Snackbar
                    id="message-id"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={showSnackBar}
                    onClose={onClearErrorMessage}
                    className={
                        successMessage ? confirmationSuccess : confirmationError
                    }
                    message={successMessage || errorMessage}
                />
            </div>
        );
    }
}

MemberPayment.propTypes = {
    cardList: PropTypes.object,
    chargeList: PropTypes.object,
    onFetchCardList: PropTypes.func.isRequired,
    onFetchChargeList: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    userID: PropTypes.number.isRequired,
    memberID: PropTypes.string.isRequired,
};

MemberPayment.defaultProps = {
    cardList: [],
    chargeList: [],
};

const mapStateToProps = (state) => ({
    cardList: state.paymentCardList,
    chargeList: state.paymentChargeList,
    confirmDeleteModalOpen: state.payments.confirmDeleteModalOpen,
    cardLastFour: state.payments.cardLastFour,
    errorMessage: state.payments.errorMessage,
    successMessage: state.payments.successMessage,
    showSnackBar: state.payments.showSnackBar,
    isLoading: state.payments.isLoading,
    isFetching: state.payments.isFetching,
    userID: state.selectedMember.get("userID"),
    memberID: state.selectedMember.get("memberID"),
    confirmDefaultCardModalOpen: state.payments.confirmDefaultCardModalOpen,
    cardID: state.payments.cardID,
    paymentMethodID: state.payments.paymentMethodID,
});

const mapDispatchToProps = (dispatch) => ({
    onFetchCardList: (userID, memberID) => dispatch(fetchCardList(userID, memberID)),
    onFetchChargeList: (member) => dispatch(fetchChargeList(member)),
    onToggle: (idx) => dispatch(toggleCharge(idx)),
    onRemovePayment: (member, userID) => dispatch(removePayment(member, userID)),
    onToggleConfirmCardDelete: () => dispatch(toggleConfirmCardDelete()),
    onConfirmPaymentDelete: (cardID, cardLastFour) =>
        dispatch(confirmPaymentDelete(cardID, cardLastFour)),
    onClearErrorMessage: () => dispatch(clearErrorMessage()),
    onSetDefaultCard: (userID, memberID, cardID, paymentMethodID) =>
        dispatch(setDefaultCard(userID, memberID, cardID, paymentMethodID)),
    onToggleConfirmDefaultCard: () => dispatch(toggleConfirmDefaultCard()),
    onConfirmDefaultCard: (cardID, cardLastFour, paymentMethodID) =>
        dispatch(confirmDefaultCard(cardID, cardLastFour, paymentMethodID)),
});


export default connect(mapStateToProps, mapDispatchToProps)(MemberPayment);


