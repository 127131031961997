import styled from "styled-components";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import { FormControl } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export const RootContainer = styled.div`
  width: 400px;  
`;

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: none;
`;

export const Header = styled(Card)`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  background-color: #202124;
  color: white;
  letterSpacing: 1px;
  & .MuiSvgIcon-root: {
    cursor: pointer;
    &:hover: {
      background-color: #585858;
      color: white;
    };
  };
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 10px;
  transition: all 0.2s ease;
  border-top: 2px solid #ddd;
  padding-top: 20px;
`;

export const CreditInformationForm = styled(FormControl)`
  display: flex;
  flexDirection: column;
  justifyContent: space-around;    
  transition: all 0.2s eas;
  padding: 24px;
`;

export const MakePaymentContainer = styled.div`
  padding: 0 24px;
  `;
export const SaveCardContainer = styled.div`
padding: 0px 24px;
`;
export const PaymentMethodHeader = styled.h4`
  padding: 0;
  margin: 0 0 5px 0;
  `;

export const TwoColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TextFieldInput = styled(TextField)`
  margin-top: 10px;
`;

export const ButtonInput = styled(Button)`
  transition: "all 0.3s linear";

  &.MuiButtonBase-root.Mui-disabled {
    cursor: default;
    pointerEvents: none;    
    border: none;
    background-color: #fff;
  }
`

export const TextFieldLabel = styled.span`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  margin: 10px 0px 0px 0px;
  font-size: .75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
`;