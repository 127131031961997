import Immutable from "immutable";
import * as constants from "../../constants";

const defaultBillingSummaryState = Immutable.fromJS({
  isFetching: false,
});

const defaultTransactionsState = {
  transactions: [],
  deposits: [],
  page: 0,
  hasNext: false,
  next: 0,
  isFetching: false,
};

const defaultBillingMonthlyChargesState = { chargeItems: [] };

const defaultbillingAdjustmentMsg = Immutable.fromJS({});

export function billingSummaryState(
  state = defaultBillingSummaryState,
  action
) {
  switch (action.type) {
    case constants.SET_BILLING_FETCHING_STATE:
      return Immutable.fromJS({
        ...state.toJS(),
        isFetching: action.isFetching,
      });
    default:
      return state;
  }
}

export function billingAdjustmentMsg(
  state = defaultbillingAdjustmentMsg,
  action
) {
  switch (action.type) {
    case constants.RECEIVE_BILLING_ADJUSTMENT:
      return Immutable.fromJS({
        response: action.response,
        showSnackBar: true,
        isFetching: action.isFetching,
      });
    case constants.HIDE_SNACKBAR:
      return state.merge(Immutable.fromJS({ showSnackBar: false }));
    default:
      return state;
  }
}

export function billingTransactions(state = defaultTransactionsState, action) {
  switch (action.type) {
    case constants.RECEIVED_ALL_BILLING_TRANSACTIONS: {
      const { transactions, page, has_next: hasNext, next } = action.payload;
      const nextState = {
        transactions:
          Number(page) === 1
            ? transactions
            : state.transactions.concat(transactions),
        deposits: state.deposits,
        page: Number(page),
        hasNext,
        next,
        isFetching: false,
      };
      return nextState;
    }
    case constants.REQUEST_BILLING_TRANSACTIONS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case constants.CLEAR_DISPUTE_INFO:
      return defaultTransactionsState;
    default:
      return state;
  }
}

export function billingMonthlyCharges(state = defaultBillingMonthlyChargesState, action) {
  switch (action.type) {
    case constants.RECIEVE_BILLING_MONTHLY_CHARGES:
      const { charge_items: chargeItems } = action.payload;
      return { ...state, chargeItems };
    default:
      return state;
  }
}
