import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { subNavigation } from "../styles/navigation.css";

const MeterTransactionsHome = ({ children }) => (
  <div>
    <header className={subNavigation}>
      <Link
        to={`/metertransactions/pending`}
        activeStyle={{ textDecoration: "none", color: "black" }}
      >
        Unprocessed Transactions
      </Link>{" "}
      <Link
        to={`/metertransactions/error`}
        activeStyle={{ textDecoration: "none", color: "black" }}
      >
        Error Transactions
      </Link>{" "}
      <Link
        to={`/metertransactions/held`}
        activeStyle={{ textDecoration: "none", color: "black" }}
      >
        Held Transactions
      </Link>{" "}
      <Link
        to={`/metertransactions/history`}
        activeStyle={{ textDecoration: "none", color: "black" }}
      >
        Transaction History
      </Link>{" "}
    </header>
    {children}
  </div>
);

MeterTransactionsHome.propTypes = {
    children: PropTypes.object,
};

MeterTransactionsHome.defaultProps = {
    children: {},
};

export default MeterTransactionsHome;