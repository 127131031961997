import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useTableStyles as useStyles } from "./styles";
import { noselect } from "../../styles/common.css";

import Card from "@material-ui/core/Card";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "../Button"

import UpdateStatement from "../Statements/UpdateStatement";
import StatementRow from "../Statements/StatementRow";

const StatementInError = (props) => {
  const classes = useStyles();
  const { doUpdateStatementStatus, statements } = props;
  const [selectAll, setSelectAll] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statementsData, setStatementsData] = useState([]);

  useEffect(() => {
    // Initialize statements to be unchecked
    let newArr = statements.map((item) => {
      return { ...item, checked: false };
    });
    setStatementsData(newArr);
  }, [statements]);

  useEffect(() => {
    // Set all statements to be checked based on master select
    let newArr = statementsData.map((item) => {
      if (item.status === "error") {
        return { ...item, checked: selectAll };
      } else {
        return { ...item };
      }
    });
    setStatementsData(newArr);
  }, [selectAll]);

  // Check selected statement
  const handleCheckedBox = (statementID, value) => {
    let newArr = statementsData.map((item) =>
      item.id === statementID
        ? { ...item, checked: value ? value : !item.checked }
        : item
    );
    setStatementsData(newArr);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  return (
    <Card className={classes.transactionPanel}>
      <h1 id="panel-title">
        Statement In Error
        <div>
          <Button
            color="secondary"
            varian="raised"
            onClick={() => {
              setSelectedStatus("new");
              setOpenPopup(true);
            }}
          >
            Recreate
          </Button>
        </div>
      </h1>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ height: "600px", overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead className={`${classes.tableHeaderRow} ${noselect}`}>
              <TableRow>
                <TableCell align="left">
                  <Tooltip placement="bottom" title="Select All Pending">
                    <Checkbox
                      style={{ color: "white" }}
                      checked={selectAll}
                      onChange={() => setSelectAll(!selectAll)}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="left">Member ID</TableCell>
                <TableCell align="left">Statement Date</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">End Date</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Average Rate</TableCell>
                <TableCell align="left">Due Date</TableCell>
                <TableCell align="left">Final</TableCell>
                <TableCell style={{ width: 150 }} align="center">
                  Status
                </TableCell>
                <TableCell align="center">PDF</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statementsData?.length > 0 ? (
                statementsData.map((item, index) => {
                  return (
                    <StatementRow
                      key={index}
                      statement={item}
                      handleCheckedBox={handleCheckedBox}
                      allowedStatus={{ error: true }}
                    />
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>No Records Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <Dialog
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        {statementsData.filter((s) => s.checked).length > 0 ? (
          <UpdateStatement
            doUpdateStatementStatus={doUpdateStatementStatus}
            selectedStatements={statementsData}
            selectedStatus={selectedStatus}
            closeManualAdjustmentPopup={handleClose}
            inReview={false}
          />
        ) : (
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openPopup}
          >
            <DialogContent dividers>
              Please select statements to adjust
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleClose()} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Dialog>
    </Card>
  );
};

StatementInError.propTypes = {
  doUpdateStatementStatus: PropTypes.func.isRequired,
  statements: PropTypes.array.isRequired,
};

export default StatementInError;
