import React from "react";
import { connect } from "react-redux";
import UserManagement from "../components/UserManagement";
import selectUserManagementContainer from "../selectors/userManagement";
import {
  getUsers,
  getRoles,
  createUser,
} from "../actions/userManagement";

const UserManagementContainer = (props) => <UserManagement {...props} />;

const mapStateToProps = (state) => ({
  ...selectUserManagementContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetUsers: data => {
    dispatch(getUsers(data));
  },
  doFetchGetRoles: () => {
    dispatch(getRoles());
  },
  createUser: data => {
    dispatch(createUser(data));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementContainer);
