import * as constants from "../../constants";

const promoInitState = {
  promos: [],
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
  isProcessing: false,
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

export function promo(state = promoInitState, action) {
  switch (action.type) {
    case constants.PROMO_GET_PROMO_SUCCESS:
      return {
        ...state,
        promos: action.payload.list.sort((a, b) => a.id - b.id)
      }
    case constants.PROMO_UPDATE_PROMO_SUCCESS:
      const { id, status, statusReason } = action.payload
      const promos = state.promos

      promos.forEach(item => {
        if (item.id === id) {
          item.status = status
          item.status_reason = statusReason
        }
      })
      return {
        ...state,
        promos: promos.toSorted((a, b) => a.id - b.id)
      }
    case constants.PROMO_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case constants.PROMO_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case constants.PROMO_IS_FETCHING:
      return {
        ...state,
        ...action,
      }
    default:
      return state;
  }
}
