import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Router } from "react-router";
import { StripeProvider } from 'react-stripe-elements';
import routes from "../routes";

const Root = ({ store, history }) => {
  if ( process.env.REACT_APP_DEFAULT_PAYMENT_PROVIDER === "STRIPE" ) {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
        <Provider store={ store }>
          <Router history={ history } routes={ routes } />
        </Provider>
      </StripeProvider>
    )
  } 
  return (
    <Provider store={ store }>
      <Router history={ history } routes={ routes } />
    </Provider>
  )
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Root;
