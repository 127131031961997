import React from "react";
import { connect } from "react-redux";
import JobNotification from "../components/JobNotifications";
import selectNotificationsContainer from "../selectors/jobNotifications";
import {
  getNotifications,
  runNotification,
  executeNotification,
  closeSnackbar
} from "../actions/jobNotifications";

const NotificationsContainer = (props) => <JobNotification {...props} />;

const mapStateToProps = (state) => ({
  ...selectNotificationsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetNotifications: () => {
    dispatch(getNotifications());
  },
  doFetchRunNotification: (id) => {
    dispatch(runNotification(id));
  },
  doFetchExecuteNotification: (id) => {
    dispatch(executeNotification(id));
  },
  closeSnackbar: () => {
    dispatch(closeSnackbar());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
