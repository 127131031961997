import { all } from "redux-saga/effects";
import searchSaga from "./searchSaga";
import meterLookup from "./meterLookupSaga";
import payments from "./paymentSaga";

export default function* rootSaga() {
  yield all([
    ...searchSaga,
    ...meterLookup,
    ...payments,
  ]);
}
