import React from "react";
import { connect } from "react-redux";
import MemberReferrals from "../components/MemberReferrals";
import {
  fetchPrimaryAccountStatus,
  fetchRewardsBalance,
  fetchReferrals,
} from "../actions/referrals";

const MemberReferralsContainer = (props) => <MemberReferrals {...props} />;

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  referralsStatus: state.referrals,
  memberReferrals: state.memberReferrals,
});

const mapDispatchToProps = (dispatch) => ({
  doFetchPrimaryAccountStatus: (params) => {
    dispatch(fetchPrimaryAccountStatus(params));
  },
  doFetchRewardsBalance: (params) => {
    dispatch(fetchRewardsBalance(params));
  },
  doFetchReferrals: (params) => dispatch(fetchReferrals(params)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberReferralsContainer);
