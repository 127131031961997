import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "../Button"
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";

const ResendEmailDialog = ({ closeEmail, notification, resendNotification }) => {
  const [emailContent, setEmailContent] = useState({
    to_email: notification.to_email,
    notification_type: notification.notification_type,
    event: notification.event,
    data_fields: notification.data_fields,
    template_id: notification.template_id,
    attachment_url: notification.attachment_url,
  })

  const handleEmailUpdate = (e) => {
    setEmailContent({ ...emailContent, [e.target.id]: e.target.value })
  }

  return (
    <Dialog
      open={true}
      contentlabel="Resend Email"
      fullWidth
      maxWidth="md"
      maxheight="80%"
    >
      <DialogTitle>
        Resend: {notification.event}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth >
          <ListItemText primary="To Email:" />
          <TextField id="to_email" value={emailContent.to_email} variant="outlined" onChange={handleEmailUpdate} />

          <ListItemText primary="Notification Type:" />
          <TextField id="notification_type" value={emailContent.notification_type} variant="outlined" disabled />

          <ListItemText primary="Event:" />
          <TextField id="event" value={emailContent.event} variant="outlined" disabled />

          <ListItemText primary="Data Fields:" />
          <TextField id="data_fields" value={JSON.stringify(notification.data_fields, null, 4)} variant="outlined" disabled multiline />

          <ListItemText primary="Template ID:" />
          <TextField id="template_id" value={emailContent.template_id} variant="outlined" disabled />

          <ListItemText primary="Attachment URL:" />
          <TextField id="attachment_url" value={emailContent.attachment_url} variant="outlined" disabled />
        </FormControl>

      </DialogContent>
      <Button
        color="primary"
        onClick={async () => {
          await resendNotification(emailContent)
          await closeEmail()
        }}
      >
        Send
      </Button>
      <Button
        color="secondary"
        onClick={async () => {
          await closeEmail()
        }}
      >
        Close
      </Button>
    </Dialog >
  )
}

export default ResendEmailDialog;
