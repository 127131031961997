import { createSelector } from "reselect";

const dnpRnpHistoryDomain = () => (state) => state.dnpRnpHistory;

const selectedMemberDomain = () => (state) => state.selectedMember;

const selectedAdminUser = () => (state) => state.user;

const selectDnpRnpHistoryContainer = () =>
  createSelector(
    dnpRnpHistoryDomain(),
    selectedMemberDomain(),
    selectedAdminUser(),
    (subState, selectedMember, user) =>
      Object.assign({}, subState, { selectedMember, user })
  );

export default selectDnpRnpHistoryContainer;
