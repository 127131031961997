import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { confirmationSuccess } from "../styles/common.css";

const CustomSnackbar = (props) => {
  const { message, open, onClose, children, className, ...rest } = props;
  const [internalOpen, setInternalOpen] = useState(false);

  useEffect(() => {
    setInternalOpen(open);
  }, [open]);

  const handleClose = (event, reason) => {
    if (onClose) {
      onClose();
    }

    if (reason === 'clickaway') {
      return;
    }

    setInternalOpen(false);
  };


  return (
    <Snackbar
      {...rest}
      className={className}
      open={internalOpen}
      autoHideDuration={className === confirmationSuccess ? 3000 : null}
      onClose={handleClose}
    >
     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span style={{
          paddingLeft: '10px',
          paddingRight: '10px'
        }}>
          {message}
        </span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: '5px',
            top: '5px'
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </Snackbar>
  );
}

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default CustomSnackbar;
