import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "../Button"
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    width: "600px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  label: {
    marginBottom: "10px"
  }
});

const CancelEnrollment = ({ handleCloseCancelDialog, details, cancelStatus, onCancel }) => {
  const classes = useStyles();
  const [notes, setNotes] = useState(null);
  const cancelMode = cancelStatus === "canceled" ? "Cancel" : "Fraud";

  return (
    <div className={classes.root}>
      <Card className={classes.card} variant="outlined">
        <div className={`${classes.cardTitle} ${noselect}`}>
          <span>{cancelMode} Enrollment</span>
          <CloseIcon onClick={() => handleCloseCancelDialog()} />
        </div>
        <Grid container style={{ padding: '10px', paddingTop: '10px' }}>
          <Grid item md={12}>
            <div style={{ paddingBottom: '10px', paddingTop: '10px' }}>
              Are you sure you want to set the enrollment status to {cancelMode}?
            </div>
          </Grid>
          <Grid style={{ paddingTop: '10px' }} item md={12}>
            <TextField
              label="Notes"
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              id="notes"
              style={{ width: '100%' }}
              onChange={(e) => setNotes(e.target.value)}
              value={notes}
              type="text"
            />
          </Grid>
        </Grid>
        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
            paddingBottom: '10px'
          }}
        >
          <Button
            color="secondary"
            varian="raised"
            onClick={() => handleCloseCancelDialog()}
          >
            Close
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => {
              await handleCloseCancelDialog()
              await onCancel(details.id, notes, cancelStatus);
            }}
          >
            Confirm
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default CancelEnrollment;
