import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { navigation } from "../styles/navigation.css";
import { logout } from "../actions/user";
import { connect } from "react-redux";
import Maintenance from "./Maintenance";
import { HAS_CSA_SERVICE } from "../util/flags";

const App = ({ children, user }) => {
  const { isReferralCreditsManager, isUserManager, isFileUploadManager, isLMIApproverManager } =
    user.permissions;

  return (
    <div>
      {process.env.REACT_APP_MAINTENANCE_MODE !== 'true' && (
        <>
          <header className={navigation}>
            {/* https://goo.gl/gtyF9S - nav component example to remove inline activestyle below */}
            <Link
              to="/members"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Members
            </Link>{" "}
            <Link
              to="/meters"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Meter Lookup
            </Link>{" "}
            <Link
              to="/enrollments"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Enrollments
            </Link>{" "}
            <Link
              to="/dnprnp/dnpqueue"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Disconnect Notices
            </Link>{" "}
            <Link
              to="/metertransactions/pending"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Transactions
            </Link>{" "}
            <Link
              to="/statementapproval"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Statement Approval
            </Link>{" "}
            <Link
              to="/payment"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Payment
            </Link>{" "}
            {
              HAS_CSA_SERVICE && <>
                <Link
                  to="/csa"
                  activeStyle={{ textDecoration: "none", color: "black" }}
                >
                  CSA
                </Link>{" "}
              </>
            }
            {process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
              <>
                <Link
                  to="/promo"
                  activeStyle={{ textDecoration: "none", color: "black" }}
                >
                  Promo
                </Link>{" "}
              </>
            )}
            <Link
              to="/reports"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Reports
            </Link>{" "}
            <Link
              to="/jobNotifications"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Notification Jobs
            </Link>{" "}
            <Link
              to="/notifications"
              activeStyle={{ textDecoration: "none", color: "black" }}
            >
              Notifications
            </Link>{" "}
            {
              isLMIApproverManager && process.env.REACT_APP_BRAND_INSTANCE_NAME === 'think' && <>
                <Link
                  to="/lmi_approval"
                  activeStyle={{ textDecoration: "none", color: "black" }}
                >
                  LMI Approval
                </Link>{" "}
              </>
            }
            {
              isFileUploadManager &&
              <Fragment>
                <Link
                  to="/batch"
                  activeStyle={{ textDecoration: "none", color: "black" }}
                >
                  Batch Imports
                </Link>{" "}
              </Fragment>
            }
            {process.env.REACT_APP_ENABLE_REFERRALS === "true" &&
              isReferralCreditsManager && (
                <Link
                  to="/referrals"
                  activeStyle={{ textDecoration: "none", color: "black" }}
                >
                  Referrals
                </Link>
              )}{" "}
            {isUserManager && (
              <Fragment>
                <Link
                  to="/management"
                  activeStyle={{ textDecoration: "none", color: "black" }}
                >
                  Settings
                </Link>{" "}
              </Fragment>
            )}
            <Link to="/login">Login</Link>{" "}
            <button onClick={() => logout()}>Logout</button>
          </header>
          {children}
        </>
      )}
      {process.env.REACT_APP_MAINTENANCE_MODE === 'true' && (
        <Maintenance />
      )}
    </div>
  )
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(App);