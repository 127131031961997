import React from "react";
import ReactTable from "react-table";
import { Link } from "react-router"
import moment from "moment";

const MemberReferralsTable = (props) => {
  const {
    referrals,
  } = props

  const formatStatus = (status) => {
    switch (status) {
      case "invitation_sent":
        return "Invitation Sent";
      case "onboarding":
        return "Onboarding";
      case "qualified":
        return "Qualified";
      case "not_qualified":
        return "Not Qualified";
      default:
        return status;
    }
  };

  const columns = [
    {
      Header: "Date",
      id: "created",
      accessor: (row) => {
        return new moment(row.created).format("YYYY/MM/DD")
      },
    },
    {
      Header: "Name",
      accessor: "referral_name",
    },
    {
      Header: "Email",
      accessor: "referral_email",
    },
    {
      Header: "Status",
      id: "status",
      accessor: (row) => {
        return formatStatus(row.status)
      }
    },
    {
      Header: "Member ID",
      id: "referral_member_id",
      accessor: (row) => {
        if (row.referral_member_id) {
          return <Link to={`/members/${row.referral_member_id}/details`}>{row.referral_member_id}</Link>
        }
        return row.referral_member_id
      },
    },
    {
      Header: "Billing Account Number",
      accessor: "billing_account_number",
    },
  ]

  return (
    <div>
      {referrals && referrals.length > 0 ?
        <ReactTable
          data={referrals}
          columns={columns}
          className="-striped -highlight"
          defaultPageSize={10}
          filterable
          defaultSorted={[{ id: "created", desc: true }]}
        />
        : <div>No Referrals</div>}
    </div>
  )
}

export default MemberReferralsTable
