import React from "react";
import { render } from "react-dom";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./theme";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import configureStore from "./store/configureStore";
import Root from "./containers/Root";

const store = configureStore();
const history = syncHistoryWithStore(browserHistory, store);

document.getElementById("app-title").innerHTML =
  typeof process.env.REACT_APP_WEBSITE_NAME != "undefined"
    ? process.env.REACT_APP_WEBSITE_NAME
    : "Admin Portal";

render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Root store={store} history={history} />
    </MuiPickersUtilsProvider>
  </ThemeProvider>,

  document.getElementById("root")
);
