import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

import ViewSelector from "../common/ViewSelector";
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      "& #iterable-logo": {
        cursor: "pointer",
        width: "25px",
        margin: "0px 10px",
        padding: "5px",
        borderRadius: "99999px",
        border: "2px solid #ddd",
        transition: "0.2s all ease",
        "&:hover": {
          transform: "scale(1.1)",
        },
      },
    },
  },
  viewContainer: {
    marginTop: "20px",
  },
  selectedViewHeader: {
    fontSize: "17px",
    padding: "20px 0px",
    paddingLeft: "15px",
    margin: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const StatementTables = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const viewNames = ["Pending Approval", "Statement In Review", "Statement In Error", "Pending Delivery"];
  const [activeView, setActiveView] = useState(0);
  const { doFetchBillingStatements, setSelectedStatusForFetch, setSelectedView } = props;

  const viewNameToStatus = {
    "Pending Approval": ["pending_approval", false],
    "Statement In Review": ["pending_approval", true],
    "Statement In Error": ["error", false],
    "Pending Delivery": ["approved", false],
  };

  const _setActiveView = async (value) => {
    try {
      const params = viewNameToStatus[viewNames[activeView]];
      setSelectedStatusForFetch(params[0]);
      setSelectedView(activeView);
      await doFetchBillingStatements({ status: params[0], inReview: params[1] });
    } catch (err) {
      console.log(err);
    } finally {
      setActiveView(value);
    }
  };
  useEffect(() => {
    _setActiveView(activeView);    
  }, [activeView]);

  return (
    <div className={classes.viewContainer}>
      <ViewSelector
        viewNames={viewNames}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        {viewNames.map((option, index) => {
          return (
            <Tab
              key={index}
              className={activeView === index ? `view activeView` : `view`}
              label={option}
              Î
            ></Tab>
          );
        })}
      </ViewSelector>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeView}
      >
        {props.children}
      </SwipeableViews>
    </div>
  );
};

StatementTables.propTypes = {
  setSelectedStatusForFetch: PropTypes.func.isRequired,
  doFetchBillingStatements: PropTypes.func.isRequired,
};

export default StatementTables;
