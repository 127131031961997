import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button"
import { KeyboardDatePicker } from "@material-ui/pickers";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputSwitch from "./InputSwitch";
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import { updateAccount } from "../../actions/members";

const useStyles = makeStyles({
  root: {
    width: "300px",
  },
  adjustmentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
    marginBottom: "10px",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
  errorMessage: {
    color: 'red', 
    margin: '10px',
    marginTop: '0px',
  }
});

const UpdateMeterDates = props => {
  const { meter, closePopup, dates, handleChange, onMeterUpdate } = props;
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState(null);
  const [notify, setNotify] = useState(true);

  useEffect(() => {
    if (dates) {
      setStartDate(dates.startDate !== "" ? moment.utc(dates.startDate).startOf('day') : null)
      setEndDate(dates.endDate !== "" ? moment.utc(dates.endDate).startOf('day') : null)
      setNotify(dates.skipNotification !== "" ? dates.skipNotification : false)
    }
  }, [dates]);

  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setError('Start Date cannot be after End Date')
    } else {
      setError(null)
    }
  }, [startDate, endDate]);

  const clearForm = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const submitForm = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = ev => {
    handleChange(meter.get("ID"), dates.index, onMeterUpdate, startDate, endDate, notify)(ev)
    setOpenPopup(false);
    closePopup(false);
    clearForm();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Update Member Dates</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <div className={classes.adjustmentForm}>
          <label>Start Date:</label>
          <KeyboardDatePicker
            margin="normal"
            format="MM/dd/yyyy"
            placeholder="mm/dd/yyyy"
            value={(startDate && startDate.format("l LT")) || null}
            KeyboardButtonProps={{"aria-label": "change date"}}
            onChange={date => setStartDate(date !== null ? moment.utc(date).startOf('day'): null)}
          />
          <label>End Date:</label>
          <KeyboardDatePicker
            margin="normal"
            format="MM/dd/yyyy"
            placeholder="mm/dd/yyyy"
            value={(endDate && endDate.format("l LT")) || null}
            KeyboardButtonProps={{"aria-label": "change date"}}
            onChange={date => setEndDate(date !== null ? moment.utc(date).startOf('day'): null)}
          />
          <div style={{overflow: 'auto', marginTop: '10px'}}>
            <label style={{float: 'left'}}>Suppress notifications:</label>
            <InputSwitch
              style={{float: 'right'}}
              checked={notify}
              onChange={ev => setNotify(ev)}
            />
          </div>
        </div>
        {error && <div className={classes.errorMessage}>{error}</div>}
        <div className={classes.optionContainer}>
          <Button color="secondary" varian="raised" onClick={() => clearForm()}>
            Clear
          </Button>
          <Button
            disabled={error !== null}
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
          >
            Apply
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span>Please Confirm Transaction</span>
        </DialogTitle>
        <DialogContent dividers>
          Are you sure you want to update the meter dates?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="secondary">
            Close
          </Button>
          <Button
            className={classes.enableButton}
            autoFocus
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onAccountUpdate: (accountID, key, updateDated) =>
    dispatch(updateAccount(accountID, key, updateDated)),
  dispatch,
});

const ConnectedUpdateMeterDates = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateMeterDates);

export default ConnectedUpdateMeterDates;
