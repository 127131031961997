import * as C from "../../constants";

const initialState = {
  exemptionList: [
    {
        "code": "00",
        "description": "No Exemptions"
    },
    {
        "code": "21",
        "description": "All State and Local Gross Receipts Tax (GRT) Exempt"
    },
    {
        "code": "32",
        "description": "State and Local Sales Tax Exempt"
    },
    {
        "code": "99",
        "description": "All Taxes Exempt – Apply no tax or fees"
    }
],
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
};

const loadTaxExcemptions = (state, action) => {
  const { ExemptionList } = action.payload;
  return {
    ...state,
    exemptionList: ExemptionList,
  };
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

export default function tax(state = initialState, action) {
  switch (action.type) {
    case C.TAX_EXCEMPTION_SUCCESS:
      return loadTaxExcemptions(state, action);
    case C.TAX_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case C.TAX_HIDE_SNACKBAR:
      return closeSnackBar(state);
    default:
      return state;
  }
}
