import React from "react";
import ReactTable from "react-table";
import Button from "../Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import { Warning } from "@material-ui/icons";

const AssignMeterTransaction = ({
  open,
  searchMeterIdentifier,
  handleChange,
  handleAssignMeter,
  searchMeters,
  searchMeterResult,
  classes,
  isProcessing,
  reassignTransaction,
  meterID,
  
}) => {
  const columns = [
    {
      Header: "Meter Identifier",
      accessor: "meterIdentifier",
    },
    {
      Header: "Member ID",
      accessor: "memberID",
      width: 300,
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      width: 100,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      width: 100,
    },
    {
      Header: "Status",
      accessor: "status.status",
    },
    {
      Header: "CSA",
      accessor: "on_csa",
      width: 100,
      Cell: (row) => (row.value ? "Yes" : "No"),
    },
    {
      Header: "",
      accessor: "ID",
      width: 100,
      Cell: (row) => (
        <Button
          onClick={ async () => 
            await handleAssignMeter(row.value, row.original.memberID, reassignTransaction)
          }
          variant="contained"
          color="secondary"
          disabled={meterID === row.value}
        >
          Assign
        </Button>
      ),
    },
  ];

  const title = reassignTransaction ? "Move to new Meter" : "Meter Assignment" ;

  return (
    <Dialog
      open={open}
      contentlabel={title}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"md"}
      classes={{ paperFullWidth: classes.assignMeterDialog }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Backdrop className={classes.backdrop} open={isProcessing}>
          <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
        </Backdrop>
        <DialogContentText id="alert-dialog-description">
          <Grid container>
            <ListItem>
              <TextField
                name="searchMeterIdentifier"
                style={{
                  marginRight: "20px",
                  width: "200px",
                }}
                label="ESI"
                value={searchMeterIdentifier}
                type="string"
                onChange={(e) =>
                  handleChange({ searchMeterIdentifier: e.target.value })
                }
              />              
              <IconButton
                aria-label="Link Meter"
                size="large"
                title="Link Meter"
                classes={{ label: classes.iconButtonLabel }}
                color="primary"
                onClick={() => searchMeters(searchMeterIdentifier)}
              >
                <SearchIcon size="large" />
              </IconButton>              
            </ListItem>
          </Grid>
          <div className={classes.searchMeterResult}>
            <h5>Select Meter</h5>

            <ReactTable
              data={searchMeterResult}
              columns={columns}
              className="-striped -highlight"
              defaultPageSize={5}
              showPagination={false}
              style={{ textAlign: "center" }}
            />
          </div>
        </DialogContentText>
        { reassignTransaction && 
          <div className={classes.warning}>            
              <Warning style={{margin:25}}/> Moving the transaction will not reverse updates made to the existing meter.            
          </div>        
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async () => {
            await handleChange({
              openAssignMeter: false,
              searchMeterResult: [],
              searchMeterIdentifier: "",
            });
          }}
          color="primary"
          variant="contained"
          Close
          style={{
            float: "right",
            marginRight: 10,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignMeterTransaction.propTypes = {};

export default AssignMeterTransaction;
