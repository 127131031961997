import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../../Button"
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, FormLabel } from "@material-ui/core/";
import { noselect } from "../../../styles/common.css";
import { processChargeBack } from "../../../actions/payment";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  addPaymentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  addPaymentText: {
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    pointerEvents: "none",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const ChargeBackForm = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState(null);
  const [otherMessage, setOtherMessage] = useState(null);
  const { memberID, startDate, endDate } = props;
  const { paymentForChargeback, closePopup, onProcessChargeBack } = props;
  const chargebackMessages = [
    "CC Credit Card Dispute",
    "R01 Insufficient Funds",
    "R02 Account Closed",
    "R03 No Account/Unable To Locate Account",
    "R04 Invalid Account #",
    "R08 Payment Stopped/Refused Payment",
    "R09 Uncollected Funds",
    "R10 Customer Advises Not Authorized",
    "R11 Cust Adv Entry Not In Accordance W/TOA",
    "R15 Beneficiary Deceased",
    "R16 Account Frozen",
    "R20 Non-Transaction Account",
    "R29 Corp Customer Advises Not Authd",
    "Other"
  ]

  const submitForm = () => {
    onProcessChargeBack(
      paymentForChargeback.id,
      message === "Other" ? otherMessage : message,
      memberID,
      startDate,
      endDate,
    );
    closePopup();
  };

  const handleClose = () => {
    closePopup();
  };

  const handleChange = (event) => {
    const { target: { value } } = event;
    setMessage(value);
    if (value === "Other") {
      setOtherMessage(null)
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Chargeback</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>

        <FormControl className={classes.addPaymentForm}>
          <FormLabel id="demo-radio-buttons-group-label">
            <h6>
              Are you sure you want to process a charge back for $
              {paymentForChargeback.Amount}?
            </h6>
            <Grid container>
              <Grid item sm={12}>
                <Select
                  value={message}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                  }}
                >
                  {chargebackMessages.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item sm={12} style={{ marginTop: "20px" }}>
                {
                  message === "Other" &&
                  <TextField
                    className={classes.textArea}
                    placeholder="Enter chargeback message..."
                    type="text"
                    color="primary"
                    width="100%"
                    value={otherMessage}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => setOtherMessage(e.target.value)}
                    style={{ width: "100%" }}
                    variant="outlined"
                  />
                }
              </Grid>
            </Grid>
          </FormLabel>
        </FormControl>
        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
          }}
        >
          <Button
            color="secondary"
            varian="raised"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
            disable={true}
          >
            Process
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onProcessChargeBack: (id, message, memberID, startDate, endDate) =>
    dispatch(processChargeBack(id, message, memberID, startDate, endDate)),
  dispatch,
});

const ConnectedChargeBackForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChargeBackForm);

export default ConnectedChargeBackForm;
