import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { noselect } from "../../styles/common.css";
import Tabs from "@material-ui/core/Tabs";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
  },
  viewSelectorContainer: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      width: "100%",
      flexDirection: "flex-start",
      borderBottom: "1.5px solid #ccc",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
    },
    "& .view": {
      cursor: "pointer",
      padding: "10px 20px",
      textAlign: "center",
      marginBottom: "-1.5px",
    },
    "& .activeView": {
      border: "1.5px solid #ccc",
      backgroundColor: "white",
      borderBottomColor: "transparent",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
    },
  },
});

export default function ViewSelector(props) {
  const classes = useStyles();
  const { activeView, setActiveView, callback } = props;

  return (
    <div className={classes.root}>
      <Tabs
        className={`${classes.viewSelectorContainer} ${noselect}`}
        value={activeView}
        onChange={(_, newValue) => {
          if (callback) {
            callback()
              .then(() => {
                setActiveView(newValue);
              })
              .catch((err) => {});
          } else {
            setActiveView(newValue);
          }
        }}
      >
        {props.children}
      </Tabs>
    </div>
  );
}
