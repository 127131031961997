import React, { useState } from 'react';
import Button from "../../Button"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  CircularProgress,
  ListItemIcon,
  Select,
  MenuItem,
} from "@material-ui/core";

import {
  Delete,
  CheckCircle,
  Error,
} from "@material-ui/icons";

export const FILE_CATEGORIES = {
  "generic": "Generic",
  "utilitybill": "Utility Bill",
  "lmi": "LMI",
  "bill": "BILL"
}

function UploadFileDialog(props) {
  const {
    onClose, // callback to update state in parent
    doUploadFile,
    open,
    setOpen,
  } = props;

  const [file_list, setFileList] = useState([]);
  const [success_list, setSuccessList] = useState([]);
  const [uploading, setUploading] = useState(false);

  function makeFileList(files) {
    const list = [];
    for (const file of files) {
      list.push({ file: file, category: "generic" });
    }
    return list;
  }

  const handleFileChange = (e) => {
    setFileList(makeFileList(e.target.files));
  }

  async function handleUploadFile() {
    const result_list = Array(file_list.length).fill("pending");
    setSuccessList([...result_list])
    setUploading(true);

    for (let i = 0; i < file_list.length; i++) {
      const file = file_list[i];
      const success = await doUploadFile(file.file, file.category);

      result_list[i] = success ? "success" : "failure";
      setSuccessList([...result_list])
    }
  }

  function handleClose() {
    setUploading(false)
    setFileList([]);
    setSuccessList([]);
    setOpen(false);
    onClose();
  }

  function getProgressIcon(i) {
    switch (success_list[i]) {
      case "pending":
        return <CircularProgress size="1rem" />;
      case "success":
        return <CheckCircle size="1rem" />;
      default:
        return <Error size="1rem" />;
    }
  }

  function handleCategoryChange(i) {
    return (e) => {
      const new_file_list = file_list.slice();
      new_file_list[i].category = e.target.value;
      setFileList(new_file_list);
    }
  }

  function buildFileListItems() {
    const listItems = [];
    if (file_list) {
      for (let i = 0; i < file_list.length; i++) {
        const file = file_list[i].file;
        listItems.push(
          <ListItem key={i}>
            {uploading && <ListItemIcon>{getProgressIcon(i)}</ListItemIcon>}
            {!uploading &&
              <Select
                onChange={handleCategoryChange(i)}
                value={file_list[i].category}
                style={{ marginRight: "10px" }}
                size="small"
              >
                {Object.entries(FILE_CATEGORIES).map(([key, value]) => { return <MenuItem key={key} value={key}>{value}</MenuItem> })}
              </Select>
            }

            <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">{file.name}</a>
            {!uploading &&
              <Button
                onClick={() => setFileList(file_list.filter((f, index) => index !== i))}
              >
                <Delete />
              </Button>
            }
          </ListItem>
        );
      }
    }
    return listItems;
  }

  return (
    <Dialog onClose={handleClose} open={open} maxwidth="lg" fullWidth>
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <input
          type="file"
          onChange={handleFileChange}
          title=""
          style={{ color: "transparent" }}
          multiple
          disabled={uploading}
        />
        <p>Or drop files below</p>
        <div style={{ minHeight: "250px", overflow: "auto", border: "1px solid #000" }}
          onDragOver={(e) => { e.preventDefault() }}
          onDrop={(e) => {
            e.preventDefault();
            if (!uploading) {

              setFileList([...file_list, ...makeFileList(e.dataTransfer.files)]);
            }
          }}
        >
          <List dense>
            {buildFileListItems()}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          Cancel
        </Button>
        {!uploading &&
          <Button
            onClick={handleUploadFile}
            disabled={!(file_list && file_list.length > 0)}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        }
        {uploading &&
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            disabled={success_list.includes("pending")}
          >
            Close
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default UploadFileDialog;
