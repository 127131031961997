import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
  },
  dateSelector: {
    width: "260px !important",
    display: "flex !important",
    alignItems: "center",
    flexDirection: "row !important",
    "& #date-selector-label": {
      fontSize: "15px",
      marginRight: "15px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  inputWrapper: {
    fontSize: "16px",
    margin: "10px 0px",
    "& label": {
      display: "inline-block",
      fontWeight: "bold",
      marginRight: "10px",
      width: "180px",
      textTransformation: "uppercase",
    },
    "& input[type=text]": {
      padding: "5px 10px",
      width: "260px",
    },
  },
  wrapper: {
    display: "flex !important",
    flexDirection: "row",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
}));
