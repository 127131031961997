import React from "react";
import { connect } from "react-redux";
import MemberRenewal from "../components/Renewal";
import {
  fetchProductOffers,
  selectProductOffer,
  submitEnrollment,
  closeSnackbar,
} from "../actions/renewal";
import selectRenewalContainer from "../selectors/renewal";

const MemberProductContainer = (props) => <MemberRenewal {...props} />;

const mapStateToProps = (state) => ({
  member: state.selectedMember,  
  ...selectRenewalContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchProductOffers: ({ memberID, enrollmentType, promoCode }) => {
    dispatch(fetchProductOffers({ memberID, enrollmentType, promoCode }));
  },
  doSelectProductOffer: (offers) => {
    dispatch(selectProductOffer(offers));
  },
  doSubmitEnrollment: ({ TOSYRACEFLConfirm, setters, requestedStartDate, enrollmentType }) => {
    dispatch(submitEnrollment({ TOSYRACEFLConfirm, setters, requestedStartDate, enrollmentType }));
  },
  closeSnackbar: () => {
    dispatch(closeSnackbar());
  },
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProductContainer);
