import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  usage: {
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    padding: "15px",
    maxHeight: "600px",
    "& .summaryInfoContainer": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: "100%",
      "& #usage-info, #compare-price-info, #all-in-rate-info": {
        "&:hover": {
          "& p": {
            transform: "scale(1.3)",
          },
          "& h1": {
            transform: "scale(0.7)",
          },
        },
      },
      "& div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "100px",
        height: "100px",
        float: "left",
        background: "#ff4081",
        margin: "10px",
        padding: "10px",
        color: "white",
        fontWeight: "bold",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        WebkitBorderRadius: "99em",
        MozBorderRadius: "99em",
        borderRadius: "99em",
        border: "12px solid #efefef",
        "& h1": {
          fontSize: "16px",
          padding: "0 10px",
          margin: "20px 0px !important",
          textAlign: "center",
          textTransform: "uppercase",
          marginBottom: "0px",
          fontWeight: "bold",
          letterSpacing: "1px",
          transition: "all 0.2s ease",
        },
        "& p": {
          textAlign: "center",
          fontSize: "15px",
          position: "relative",
          bottom: "15px",
          transition: "all 0.2s ease",
        },
      },
      "& div:nth-child(1)": {
        backgroundColor: "#ffa600",
      },
      "& div:nth-child(2)": {
        backgroundColor: "#4bad65",
      },
      "& div:nth-child(3)": {
        backgroundColor: "#bc5090",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
    },
  },
  topPanel: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  dateSelector: {
    display: "flex",
    alignItems: "center",
    margin: "0px 15px",
    "& #date-selector-label": {
      fontSize: "15px",
      marginRight: "15px",
    },
  },
  inputSelector: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    "& span": {
      marginRight: "10px",
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  subRowContainer: {
    display: "flex",
  },
}));