import * as C from "../../constants";

const initialState = {
    initialized: false,
    page: 0,
    limit: 100,
    status: "all",
    transactions: [],
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
};

const loadTransactions = (state, action) => {
    return {
        ...state,
        ...action,
    };
};

const updateFilter = (state, action) => {
    return {
        ...state,
        ...action.updatedInfo,
    };
};

const closeSnackBar = (state) => {
    return {
        ...state,
        showSnackBar: false,
        successMessage: "",
        errorMessage: "",
    };
};

const openSnackBar = (state, action) => {
    return {
        ...state,
        showSnackBar: true,
        ...action,
    };
};

const updateDisconnectNoticeStatus = (state, action) => {
  const { id, status } = action.updatedInfo;
  const trans = state.transactions;
  const index = trans.findIndex((t) => t.id === id);
  const record = {
    ...trans[index],
    status,
  }
  
  return {
    ...state,
    
      transactions: [
        ...trans.slice(0, index),
        record,
        ...trans.slice(index + 1),
      ],
    
  };
}

export default function dnp(state = initialState, action) {
    switch (action.type) {
        case C.DNP_RNP_UPDATE_FILTERS:
            return updateFilter(state, action);
        case C.DNP_RNP_LOAD_TRANSACTIONS:
            return loadTransactions(state, action);
        case C.DNP_RNP_CLOSE_SNACKBAR:
            return closeSnackBar(state);
        case C.DNP_RNP_OPEN_SNACKBAR:
            return openSnackBar(state, action);
        case C.DISCONNECT_NOTICE_UPDATE:
            return updateDisconnectNoticeStatus(state, action);
        default:
            return state;
    }
}
