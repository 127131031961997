import * as constants from "../../constants";
import fetch from "../util/api-ajax";

export const createErrorMessage = (errorMessage) => ({
  type: constants.NOTIFICATIONS_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.NOTIFICATIONS_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.NOTIFICATIONS_CLOSE_SNACKBAR });
};

function setIsFetching(isFetching) {
  return {
    type: constants.NOTIFICATIONS_IS_FETCHING,
    isFetching,
  };
}

function getNotificationsSuccess(payload) {
  return {
    type: constants.NOTIFICATIONS_GET_NOTIFICATIONS_SUCCESS,
    payload,
  };
}

function runNotificationsSuccess(payload) {
  return {
    type: constants.NOTIFICATIONS_RUN_NOTIFICATION_SUCCESS,
    payload,
  };
}

function executeNotificationsSuccess(payload) {
  return {
    type: constants.NOTIFICATIONS_EXECUTE_NOTIFICATION_SUCCESS,
    payload,
  };
}

export function getNotifications() {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.NOTIFICATIONS_LIST_NOTIFICATIONS)
      .then((res) => {
        dispatch(getNotificationsSuccess(res))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}

export function runNotification(id) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.NOTIFICATIONS_PREVIEW_NOTIFICATION, { id, preview_mode: true })
      .then((res) => {
        dispatch(runNotificationsSuccess(res))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}

export function executeNotification(id) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.NOTIFICATIONS_PREVIEW_NOTIFICATION, { id })
      .then((res) => {
        dispatch(executeNotificationsSuccess(res))
        dispatch(setSuccessMessage(`Number of notifications that were triggered: ${res.count || 0}`));
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}