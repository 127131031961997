import React from "react";
import { connect } from "react-redux";
import NoteTags from "../components/NoteTags";
import {
  getNoteTags,
  createNoteTag,
  updateNoteTag,
  deleteNoteTag,
} from "../actions/members";

const NoteTagsContainer = (props) => <NoteTags {...props} />;

const mapStateToProps = (state) => ({
  noteTags: state.memberNoteTags,
});

const mapDispatchToProps = (dispatch) => ({
  doGetNoteTags: () => dispatch(getNoteTags()),
  doCreateNoteTag: (data) => dispatch(createNoteTag(data)),
  doUpdateNoteTag: (data) => dispatch(updateNoteTag(data)),
  doDeleteNoteTag: (data) => dispatch(deleteNoteTag(data)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteTagsContainer);