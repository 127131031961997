import Immutable from "immutable";
import moment from "moment";
import * as constants from "../../constants";

const defaultCardList = Immutable.fromJS([]);
const defaultChargeList = Immutable.fromJS([]);
const defaultPaymentRefundMsg = Immutable.fromJS({});
const paymentInitState = {
  isLoading: false,
  confirmDeleteModalOpen: false,
  confirmDefaultCardModalOpen: false,
  errorMessage: "",
  successMessage: "",
  cardID: "",
  paymentMethodID: "",
  cardLastFour: "",
  showSnackBar: false,
  statements: [],
  selectedStartDate: moment().subtract(2, "days").format("YYYY-MM-DD"),
  selectedEndDate: moment().format("YYYY-MM-DD"),
  unallocatedPayments: []
};

const toggleConfirmDelete = (state) => {
  return {
    ...state,
    confirmDeleteModalOpen: !state.confirmDeleteModalOpen,
  };
};

const setCardToDelete = (state, action) => {
  return {
    ...state,
    ...action,
    confirmDeleteModalOpen: true,
  };
};

const toggleConfirmDefaultCard = (state) => {
  return {
    ...state,
    confirmDefaultCardModalOpen: !state.confirmDefaultCardModalOpen,
  };
};

const setCardToDefault = (state, action) => {
  return {
    ...state,
    ...action,
    confirmDefaultCardModalOpen: true,
  };
};

const setErrorMessage = (state, action) => {
  return {
    ...state,
    errorMessage: action.errorMessage,
    showSnackBar: true,
  };
};

const setSuccessMessage = (state, action) => {
  return {
    ...state,
    successMessage: action.successMessage,
    showSnackBar: true,
  };
};

const clearErrorMessage = (state) => {
  return {
    ...state,
    errorMessage: "",
    showSnackBar: false,
  };
};

const setIsLoading = (state, isLoading) => {
  return {
    ...state,
    isLoading,
  };
};

const setEligibleStatements = (state, action) => {
  const { statements } = action.payload;
  return {
    ...state,
    statements,
  };
};

const setSelectedStartDateForFetch = (state, selectedStartDate) => {
  return {
    ...state,
    selectedStartDate: selectedStartDate,
  };
};

const setSelectedEndDateForFetch = (state, selectedEndDate) => {
  return {
    ...state,
    selectedEndDate: selectedEndDate,
  };
};

const setUnallocatedPaymentList = (state, action) => {
  const { list } = action.payload;
  return {
    ...state,
    unallocatedPayments: list,
  };
};

export function payments(state = paymentInitState, action) {
  switch (action.type) {
    case constants.PAYMENTS_TOGGLE_CONFIRM_DELETE:
      return toggleConfirmDelete(state);
    case constants.PAYMENTS_SET_CARD_DELETE:
      return setCardToDelete(state, action);
    case constants.PAYMENTS_TOGGLE_CONFIRM_DEFAULT_CARD:
      return toggleConfirmDefaultCard(state);
    case constants.PAYMENTS_SET_DEFAULT_CARD:
      return setCardToDefault(state, action);
    case constants.PAYMENTS_SET_ERROR:
      return setErrorMessage(state, action);
    case constants.PAYMENTS_CLEAR_ERROR:
      return clearErrorMessage(state);
    case constants.PAYMENTS_INITIALIZED:
      return setIsLoading(state, action.isLoading);
    case constants.RECEIVE_BILLING_ELIGIBLE_STATEMENTS:
      return setEligibleStatements(state, action);
    case constants.PAYMENTS_SET_START_DATE_FOR_FETCH:
      return setSelectedStartDateForFetch(state, action.payload);
    case constants.PAYMENTS_SET_END_DATE_FOR_FETCH:
      return setSelectedEndDateForFetch(state, action.payload);
    case constants.RECEIVE_UNALLOCATED_PAYMENT:
      return setUnallocatedPaymentList(state, action);
    case constants.UNALLOCATED_PAYMENT_SUCCESS:
      return setSuccessMessage(state, action);
    case constants.UNALLOCATED_PAYMENT_ERROR:
      return setErrorMessage(state, action);
    default:
      return state;
  }
}

export function paymentCardList(state = defaultCardList, action) {
  switch (action.type) {
    case constants.RECEIVE_PAYMENT_CARDS:
      return Immutable.fromJS(action.data);
    default:
      return state;
  }
}

export function paymentChargeList(state = defaultChargeList, action) {
  switch (action.type) {
    case constants.RECEIVE_PAYMENT_CHARGES:
      return Immutable.fromJS(action.data);
    case constants.TOGGLE_CHARGE: {
      const bool = !state.get(action.index).get("show");
      const newState = state.setIn([action.index, "show"], bool);
      return newState;
    }
    case constants.ADD_PAYMENT_CHARGES:
      return Immutable.fromJS([action.data, ...state]);
    default:
      return state;
  }
}

export function paymentRefundMsg(state = defaultPaymentRefundMsg, action) {
  switch (action.type) {
    case constants.RECEIVE_PAYMENT_REFUND:
      return Immutable.fromJS(action.data);
    default:
      return state;
  }
}
