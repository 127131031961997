import React, { useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import selectNotificationsContainer from "../../selectors/notifications";
import {
  getNotifications,
  getNotificationViewUrl,
  closeEmail,
  getNotificationEvents,
  setSelectedNotification,
  resendNotification,
} from "../../actions/notifications";

import Button from "../Button"
import ReactTable from "react-table";
import Tooltip from "@material-ui/core/Tooltip";
import OpenMail from '@material-ui/icons/MailOutline';
import OpenAttachment from '@material-ui/icons/AttachFile';
import OpenEvent from '@material-ui/icons/EventNote';
import Resend from '@material-ui/icons/Send';
import EmailView from "../NotificationsView/EmailView";
import EventHistory from "../NotificationsView/EventHistory";
import ResendEmailDialog from "../NotificationsView/ResendEmailDialog";
import StatusOverlay from "../StatusOverlay";

const getDate = (value) => {
  if (!value) {
    return "";
  }
  const d = new Date(value);
  return moment(d).format("YYYY-MM-DD");
};

const NotificationsView = props => {
  const {
    searchTerms,
    notifications,
    selectedNotification,
    doFetchGetNotifications,
    doFetchNotificationViewUrl,
    doFetchNotificationEvents,
    doSetSelectedNotification,
    doCloseEmail: doCloseDialog,
    doResendNotification,
  } = props

  const [resendDialogOpen, setResendDialogOpen] = React.useState(false);

  useEffect(() => {
    doFetchGetNotifications(searchTerms);
  }, [])

  const openEmailDialog = (notification) => {
    doFetchNotificationViewUrl(notification);
  }

  const openEventsDialog = (notification) => {
    doFetchNotificationEvents(notification);
  }

  const openResendDialog = (notification) => {
    doSetSelectedNotification({ notification });
    setResendDialogOpen(true);
  }

  const closeResendDialog = () => {
    setResendDialogOpen(false);
    doCloseDialog();
  }

  const actionButtonStyle = {
    marginRight: "5px",
    maxWidth: "30px",
    maxHeight: "30px",
    minWidth: "15px",
    minHeight: "15px",
  }

  const columns = [
    {
      Header: "Email",
      accessor: "to_email",
    },
    {
      Header: "Type",
      accessor: "notification_type",
    },
    {
      Header: "Event",
      accessor: "event",
    },
    {
      Header: "Email Status",
      id: "email_status",
      accessor: (row) => {
        const provider = row.email_details.provider
        const status = row.email_details.status
        let message = ''
        if (status) {
          message = status.charAt(0).toUpperCase() + status.slice(1)
        }

        return provider ? `${message} by ${provider}` : message
      }
    },
    {
      Header: "Print Status",
      accessor: (row) => row.print_details.status,
      id: "print_status",
    },
    {
      Header: "Notification Date",
      id: "date",
      accessor: "created",
      Cell: (row) => {
        return getDate(row.value)
      },
    },
    {
      Header: "",
      id: "commands",
      width: 156,
      style: { textAlign: "left" },
      filterable: false,
      sortable: false,
      accessor: (row) => (
        <div>
          <Tooltip placement="top" title="Open email"><span>
            <Button
              style={actionButtonStyle}
              onClick={() => openEmailDialog(row)}
              variant="contained"
              color="secondary"
              disabled={row.email_details.provider === "iterable"}
            >
              <OpenMail />
            </Button>
          </span></Tooltip>
          <Tooltip placement="top" title="Open attachment"><span>
            <Button
              style={actionButtonStyle}
              onClick={() => window.open(row.attachment_url, '_blank')}
              variant="contained"
              color="secondary"
              disabled={row.attachment_url === ""}
            >
              <OpenAttachment />
            </Button>
          </span></Tooltip>
          <Tooltip placement="top" title="View events"><span>
            <Button
              style={actionButtonStyle}
              onClick={() => openEventsDialog(row)}
              variant="contained"
              color="secondary"
              disabled={row.email_details.provider === "iterable"}
            >
              <OpenEvent />
            </Button>
          </span></Tooltip>
          <Tooltip placement="top" title="Resend Email"><span>
            <Button
              style={actionButtonStyle}
              onClick={() => openResendDialog(row)}
              variant="contained"
              color="secondary"
              disabled={row.email_details.provider !== "sendgrid"}
            >
              <Resend />
            </Button>
          </span></Tooltip>
        </div>
      )
    },
  ]

  return (
    <div>
      {
        notifications && notifications.length > 0 ?
          <Fragment>
            <ReactTable
              data={notifications}
              columns={columns}
              style={{ textAlign: 'center' }}
              className="-striped -highlight"
              defaultPageSize={10}
              defaultSortDesc
              defaultSorted={[
                { id: "date", desc: true },
              ]}
              filterable
              default
            />
          </Fragment> :
          <div>
            There are no notifications to show
          </div>
      }
      {selectedNotification && selectedNotification.emailContent &&
        <EmailView notification={selectedNotification} closeEmail={doCloseDialog} />
      }
      {selectedNotification && selectedNotification.events &&
        <EventHistory events={selectedNotification.events} closeEmail={doCloseDialog} />
      }
      {selectedNotification && resendDialogOpen &&
        <ResendEmailDialog notification={selectedNotification.notification} closeEmail={closeResendDialog} resendNotification={doResendNotification} />
      }
      <StatusOverlay />
    </div>
  )
}


const NotificationsContainer = (props) => <NotificationsView {...props} />;

const mapStateToProps = (state) => ({
  ...selectNotificationsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetNotifications: (searchTerms) => {
    dispatch(getNotifications(searchTerms));
  },
  doFetchNotificationViewUrl: (notification) => {
    dispatch(getNotificationViewUrl(notification));
  },
  doFetchNotificationEvents: (notification) => {
    dispatch(getNotificationEvents(notification));
  },
  doSetSelectedNotification: (notification) => {
    dispatch(setSelectedNotification(notification));
  },
  doResendNotification: (notification) => {
    dispatch(resendNotification(notification));
  },
  doCloseEmail: () => {
    dispatch(closeEmail());
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);