import * as C from "../../constants";

const initialState = {
  isProcessing: false,
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

const setIsProcessing = (state, isProcessing) => {
  return {
    ...state,
    isProcessing: isProcessing,
  };
};

export default function transitionOfService(state = initialState, action) {
  switch (action.type) {
    case C.METER_CLOSE_SNACKBAR:
    case C.TRANSFER_OF_SERVICE_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case C.METER_OPEN_SNACKBAR:
    case C.TRANSFER_OF_SERVICE_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case C.SET_METER_IS_PROCESSING:
    case C.SET_TRANSFER_OF_SERVICE_IS_PROCESSING:
      return setIsProcessing(state, action.payload);
    default:
      return state;
  }
}
