import * as constants from "../../constants";
import fetch from "../util/api-ajax";

export const createErrorMessage = (errorMessage) => ({
  type: constants.REFERRALS_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.REFERRALS_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.REFERRALS_CLOSE_SNACKBAR });
};

function isProcessing(payload) {
  return {
    type: constants.UPDATE_REFERRALS_IS_PROCESSING,
    payload: payload,
  };
}

function requestReferralCreditsSummaries() {
  return {
    type: constants.REQUEST_REFERRAL_CREDITS_SUMMARIES,
  };
}

function receiveReferralCreditsSummaries(payload) {
  return {
    type: constants.RECEIVE_REFERRAL_CREDITS_SUMMARIES,
    payload: payload,
  };
}

function requestReferralCredits() {
  return {
    type: constants.REQUEST_REFERRAL_CREDITS,
  };
}

function receiveReferralCredits(payload) {
  return {
    type: constants.RECEIVE_REFERRAL_CREDITS,
    payload: payload,
  };
}

function requestApproveReferralCredits() {
  return {
    type: constants.REQUEST_APPROVE_REFERRAL_CREDITS,
  };
}

function approveReferralCreditsSuccess() {
  return {
    type: constants.APPROVE_REFERRAL_CREDITS_SUCCESS,
  };
}

function approveReferralCreditsFailure() {
  return {
    type: constants.APPROVE_REFERRAL_CREDITS_FAILURE,
  };
}

export const approveReferralCredits =
  ({ user_ids }) =>
    (dispatch) => {
      dispatch(requestApproveReferralCredits);
      dispatch(isProcessing(true));

      const payload = {
        user_ids,
      };

      fetch(constants.APPROVE_REFERRAL_CREDITS, payload)
        .then((res) => {
          if (res.result) {
            dispatch(setSuccessMessage("Referral credits successfully updated"));
            dispatch(fetchReferralCreditSummaries({ isInitialLoad: true }));
            dispatch(approveReferralCreditsSuccess);
          } else {
            dispatch(setErrorMessage("Failed to update referral credits"));
            dispatch(approveReferralCreditsFailure);
          }
        })
        .catch((err) => {
          dispatch(setErrorMessage(err.message));
          dispatch(approveReferralCreditsFailure);
        })
        .finally(() => {
          dispatch(isProcessing(false));
        });
    };

export const fetchReferralCreditSummaries =
  ({ isInitialLoad }) =>
    (dispatch, getState) => {
      dispatch(requestReferralCreditsSummaries());
      dispatch(isProcessing(true));

      const store = getState();
      const { limit, page } = store.referrals;
      let payload = {
        page: page + 1,
        limit,
        status: "pending_approval",
      };

      if (isInitialLoad) {
        payload.page = 1;
      }

      fetch(constants.LIST_REFERRAL_CREDITS_SUMMARIES, payload)
        .then((res) => {
          dispatch(receiveReferralCreditsSummaries({ ...res, isInitialLoad }));
          dispatch(isProcessing(false));
        })
        .catch((err) => {
          dispatch(setErrorMessage(err.message));
          dispatch(isProcessing(false));
        });
    };

export const fetchReferralCredits =
  ({ user_id }, callback) =>
    (dispatch) => {
      const payload = {
        user_id: user_id,
        status: "pending_approval",
      };

      dispatch(requestReferralCredits());
      dispatch(isProcessing(true));

      fetch(constants.LIST_REFERRAL_CREDITS, payload)
        .then((res) => {
          dispatch(receiveReferralCredits({ user_id, ...res }));
          dispatch(isProcessing(false));
        })
        .catch((err) => {
          dispatch(setErrorMessage(err.message));
          dispatch(isProcessing(false));
        })
        .finally(() => {
          // handles component related events
          if (callback) {
            callback();
          }
        });
    };

// Fetches and appends billing account numbers to list of referrals or to primary account details
async function addAccountNumbers(payload) {
  const memberIDs = payload.map((member) => member.referral_member_id ? member.referral_member_id : member.member_id);

  var billingInfo = await fetch(constants.BILLING_ACCOUNTS, { memberIDs, limit: memberIDs.length });


  const modifiedPayload = payload.map((member) => {
    const billing = billingInfo.accounts.find((billing) => billing.memberID === member.referral_member_id || billing.memberID === member.member_id);
    member.billing_account_number = billing?.account_number;
    return member;
  })

  return modifiedPayload;
}

function receiveReferrals(payload) {
  return {
    type: constants.RECEIVE_REFERRALS,
    payload: payload,
  };
}

export const fetchReferrals = (payload) => async (dispatch) => {
  dispatch(isProcessing(true));

  try {
    // skip pagination for now
    var refs = await fetch(constants.LIST_REFERRALS, payload);
    if (refs.has_next) {
      refs = await fetch(constants.LIST_REFERRALS, { ...payload, limit: refs.total });
    }

    const updatedRefs = await addAccountNumbers(refs.referrals);

    dispatch(receiveReferrals(updatedRefs));
  } catch (err) {
    dispatch(setErrorMessage(err.message));
  } finally {
    dispatch(isProcessing(false));
  }
}

function receivePrimaryAccountStatus(payload) {
  return {
    type: constants.RECEIVE_REFERRALS_PRIMARY_ACCOUNT,
    payload: payload,
  };
}

export const fetchPrimaryAccountStatus = (payload) => async (dispatch) => {
  dispatch(isProcessing(true));
  try {
    const res = await fetch(constants.REFERRALS_PRIMARY_ACCOUNT, payload);
    const accountDetails = await addAccountNumbers([res.details]);
    dispatch(receivePrimaryAccountStatus(accountDetails[0]));
  } catch (err) {
    dispatch(setErrorMessage(err.message));
  } finally {
    dispatch(isProcessing(false));
  }
}

function recieveRewardsBalance(payload) {
  return {
    type: constants.RECEIVE_REFERRALS_REWARDS_BALANCE,
    payload: payload,
  };
}

export const fetchRewardsBalance = (payload) => async (dispatch) => {
  dispatch(isProcessing(true));
  try {
    const res = await fetch(constants.REFERRALS_REWARDS_BALANCE, payload);
    dispatch(recieveRewardsBalance(res));
  } catch (err) {
    dispatch(setErrorMessage(err.message));
  } finally {
    dispatch(isProcessing(false));
  }
}
