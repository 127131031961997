import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "../components/Snackbar"
import Dialog from "@material-ui/core/Dialog";
import SetDisconnectNoticeStatus from "../components/members/SetDisconnectNoticeStatus";
import Button from "../components/Button"
import moment from "moment";
import selectDnpRnpHistoryContainer from "../selectors/dnpRnpHistory";
import * as actions from "../actions/dnpRnpHistory";
import {
  confirmationError,
  confirmationSuccess,
} from "../styles/common.css";
import currency from "../util/currency-formatter";
import Grid from "@material-ui/core/Grid";
import XLSX from "xlsx";


const styles = () => ({
  list: {
    listStyle: "none",
    paddingLeft: "0",
  },
  column: {
    width: "50%",
  },
});

const classes = {
  root: {
    width: "500px",
  },
  disconnectTypeForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
};


class DnpRnpHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusEdit: false,
      showOpenStatusDialog: false,
      selectedRecordID: 0,
      selectedStatus: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.memberId &&
      this.props.selectedMember.get("accountID") !==
      nextProps.selectedMember.get("accountID")
    ) {
      this.props.doFetchMemberDnpTransactions(
        this.props.selectedMember.get("accountID")
      )
    }
  }

  componentWillMount() {
    if (this.props.params.memberId) {
      this.props.doFetchMemberDnpTransactions(
        this.props.selectedMember.get("accountID")
      );
    } else {
      this.props.doFetchAllDnpTransactions();
    }
  }

  componentWillUnmount() { }

  getDate = (value) => {
    if (!value) {
      return "";
    }
    const d = value.split("T")[0];
    return moment(d).format("MMM DD, YYYY");
  };

  handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.props.updateFilters({ [name]: value });
    if (this.props.params.memberId) {
      this.props.doFetchMemberDnpTransactions(this.props.params.memberId);
    } else {
      this.props.doFetchAllDnpTransactions();
    }
  };

  handleCloseStatusDialog = () => {
    this.setState({ showOpenStatusDialog: false });
  };

  openSetStatusDialog = (id, status) => {
    this.setState({ selectedRecordID: id });
    this.setState({ selectedStatus: status });
    this.setState({ showOpenStatusDialog: true });
  };

  render() {
    const { status, transactions, showSnackBar, successMessage, errorMessage } = this.props;
    const columns = [
      {
        Header: "Member ID",
        accessor: "memberID",
        Cell: (row) => (
          <Link to={`/members/${row.value}/details`}>{row.value}</Link>
        ),
      },
      {
        Header: "Amount Due",
        accessor: "amount_due",
        Cell: (row) => (
          <div style={{ textAlign: "center" }}>{currency(row.value)}</div>
        ),
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        Cell: (row) => (
          <div style={{ textAlign: "center" }}>
            {moment.utc(row.value).format("YYYY-MM-DD")}
          </div>
        ),
      },
      {
        Header: "Date Sent",
        accessor: "date_sent",
        Cell: (row) => (
          <div style={{ textAlign: "center" }}>
            {(row.value && moment.utc(row.value).format("YYYY-MM-DD")) || "---"}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "",
        accessor: "id",
        Cell: (i) => (
          <Button
            onClick={() => {
              this.openSetStatusDialog(i.row.id, i.row.status);
            }}
            variant="contained"
            color="secondary"
            disabled={this.props.user && this.props.user.roles && this.props.user.roles.includes('csr')}
          >
            Set Status
          </Button>
        ),
      },
    ];

    function formatter(value) {
      const patternInt = /^-?\d+$/;
      const patternFloat = /^-?\d+(\.\d+)?$/;
      const patternDate = /^\d{4}-\d{2}-\d{2}$/;
      const patternDatetime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z$/;
      if (patternInt.test(value)) {
        return parseInt(value, 10);
      } else if (patternFloat.test(value)) {
        return parseFloat(value);
      } else if (patternDate.test(value)) {
        return new Date(value + "T00:00:00.000");
      } else if (patternDatetime.test(value)) {
        return new Date(value.replace('Z', ''));
      } else {
        return value;
      }
    }

    function convertToCSV(data) {
      const excelColumns = columns.filter(z => z.Header != "").map(z => z.accessor);
      const orderedData = data.map(item =>
        excelColumns.reduce((acc, column) => {
          let title = column.split('_').join(' ')
          title = title.charAt(0).toUpperCase() + title.slice(1)
          acc[title] = formatter(item[column]);
          return acc;
        }, {})
      );

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(orderedData, {
        header: excelColumns.map(item => {
          const title = item.split('_').join(' ')
          return title.charAt(0).toUpperCase() + title.slice(1)
        })
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
      const fileName = 'Report.xlsx';
      XLSX.writeFile(workbook, fileName);
    }

    return (
      <div>
        <h4>Disconnect Notices</h4>
        Status Filter :{" "}
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Select
              value={status}
              onChange={this.handleChange}
              inputProps={{
                name: "status",
              }}
              style={{ marginBottom: 20, marginLeft: 20, minWidth: 150 }}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="sent">Sent</MenuItem>
              <MenuItem value="disconnected">Disconnected</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </Grid>
          <Grid item md={6}>
            <Button
              style={{ float: "right" }}
              variant="contained"
              color="primary"
              onClick={e => { convertToCSV(transactions) }}
            >
              EXPORT
            </Button>
          </Grid>
        </Grid>
        <ReactTable
          data={transactions}
          columns={columns}
          className="-striped -highlight"
          defaultPageSize={10}
          defaultSortOrder="Request Date"
        />
        <Snackbar
          id="message-id"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={showSnackBar}
          onClose={this.props.closeSnackbar}
          className={successMessage ? confirmationSuccess : confirmationError}
          message={successMessage || errorMessage}
        />
        <Dialog
          style={classes.dialog}
          onClose={this.handleCloseStatusDialog}
          aria-labelledby="customized-dialog-title"
          open={this.state.showOpenStatusDialog}
        >
          <SetDisconnectNoticeStatus
            recordID={this.state.selectedRecordID}
            currentStatus={this.state.selectedStatus}
            onStatusChange={this.props.updateDisconnectNoticeStatus}
            closePopup={this.handleCloseStatusDialog}
          />
        </Dialog>
      </div>
    );
  }
}

DnpRnpHistory.propTypes = {
  transactions: PropTypes.array.isRequired,
};

const mapStateToProps = selectDnpRnpHistoryContainer();

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  dispatch,
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DnpRnpHistory)
);
