import * as constants from "../../constants";
import fetch from "../util/api-ajax";

function requestUsage() {
  return {
    type: constants.REQUEST_USAGE,
    isFetching: true,
  };
}

function receiveUsage(data) {
  return {
    type: constants.RECEIVE_USAGE,
    isFetching: false,
    data,
  };
}

function requestGeneration() {
  return {
    type: constants.REQUEST_GENERATION,
    isFetching: true,
  };
}

function receiveGeneration(data) {
  return {
    type: constants.RECEIVE_GENERATION,
    isFetching: false,
    data,
  };
}

function requestMonthlyUsage() {
  return {
    type: constants.REQUEST_MONTHLY_USAGE,
    isFetching: true,
  };
}

function receiveMonthlyUsage(data) {
  return {
    type: constants.RECEIVE_MONTHLY_USAGE,
    isFetching: false,
    data,
  };
}

function requestNetMeteringBank() {
  return {
    type: constants.REQUEST_NET_METERING_BANK,
    isFetching: true,
  };
}

function receiveNetMeteringBank(data) {
  return {
    type: constants.RECEIVE_NET_METERING_BANK,
    isFetching: false,
    data,
  };
}

export function getUsage(memberID, guestUserID) {
  return (dispatch) => {
    dispatch(requestUsage());

    const meterQuery = {
      memberID,
      include_inactive: false,
    };

    const searchObj = {
      iana_timezone: "America/Chicago",
      load_zone: "LZ_HOUSTON",
    };

    const endpointMeter = guestUserID ? constants.GUEST_METER_SEARCH_URL : constants.METER_SEARCH_URL;
    const endpointUsage = guestUserID ? constants.GUEST_GET_USAGE : constants.GET_USAGE;

    return fetch(endpointMeter, meterQuery)
      .then((json) => {
        searchObj.meterID = json.meters[0].ID;
        return fetch(endpointUsage, searchObj).then(data => dispatch(receiveUsage(data)));
      });
  };
}

export function getGeneration(memberID, aggregation) {
  return (dispatch) => {
    dispatch(requestGeneration());

    const meterQuery = {
      memberID,
      include_inactive: false,
    };

    const endpointMeter = constants.METER_SEARCH_URL;
    const endpointGeneration = constants.GET_GENERATION;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthString = month < 10 ? `0${month}` : month;
    const day = currentDate.getDate();
    const dayString = day < 10 ? `0${day}` : day;

    let period = "";
    if (aggregation === "daily") {
      period = `${year}-${monthString}-${dayString}`
    } else if (aggregation === "monthly") {
      period = `${year}-${monthString}-01`
    } else if (aggregation === "yearly") {
      period = `${year}-01-01`
    }

    return fetch(endpointMeter, meterQuery)
      .then((json) => {
        return fetch(endpointGeneration, {
          aggregation,
          meterID: json.meters[0].ID,
          period: period,
        }).then(data => dispatch(receiveGeneration(data)));
      });
  };
}

export function getMonthlyUsage(memberID) {
  return (dispatch) => {
    dispatch(requestMonthlyUsage());

    const meterQuery = {
      memberID,
      include_inactive: false,
    };

    return fetch(constants.METER_SEARCH_URL, meterQuery)
      .then((json) => {
        return fetch(constants.USAGE_GET_MONTHLY_USAGE, {
          meter_id: json.meters[0].ID,
        }).then(data => dispatch(receiveMonthlyUsage(data)));
      });
  };
}

export function getNetMeteringBank(memberID) {
  return (dispatch) => {
    dispatch(requestNetMeteringBank());

    return fetch(constants.USAGE_GET_NET_METERING_BANK_CREDIT, {
      member_id: memberID,
    }).then(data => dispatch(receiveNetMeteringBank(data)));
  };
}