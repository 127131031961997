import React from "react";
import { connect } from "react-redux";
import MemberBilling from "../components/Billings";
import {
  initializeTransactionSummary,
  applyAdjustment,
  hideSnackBar,
  loadMoreBillingTransactions,
} from "../actions/billing";
import { noteCreate } from "../actions/members";

const MemberBillingContainer = (props) => <MemberBilling {...props} />;

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  billingSummaryState: state.billingSummaryState,
  confMsg: state.billingAdjustmentMsg,
  acceptedPairs: state.acceptedPairs,
  transactions: state.billingTransactions,
});

const mapDispatchToProps = (dispatch) => ({
  doApplyAdjustment: (
    userId,
    memberId,
    type,
    subType,
    applyType,
    amount,
    comment,
    date
  ) => {
    dispatch(
      applyAdjustment(
        userId,
        memberId,
        type,
        subType,
        applyType,
        amount,
        comment,
        date
      )
    );
  },
  doInitTransactionSummary: (memberID) => {
    dispatch(initializeTransactionSummary({ memberID }));
  },
  onHideSnackBar: () => dispatch(hideSnackBar()),
  doRequestAllBillingTransactions: (memberId, page) => {
    dispatch(loadMoreBillingTransactions(memberId, page));
  },
  onNoteCreate: (authorID, authorEmail, userID, memberID, body) =>
    dispatch(noteCreate(authorID, authorEmail, userID, memberID, body)),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberBillingContainer);
