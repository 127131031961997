import React, { useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import {
  validateEmail,
  validatePhone,
  validateZip,
} from "../../util/validations";

import { PhoneNumberMask, phoneUnmaskedValue } from "../../util/masks"

const StyledTextField = ({ label, name, value, onChange, disabled, ...props }) => <div>
  <TextField label={label} name={name} value={value} onChange={onChange} disabled={disabled} variant="outlined" size="small" fullWidth {...props} />
</div>

export const UserFields = ({ userDetails, setUserDetails, disabled = false }) => {
  const [maskedPhone, setMaskedPhone] = useState(userDetails.phone);

  function onChangeUser(event) {
    setUserDetails({
      ...userDetails,
      [event.target.name]: event.target.value,
    });
  }

  function onChangePhone(event) {
    // Keep a text version of the phone number so deleting characters works
    setMaskedPhone(event.target.value);
    setUserDetails({
      ...userDetails,
      phone: phoneUnmaskedValue(event.target.value),
    });
  }

  return <Grid container spacing={2}>
    <Grid item sm={12}>
      <StyledTextField
        label="Company Name (billing name)"
        name="company_name"
        value={userDetails.company_name}
        onChange={onChangeUser}
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="First Name"
        name="first_name"
        value={userDetails.first_name}
        onChange={onChangeUser}
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="Last Name"
        name="last_name"
        value={userDetails.last_name}
        onChange={onChangeUser}
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="Email"
        name="email"
        value={userDetails.email}
        onChange={onChangeUser}
        disabled={disabled}
        error={!disabled && userDetails.email && !validateEmail(userDetails.email)}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="Phone"
        name="phone"
        value={maskedPhone}
        onChange={onChangePhone}
        disabled={disabled}
        InputProps={{ inputComponent: PhoneNumberMask }}
        error={!disabled && userDetails.phone && !validatePhone(userDetails.phone)}
      />
    </Grid>
    <Grid item sm={6}>
      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="language-label">Language</InputLabel>
        <Select labelId="language-label" label="Language" onChange={onChangeUser} name="language" value={userDetails.language} disabled={disabled}>
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="es">Spanish</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  </Grid>
}

export const AddressFields = ({ address, setAddress, disabled = false }) => {
  function onChangeAddress(event) {
    setAddress({
      ...address,
      [event.target.name]: event.target.value,
    })
  }

  function onChangeZip(event) {
    if (event.target.value.length > 5) {
      return;
    }
    setAddress({
      ...address,
      postal_code: event.target.value.replace(/\D/g, ""),
    })
  }

  return <Grid container spacing={2}>
    <Grid item sm={12}>
      <StyledTextField
        label="Line 1"
        name="line1"
        value={address.line1}
        onChange={onChangeAddress}
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={12}>
      <StyledTextField
        label="Line 2"
        name="line2"
        value={address.line2}
        onChange={onChangeAddress}
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="City"
        name="city"
        value={address.city}
        onChange={onChangeAddress}
        disabled={disabled}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="State"
        name="state"
        value={address.state}
        onChange={onChangeAddress}
        disabled={true}
      />
    </Grid>
    <Grid item sm={6}>
      <StyledTextField
        label="Zip Code"
        name="postal_code"
        value={address.postal_code}
        onChange={onChangeZip}
        disabled={disabled}
        error={!disabled && address.postal_code && !validateZip(address.postal_code)}
      />
    </Grid>
  </Grid>
}
