import PropTypes from "prop-types";
import React, { useState } from "react";
import moment from "moment";
import { useStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";
import Button from "../Button"
import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import StatementTables from "./StatementTables";
import PendingApproval from "./PendingApproval";
import PendingDelivery from "./PendingDelivery";
import StatementInError from "./StatementInError";
import StatementInReview from "./StatementInReview";

const StatementApproval = (props) => {
  const classes = useStyles();
  const {
    setSelectedStatusForFetch,
    doFetchBillingStatements,
    doUpdateStatementStatus,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
    statements,
    selectedStatusForFetch,
  } = props;

  const [selectedView, setSelectedView] = useState(0);

  const convertToCSV = (data)=> {
    const headers = [
      {
        Header: "Member ID",
        accessor: "member_id",
      },
      {
        Header: "Statement Date",
        accessor: "statement_date",
        format: "Date",
      },
      {
        Header: "Start Date",
        accessor: "spsd",
        format: "Date",
      },
      {
        Header: "End Date",
        accessor: "sped",
        format: "Date",
      },
      {
        Header: "Amount",
        accessor: "amount_due",
      },
      {
        Header: "Average Rate",
        accessor: "summary.average_rate",
      },
      {
        Header: "Due Date",
        accessor: "due_date",
        format: "Date",
      },
      {
        Header: "Final",
        accessor: "is_final",
      },
      {
        Header: "Exceptions",
        accessor: "exceptions",
        type: "array"
      },,
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "PDF",
        accessor: "pdf_url",
      }
    ]
    const header = headers.map(x => x.Header).join(",");
    const values = data.filter(d=> selectedView!==0 || (!d.exceptions || d.exceptions.length === 0)).map(x => headers.map(y => {
      if(y.accessor.includes(".")){
        const keys = y.accessor.split(".")
        return JSON.parse(x[keys[0]])[keys[1]]
      } 
      if(y.format === "Date"){
        return moment.utc(x[y.accessor]).format("YYYY-MM-DD")
      }
      if(y.type === "array"){
        return `"${x[y.accessor].join(",")}"`
      }
      return x[y.accessor]
    }).join(","));
    const csv = [header, ...values].join("\n");    
    const csvContent = `data:text/csv;charset=utf-8,${csv}`;
    const encodedURI = encodeURI(csvContent);
    window.open(encodedURI);
  }
  
  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">Statement Approval
        <Button
          color="secondary"
          variant="contained"
          onClick={() => convertToCSV(statements)}
          style={{ float: "right", marginBottom: "5px" }}
          disabled={isProcessing || statements.filter( d=> selectedView!==0 || (!d.exceptions || !d.exceptions.length)).length === 0}
        >
          EXPORT
        </Button>
      </h1>
      <StatementTables
        setSelectedStatusForFetch={setSelectedStatusForFetch}
        doFetchBillingStatements={doFetchBillingStatements}
        setSelectedView={setSelectedView}
      >
        <PendingApproval
          statements={statements}
          doUpdateStatementStatus={doUpdateStatementStatus}
        />
        <StatementInReview
          statements={statements}
          doUpdateStatementStatus={doUpdateStatementStatus}
        />
        <StatementInError
          statements={statements}
          doUpdateStatementStatus={doUpdateStatementStatus}
        />
        <PendingDelivery
          statements={statements}
          doUpdateStatementStatus={doUpdateStatementStatus}
        />
      </StatementTables>
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

StatementApproval.propTypes = {
  setSelectedStatusForFetch: PropTypes.func.isRequired,
  doFetchBillingStatements: PropTypes.func.isRequired,
  doUpdateStatementStatus: PropTypes.func.isRequired,
};

export default StatementApproval;
