import React from "react";
import { connect } from "react-redux";
import Reports from "../components/Reports";
import {
  getReports,
  runReport,
} from "../actions/reports";
import selectReportsContainer from "../selectors/reports";

const ReportsContainer = (props) => <Reports {...props} />;

const mapStateToProps = (state) => ({
  ...selectReportsContainer(state)(state),
});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetReports: () => {
    dispatch(getReports());
  },
  doFetchRunReport: (id) => {
    dispatch(runReport(id));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
