import React, { useEffect } from "react";
import { useStyles } from "./styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";
import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import BatchView from "./BatchView";
import FileImport from "./FileImport";

const Batch = (props) => {
  const classes = useStyles();
  const {
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isLoading,
    adminUser,
  } = props;

  return (
    <div className={classes.root}>
      <Snackbar
        id="message-id"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackBar}
        onClose={closeSnackbar}
        className={successMessage ? confirmationSuccess : confirmationError}
        message={successMessage || errorMessage}
      />
      <h1 id="page-title">Batch Imports</h1>
      <BatchView>
        <FileImport adminUser={adminUser}/>
      </BatchView>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

export default Batch;
