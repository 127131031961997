import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  FormControl,
  TextField,
} from "@material-ui/core/";
import Button from "../../Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { useStyles } from "./styles";
import currency from "../../../util/currency-formatter";
// fetch level billing by account id
import {
  fetchLevelBillings,
  fetchCreateLevelBilling,
} from "../../../actions/search";
import { noselect } from "../../../styles/common.css";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Error } from "@material-ui/icons";

const LevelBilling = (props) => {
  const classes = useStyles();
  const {
    adminUser,
    accountID,
    levelBillings,
    onFetchCreateLevelBilling,
    onFetchLevelBillings,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [disableForm, setDisableForm] = useState(false);
  const { isBillingManager } = adminUser.permissions;

  useEffect(() => {
    if (accountID) {
      onFetchLevelBillings(accountID);
    }
  }, [accountID]);

  useEffect(() => {
    // if level billing exist in pending/in_progress then do not allow create actions
    const notEligible = levelBillings?.some(
      (item) =>
        item.get("status") === "pending" || item.get("status") === "in_progress"
    );
    setDisableForm(notEligible);
  }, [levelBillings]);

  const handleCloseDialog = () => {
    setAmount("");
    setOpenDialog(false);
  };

  const handleAmountChange = (e) => {
    let value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === "") {
      setAmount(e.target.value);
    }
  };

  const validateInput = () => {
    return amount && parseFloat(amount);
  };

  const handleCreateLevelBilling = async () => {
    await onFetchCreateLevelBilling({ account_id: accountID, amount: amount })
      .then(() => {
        handleCloseDialog();
        onFetchLevelBillings(accountID);
      })
      .catch(() => {
        setError("Failed to create level billing");
      })
      .finally(() => handleCloseDialog());
  };

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item md={6}>
          <h4 style={{ marginLeft: "25px" }}>Level Billing</h4>
        </Grid>
        <Grid item md={6}>
          <div style={{ float: "right", marginBottom: "10px" }}>
            <IconButton
              aria-label="Create"
              size="medium"
              title="Create"
              color="primary"
              onClick={() => setOpenDialog(true)}
              disabled={!isBillingManager}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
          </div>
        </Grid>
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="collapsible table"
            style={{ fontSize: 14 }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">End Date</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {levelBillings &&
                levelBillings.map((lb, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {currency(lb.get("amount"))}
                      </TableCell>
                      <TableCell align="center">
                        {lb.get("start_date")
                          ? moment
                              .utc(lb.get("start_date"))
                              .format("YYYY-MM-DD")
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {lb.get("end_date")
                          ? moment.utc(lb.get("end_date")).format("YYYY-MM-DD")
                          : "-"}
                      </TableCell>
                      <TableCell align="center">{lb.get("status")}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog
        onClose={() => handleCloseDialog()}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <div className={classes.dialogRoot}>
          <Card className={classes.createNoteContainer} variant="outlined">
            <div className={`${classes.menuBar} ${noselect}`}>
              <span>Create Level Billing</span>
              <CloseIcon onClick={() => handleCloseDialog()} />
            </div>
            <FormControl className={classes.dialogForm}>
              <TextField
                label={`Enter Amount`}
                placeholder="0.00"
                onChange={(e) => handleAmountChange(e)}
                value={amount}
                type="money"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dialogText}
                disabled={disableForm}
              />
            </FormControl>
            {error && (
              <div
                style={{
                  color: "red",
                  marginLeft: "25px",
                  marginBottom: "15px",
                }}
              >
                {error}
              </div>
            )}
            {levelBillings && disableForm && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span
                  style={{
                    backgroundColor: "red",
                    padding: "10px",
                    paddingLeft: "22px",
                    color: "black",
                    width: "100%",
                  }}
                >
                  <Error /> {"Level billing already pending / in progress"}
                </span>
              </div>
            )}
            <div
              className={classes.optionContainer}
              style={{
                borderTop: "2px solid #ddd",
              }}
            >
              <Button
                autoFocus
                color="secondary"
                varian="raised"
                onClick={async () => await handleCloseDialog()}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                className={
                  validateInput() ? classes.enableButton : classes.disableButton
                }
                color="primary"
                varian="raised"
                onClick={async () => {
                  await handleCreateLevelBilling(accountID);
                }}
                disabled={disableForm}
              >
                Save
              </Button>
            </div>
          </Card>
        </div>
      </Dialog>
    </Card>
  );
};

LevelBilling.propTypes = {
  adminUser: PropTypes.object,
  accountID: PropTypes.string,
  levelBillings: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onFetchLevelBillings: (accountID) => dispatch(fetchLevelBillings(accountID)),
  onFetchCreateLevelBilling: (accountID) =>
    dispatch(fetchCreateLevelBilling(accountID)),
  dispatch,
});

const ConnectedLevelBilling = connect(
  mapStateToProps,
  mapDispatchToProps
)(LevelBilling);

export default ConnectedLevelBilling;
