import PropTypes from "prop-types";
import React, { useEffect } from "react";

import EnrollmentTable from "./EnrollmentTable";

const HeldEnrollments = ({
  enrollments,
  selectedView,
  fetchAddNote,
  fetchEnrollments,
  fetchCancelEnrollments,
  resubmitEnrollment,
  fetchUpdateEnrollmentName,
  adminUser,
}) => {
  const searchCriteria = { "status": "hold" };

  useEffect(async () => {
    if (selectedView == "Held Enrollments") {
      await fetchEnrollments(searchCriteria, true)
    }
  }, [selectedView]);

  return (
    <div style={{ marginTop: "30px" }}>
      <EnrollmentTable
        isHold={true}
        adminUser={adminUser}
        searchCriteria={searchCriteria}
        enrollments={enrollments}
        fetchAddNote={fetchAddNote}
        fetchEnrollments={fetchEnrollments}
        fetchCancelEnrollments={fetchCancelEnrollments}
        resubmitEnrollment={resubmitEnrollment}
        fetchUpdateEnrollmentName={fetchUpdateEnrollmentName}
      />
    </div>
  );
};

HeldEnrollments.propTypes = {
  enrollments: PropTypes.array,
};

export default HeldEnrollments;
