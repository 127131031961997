import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../../Button"
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, FormLabel } from "@material-ui/core/";
import { noselect } from "../../../styles/common.css";
import { processRefund } from "../../../actions/payment";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  RefundForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  paymentText: {    
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",    
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    pointerEvents: "none",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const RefundForm = (props) => {
  const classes = useStyles();
  const [amount, setAmount] = useState();
  const [active, setActive] = useState(true);
  const [message, setMessage] = useState("");
  const { closePopup, onProcessRefund } = props;
  const { paymentForRefund, memberID, startDate, endDate } = props;
  

  const submitForm = () => {          
    if (active) {
      setActive(false)
      onProcessRefund(
        paymentForRefund.id,
        amount,
        message,
        memberID,
        startDate,
        endDate,
      );          
      closePopup();
    }
  };

  const validateInput = () => {    
      return (
        amount &&
        parseFloat(paymentForRefund.Amount) >= parseFloat(amount) &&
        amount > 0
      );
  }

  const handleClose = () => {
    closePopup();
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Refund</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>

        <FormControl className={classes.RefundForm}>
          <FormLabel id="demo-radio-buttons-group-label">
            <h4>Make a refund</h4>
          </FormLabel>

          <TextField
            label={`Enter refund amount (Max $${paymentForRefund.Amount})`}
            placeholder="0.00"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            type="number"
            required
            InputLabelProps={{
              shrink: true,
            }}
            className={classes.paymentText}
          />

          <TextField
            value={message}
            placeholder={"Enter refund message..."}
            id="user-note"
            onChange={(e) => setMessage(e.target.value)}
            className={classes.paymentText}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>

        <div
          className={classes.optionContainer}
          style={{
            borderTop: "2px solid #ddd",
          }}
        >
          <Button
            disabled={!active}
            color="secondary"
            varian="raised"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            className={
              validateInput() ? classes.enableButton : classes.disableButton
            }
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
            disable={true}
          >
            Process
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onProcessRefund: (id, amount, message, memberID, startDate, endDate) =>
    dispatch(processRefund(id, amount, message, memberID, startDate, endDate)),
  dispatch,
});

const ConnectedRefundForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RefundForm);

export default ConnectedRefundForm;
