import Immutable from "immutable";
import * as constants from "../../constants";

const defaultSelectedState = Immutable.fromJS({
  address: {},
  meters: [],
  notes: [],
  products: [],
  deposits: [],
  showSnackBar: false,
  isFetching: false,
  lmi_types: [],
  rateCodes: []
});

const defaultSearchResultState = Immutable.fromJS({
  hasNext: false,
  page: 0,
  members: [],
  isLoading: false,
});

const defaultMemberDocumentsState = {
  documents: [],
}

const defaultNoteTags = {
  tags: {},
}

export function memberSearchResult(state = defaultSearchResultState, action) {
  switch (action.type) {
    case constants.UPDATE_LOADING_SEARCH_STATE:
      return state.merge(
        Immutable.fromJS({
          isLoading: action.isLoading,
        })
      );
    case constants.CLEAR_SEARCH_RESULTS:
      return state.merge(
        Immutable.fromJS({
          hasNext: false,
          page: 0,
          members: [],
        })
      );
    case constants.SEARCH_PAGINATION_STARTED:
      return state.merge(
        Immutable.fromJS({
          hasNext: true,
        })
      );
    case constants.SEARCH_PAGINATION_COMPLETE:
      return state.merge(
        Immutable.fromJS({
          hasNext: false,
        })
      );
    case constants.INCREMENT_SEARCH_RESULTS_PAGE_COUNT:
      return state.merge(
        Immutable.fromJS({
          page: action.page,
        })
      );
    case constants.RECEIVE_GUEST_MEMBERS:
    case constants.RECEIVE_MEMBERS: {
      const list = Immutable.List([...action.listOfMembers]);
      const stateMembers = state.get("members").concat(list);
      const members = Array.from(stateMembers);
      return state.merge(
        Immutable.fromJS({
          members,
        })
      );
    }
    default:
      return state;
  }
}

export function selectedMember(state = defaultSelectedState, action) {
  switch (action.type) {
    case constants.CLEAR_SELECTED_MEMBER:
      return defaultSelectedState;
    case constants.RECEIVE_STRIPE_ACCOUNT_ID:
      return state.merge(
        Immutable.fromJS({ stripeAccountId: action.stripeAccountId })
      );
    case constants.SELECTED_MEMBER:
      return state.merge(Immutable.fromJS(action.member));
    case constants.RECEIVE_MEMBER_USER_INFO:
      return state.merge(Immutable.fromJS(action.memberUserInfo));
    case constants.CLEAR_DISPUTE_INFO:
      return defaultSelectedState;
    case constants.RECEIVE_MEMBER_METER_INFO:
      return state.merge(Immutable.fromJS(action.memberMeterInfo));
    case constants.REQUEST_CHANGE_TO_ACCOUNT:
      return state.merge(Immutable.fromJS({ isFetching: action.isFetching }));
    case constants.REQUEST_ADD_FUNDS:
      return state.merge(Immutable.fromJS({ isFetching: action.isFetching }));
    case constants.RECEIVE_ADD_FUNDS_CONF:
    case constants.RECEIVE_APPLY_REFUND_CONF:
    case constants.RECEIVE_CLOSE_ACCOUNT_CONF:
    case constants.RECEIVE_BILLING_ACTIVATE:
    case constants.RECEIVE_ADD_METER_CONF:
    case constants.RECEIVE_USER_UPDATE:
    case constants.RECEIVE_MEMBER_UPDATE:
    case constants.REQUEST_UPDATE_AUTOPAY:
    case constants.REQUEST_UPDATE_STATEMENT_DELIVERY:
    case constants.UPDATE_AUTO_PAY_CONF:
    case constants.UPDATE_STATEMENT_DELIVERY_CONF:
    case constants.MEMBER_PRODUCT_REMOVE_SUCCESS:
    case constants.RECEIVE_CAPTURE_METER_DEPOSIT_CONF:
      return state.merge(
        Immutable.fromJS({
          isFetching: action.isFetching,
          showSnackBar: true,
          response: action.response,
          [action.property]: action.value,
        })
      );
    case constants.HIDE_SNACKBAR:
      return state.merge(Immutable.fromJS({ showSnackBar: false }));
    case constants.MEMBERS_GET_ALL_SUCCESS:
      const {
        membersList: { Members: members },
        selectedMemberID,
      } = action.payload;
      if (members.length === 1) {
        return state.merge(
          Immutable.fromJS({
            meterName: false,
          })
        );
      } else {
        return state.merge(
          Immutable.fromJS({
            meterName:
              members.findIndex(
                (member) => member.memberID === selectedMemberID
              ) + 1,
            relatedMembers: members.filter(
              (member) => member.memberID !== selectedMemberID
            ),
          })
        );
      }
    case constants.RECEIVE_RELATED_MEMBER_METER_INFO:
      return state.merge(
        Immutable.fromJS({
          relatedMeters: [
            ...(state.get("relatedMeters") || []),
            ...(action.memberMeterInfo?.meters || []),
          ],
        })
      );
    case constants.MEMBERS_GET_ALL_FAILURE:
      return state.merge(
        Immutable.fromJS({
          meterName: "Error fatching meter names",
        })
      );
    case constants.CLEAR_MEMBER_CONF_MSG:
      return state.merge(Immutable.fromJS({ actionConf: {} }));
    case constants.MEMBER_GET_PRODUCT_SUCCESS:
      return state.merge(Immutable.fromJS({ products: action.payload }));
    case constants.METER_GET_DEPOSITS_SUCCESS:
      return state.merge(Immutable.fromJS({ deposits: action.payload }));
    case constants.BILLING_GET_ACCOUNT_BALANCE_SUCCESS:
      return state.merge(Immutable.fromJS({ accountBalance: action.payload }));
    case constants.BILLING_GET_ACCOUNT_TRANSACTION_SUCCESS:
      return state.merge(Immutable.fromJS({ transactions: action.payload.transactions }));
    case constants.MEMBER_NOTES_GET_SUCCESS:
      return state.merge(Immutable.fromJS({ notes: action.payload }));
    case constants.MEMBER_NOTE_UPDATE_REQUEST:
      return state.merge(Immutable.fromJS({ isFetching: true }));
    case constants.MEMBER_NOTE_UPDATE_SUCCESS: {
      const index = state
        .get("notes")
        .findIndex((n) => n.get("ID") === action.payload.ID);
      let notes;
      if (index !== -1) {
        notes = state.get("notes").set(index, Immutable.fromJS(action.payload));
      } else {
        console.error("Note ID not found");
        notes = state.get("notes");
      }

      const isFetching = false;
      const response = Immutable.fromJS({
        success: true,
        msg: "Note updated",
      });
      const showSnackBar = true;
      return state.merge(
        Immutable.fromJS({ notes, isFetching, showSnackBar, response })
      );
    }
    case constants.MEMBER_NOTE_CREATE_REQUEST:
      return state.merge(Immutable.fromJS({ isFetching: true }));
    case constants.RECEIVE_LMI_TYPES:
      return state.merge(
        Immutable.fromJS({ lmi_types: action.data })
      );
    case constants.MEMBER_NOTE_CREATE_SUCCESS: {
      const res = Immutable.fromJS(action.payload);
      const notes = state.get("notes").push(res);
      const isFetching = false;
      const response = Immutable.fromJS({
        success: true,
        msg: "Note created",
      });
      const showSnackBar = true;
      return state.merge(
        Immutable.fromJS({ notes, isFetching, showSnackBar, response })
      );
    }
    case constants.MEMBER_DPP_GET_SUCCESS:
      return state.merge(Immutable.fromJS({ dpps: action.payload }));
    case constants.MEMBER_LIST_LEVEL_BILLING_SUCCESS:
      return state.merge(Immutable.fromJS({ level_billings: action.payload }));
    case constants.TDSP_LIST_RATE_CODES:
      return state.merge(Immutable.fromJS({ rateCodes: action.payload }));
    case constants.MEMBER_DPP_GET_FAILURE:
    case constants.MEMBER_GET_PRODUCT_FAILTURE:
    case constants.METER_GET_DEPOSITS_FAILTURE:
    case constants.MEMBER_NOTES_GET_FAILURE:
    case constants.MEMBER_NOTE_UPDATE_FAILURE:
    case constants.MEMBER_NOTE_CREATE_FAILURE: {
      const { error: msg } = action.payload;
      const response = Immutable.fromJS({ success: false, msg });
      return state.merge(
        Immutable.fromJS({
          isFetching: false,
          showSnackBar: true,
          response,
        })
      );
    }
    case constants.MEMBER_RESET_REQUEST: {
      return state.merge(Immutable.fromJS({ isFetching: true }));
    }
    case constants.MEMBER_RESET_FAIL: {
      const response = Immutable.fromJS({
        success: true,
        msg: "Error occurred while sending reset passwrod. Please try again",
      });
      return state.merge(
        Immutable.fromJS({
          isFetching: false,
          showSnackBar: true,
          response,
        })
      );
    }
    case constants.MEMBER_RESET_SUCCESS: {
      const response = Immutable.fromJS({
        success: true,
        msg: "Password reset email sent",
      });
      return state.merge(
        Immutable.fromJS({
          isFetching: false,
          showSnackBar: true,
          response,
        })
      );
    }
    default:
      return state;
  }
}

export function memberDocuments(state = defaultMemberDocumentsState, action) {
  switch (action.type) {
    case constants.MEMBER_LIST_DOCUMENTS:
      return {
        ...state,
        documents: action.payload,
      }
    default:
      return state;
  }
}

export function memberNoteTags(state = defaultNoteTags, action) {
  switch (action.type) {
    case constants.MEMBER_NOTE_TAGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state;
  }
}
