import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "../Button"
import { FormControl } from "@material-ui/core/";
import TextField from "@material-ui/core/TextField";
import NotificationsView from "../NotificationsView";

const Notifications = props => {
  const [search, setSearch] = useState(null);
  const {
    doFetchGetNotifications,
  } = props

  const handleConfirm = e => {
    e.preventDefault();
    doFetchGetNotifications({ search })
  }

  return (
    <div>
      <Grid container>
        <Grid item sm={6}>
          <h4>Notifications</h4>
        </Grid>
      </Grid>
      <Grid item md={12}>
        <FormControl style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}>
          <Grid container>
            <Grid item xs={3} style={{ marginRight: "30px" }}>
              <div style={{ marginBottom: '-5px' }}><label>Search</label></div>
              <TextField
                value={search ? search : ""}
                type="text"
                onChange={e => setSearch(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={3} style={{ marginRight: "30px" }}>
              <div>
                <Button
                  autoFocus
                  onClick={handleConfirm}
                  color="primary"
                  variant="contained"
                  style={{ marginTop: "10px" }}
                >
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <br />
      <NotificationsView searchTerms={{ search }} />
    </div>
  )
}

export default Notifications