import Immutable from "immutable";
import * as constants from "../../constants";

const defaultMeterResultState = Immutable.List();

export function meterResult(state = defaultMeterResultState, action) {
    switch (action.type) {
        case constants.RECEIVE_METERS:
            return Immutable.List(action.data);
        default:
            return state;
    }
}
