import { createSelector } from "reselect";

const statements = () => (state) => state.statements;

const selectStatementsContainer = () =>
  createSelector(statements(), (statementsData) => {
    return statementsData;
  });

export default selectStatementsContainer;
