import * as constants from "../../constants";

const initialState = {
  selected_csa: {
    csa: {},
    csa_user: {},
    csa_members: [],
  },
  csas: [],
};

export default function csa(state = initialState, action) {
  switch (action.type) {
    case constants.CSA_RECEIVE_CSA:
      return {
        ...state,
        selected_csa: {
          ...state.selected_csa,
          csa: action.payload.csa,
        },
      }
    case constants.CSA_RECEIVE_USER:
      return {
        ...state,
        selected_csa: {
          ...state.selected_csa,
          csa_user: action.payload.user,
        },
      }
    case constants.CSA_RECEIVE_MEMBERS:
      return {
        ...state,
        selected_csa: {
          ...state.selected_csa,
          csa_members: action.payload.members,
        },
      }
    case constants.CSA_LIST_CSA:
      return {
        ...state,
        csas: action.payload.csas,
      }
    default:
      return state;
  }
}
