import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import moment from "moment";
import Button from "../../../components/Button"
import Dialog from "@material-ui/core/Dialog";
import {
  IconButton,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core/";

import { userUpdate } from "../../../actions/members";
import { styled } from "@material-ui/core/styles";
import { useStyles } from "./styles";
import RemoveMemberProduct from './RemoveMemberProduct'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const MemberProduct = (props) => {
  const classes = useStyles();
  const { member, isTXMarket, adminUser } = props;
  const [userID, setUserID] = useState("");
  const [products, setProducts] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (member.get("userID")) {
      setUserID(member.get("userID"));
    }
    if (member.get("products")) {
      setProducts(member.get("products"));
    }
  }, [member]);

  function Row({ row }) {
    const [expanded, setExpanded] = React.useState(false);
    const jsonString = row.get("documents");
    const documents = JSON.parse(jsonString);
    const [billingCommodityLabel, setBillingCommodityLabel] = useState("");
    const [touCommodityRates, setTouCommodityRates] = useState({});

    useEffect(() => {
      const charges = JSON.parse(row.get("billing")).charges;
      if (charges){
        if (charges.includes("tou_commodity_rate")) {
          setBillingCommodityLabel("TOU");
          const touRates = JSON.parse(row.get("billing")).tou_commodity_rates;
          setTouCommodityRates(touRates);
        } else if (charges.includes("variable_commodity_rate")) {
          setBillingCommodityLabel("VAR");
        } else if (charges.includes("fixed_commodity_rate")) {
          const commodityRate = JSON.parse(row.get("billing")).commodity_rate;
          setBillingCommodityLabel(commodityRate);
        }
      }
    }, [row]);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const productStartDate = moment
      .utc(row.get("start_date"))
      .format("YYYY-MM-DD");
    const productEndDate = moment.utc(row.get("end_date")).format("YYYY-MM-DD");

    return [
      <TableRow style={{ cursor: "pointer" }} onClick={handleExpandClick}>
        <TableCell align="center">{row.get("product_code")}</TableCell>
        <TableCell align="center">{billingCommodityLabel}</TableCell>
        <TableCell align="center">
          {productStartDate !== "Invalid date" ? productStartDate : ""}
        </TableCell>
        <TableCell align="center">
          {productEndDate !== "Invalid date" ? productEndDate : ""}
        </TableCell>
        <TableCell>
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </TableCell>
      </TableRow>,
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List>
              <ListItem>
                <ListItemText primary={`Status: ${row.get("status")}`} />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`Term Months: ${row.get("term_months")}`}
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={`Promo Code: ${row.get("promo_code")}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`Signing Date: ${moment
                    .utc(row.get("signing_date"))
                    .format("YYYY-MM-DD")}`}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={`Renewal Date: ${moment
                    .utc(row.get("renewal_date"))
                    .format("YYYY-MM-DD")}`}
                />
              </ListItem>
              <ListItem className={classes.productDetail}>
                <ListItemText primary={`Documents: `} />
                <List>
                  {documents &&
                    documents.map((d, i) => {
                      return (
                        <ListItem key={i} className={classes.productDetailList}>
                          <a
                            rel="noopener noreferrer"
                            href={`${d.location}`}
                            target="_blank"
                          >
                            <ListItemText primary={d?.description} />
                          </a>
                        </ListItem>
                      );
                    })}
                </List>
              </ListItem>
            </List>
          </Collapse>
        </TableCell>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, verticalAlign: "baseline" }}
          colSpan={3}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List>
              {
                isTXMarket &&
                adminUser.permissions.isProductManager &&
                row.get("end_date") && row.get("start_date") &&
                new Date(row.get("start_date")) > new Date() &&
                <ListItem style={{
                  float: 'right',
                  width: '50%',
                }} className={classes.productDetail}>
                  <Button
                    style={{ width: '100%', marginTop: '10px' }}
                    variant='contained'
                    color='primary'
                    onClick={e => {
                      setSelectedRow(row)
                      setDialog(true)
                    }}
                  >
                    Remove
                  </Button>
                </ListItem>
              }
              {billingCommodityLabel == "TOU" && (
                <ListItem className={classes.productDetail}>
                  <ListItemText primary={`TOU Commodity Rate Details: `} />
                  <List>
                    {touCommodityRates &&
                      Object.keys(touCommodityRates).map((key, i) => {
                        return (
                          <ListItem
                            key={i}
                            className={classes.productDetailList}
                          >
                            <ListItemText
                              primary={
                                <div>{`${key}: ${touCommodityRates[key]}`}</div>
                              }
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </ListItem>
              )}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>,
    ];
  }

  return (
    <Card className={classes.root}>
      <h4 style={{ marginLeft: "25px" }}>Product</h4>
      <Dialog
        className={classes.dialog}
        onClose={() => setDialog(!dialog)}
        aria-labelledby="customized-dialog-title"
        open={dialog}
      >
        <RemoveMemberProduct 
          closePopup={() => setDialog(!dialog)}
          handleChange={body => {
            props.handleEvent({...selectedRow.toJSON(), body})
          }}
        />
      </Dialog>
      <TableContainer>
        <Table aria-label="collapsible table" style={{ fontSize: 14 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Product Code</TableCell>
              <TableCell align="center">Billing Commodity Rate</TableCell>
              <TableCell align="center">Start Date</TableCell>
              <TableCell align="center">End Date</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {products &&
              products.map((p, index) => {
                return <Row key={index} row={p} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

MemberProduct.propTypes = {
  member: PropTypes.object,
  onUserUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onUserUpdate: (userId, property, value) =>
    dispatch(userUpdate(userId, property, value)),
  dispatch,
});

const ConnectedMemberProduct = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberProduct);

export default ConnectedMemberProduct;
