import PropTypes from "prop-types";
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button";
import { Link } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import moment from "moment";
import currency from "../../util/currency-formatter";

const useStyles = makeStyles({
  root: {
    width: "500px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px 10px 0px",
    margin: "0px 10px",
    "& span": {
      fontWeight: "bold",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
    borderTop: "2px solid #ddd",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const UpdateReferrals = (props) => {
  const classes = useStyles();
  const {
    doApproveReferralCredits,
    referralCreditsSummaries,
    closeUpdatePopup,
    isProcessing,
  } = props;

  const [openConfirmPopup, setConfirmPopup] = useState(false);

  const submitForm = () => {
    setConfirmPopup(true);
  };

  const handleClose = () => {
    setConfirmPopup(false);
  };

  const handleConfirm = async () => {
    // get all checked referral credits
    const user_ids = referralCreditsSummaries
      .filter((s) => {
        if (s.checked) return true;
        return false;
      })
      .map((rc) => rc.user_id);

    await doApproveReferralCredits({
      user_ids,
    });

    // close update panel popups
    setConfirmPopup(false);
    closeUpdatePopup(false);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.container} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Update Referral Credits Status</span>
          <CloseIcon onClick={() => closeUpdatePopup()} />
        </div>
        <div className={classes.body}>
          {referralCreditsSummaries.map((referralCredit, index) => {
            if (referralCredit.checked)
              return (
                <div key={index}>
                  <span>
                    Email:{" "}
                    <Link
                      style={{ color: "#3f51b5" }}
                      to={`/members/${referralCredit.id}/details`}
                    >
                      {referralCredit.email}
                    </Link>{" "}
                  </span>
                  <span>
                    Total Rebate: {currency(referralCredit.total_credit_amount)}
                  </span>
                </div>
              );
          })}
        </div>
        <div className={classes.optionContainer}>
          <Button
            color="secondary"
            varian="raised"
            onClick={async () => await closeUpdatePopup()}
          >
            Cancel
          </Button>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
          >
            Apply
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openConfirmPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span>Please confirm the following transactions</span>
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="secondary">
            Go Back
          </Button>
          <Button
            className={classes.enableButton}
            autoFocus
            onClick={handleConfirm}
            color="primary"
            disabled={isProcessing}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UpdateReferrals.propTypes = {
  doApproveReferralCredits: PropTypes.func.isRequired,
  referralCreditsSummaries: PropTypes.array.isRequired,
  closeUpdatePopup: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
};

export default UpdateReferrals;
