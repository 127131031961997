import PropTypes from "prop-types";
import React, { useState } from "react";
import moment from "moment";
import Button from "../Button"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";

const CreateTransferService = ({
  openPopup,
  setOpenPopup,
  classes,
  meter,
  moveOutDate,
  moveInDate,
  setMoveOutDate, 
  setMoveInDate,
  updateBillingAddress,
  setUpdateBillingAddress,
  doUpdateEnrollment,
}) => {
  const [formError, setFormError] = useState(false);
  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = async () => {
    if (!formError) {
      doUpdateEnrollment({
        meterUID: meter.esi,
        moveOutDate: moment.utc(moveOutDate).format("YYYY-MM-DD"),
        requestedStartDate: moment.utc(moveInDate).format("YYYY-MM-DD"),
        serviceAddress: meter.address,
        updateBillingAddress,
      });
      setOpenPopup(false);
    }
  };

  return (
    <Dialog
      open={openPopup}
      contentlabel="Transaction Raw Data"
      fullWidth={true}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography color="secondary" style={{ fontSize: "30px" }}>
          Transfer Service Request
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.contentFullView}>
          {!formError ? (
            <div>
              <div className={classes.inputWrapper}>
                <label htmlFor="meter_identifier">
                  {`Meter Identifier:`}
                  <input type="text" disabled value={meter.esi && meter.esi} />
                </label>
              </div>
              <div
                className={`${classes.dateSelector} ${classes.inputWrapper}`}
              >
                <label htmlFor="move_out_date">
                  {"Move Out Date:"}
                  <KeyboardDatePicker
                    style={{
                      margin: "15px 0px",
                      width: "280px",
                    }}
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={moment.utc(moveOutDate).format("l LT")}
                    onChange={(v) => setMoveOutDate(moment.parseZone(v).format('l LT'))}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </label>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="move_in_date">
                  {"Move In Date:"}
                  <KeyboardDatePicker
                    style={{
                      margin: "15px 0px",
                      width: "280px",
                    }}
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={moment.utc(moveInDate).format("l LT")}
                    onChange={(v) => setMoveInDate(moment.parseZone(v).format('l LT'))}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </label>
              </div>
              <div className={classes.inputWrapper}>
                <label htmlFor="meter_identifier">
                  {`Service Address:`}
                  <textarea
                    style={{ resize: "none", width: "280px", height: "65px" }}
                    disabled
                    value={`${meter.address.line1}\n${
                      meter.address.line2 && meter.address.line2
                    }${meter.address.city}, ${meter.address.state}\n${
                      meter.address.postal_code
                    }`}
                  ></textarea>
                </label>
              </div>
              <div>
                Use service address as billing address:{" "}
                <Checkbox
                  type="checkbox"
                  className="checkbox"
                  checked={updateBillingAddress}
                  onChange={() =>
                    setUpdateBillingAddress(!updateBillingAddress)
                  }
                />
              </div>
            </div>
          ) : (
            <div>Please complete every field of the form.</div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleClose()} color="secondary">
          Cancel
        </Button>
        <Button
          className={!formError ? classes.enableButton : classes.disableButton}
          autoFocus
          onClick={handleConfirm}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CreateTransferService.propTypes = {
  meter: PropTypes.object.isRequired,
  openPopup: PropTypes.bool.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  classes: PropTypes.any.isRequired,
  moveOutDate: PropTypes.object.isRequired,
  moveInDate: PropTypes.object.isRequired,
  setMoveOutDate: PropTypes.func.isRequired,
  setMoveInDate: PropTypes.func.isRequired,
  updateBillingAddress: PropTypes.bool.isRequired,
  setUpdateBillingAddress: PropTypes.func.isRequired,
  doUpdateEnrollment: PropTypes.func.isRequired,
};

export default CreateTransferService;
