import React from "react";
import { connect } from "react-redux";
import Notifications from "../components/Notifications";

import {
  getNotifications,
} from "../actions/notifications";

const NotificationsContainer = (props) => <Notifications {...props} />;

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  doFetchGetNotifications: (searchTerms) => {
    dispatch(getNotifications(searchTerms));
  },
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
