import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '../Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  root: {
    width: '300px',
  },
  adjustmentForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '10px 0px 20px 0px',
    margin: '0px 10px',
    transition: 'all 0.2s ease',
  },
  createVolumeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: 'none',
  },
  textAreaContainer: {
    margin: '10px',
    height: '100px',
  },
  textArea: {
    fontFamily: 'Roboto Condensed',
    fontSize: '20px',
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    resize: 'none',
    '&:focus': {
      outline: 'none !important',
    },
  },
  menuBar: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '10px',
    backgroundColor: '#202124',
    color: 'white',
    letterSpacing: '1px',
    '& .MuiSvgIcon-root': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#585858',
        color: 'white',
      },
    },
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0px 10px',
    transition: 'all 0.2s ease',
    marginBottom: '10px',
  },
  disableButton: {
    cursor: 'default',
    color: 'rgba(0,0,0,0.2)',
  },
  enableButton: {
    transition: 'all 0.3s linear',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '15px',
    fontWeight: 'bold',
    margin: '10px',
    paddingBottom: '3px',
    borderBottom: '1px solid black',
  },
  contentFullView: {
    fontSize: '15px',
    maxHeight: '100%',
    padding: '10px',
    whiteSpace: 'pre-wrap',
  },
  confirmDialog: {
    textAlign: 'center',
    '& .MuiTypography-root': {
      width: '100%',
    },
  },
  errorMessage: {
    color: 'red',
    margin: '10px',
    marginTop: '0px',
  }
});

const MemberCancelled = props => {
  const { 
    closePopup, 
    handleChange, 
  } = props;
  const classes = useStyles();
  const [cancellationReason, setCancellationReason] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState(null);

  const submitForm = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = () => {
    handleChange({
      cancellation_reason: cancellationReason,
      status: 'cancelled'
    });
    setOpenPopup(false);
    closePopup(false);
    setCancellationReason(null);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createVolumeContainer} variant='outlined'>
        <div className={`${classes.menuBar}`}>
          <span>Cancellation</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <div className={classes.adjustmentForm}>
          <Grid item sm={12} style={{ marginTop: '20px' }}>
            <FormControl variant='outlined' size='small' fullWidth>
              <label id='rate-code'>Cancellation Reason</label>
              <Select onChange={(e) => setCancellationReason(e.target.value)} value={cancellationReason}>
                <MenuItem key={'customer_initiated'} value={'customer_initiated'}>{'Customer Initiated'}</MenuItem>
                <MenuItem key={'utility_inactive'} value={'utility_inactive'}>{'Utility Inactive'}</MenuItem>
                <MenuItem key={'dropped_non_pay'} value={'dropped_non_pay'}>{'Dropped non pay'}</MenuItem>
                <MenuItem key={'ineligible'} value={'ineligible'}>{'Ineligible'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </div>
        {error && <div className={classes.errorMessage}>{error}</div>}
        <div className={classes.optionContainer}>
          <Button
            disabled={error !== null}
            className={classes.enableButton}
            color='primary'
            varian='raised'
            onClick={async () => {
              if (cancellationReason.length > 0) {
                await submitForm()
              } else {
                setError('Please select a cancellation reason to proceed')
              }
            }}
          >
            Save
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={openPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id='customized-dialog-title'
          onClose={handleClose}
        >
          <span>Please Confirm</span>
        </DialogTitle>
        <DialogContent dividers>
          Are you sure you want to cancel this account?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color='secondary'>
            Close
          </Button>
          <Button
            className={classes.enableButton}
            autoFocus
            onClick={handleConfirm}
            color='primary'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember.toJSON(),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const ConnectedMemberCancelled = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberCancelled);

export default ConnectedMemberCancelled;
