import { createSelector } from "reselect";

export const selectingMember = () => (state) => state.selectedMember;

const selectMembersContainer = () =>
  createSelector(
    [selectingMember(), (state) => state.memberNoteTags],
    (subState, NoteTags) =>
      Object.assign(
        {},
        {
          ...subState,
          noteTags: NoteTags,
          member: subState,
          notes: subState.get("notes"),
        }
      )
  );

export const selectedMemberContainer = () =>
  createSelector(selectingMember(), (subState) =>
    Object.assign(
      {},
      {
        email: subState.get("email"),
      }
    )
  );

export default selectMembersContainer;
