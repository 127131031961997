import * as C from "../../constants";

const initialState = {
    initialized: false,
    page: 1,
    limit: 100,
    has_next: false,
    selectedIndex: -1,
    selectedTransaction: {},
    users: JSON.parse(localStorage.getItem('users')) || {0: 'System', 9999999: 'System'},
    transactions: [],
    transactionTypes: [],
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
    transactionIsModified: false,
    searchMeterIdentifier: "",
    openAssignMeter: false,
    searchMeterResult: [],
    isProcessing: false,
    openCreateTransaction: false,
    openConfirmTransactionProcessing: false,
    newTransactionData: {
        fileType: "gaa",
        transactionTypeName: "",
        transactionSubType: "Request",
    },
    allTransactionTypes: {
        gaa: [
            "Switch",
            "Cancel Switch",
            "Date Change",
            "Move In",
            "Move Out",
            "CSA Register",
            "CSA Deregister",
            "B44 Move Out",
            "Maintain Customer Information"
        ],
        bso: [
            "SO Disconnect",
            "SO Reconnect",
            "SO Switch Hold",
            "SO Meter Test",
            "SO Read",
            "SO Change Order",
        ],
    },
};

const loadTransactions = (state, action) => {
    return {
        ...state,
        ...action,
        transactions:
            action.page === "1"
                ? action.transactions
                : [...state.transactions, ...action.transactions],
    };
};

const closeSnackBar = (state) => {
    return {
        ...state,
        showSnackBar: false,
        successMessage: "",
        errorMessage: "",
    };
};

const openSnackBar = (state, action) => {
    return {
        ...state,
        showSnackBar: true,
        ...action,
    };
};

const selectTransaction = (state, action) => {
    return {
        ...state,
        selectedIndex: state.transactions.findIndex(
            (t) => t.id === action.transactionID
        ),
        selectedTransaction: state.transactions.find(
            (t) => t.id === action.transactionID
        ),
        transactionIsModified: false,
    };
};

const loadMeterData = (state, action) => {
    const selectedIndex = state.transactions.findIndex(
        (t) => t.id === action.transaction.id
    );
    return {
        ...state,
        transactions: [
            ...state.transactions.slice(0, selectedIndex),
            action.transaction,
            ...state.transactions.slice(selectedIndex + 1),
        ],
        selectedIndex,
    };
};

const updateTransaction = (state, action) => {
    return {
        ...state,
        selectedTransaction: {
            ...state.selectedTransaction,
            ...action.updatedInfo,
        },
        transactionIsModified:
            action.updatedInfo.transactionIsModified !== undefined
                ? action.updatedInfo.transactionIsModified
                : true,
    };
};

const mergeSelectedTransaction = (state) => {
    const { selectedIndex, selectedTransaction } = state;
    return {
        ...state,
        transactions: [
            ...state.transactions.slice(0, selectedIndex),
            selectedTransaction,
            ...state.transactions.slice(selectedIndex + 1),
        ],
    };
};

const updateState = (state, action) => {
    return {
        ...state,
        ...action.updatedInfo,
    };
};

const updateUsers = (state, action) => {
    const updatedUsers = {
        ...state.users,
        ...action.users
    }
    localStorage.setItem('users', JSON.stringify(updatedUsers))
    return {
        ...state,
        users: updatedUsers
    };
};

const toggleProcessing = (state) => {
    return {
        ...state,
        isProcessing: !state.isProcessing,
    };
};

const updateNewTransaction = (state, action) => {
    return {
        ...state,
        newTransactionData: {
            ...state.newTransactionData,
            ...action.updatedInfo,
        },
    };
};

const updateNewTransactionDetail = (state, action) => {
    const { record, index, updatedInfo } = action;
    const recordArray =
        state.newTransactionData.transaction.transactionData[record];

    return {
        ...state,
        newTransactionData: {
            ...state.newTransactionData,
            transaction: {
                ...state.newTransactionData.transaction,
                transactionData: {
                    ...state.newTransactionData.transaction.transactionData,
                    [record]: [
                        ...recordArray.slice(0, index),
                        { ...recordArray[index], ...updatedInfo },
                        ...recordArray.slice(index + 1),
                    ],
                },
            },
        },
    };
};

const addTransaction = (state, action) => {
    const { transaction } = action;
    return {
        ...state,
        transactions: [transaction, ...state.transactions],
    };
};

const addTransactionTypes = (state, action) => {
    const { transactionTypes } = action;
    return {
        ...state,
        transactionTypes: transactionTypes,
    };
};

export default function meterTransaction(state = initialState, action) {
    switch (action.type) {
        case C.METER_TRANSACTION_LOAD_TRANSACTIONS:
            return loadTransactions(state, action);
        case C.METER_TRANSACTION_CLOSE_SNACKBAR:
            return closeSnackBar(state);
        case C.METER_TRANSACTION_OPEN_SNACKBAR:
            return openSnackBar(state, action);
        case C.METER_TRANSACTION_SELECT_TRANSACTION:
            return selectTransaction(state, action);
        case C.METER_TRANSACTION_LOAD_DATA:
            return loadMeterData(state, action);
        case C.METER_TRANSACTIONS_UPDATE:
            return updateTransaction(state, action);
        case C.METER_TRANSACTIONS_UPDATE_STATE:
            return updateState(state, action);
        case C.METER_TRANSACTION_GET_USERS:
            return updateUsers(state, action);
        case C.METER_TRANSACTIONS_TOGGLE_PROCESSING:
            return toggleProcessing(state);
        case C.METER_TRANSACTIONS_NEW_UPDATE:
            return updateNewTransaction(state, action);
        case C.METER_TRANSACTIONS_NEW_UPDATE_DETAIL:
            return updateNewTransactionDetail(state, action);
        case C.METER_TRANSACTION_ADD_TRANSACTION:
            return addTransaction(state, action);
        case C.METER_TRANSACTION_MERGE_SELECTED:
            return mergeSelectedTransaction(state);
        case C.METER_TRANSACTIONS_TYPE_UPDATE:
            return addTransactionTypes(state, action)
        default:
            return state;
    }
}
