import * as C from "../../constants";

const initialState = {
  referralCreditsSummaries: [],
  referralCreditsSummariesIndex: {}, // used for subrow detailed view
  page: 1,
  total: 0,
  hasNext: false,
  limit: 100,
  isProcessing: false,
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
};

const defaultMemberReferrals = {
  referrals: [],
  primaryAccountStatus: "",
  rewardsBalance: NaN,
  primaryAccountMember: "",
  primaryBillingAccountNumber: "",
};

const setIsProcessing = (state, isProcessing) => {
  return {
    ...state,
    isProcessing: isProcessing,
  };
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

const receiveReferralCredits = (state, action) => {
  const { user_id, referral_credits } = action.payload;
  // referralCreditsSummariesIndex should not be null at this stage
  let summariesIndex = state.referralCreditsSummariesIndex;
  summariesIndex[user_id].referral_credits = referral_credits;
  return {
    ...state,
    referralCreditsSummariesIndex: summariesIndex,
  };
};

const receiveReferralCreditsSummaries = (state, action) => {
  const { referral_credits_summaries, page, total, has_next, isInitialLoad } =
    action.payload;

  let referralCreditsSummariesIndex = state.referralCreditsSummariesIndex
    ? state.referralCreditsSummariesIndex
    : {};

  // add summary expanded view data. handles additional summaries being loaded
  if (referral_credits_summaries) {
    for (const summary of referral_credits_summaries) {
      const { user_id } = summary;
      referralCreditsSummariesIndex[user_id] = summary;
    }
  }

  return {
    ...state,
    // concat if loading more
    referralCreditsSummaries: isInitialLoad
      ? referral_credits_summaries
      : Array.from(state.referralCreditsSummaries).concat(
        referral_credits_summaries
      ),
    referralCreditsSummariesIndex: referralCreditsSummariesIndex,
    page,
    total,
    hasNext: has_next,
  };
};

export default function statements(state = initialState, action) {
  switch (action.type) {
    case C.REFERRALS_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case C.REFERRALS_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case C.RECEIVE_REFERRAL_CREDITS:
      return receiveReferralCredits(state, action);
    case C.RECEIVE_REFERRAL_CREDITS_SUMMARIES:
      return receiveReferralCreditsSummaries(state, action);
    case C.REQUEST_APPROVE_REFERRAL_CREDITS:
      return setIsProcessing(state, true);
    case C.APPROVE_REFERRAL_CREDITS_SUCCESS:
    case C.APPROVE_REFERRAL_CREDITS_FAILURE:
      return setIsProcessing(state, false);
    case C.UPDATE_REFERRALS_IS_PROCESSING:
      return setIsProcessing(state, action.payload);
    default:
      return state;
  }
}

export function memberReferrals(state = defaultMemberReferrals, action) {
  switch (action.type) {
    case C.RECEIVE_REFERRALS:
      return {
        ...state,
        referrals: action.payload,
      };
    case C.RECEIVE_REFERRALS_PRIMARY_ACCOUNT:
      return {
        ...state,
        primaryAccountStatus: action.payload.status,
        primaryAccountMember: action.payload.member_id,
        primaryBillingAccountNumber: action.payload.billing_account_number,
      };
    case C.RECEIVE_REFERRALS_REWARDS_BALANCE:
      return {
        ...state,
        rewardsBalance: action.payload.balance,
      };
    default:
      return state;
  }
}

