import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  table: {
    minWidth: 650,
  },
}));

export const useTableStyles = makeStyles({
  tableHeaderRow: {
    "& .MuiTableCell-root": {
      padding: 0,
    },
  },
  tableBody: {
    "& .MuiTableCell-root": {
      padding: 0,
    },
  },
  checkbox: {
    padding: "0px !important",
  },
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
      border: "none",
    },
  },
});
