import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../../Button"
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    width: "300px",
  },
  adjustmentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
    marginBottom: "10px",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
  errorMessage: {
    color: 'red',
    margin: '10px',
    marginTop: '0px',
  }
});

const RemoveMemberProduct = props => {
  const { closePopup, handleChange } = props;
  const classes = useStyles();
  const [note, setNote] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState(null);

  const submitForm = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleConfirm = () => {
    handleChange(note);
    setOpenPopup(false);
    closePopup(false);
    setNote(null);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar}`}>
          <span>Remove Member Product</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <div className={classes.adjustmentForm}>
          <TextField
            label="Add Note"
            id="note"
            onChange={(e) => setNote(e.target.value)}
            value={note}
            type="text"
          />
        </div>
        {error && <div className={classes.errorMessage}>{error}</div>}
        <div className={classes.optionContainer}>
          <Button
            disabled={error !== null}
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => {
              if (note && note.length > 0) {
                await submitForm()
              } else {
                setError('Please add a note to proceed')
              }
            }}
          >
            Remove
          </Button>
        </div>
      </Card>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        <DialogTitle
          className={classes.confirmDialog}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <span>Please Confirm</span>
        </DialogTitle>
        <DialogContent dividers>
          Are you sure you want to remove this product?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleClose()} color="secondary">
            Close
          </Button>
          <Button
            className={classes.enableButton}
            autoFocus
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

const ConnectedRemoveMemberProduct = connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveMemberProduct);

export default ConnectedRemoveMemberProduct;
