import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MemberReferralsTable from "./MemberReferralsTable";
import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import currency from "../../util/currency-formatter";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  label: {
    marginRight: "50px",
  },

}));

const MemberReferrals = (props) => {
  const classes = useStyles();
  const {
    member,
    memberReferrals: {
      primaryAccountMember,
      primaryAccountStatus,
      primaryBillingAccountNumber,
      rewardsBalance,
      referrals,
    },
    referralsStatus: {
      showSnackBar,
      successMessage,
      errorMessage,
      closeSnackbar,
      isProcessing,
      confirmationError,
      confirmationSuccess,
    },
    doFetchPrimaryAccountStatus,
    doFetchRewardsBalance,
    doFetchReferrals,
  } = props;

  const getData = () => {
    doFetchPrimaryAccountStatus({ user_id: member.get("userID") })
    doFetchRewardsBalance({ userID: member.get("userID") })
    doFetchReferrals({ user_id: member.get("userID") })
  }

  useEffect(() => {
    getData()
  }, [])

  return <div>
    <Snackbar
      id="message-id"
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={showSnackBar}
      onClose={closeSnackbar}
      className={successMessage ? confirmationSuccess : confirmationError}
      message={successMessage || errorMessage}
    />
    <h4>Referrals</h4>

    <div>

      <Button color="primary" onClick={getData} style={{ float: "right" }}>
        Refresh
      </Button>
      <div style={{ maxWidth: "900px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemText primary="Rewards Account Balance: " />
                {currency(rewardsBalance)}
              </ListItem>
              <ListItem>
                <ListItemText primary="Referrals Account Status: " />
                {primaryAccountStatus.charAt(0).toUpperCase() + primaryAccountStatus.slice(1)}
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List>
              <ListItem>
                <ListItemText primary="Primary Member ID: " />
                {primaryAccountMember ? <a href={`/members/${primaryAccountMember}/details`}>{primaryAccountMember}</a> : ""}
              </ListItem>
              <ListItem>
                <ListItemText primary="Primary Billing Account Number: " />
                {primaryBillingAccountNumber}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>

    </div>
    <MemberReferralsTable referrals={referrals} />
    <Backdrop className={classes.backdrop} open={isProcessing}>
      <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
    </Backdrop>
  </div>;
}

export default MemberReferrals;
