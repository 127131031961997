import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Link } from "react-router";

import { fetchMeters } from "../actions/meters";
import { membersWrapper } from "../styles/members.css";
import { metersWrapper } from "../styles/meters.css";
import { hrText } from "../styles/common.css";

class Meters extends Component {
  render() {
    const { meters, doFetchMeters } = this.props;
    const data = Array.from(meters);
    const columns = [
      {
        Header: "Supported",
        accessor: "supported_meter",
        Cell: (row) => (row.value ? "true" : "false"),
      },
      {
        Header: "Meter Identifier",
        accessor: "esi",
        width: 250,
      },
      {
        Header: "Address",
        accessor: "address.line1",
      },
      {
        Header: "City",
        id: "city",
        accessor: "address.city",
      },
      {
        Header: "TDSP",
        accessor: "duns",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Premise Type",
        accessor: "premise",
      },
      {
        Header: "Load Zone",
        accessor: "load_zone",
      },
      {
        Header: "Meter Type",
        accessor: "tdsp_ams_indicator",
      },
      {
        Header: "Switch Hold",
        accessor: "switch_hold",
        Cell: (row) => (
          //   TODO: MeterLook up should change ESI namespace to MeterIdentifier
          <Link to={`/meters/${row.original.esi}`}>
            {row.value ? "true" : "false"}
          </Link>
        ),
      },
    ];

    return (
      <div>
        <div className={`${membersWrapper} ${metersWrapper}`}>
          <div>
            <label htmlFor="meterIdentifier">
              {"Meter Identifier:"}
              <input
                type="text"
                ref={(node) => {
                  this.meterIdentifier = node;
                }}
              />
            </label>
          </div>
          <hr className={hrText} data-content="OR" />
          <div>
            <label htmlFor="street_address">
              {"Street Address:"}
              <input
                type="text"
                ref={(node) => {
                  this.streetAddress = node;
                }}
                placeholder="required"
              />
            </label>
          </div>
          <div>
            <label htmlFor="address2">
              {"Address 2"}
              <input
                type="text"
                ref={(node) => {
                  this.addressTwo = node;
                }}
              />
            </label>
          </div>
          <div>
            <label htmlFor="postal_code">
              {"Zip Code:"}
              <input
                type="text"
                ref={(node) => {
                  this.postalCode = node;
                }}
                placeholder="required"
              />
            </label>
          </div>

          <button
            className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
            onClick={() =>
              doFetchMeters({
                //   TODO: MeterLook up should change ESI namespace to MeterIdentifier
                esiID: this.meterIdentifier.value,
                line1: this.streetAddress.value,
                line2: this.addressTwo.value,
                postal_code: this.postalCode.value,
              })
            }
          >
            {"Search"}
          </button>
        </div>
        <div>
          <ReactTable
            data={data}
            columns={columns}
            style={{ height: "600px", width: "1600px" }}
            className="-striped -highlight"
            filterable
            noDataText=""
            defaultPageSize={100}
          />
        </div>
      </div>
    );
  }
}

Meters.propTypes = {
  doFetchMeters: PropTypes.func.isRequired,
  meters: PropTypes.object,
};

Meters.defaultProps = {
  meters: [],
};

const mapStateToProps = (state) => ({
  meters: state.meterResult,
});

const mapDispatchToProps = (dispatch) => ({
  doFetchMeters: (s, e) => {
    dispatch(fetchMeters(s, e));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Meters);
