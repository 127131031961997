import { takeLatest, takeEvery, call, select, put } from "redux-saga/effects";
import "babel-polyfill";
import * as C from "../../constants";
import fetch from "../util/api-ajax";
import {
  requestMeterLookupSuccess,
  requestMeterLookupFailure,
  meterLookupUpdateSuccess,
  meterLookupUpdateFailure,
} from "../actions/meterLookup";

function* requestMeter(action) {
  const { meterIdentifier } = action.payload;
  try {
    //   TODO: MeterLook up should change ESI namespace to MeterIdentifier
    const meter = yield fetch(C.METER_LOOKUP, { esiID: meterIdentifier });
    meter.switch_hold = meter.switch_hold ? "y" : "n";
    yield put(requestMeterLookupSuccess(meter));
  } catch (err) {
    yield put(requestMeterLookupFailure());
  }
}

function* updateMeter(action) {
  try {
    const meter = yield fetch(C.METER_LOOKUP_UPDATE, action.payload);
    meter.switch_hold = meter.switch_hold ? "y" : "n";
    yield put(meterLookupUpdateSuccess(meter));
  } catch (err) {
    yield put(meterLookupUpdateFailure());
  }
}

export default [
  takeEvery(C.REQUEST_METER_LOOKUP, requestMeter),
  takeLatest(C.METER_LOOKUP_UPDATED, updateMeter),
];
