export function validatePhone(phone) {
  const phoneRegex = /^\d{11}$/;
  return phone && phoneRegex.test(phone);
}

export function validateEmail(email) {
  const emailRegex = /^[\S]+@[\S]+\.[\S]+$/;
  return email && emailRegex.test(email);
}

export function validateZip(zip) {
  const zipRegex = /^\d{5}$/;
  return zip && zipRegex.test(zip);
}