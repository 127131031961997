import Button from "@material-ui/core/Button";
import React, { useRef, useState, useEffect } from "react";

const CustomButton = React.forwardRef(function CustomButton(props, ref) {
  const {disabled, ...rest} = props;
  const isExecutingRef = useRef(false);
  const [isDisabled, setIsDisabled] = useState(disabled)

  useEffect(() => {
    setIsDisabled(isExecutingRef.current);
  }, [isExecutingRef.current]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const handleClick = event => {
    requestAnimationFrame(async () => {
      if (!isExecutingRef.current) {
        isExecutingRef.current = true;
        await Promise.resolve(props.onClick(event))
          .then(() => {
            isExecutingRef.current = false;
          })
          .catch(() => {
            isExecutingRef.current = false;
          });
      }
    });
  }

  return (
    <Button {...rest} onClick={handleClick} disabled={isDisabled} ref={ref}> 
      {props.children}
    </Button>
  );
})

export default CustomButton;
