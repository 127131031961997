import React from "react";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Button from "../Button"
import ReactTable from "react-table";

const columns = [
  {
    Header: "Timestamp",
    accessor: "event_timestamp",
    width: 120,
    Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{
      moment(value).format("YYYY-MM-DD hh:mm:ss")}</div>)
  },
  {
    Header: "Activity",
    accessor: "activity",
    width: 100,
    Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
  },
  {
    Header: "Data",
    accessor: "attachment_url",
    Cell: (row) => (
      <div>
        {
          Object.keys(row.original.event_data).map(
            (key) => (
              <div key={key}>

                {key}: {JSON.stringify(row.original.event_data[key])}

              </div>
            ))
        }
      </div>
    )
  },
]

const EventHistory = ({ closeEmail, events }) => {
  return (
    <Dialog
      open={true}
      contentlabel="Events History"
      ariaHideApp={false}
      fullWidth={true}
      maxHeight="80%"
      maxWidth={"md"}
    >
      <DialogTitle>
        <Typography color="secondary" variant="h6">
          Event History
        </Typography>
      </DialogTitle>
      <DialogContent>
        <ReactTable
          data={events}
          columns={columns}
          className="-striped -highlight"
          defaultPageSize={10}
          defaultSortDesc
          filterable
        />
      </DialogContent>
      <Button
        color="primary"
        varian="raised"
        onClick={async () => {
          await closeEmail()
        }}
      >
        Close
      </Button>
    </Dialog>
  )
}

export default EventHistory;
