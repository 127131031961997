import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "./styles"

const Usage = props => {
  const classes = useStyles();
  const [timeInterval, setTimeInterval] = useState("daily");
  const [data, setData] = useState([]);
  const { doGetUsage, params, guest, usage } = props;
  const guestId = params.guestUserId;
  const memberId = guestId ? guest.get("memberID") : params.memberId;

  const onSelectChange = e => {
    setTimeInterval(e.target.value)
  }

  useEffect(() => {
    if (memberId) {
      if (guestId && guest.get("memberID")) {
        doGetUsage(memberId, guestId);
      } else if (!guestId) {
        doGetUsage(memberId);
      }
    }
  }, [memberId]);

  useEffect(() => {
    if (usage && usage[timeInterval] !== undefined) {
      let rawData = usage[timeInterval];
      rawData.sort(function(a, b) {
        var dateA = new Date(a.time);
        var dateB = new Date(b.time);
        return dateB - dateA;
      });
      setData(rawData)
    }
  }, [usage, timeInterval]);

  const columns = [
    {
      Header: "Time",
      accessor: "time",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {moment
            .tz(row.value, "America/Chicago")
            .format("MMM DD, YYYY, h:mm a")}
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price_ckwh",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}¢</div>,
    },
    {
      Header: "Usage",
      accessor: "usage_kwh",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value} kW/h</div>,
    },
    {
      Header: "Cost",
      accessor: "cost_dollars",
      Cell: (row) => <div style={{ textAlign: "center" }}>${row.value}</div>,
    },
  ];

  return (
    <div>
      <div className={classes.inputSelector}>
        <span>Select interval</span>
        <Select
          value={timeInterval}
          onChange={onSelectChange}
          inputProps={{ name: "Interval" }}
          style={{
            width: '200px',
          }}
        >
          <MenuItem value={"hourly"}>Hourly</MenuItem>
          <MenuItem value={"daily"}>Daily</MenuItem>
          <MenuItem value={"monthly"}>Monthly</MenuItem>
        </Select>
      </div>
      <ReactTable
        data={data}
        columns={columns}
        style={{ height: "600px", marginTop: 15 }}
        className="-striped -highlight"
        filterable
        noDataText=""
        defaultPageSize={100}
      />
    </div>
  );
}

export default Usage