import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Switch from "@material-ui/core/Switch";
import { noselect } from "../../styles/common.css";

const useStyles = makeStyles({
  table: {
    fontSize: "15px",
    "& .MuiTableCell-root": {
      padding: "10px",
    },
  },
  tableBody: {
    "& .MuiTableRow-root:nth-child(even)": {
      backgroundColor: "rgba(220, 220, 220, .5)",
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      fontSize: "15px",
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "17px",
      color: "white",
    },
  },
  actionColumn: {
    "& .event-type-container": {
      display: "flex",
      justifyContent: "flex-start",
      cursor: "pointer",
      transition: "all 0.15s ease",
      "&:hover": {
        opacity: "0.5",
      },
    },
    "& .event-type-container .MuiSvgIcon-root": {
      marginRight: "10px",
    },
  },
  eventColumn: {
    width: "130px",
    "& .event-type-container": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .event-type-container .MuiSvgIcon-root": {
      marginRight: "15px",
    },
    "& .event-type-container .event-type-empty-icon": {
      width: "20px",
      height: "20px",
      marginRight: "15px",
    },
  },
  switchOn: {
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "#27ae60",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#27ae60 !important",
    },
  },
  switchOff: {
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: "black",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "black !important",
    },
  },
});

export default function ChannelPreferencesTable(props) {
  const classes = useStyles();
  const { user, channels } = props;
  const rows =
    user.hasOwnProperty("dataFields") && user.dataFields.unsubscribedChannelIds
      ? channels.map((channel) => {
          return {
            id: channel.id,
            name: channel.name,
            channelType: channel.channelType,
            messageMedium: channel.messageMedium,
            status: user.dataFields.unsubscribedChannelIds.includes(channel.id)
              ? false
              : true,
          };
        })
      : channels.map((channel) => {
          return {
            id: channel.id,
            name: channel.name,
            channelType: channel.channelType,
            messageMedium: channel.messageMedium,
            status: true,
          };
        });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    channels && channels.length > 0 ? channels.length : 5
  );

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const columns = ["ID", "Name", "Channel Type", "Message Medium", "Status"];
  return (
    <TableContainer
      style={{ maxWidth: "650px", marginBottom: "100px" }}
      component={Paper}
    >
      {channels.length > 0 && user && (
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow className={`${classes.tableHeaderRow} ${noselect}`}>
              {columns.map((col, index) => {
                return <TableCell key={index}>{col}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {rows.map((row) => (
              <TableRow className={classes.tableRow} key={row.id}>
                <TableCell component="th" scope="row" align="left">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.channelType}</TableCell>
                <TableCell align="left">{row.messageMedium}</TableCell>
                <TableCell align="left">
                  <Switch
                    classes={{
                      root: row.status ? classes.switchOn : row.statusOff,
                    }}
                    checked={row.status}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      )}
    </TableContainer>
  );
}
