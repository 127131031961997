import { createSelector } from "reselect";
import { selectingMember } from "./selectedMember";
import moment from "moment";

const tos = () => (state) => state.transferOfService;

const formData = () =>
  createSelector(selectingMember(), (member) => {
    const m = member.toJS();
    const hasMeter = m.meters.length > 0 ? true : false;    
    

    return Object.assign(
      {},
      {
        userID: m.userID,
        memberID: m.memberID,
        memberAgreement: true,
        birthdate: moment.utc(m.birthday).format("YYYY-MM-DD"),
        firstName: m.first_name,
        lastName: m.last_name,
        existingMeterID: hasMeter ? m.meters[0].ID : "",
        paperlessOptIn: m.statement_delivery === "email" ? true : false,
        language: m.language,
        autopay: m.autopay,        
        billingAddress: m.billing_address ? m.billing_address : "",
      }
    );
  });

export const enrollmentUpdatePayload = () =>
  createSelector(selectingMember(), formData(), (member, formData) => {
    const m = member.toJS();
    return Object.assign(
      {},
      {
        email: m.email,
        phone: m.phoneRaw ? m.phoneRaw : "",
        enrollmentType: "move",
        createdBy: "admin",
        formData,
      }
    );
  });

const selectTosContainer = () =>
  createSelector(
    enrollmentUpdatePayload(),
    tos(),
    (enrollmentUpdatePayload, tos) =>
      Object.assign({}, { ...tos, enrollmentUpdatePayload })
  );

export default selectTosContainer;
