import { createSelector } from "reselect";

const batch = () => (state) => state.batch;

const selectBatchContainer = () =>
  createSelector(batch(), (batchData) => {
    return batchData;
  });

export default selectBatchContainer;
