import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Chip from "@material-ui/core/Chip";
import NoteCard from "./NoteCard.js";
import moment from "moment";
import { noselect } from "../../styles/common.css";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
  table: {
    fontSize: "15px",
  },
  tableRow: {
    "& .MuiTableCell-root": {
      fontSize: "15px",
    },
  },
  tableHeaderRow: {
    backgroundColor: "#333",
    "& .MuiTableCell-root": {
      fontSize: "17px",
      color: "white",
    },
  },
  noteColumn: {
    minWidth: "500px",
  },

  pagination: {
    // Force left alignment on pagination controls
    "& .MuiTablePagination-spacer": {
      flex: "none",
    },
  },
});

export default function NotesTable(props) {
  const classes = useStyles2();
  const { notesData, availableTags } = props;
  const rows = notesData.map((noteInfo) => {
    const createdUtcDate = noteInfo.get("created");
    const createdDate = new Date(createdUtcDate);
    return {
      createdDate: moment(createdDate).format("MMMM Do YYYY, h:mm:ss a"),
      author: noteInfo.get("authorEmail"),
      member: noteInfo.get("memberID"),
      content: noteInfo.get("body"),
      tags: noteInfo.get("tags"),
    };
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      style={{ marginBottom: "80px" }} // Avoid overlapping with the add note button
      component={Paper}
    >
      <Table className={classes.table} aria-label="custom pagination table">
        <TableHead>
          <TableRow className={`${classes.tableHeaderRow} ${noselect}`}>
            <TableCell>Created Date</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Member</TableCell>
            <TableCell>Tags</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow className={classes.tableRow} key={row.createdDate}>
              <TableCell
                component="th"
                scope="row"
                style={{ minWidth: 120 }}
                align="left"
              >
                {row.createdDate}
              </TableCell>
              <TableCell style={{ minWidth: 160 }} align="left">
                {row.author}
              </TableCell>
              <TableCell style={{ minWidth: 120, fontSize: "12px" }} align="left">
                <a href={`/members/${row.member}/details`}>{row.member}</a>
              </TableCell>
              <TableCell align="left">
                {row.tags.filter(tag => availableTags.hasOwnProperty(tag)).map((tag, index) => <Chip
                  key={index}
                  size="small"
                  style={{ margin: "1px" }}
                  variant="outlined"
                  label={availableTags[tag]?.name}
                />)}
              </TableCell>
              <TableCell classes={{ root: classes.noteColumn }} align="left">
                <NoteCard noteInfo={row} />
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow >
            <TablePagination
              classes={{ root: classes.pagination }}
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
                native: true,
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
