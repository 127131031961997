import * as constants from "../../constants";
import fetch from "../util/api-ajax";

const createErrorMessage = (message) => ({
  type: constants.USER_MANAGEMENT_SET_ERROR_MESSAGE,
  message,
});

const createSuccessMessage = (message) => ({
  type: constants.USER_MANAGEMENT_SET_SUCCESS_MESSAGE,
  message,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

function setIsFetching(isFetching) {
  return {
    type: constants.REQUEST_CHANGE_TO_ACCOUNT,
    isFetching,
  };
}

function getUsersSuccess(payload) {
  return {
    type: constants.USER_MANAGEMENT_GET_USERS_SUCCESS,
    payload,
  };
}

function getRolesSuccess(payload) {
  return {
    type: constants.USER_MANAGEMENT_GET_ROLES_SUCCESS,
    payload,
  };
}

export function getUsers(data) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.USERS_MANAGEMENT_GET_USERS, data)
      .then((res) => {
        dispatch(getUsersSuccess(res))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}

export function getRoles() {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.USERS_MANAGEMENT_GET_ROLES)
      .then((res) => {
        dispatch(getRolesSuccess(res))
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}

export function createUser(data) {
  return (dispatch) => {
    dispatch(setIsFetching(true));
    return fetch(constants.USERS_MANAGEMENT_CREATE_USER, data)
      .then((res) => {
        dispatch(setIsFetching(false))
        dispatch(getUsers())
      })
      .finally(() => dispatch(setIsFetching(false)))
      .catch((error) => {
        dispatch(setErrorMessage(error.message));
      });
  };
}