import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactTable from "react-table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useStyles } from "./styles"
import Grid from "@material-ui/core/Grid";

const Generation = props => {
  const classes = useStyles();
  const [timeInterval, setTimeInterval] = useState("monthly");
  const [data, setData] = useState([]);
  const { params, guest, generation, doGetGeneration } = props;
  const guestId = params.guestUserId;
  const memberId = guestId ? guest.get("memberID") : params.memberId;

  const onSelectChange = e => {
    setTimeInterval(e.target.value)
    if (memberId) {
      doGetGeneration(memberId, e.target.value);
    }
  }

  useEffect(() => {
    if (memberId) {
      doGetGeneration(memberId, timeInterval);
    }
  }, [memberId]);

  useEffect(() => {
    if (generation && generation.children !== undefined) {
      let rawData = generation.children;
      rawData.sort(function(a, b) {
        var dateA = new Date(a.period);
        var dateB = new Date(b.period);
        return dateB - dateA;
      });
      setData(rawData)
    }
  }, [generation]);

  const columns = [
    {
      Header: "Time",
      accessor: "period",
      Cell: (row) => (
        <div style={{ textAlign: "center" }}>
          {moment
            .tz(row.value, "America/Chicago")
            .format("MMM DD, YYYY, h:mm a")}
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price_ckwh",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}¢</div>,
    },
    {
      Header: "Usage",
      accessor: "usage_kwh",
      Cell: (row) => <div style={{ textAlign: "center" }}>{row.value} kW/h</div>,
    },
    {
      Header: "Cost",
      accessor: "cost_dollars",
      Cell: (row) => <div style={{ textAlign: "center" }}>${row.value}</div>,
    },
  ];

  return (
    <div>
      <div className={classes.inputSelector}>
        <span>Select interval</span>
        <Select
          value={timeInterval}
          onChange={onSelectChange}
          inputProps={{ name: "Interval" }}
          style={{
            width: '200px',
          }}
        >
          <MenuItem value={"daily"}>Hourly</MenuItem>
          <MenuItem value={"monthly"}>Daily</MenuItem>
          <MenuItem value={"yearly"}>Monthly</MenuItem>
        </Select>
      </div>
      <Grid container>
        <Grid item xs={10}>
          <ReactTable
            data={data}
            columns={columns}
            style={{ height: "600px", marginTop: 15 }}
            className="-striped -highlight"
            filterable
            noDataText=""
            defaultPageSize={100}
          />
        </Grid>
        <Grid item xs={2} className={classes.usage}>
          <div className="summaryInfoContainer">
            <div id="usage-info">
              <h1>{`Usage:`}</h1>
              <p>{`${data.usage_kwh || 0} kWh`}</p>
            </div>
            <div id="all-in-rate-info">
              <h1>{`All in rate:`}</h1>
              <p>{`${data.avg_cost || 0} ¢/kWh`}</p>
            </div>
            <div id="compare-price-info">
              <h1>{`Price to Compare:`}</h1>
              <p>{`${data.price_ckwh || 0} ¢/kWh`}</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Generation