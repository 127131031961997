import * as constants from "../../constants";

const defaultPageStatus = {
  actionsProcessing: 0,
  showSnackBar: false,
  successMessages: [],
  errorMessages: [],
};


export default function pageStatus(state = defaultPageStatus, action) {
  switch (action.type) {
    case constants.STATUS_CLOSE_SNACKBAR:
      return {
        ...state,
        showSnackBar: false,
        successMessages: [],
        errorMessages: [],
      };
    case constants.STATUS_ERROR_MESSAGE:
      return {
        ...state,
        showSnackBar: true,
        errorMessages: [...state.errorMessages, action.payload],
      };
    case constants.STATUS_SUCCESS_MESSAGE:
      return {
        ...state,
        showSnackBar: true,
        successMessages: [...state.successMessages, action.payload],
      };
    case constants.STATUS_INCREMENT_PROCESSING:
      return {
        ...state,
        actionsProcessing: state.actionsProcessing + 1
      };
    case constants.STATUS_DECREMENT_PROCESSING:
      return {
        ...state,
        actionsProcessing: state.actionsProcessing - 1
      };
    default:
      return state;
  }
}