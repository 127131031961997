import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTableStyles as useStyles } from "../styles";

import moment from "moment";
import currency from "../../../util/currency-formatter";
import dateFormat from "dateformat";

import ReactTable from "react-table";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Link } from "react-router";
import Button from "../../Button"
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import UpdateStatement from "../UpdateStatement";
import { KeyboardDatePicker } from "@material-ui/pickers";

import StatementDetailsRow from "../StatementDetailsRow";

const AutoPayStatementTable = (props) => {
  const {
    statements,
    doUpdateEligibleStatements,
    setSelectedEndDateForFetch,
    setSelectedStartDateForFetch,
  } = props;

  const classes = useStyles();

  const [eligibleStatements, setEligibleStatements] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(2, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [selectAll, setSelectAll] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const resetDatesOnPageLeave = function () {
      setSelectedStartDateForFetch(
        moment().subtract(2, "days").format("YYYY-MM-DD")
      );
      setSelectedEndDateForFetch(moment().format("YYYY-MM-DD"));
    };
    return resetDatesOnPageLeave();
  }, []);

  useEffect(() => {
    // Initialize statements to be unchecked
    let newArr = statements.map((statement) => {
      return {
        ...statement,
        summary: statement?.summary ? JSON.parse(statement.summary) : {},
        checked: false,
      };
    });
    setEligibleStatements(newArr);
  }, [statements]);

  useEffect(() => {
    if (eligibleStatements.length > 0) {
      // Set all statements to be checked based on master select
      let newArr = eligibleStatements.map((statement) => {
        if (statement) {
          return { ...statement, checked: selectAll };
        } else {
          return { ...statement };
        }
      });
      setEligibleStatements(newArr);
    }
  }, [selectAll]);

  // Check selected statement
  const handleCheckedBox = (statementID, value) => {
    let newArr = eligibleStatements.map((statement) =>
      statement.id === statementID
        ? { ...statement, checked: value ? value : !statement.checked }
        : statement
    );
    setEligibleStatements(newArr);
  };

  const handleClose = () => {
    setOpenPopup(false);
  };

  const handleChangeForDate = (date, type) => {
    if (type === "start") {
      setStartDate(date);
      setSelectedStartDateForFetch(moment(date).format("YYYY-MM-DD"));
    } else if (type === "end") {
      setEndDate(date);
      setSelectedEndDateForFetch(moment(date).format("YYYY-MM-DD"));
    }
  };

  const columnStyle = {
    textAlign: "center",
  };
  const headerStyle = {
    fontWeight: "700",
    alignSelf: "center",
  };
  const eligibleStatementColumns = [
    {
      id: "checkbox",
      Header: (
        <Tooltip placement="bottom" title="Select All">
          <Checkbox
            className={classes.checkboxCell}
            checked={selectAll}
            onClick={(e) => {
              e.stopPropagation();

              setSelectAll(!selectAll);
            }}
            onChange={(e) => {
              e.stopPropagation();
            }}
          />
        </Tooltip>
      ),
      accessor: (statement) => (
        <Checkbox
          onClick={(e) => {
            handleCheckedBox(statement.id);
          }}
          checked={statement.checked ? statement.checked : false}
          name="checkedA"
        />
      ),
      sortable: false,
      style: {
        ...columnStyle,
        padding: 0,
      },
      headerStyle: {
        ...headerStyle,
        padding: 0,
      },
    },
    {
      id: "Member ID",
      Header: "Member ID",
      accessor: (statement) => (
        <Link
          style={{ color: "#3f51b5" }}
          to={`/members/${statement.member_id}/details`}
        >
          {statement.member_id}
        </Link>
      ),
      style: columnStyle,
      headerStyle,
    },
    {
      id: "due_date",
      Header: "Due Date",
      accessor: (s) => dateFormat(s.due_date, "mmm d, yyyy", true),
      sortMethod: (a, b) => {
        if (new Date(a).getTime() > new Date(b).getTime()) {
          return 1;
        }
        return -1;
      },
      style: columnStyle,
      headerStyle,
    },
    {
      id: "Amount Due",
      Header: "Statement Amount",
      accessor: (s) => currency(s?.amount_due),
      style: columnStyle,
      headerStyle,
    },
    {
      id: "Pending Credits",
      Header: "Pending Credits",
      accessor: (s) => currency(s?.pending_credits),
      style: columnStyle,
      headerStyle,
    },
    {
      id: "Current Balance",
      Header: "Remaining Due",
      accessor: (s) => currency(s?.autopay_amount),
      style: columnStyle,
      headerStyle,
    },
  ];

  return (
    <>
      <div className={classes.tableOptionsContainer}>
        <div className={classes.dateSelectors}>
          <label>Start Date: </label>
          <KeyboardDatePicker
            dateformat="YYYY-MM-DD"
            value={startDate}
            maxDate={moment(endDate).subtract(2, "days")}
            onChange={() => null}
            onAccept={(value) => {
              handleChangeForDate(value, "start");
            }}
            views={["year", "month", "date"]}
            style={{ margin: "15px 0px", width: "200px" }}
          />
          <label>End Date: </label>
          <KeyboardDatePicker
            dateformat="YYYY-MM-DD"
            value={endDate}
            onChange={() => null}
            maxDate={moment()}
            onAccept={(value) => {
              handleChangeForDate(value, "end");
            }}
            views={["year", "month", "date"]}
            style={{ margin: "15px 0px", width: "200px" }}
          />
        </div>
        <Button
          color="primary"
          varian="raised"
          onClick={() => {
            setOpenPopup(true);
          }}
        >
          Approve
        </Button>
      </div>
      <ReactTable
        className={"-striped -highlight " + classes.table}
        data={eligibleStatements}
        columns={eligibleStatementColumns}
        defaultPageSize={10}
        SubComponent={({ original: statement }) => {
          return (
            <Table className={classes.subTable}>
              <TableBody>
                <StatementDetailsRow summary={statement?.summary} />
              </TableBody>
            </Table>
          );
        }}
      />
      <Dialog
        className={classes.dialog}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPopup}
      >
        {eligibleStatements.filter((s) => s.checked).length > 0 ? (
          <UpdateStatement
            doUpdateEligibleStatements={doUpdateEligibleStatements}
            selectedStatements={eligibleStatements}
            closeManualAdjustmentPopup={handleClose}
          />
        ) : (
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={openPopup}
          >
            <DialogContent dividers>
              Please select statements to adjust
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleClose()} color="secondary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Dialog>
    </>
  );
};

AutoPayStatementTable.propTypes = {
  statements: PropTypes.array.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  setSelectedStartDateForFetch: PropTypes.func.isRequired,
  setSelectedEndDateForFetch: PropTypes.func.isRequired,
  doUpdateEligibleStatements: PropTypes.func.isRequired,
};

export default AutoPayStatementTable;
