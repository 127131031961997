import Immutable from "immutable";
import * as C from "../../constants";

const initialState = Immutable.fromJS({
  pairs: [],
});

export function acceptedPairs(state = initialState, action) {
  switch (action.type) {
    case C.RECEIVED_ACCEPTED_TYPE_SUBTYPE_PAIRS:
      // Sort Array by  type description
      action.payload.pairs.sort((a, b) =>
        a.type_description > b.type_description ? 1 : -1
      );
      return Immutable.fromJS(action.payload);
    default:
      return state;
  }
}
