import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
} from "@material-ui/core";
import {
  UserFields,
  AddressFields,
} from "./UserFields";

import {
  validateEmail,
  validatePhone,
  validateZip,
} from "../../util/validations";


const AddCSADialog = ({ closeDialog, doCreateCSA }) => {
  const [userDetails, setUserDetails] = useState({
    company_name: "",
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    language: "en",
    billing_address: {
      line1: "",
      line2: "",
      city: "",
      state: "TX",
      postal_code: "",
    },
  });

  function setAddress(newAddress) {
    setUserDetails({
      ...userDetails,
      billing_address: newAddress,
    });
  }

  function createCSA() {
    doCreateCSA(userDetails);
  }

  function validateInput() {
    const phoneOk = validatePhone(userDetails.phone);
    const emailOk = validateEmail(userDetails.email);
    const zipOk = validateZip(userDetails.billing_address.postal_code);
    const userOk = userDetails.first_name && userDetails.last_name;
    const addressOk = userDetails.billing_address.line1;
    return userOk && addressOk && phoneOk && emailOk && zipOk;
  }

  return <Dialog
    open={true}
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>
      Add CSA Owner
    </DialogTitle>
    <DialogContent>
      <FormControl fullWidth >
        <UserFields userDetails={userDetails} setUserDetails={setUserDetails} />
        <br />
        <Typography variant="h6">Address</Typography>
        <br />
        <AddressFields address={userDetails.billing_address} setAddress={setAddress} />
      </FormControl>
    </DialogContent >
    <DialogActions>
      <Button
        color="secondary"
        variant="contained"
        onClick={closeDialog}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          createCSA();
          closeDialog();
        }}
        disabled={!validateInput()}
      >
        Add CSA
      </Button>
    </DialogActions>
  </Dialog >
}

export default AddCSADialog;
