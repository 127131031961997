import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import selectIterableDataContainer from "../selectors/iterable";

export const createErrorMessage = (errorMessage) => ({
  type: constants.ITERABLE_OPEN_SNACKBAR,
  errorMessage,
});

export const setErrorMessage = (message) => (dispatch) => {
  dispatch(createErrorMessage(message));
};

const createSuccessMessage = (successMessage) => ({
  type: constants.ITERABLE_OPEN_SNACKBAR,
  successMessage,
});

export const setSuccessMessage = (message) => (dispatch) => {
  dispatch(createSuccessMessage(message));
};

const setIsProcessing = (isProcessing) => {
  return {
    type: constants.ITERABLE_UPDATE_STATE,
    updatedInfo: {
      isProcessing,
    },
  };
};

const addToTemplateObject = (payload) => {
  return {
    type: constants.ITERABLE_ADD_TO_TEMPLATES,
    payload,
  };
};

const setChannelCollection = (payload) => {
  return {
    type: constants.ITERABLE_SET_CHANNEL_COLLECTION,
    payload,
  };
};

const setCampaignCollection = (payload) => {
  return {
    type: constants.ITERABLE_SET_CAMPAIGN_COLLECTION,
    payload,
  };
};

const setIterableUserData = (payload) => {
  return {
    type: constants.ITERABLE_REQUEST_SUCCESS,
    payload,
  };
};
export const closeSnackbar = () => {
  return (dispatch) => dispatch({ type: constants.ITERABLE_CLOSE_SNACKBAR });
};

export const requestIterableUserData = (email) => (dispatch, getState) => {
  const { templateCollection } = selectIterableDataContainer()(getState());

  dispatch(setIsProcessing(true));

  return fetch(constants.ITERABLE_USER_INFO, { email })
    .then((res) => JSON.parse(res.body))
    .then((res) => {
      dispatch({
        type: constants.ITERABLE_UPDATE_USER_INFO,
        payload: res,
      });

      return fetch(constants.ITERABLE_USER_EVENTS, { email });
    })
    .then((res) => JSON.parse(res.body))
    .then((eventRes) => {
      eventRes.events.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      const promises = eventRes.events.map(async (event) => {
        if (event.eventType === "emailSend") {
          if (templateCollection.hasOwnProperty(event.templateId)) {
            event.emailTemplate = templateCollection[event.templateId];
          } else {
            try {
              let emailTemplate = await requestIterableEmailTemplate(
                event.templateId,
                dispatch
              );
              event.emailTemplate = emailTemplate;
            } catch (err) {
              event.emailTemplate = {};
            }
          }
          return event;
        }
        return event;
      });

      return Promise.all(promises);
    })
    .then((eventsPromiseRes) => {
      dispatch(setIterableUserData({ events: eventsPromiseRes }));
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableUserInfo = (email) => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetch(constants.ITERABLE_USER_INFO, email)
    .then((res) => JSON.parse(res.body))
    .then((res) => {
      dispatch({
        type: constants.ITERABLE_UPDATE_USER_INFO,
        payload: res,
      });
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableUserEvents = (email) => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetch(constants.ITERABLE_USER_EVENTS, { email })
    .then((res) => JSON.parse(res.body))
    .then((res) => {
      res.events.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const promises = res.events.map(async (event) => {
        if (event.eventType === "emailSend") {
          let emailTemplate = await requestIterableEmailTemplate(
            event.templateId,
            dispatch
          );
          event.emailTemplate = emailTemplate;
          return event;
        }
        return event;
      });

      Promise.all(promises)
        .then((events) => {
          dispatch(setIterableUserData({ events: events }));
          dispatch(setIsProcessing(false));
        })
        .catch((err) => {
          dispatch(setErrorMessage(err));
          dispatch(setIsProcessing(false));
        });
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableEmailView = (email, messageId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setIsProcessing(true));
    return fetch(constants.ITERABLE_VIEW_EMAIL_IN_BROWSER, { email, messageId })
      .then((res) => res.body)
      .then((res) => {
        dispatch(setIsProcessing(false));
        resolve(res);
      })
      .catch((err) => {
        dispatch(setIsProcessing(false));
        reject(err);
      });
  });
};

export const requestIterableChannels = () => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetch(constants.ITERABLE_CHANNELS)
    .then((res) => JSON.parse(res.body))
    .then((res) => {
      res.channels.sort(function (a, b) {
        return a.id - b.id;
      });
      res.channels.sort(function (a, b) {
        return a.channelType < b.channelType
          ? -1
          : a.channelType > b.channelType
          ? 1
          : 0;
      });

      const collection = res.channels.reduce((obj, channel) => {
        return {
          ...obj,
          [channel.id]: channel,
        };
      }, {});

      dispatch(setIterableUserData(res));
      dispatch(setChannelCollection(collection));
      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableCampaigns = () => (dispatch) => {
  dispatch(setIsProcessing(true));
  return fetch(constants.ITERABLE_CAMPAIGNS)
    .then((res) => JSON.parse(res.body))
    .then((res) => {
      const collection = res.campaigns.reduce((obj, campaign) => {
        return {
          ...obj,
          [campaign.id]: campaign,
        };
      }, {});

      dispatch(setCampaignCollection(collection));

      dispatch(setIsProcessing(false));
    })
    .catch((err) => {
      dispatch(setErrorMessage(err.message));
      dispatch(setIsProcessing(false));
    });
};

export const requestIterableEmailTemplate = (templateId, dispatch) => {
  return new Promise((resolve, reject) => {
    return fetch(constants.ITERABLE_GET_EMAIL_TEMPLATE, {
      templateId: templateId,
    })
      .then((res) => JSON.parse(res.body))
      .then((res) => {
        dispatch(
          addToTemplateObject({ templateId: templateId, template: res })
        );
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export function clearIterableUserData() {
  return (dispatch) =>
    dispatch({
      type: constants.CLEAR_ITERABLE_DATA,
    });
}
