import { takeLatest, call, select, put } from "redux-saga/effects";
import "babel-polyfill";
import * as C from "../../constants";
import fetch from "../util/api-ajax";
import moment from "moment";

import {
  receiveEligibleStatements,
  isProcessing,
  setErrorMessage,
} from "../actions/payment";

function* fetchEligibleStatements() {
  const start_date = yield select((store) => store.payments.selectedStartDate);
  const end_date = yield select((store) => store.payments.selectedEndDate);

  // Selected start date must not be more than the end date. End date must not be future dates
  if (!moment(start_date).isSameOrBefore(end_date)) {
    return;
  }
  yield put(isProcessing(true));

  const payload = {
    start_date,
    end_date,
  };

  try {
    const res = yield call(fetch, C.BILLING_ELIGIBLE_STATEMENTS, payload);
    yield put(receiveEligibleStatements(res));
    yield put(isProcessing(false));
  } catch (err) {
    yield put(setErrorMessage(err.message));
    yield put(isProcessing(false));
  }
}

export default [
  takeLatest(C.REQUEST_BILLING_ELIGIBLE_STATEMENTS, fetchEligibleStatements),
];
