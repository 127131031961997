import PropTypes from "prop-types";
import React, { useState, useEffect, Fragment } from "react";
import { FormControl } from "@material-ui/core/";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Button from "../Button"
import ReactTable from "react-table";
import XLSX from "xlsx";
import StatusOverlay from "../StatusOverlay"
import FileDownload from "@material-ui/icons/FileDownload";

const Reports = props => {

  const [resultReport, setResultReport] = useState({});
  const [selectedReport, setSelectedReport] = useState({});
  const {
    payload,
    reports,
    columns,
    doFetchGetReports,
    doFetchRunReport,
  } = props;

  useEffect(() => {
    doFetchGetReports({});
  }, []);

  useEffect(() => {
    setResultReport({
      report: selectedReport,
      result: payload
    })
  }, [payload]);

  const previewReport = e => {
    e.preventDefault()
    if (selectedReport.report_id !== undefined) {
      doFetchRunReport(selectedReport.report_id)
    }
  }

  function formatter(value) {
    const patternInt = /^-?\d+$/;
    const patternFloat = /^-?\d+(\.\d+)?$/;
    const patternDate = /^\d{4}-\d{2}-\d{2}$/;
    const patternDatetime = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z$/;
    if (patternInt.test(value)) {
      return parseInt(value, 10);
    } else if (patternFloat.test(value)) {
      return parseFloat(value);
    } else if (patternDate.test(value)) {
      return new Date(value + "T00:00:00.000");
    } else if (patternDatetime.test(value)) {
      return new Date(value.replace('Z', ''));
    } else {
      return value;
    }
  }

  function convertToCSV(data) {
    const headers = columns.map(item => {
      const title = item.split('_').join(' ')
      return { header: title.charAt(0).toUpperCase() + title.slice(1), accessor: item }
    });
    
    const header = headers.map(x => `"${x.header}"`).join(',');
    const values = data.map(x => headers.map(y => {
      const value = x[y.accessor];
      return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
    }).join(','));
    
    const csv = [header, ...values].join('\n');
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
  
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${selectedReport.name}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  function convertToXLSX(data) {
    const orderedData = data.map(item =>
      columns.reduce((acc, column) => {
        let title = column.split('_').join(' ')
        title = title.charAt(0).toUpperCase() + title.slice(1)
        acc[title] = formatter(item[column]);
        return acc;
      }, {})
    );

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(orderedData, {
      header: columns.map(item => {
        const title = item.split('_').join(' ')
        return title.charAt(0).toUpperCase() + title.slice(1)
      })
    });
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    const fileName = `${selectedReport.name}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  }

  return (
    <div>
      <StatusOverlay />
      <Grid container>
        <Grid item sm={6}>
          <h4>Reports</h4>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} md={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="enroll-type">Select Report</InputLabel>
            <Select
              onChange={(e) => setSelectedReport(reports.filter(x => x.report_id === e.target.value)[0])}
            >
              {reports && reports.filter(val => val.is_active).map((val, index) => <MenuItem key={index} value={val.report_id}>{val.name}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={3}>
          <Button
            style={{ margin: "10px" }}
            variant="contained"
            color="primary"
            onClick={previewReport}
          >
            PREVIEW
          </Button>
        </Grid>
      </Grid>
      <br />
      {
        resultReport && resultReport.result && resultReport.result.length > 0 ?
          <Fragment>
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <h4>{resultReport.report.name}</h4>
              </Grid>
              <Grid item md={6}>
                <Button
                  style={{ margin: "10px", float: "right" }}
                  variant="contained"
                  color="primary"
                  onClick={e => convertToXLSX(resultReport.result)}
                >
                  <FileDownload /> <div style={{ marginLeft: '10px' }}>XLSX</div>
                </Button>
                <Button
                  style={{ margin: "10px", float: "right" }}
                  variant="contained"
                  color="primary"
                  onClick={e => convertToCSV(resultReport.result)}
                >
                  <FileDownload /> <div style={{ marginLeft: '10px' }}>CSV</div>
                </Button>
              </Grid>
            </Grid>
            <ReactTable
              data={resultReport.result}
              columns={columns.map(item => {
                const title = item.split('_').join(' ')
                return {
                  Header: title.charAt(0).toUpperCase() + title.slice(1),
                  accessor: item,
                }
              })}
              className="-striped -highlight"
              defaultPageSize={10}
              defaultSortDesc
              filterable
            />
          </Fragment> :
          <div>
            Please select and preview a report on the list
          </div>
      }
    </div>
  );
}

Reports.propTypes = {
  reports: PropTypes.array.isRequired,
  doFetchGetReports: PropTypes.func.isRequired,
  doFetchRunReport: PropTypes.func.isRequired,
};

export default Reports