import fish from "isomorphic-fetch";

function getUrl(path) {
  const fullUrl = getBaseUrl(path); // returns url
  return fullUrl;
}
function getBaseUrl(url) {
  let endpointPrefix = process.env.REACT_APP_BASE_API_URL;
  return endpointPrefix + url;
}

function getOptions(url, payload) {
  const options = {
    method: "POST",
    body: JSON.stringify(payload),
  };
  const accessToken = localStorage.getItem("access_token");
  if (!url.includes("signin") && accessToken) {
    const bearerToken = `Bearer ${localStorage.getItem("access_token")}`;
    const customHeaders = new Headers();
    customHeaders.append("Authorization", bearerToken);
    options.headers = customHeaders;
    options.mode = "cors";
  }
  return options;
}

function refreshTokenAndRetry(url, payload) {
  const refreshToken = localStorage.getItem("refresh_token");
  localStorage.removeItem("access_token");
  if (refreshToken) {
    const tokenRefreshUrl = getUrl("/users/token/refresh");
    const refreshOptions = getOptions(tokenRefreshUrl, {
      token: refreshToken,
    });
    refreshOptions.mode = "cors";
    return fish(tokenRefreshUrl, refreshOptions)
      .then((responseOne) => responseOne.json())
      .then((responseTwo) => {
        localStorage.setItem("access_token", responseTwo.access_token);
        const options = getOptions(url, payload);
        return fish(url, options)
          .then((responseThree) => responseThree.json())
          .then((responseFour) => responseFour);
      })
      .catch((error) => {
        localStorage.clear();
        window.location.reload();
      });
  }
  return undefined;
}

export default function fetch(path, payload, useFullUrl = false, useRawResponse = false) {
  const url = useFullUrl ? path : getUrl(path);
  const options = useFullUrl ? {} : getOptions(url, payload);
  return genericFetch(url, options, useRawResponse, (json) => refreshTokenAndRetry(url, payload));
}

/**
 * This function is used to make a generic fetch request
 * @param {(json:any) => Promise<any>} onAuthError - Function to reauthorize and retry the request (otherwise the promise is rejected as normal)
*/
export async function genericFetch(url, options, useRawResponse = false, onAuthError = (json) => Promise.reject(json)) {
  try {
    const response = await fish(url, options);
    if (useRawResponse) {
      return response;
    }
    const json = await response.json();
    if (response.status === 401) {
      // Authorization Error
      return onAuthError(json);
    }
    if (response.status >= 200 && response.status < 300) {
      // Any kinds of Success
      return json;
    }
    if (response.status >= 400) {
      // Any kinds of failure other than Authorization
      return Promise.reject(json);
    }
    return undefined;
  } catch (error) {
    if (error) {
      return Promise.reject(error);
    }
    return undefined;
  }
}
