import React, { useState, useEffect, Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Button from "../Button"
import ReactTable from "react-table";
import { makeStyles } from "@material-ui/core/styles";
import JSONInput from "react-json-ide";
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import locale from "react-json-ide/locale/en";
import Snackbar from "../Snackbar"
import { confirmationError, confirmationSuccess } from "../../styles/common.css";
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  transactionData: {
    display: "flex",
    flexDirection: "column",
    fontSize: "18px",
    padding: "15px",
    marginRight: "20px",
    border: "1px solid #ddd",
  },
  listTransaction: {
    padding: "10px",
    color: "black"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  }
}))

const PreviewMode = ({ openDialog, setOpenDialog, preview }) => {
  const classes = useStyles()
  const [details, setDetails] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (preview && preview.length > 0) {
      setDetails({ ...preview[0], index: 0 })
    }
  }, [preview])

  return (
    <Dialog
      open={openDialog}
      ariaHideApp={false}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle>
        Preview
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <DialogContentText>
          <Grid container>
            <Grid item md={4}>
              <span style={{ marginLeft: "10px" }}>Emails</span>
              <List className={classes.listTransaction}>
                {
                  preview && preview.map((item, index) => (
                    <ListItem
                      key={index}
                      button
                      selected={selectedIndex === index || details.index === index}
                      onMouseEnter={() => setSelectedIndex(index)}
                      onMouseLeave={() => setSelectedIndex(null)}
                      onClick={() => setDetails({ ...item, index })}
                    >
                      <ListItemText primary={item.to_email} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item md={8}>
              <span>Data Fields</span>
              <Card className={classes.transactionData}>
                {
                  details && details.data_fields &&
                  <JSONInput
                    id="transactionData"
                    placeholder={JSON.parse(details.data_fields)}
                    locale={locale}
                    height="429px"
                    width="100%"
                    viewOnly
                    reset
                  />
                }
              </Card>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpenDialog(false)}
          color="primary"
          variant="contained"
          style={{
            float: "right",
            marginRight: 20,
            marginBottom: 10,
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const JobNotifications = props => {

  const [selectedNotification, setSelectedNotification] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const {
    jobNotifications,
    payload,
    doFetchGetNotifications,
    doFetchRunNotification,
    doFetchExecuteNotification,
    closeSnackbar,
    showSnackBar,
    successMessage,
    errorMessage,
    isProcessing,
  } = props
  const [selectedPreview, setSelectedPreview] = useState(payload);
  const classes = useStyles()

  useEffect(() => {
    setSelectedPreview(payload)
  }, [payload, selectedPreview])

  useEffect(() => {
    doFetchGetNotifications();
  }, [])

  const previewNotification = row => {
    if (row.id !== undefined) {
      setSelectedNotification(row)
      doFetchRunNotification(row.id)
    }
  }

  const executeNotification = row => {
    if (row.id !== undefined) {
      setSelectedNotification(row)
      doFetchExecuteNotification(row.id)
    }
  }

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Job Type",
      accessor: "job_type",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Notification Type",
      accessor: "notification_type",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Report Name",
      accessor: "report_name",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Document Template",
      accessor: "document_template",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Event Name",
      accessor: "event_name",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "Last Run",
      accessor: "last_run",
      Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>)
    },
    {
      Header: "",
      accessor: "id",
      width: 100,
      Cell: (row) => (
        <Button
          onClick={async () => {
            await previewNotification(row.original)
            await setOpenDialog(true)
          }}
          variant="contained"
          color="primary"
        >
          Preview
        </Button>
      )
    },
    {
      Header: "",
      accessor: "id",
      width: 100,
      Cell: (row) => (
        <Button
          onClick={async () => await executeNotification(row.original)}
          variant="contained"
          color="secondary"
        >
          Execute
        </Button>
      )
    }
  ]

  return (
    <div>
      <Grid container>
        <Grid item sm={6}>
          <h4>Notification Jobs</h4>
        </Grid>
      </Grid>
      {
        successMessage || errorMessage &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id="message-id"
          open={showSnackBar}
          onClose={closeSnackbar}
          className={successMessage ? confirmationSuccess : confirmationError}
          message={successMessage || errorMessage}
        />
      }
      <Backdrop className={classes.backdrop} open={isProcessing}>
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
      <PreviewMode
        preview={selectedPreview}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        notification={selectedNotification}
      />
      <br />
      {
        jobNotifications && jobNotifications.length > 0 ?
          <Fragment>
            <ReactTable
              data={jobNotifications}
              columns={columns}
              className="-striped -highlight"
              defaultPageSize={10}
              defaultSortDesc
              filterable
            />
          </Fragment> :
          <div>
            There are no notifications to show
          </div>
      }
    </div>
  )
}

export default JobNotifications