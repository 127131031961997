import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
      border: "none",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  inputWrapper: {
    fontSize: "16px",
    margin: "10px 0px",
    "& label": {
      display: "inline-block",
      fontWeight: "bold",
      marginRight: "10px",
      width: "180px",
      textTransformation: "uppercase",
    },
    "& input[type=text]": {
      padding: "5px 10px",
      width: "260px",
    },
  },
}));
