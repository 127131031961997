import Immutable from "immutable";
import * as constants from "../../constants";

const defaultUsageState = Immutable.fromJS({
  usage: {},
  generation: {},
  monthlyUsage: [],
  netMeteringBank: []
});

export default function usage(state = defaultUsageState, action) {
  switch (action.type) {
    case constants.RECEIVE_USAGE:
      return {
        ...state,
        usage: action.data,
      };
    case constants.RECEIVE_GENERATION:
      return {
        ...state,
        generation: action.data,
      };
    case constants.RECEIVE_MONTHLY_USAGE:
      return {
        ...state,
        monthlyUsage: action.data.list,
      };
    case constants.RECEIVE_NET_METERING_BANK:
      return {
        ...state,
        netMeteringBank: action.data.list,
      };
    default:
      return state;
  }
}
