import { connect } from "react-redux";
import {
  listMemberDocuments,
  uploadMemberDocument,
  deleteMemberDocument,
  updateMemberDocument,
  openMemberDocument,
} from "../actions/members";
import MemberDocuments from "../components/members/MemberDocuments";

const mapStateToProps = state => ({
  member: state.selectedMember,
  memberDocuments: state.memberDocuments,
});

const mapDispatchToProps = dispatch => ({
  doFetchMemberDocuments: (memberID) => {
    dispatch(listMemberDocuments(memberID));
  },
  doUploadDocument: (memberID, file, category) => {
    return dispatch(uploadMemberDocument(memberID, file, category));
  },
  doDeleteDocument: (memberID, documentID) => {
    dispatch(deleteMemberDocument(memberID, documentID));
  },
  doUpdateDocument: (parameters) => {
    dispatch(updateMemberDocument(parameters));
  },
  doOpenDocument: (memberID, documentID) => {
    dispatch(openMemberDocument(memberID, documentID));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberDocuments);
