import { takeEvery, all, put, call } from "redux-saga/effects";
import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import { receiveMembers, setIsLoading } from "../actions/search";
import "babel-polyfill";

export function* searchSaga(action) {
  const { payload: fields } = action;

  try {
    // fetch meter records from proton, eci and solar
    const resp = yield fetch(constants.METER_SEARCH_URL, {
      meterIdentifier: fields.meterIdentifier,
    });

    // store proton search results
    const meterMemberIDs = resp.meters.map((meter) => meter.memberID);

    // fetch eci meter records
    try {
      const respEci = yield fetch(constants.ECI_METER_URL, {
        utilityAccountNumber: fields.meterIdentifier,
        forSearch: true
      });

      // store eci search results
      if (respEci && respEci.meter) {
        meterMemberIDs.push(respEci.meter.memberId);
        respEci.meter.memberID=respEci.meter.memberId;
        respEci.meter.status=respEci.meter.meterStatusText;
        respEci.meter.start_date=respEci.meter.meterServiceStartDate;
        respEci.meter.end_date=respEci.meter.meterServiceEndDate;
        respEci.meter.utilityDunsNumber=respEci.meter.utilityDunsNumber;
        resp.meters.push(respEci.meter);
      }
    } catch {}

    try {
    // fetch solar meter records
    const respSolar = yield fetch(constants.CS_METER_URL, {
      utilityAccountNumber: fields.meterIdentifier,
    });

    // store solar search results
    if (respSolar && respSolar.meter) {
      meterMemberIDs.push(respSolar.meter.member_id);
      respSolar.meter.memberID=respSolar.meter.member_id;
      respSolar.meter.meterIdentifier=respSolar.meter.meter_identifier;
      resp.meters.push(respSolar.meter);
    }
    } catch {}

    // fetch members for each meter
    let membersResp = yield all(
      meterMemberIDs.map((memberID) => {
        return call(fetch, constants.MEMBER_GET_URL, {
          memberID,
        });
      })
    );

    // Get list of member ids
    let memberIDs = membersResp.map((member) => member.memberID);

    // Fetch billing info for each member in member id list
    let billingResp = yield call(fetch, constants.BILLING_ACCOUNTS, {
      memberIDs: memberIDs,
      return_last_successful_payment: "true",
    });

    // Construct search result
    membersResp.map((member) => {
      const meter = resp.meters.find(
        (meter) => meter.memberID === member.memberID
      );
      const billingInfo = billingResp.accounts.find(
        (billing) => billing.memberID === member.memberID
      );

      // get address from meter
      const address =  meter?.service_address // proton meter
        || meter?.meta_data?.address // cs_meter
        || meter?.serviceAddress // eci meter;
      member.meterAddress = address.lineOne || address.line1;
      member.meterDetail = meter;
      member.account_balance = billingInfo?.account_balance;
      member.last_successful_payment =
        billingInfo?.last_successful_payment?.amount;
      member;
    });

    if (membersResp.length !== 0) {
      // display member
      yield put(receiveMembers({ Members: membersResp }));
    } else {
      // empty members
      yield put(receiveMembers({ Members: [{ no_results: true }] }));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    console.log("error fetching members:", err);
    yield put(setIsLoading(false));
    return;
  }
}

export default [takeEvery(constants.ESI_SEARCH, searchSaga)];
