import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  confirmationError,
  confirmationSuccess,
} from "../../styles/common.css";

import Snackbar from "../Snackbar"
import Backdrop from "@material-ui/core/Backdrop";
import { ClipLoader } from "react-spinners";
import MonthlyChargesPanel from "./MonthlyChargesPanel";
import TransactionPanel from "./TransactionPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& #page-title": {
      fontSize: "24px",
      marginBottom: "0px",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MemberBilling = (props) => {
  const classes = useStyles();
  const {
    billingSummaryState,
    confMsg,
    acceptedPairs,
    transactions: { transactions, hasNext, page, isFetching },
    doRequestAllBillingTransactions,
    authData,
    params,
    doApplyAdjustment,
    onNoteCreate,
    onHideSnackBar,
    doInitTransactionSummary,
  } = props;

  useEffect(() => {
    const { params } = props;

    doInitTransactionSummary(params.memberId);
  }, []);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onHideSnackBar();
  };

  // Create a formated price string  
  function money(string) {
    if (typeof string === "number") {
      const value = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(string);
      return value;
    } else if (typeof string !== "string" || 0 === string.length) {
      return "N/A";
    } else {
      if (string.charAt(0) === "-") {
        return `${string.slice(0, 1)}$${parseFloat(string.slice(1)).toFixed(
          2
        )}`;
      }
      return `$${parseFloat(string).toFixed(2)}`;
    }
  }

  return (
    <div className={classes.root}>
      <h1 id="page-title">Billing</h1>
      <MonthlyChargesPanel
        memberID={params.memberId}
        money={money}
      />
      <TransactionPanel
        authData={authData}
        transactions={transactions}
        acceptedPairs={acceptedPairs}
        hasNext={hasNext}
        page={page}
        money={money}
        params={params}
        doApplyAdjustment={doApplyAdjustment}
        doRequestAllBillingTransactions={doRequestAllBillingTransactions}
        onNoteCreate={onNoteCreate}
      />
      {confMsg && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={confMsg.get("showSnackBar")}
          onClose={handleCloseSnackBar}
          className={confMsg.getIn(["response", "success"]) ? confirmationSuccess : confirmationError}
          message={confMsg.getIn(["response", "msg"])}
        />
      )}
      <Backdrop
        className={classes.backdrop}
        open={billingSummaryState.get("isFetching") || isFetching}
      >
        <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
      </Backdrop>
    </div>
  );
};

MemberBilling.propTypes = {
  member: PropTypes.object.isRequired,
};

export default MemberBilling;
