import * as C from "../../constants";

const initialState = {
  statements: [],
  selectedStatusForFetch: "",
  isProcessing: false,
  showSnackBar: false,
  successMessage: "",
  errorMessage: "",
};

const closeSnackBar = (state) => {
  return {
    ...state,
    showSnackBar: false,
    successMessage: "",
    errorMessage: "",
  };
};

const openSnackBar = (state, action) => {
  return {
    ...state,
    showSnackBar: true,
    ...action,
  };
};

const receiveStatements = (state, action) => {
  const { statements } = action.payload;
  return {
    ...state,
    statements: statements,
  };
};

const setIsProcessing = (state, isProcessing) => {
  return {
    ...state,
    isProcessing: isProcessing,
  };
};

const setSelectedStatusForFetch = (state, selectedStatusForFetch) => {
  return {
    ...state,
    selectedStatusForFetch: selectedStatusForFetch,
  };
};

export default function statements(state = initialState, action) {
  switch (action.type) {
    case C.STATEMENT_CLOSE_SNACKBAR:
      return closeSnackBar(state);
    case C.STATEMENT_OPEN_SNACKBAR:
      return openSnackBar(state, action);
    case C.RECEIVE_BILLING_STATEMENTS:
      return receiveStatements(state, action);
    case C.REQUEST_UPDATE_STATEMENT_STATUS:
      return setIsProcessing(state, true);
    case C.UPDATE_STATEMENT_STATUS_SUCCESS:
      return setIsProcessing(state, false);
    case C.UPDATE_STATEMENT_STATUS_FAILURE:
      return setIsProcessing(state, false);
    case C.SET_STATEMENT_IS_PROCESSING:
      return setIsProcessing(state, action.payload);
    case C.SET_SELECTED_STATEMENT_STATUS_FOR_FETCH:
      return setSelectedStatusForFetch(state, action.payload);
    default:
      return state;
  }
}
