import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "../../Button";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import { noselect } from "../../../styles/common.css";
import {
  fetchMemberAccountBalance,
  fetchAccountBalanceTransactions
} from "../../../actions/search";
import OneTimeCardPayment from "./OneTimeCardPayment";
import OneTimeCardPaymentICheck from "./OneTimeCardPaymentICheck";
import OneTimeCardPaymentStripe from "./OneTimeCardPaymentStripe";
import selectAccountFlagsContainer from "../../../selectors/accountFlags";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  FormControl,
} from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PaymentIcon from "@material-ui/icons/Payment";
import { useStyles } from "./styles";
import currency from "../../../util/currency-formatter";
import moment from "moment";

const AccountBalance = (props) => {
  const classes = useStyles();
  const {
    userID,
    memberID,
    accountID,
    market,
    accountBalance,
    onApplyAddFunds,
    onMakeOneTimePayment,
    onMakeOneTimePaymentICheck,
    onMakeOneTimePaymentStripe,
    onFetchMemberAccountBalance,
    onFetchAccountBalanceTransactions,
    selectedAccountFlags,
    transactions,
    creditCardFee,
  } = props;
  const [amount, setAmount] = useState("");
  const [openManualPayment, setOpenManualPayment] = useState(false);
  const [openOnetimePayment, setOpenOneTimePayment] = useState(false);
  const showWriteOff = selectedAccountFlags ? selectedAccountFlags.filter(x => x.flag_type === "WO" && x.action !== "remove").length > 0 : false
  const writeOffAmount = transactions ? transactions.reduce((total, obj) => parseFloat(obj.get("amount")) + total, 0).toFixed(3) : 0
  const isCheckPayment = process.env.REACT_APP_DEFAULT_PAYMENT_PROVIDER === "ICHECK"
  const isStripePayment = process.env.REACT_APP_DEFAULT_PAYMENT_PROVIDER === "STRIPE"
  const isChasePayment = !(isCheckPayment || isStripePayment)
  const validateInput = () => {
    return amount && parseFloat(amount);
  };

  const closePaymentDialog = () => {
    setAmount("");
    setOpenManualPayment(false);
  };

  const closeOnetimePaymentDialog = () => {
    setAmount("");
    setOpenOneTimePayment(false);
  };
  
  useEffect(() => {
    if (showWriteOff && !transactions) {
      onFetchAccountBalanceTransactions({
        "member_id": memberID,
        "account_id": accountID,
        "transaction_type_name": "adjustments",
        "transaction_subtype_name": "writeoff",
      })
    }
  }, [showWriteOff]);

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item md={6}>
          <h4 style={{ marginLeft: "25px" }}>Account Balance</h4>
        </Grid>
        <Grid item md={6}>
          <div style={{ float: "right", marginBottom: "10px" }}>
            <IconButton
              aria-label="One-time Payment"
              size="medium"
              title="One-time Payment"
              classes={{ label: classes.iconButtonLabel }}
              color="primary"
              onClick={() => setOpenOneTimePayment(true)}
            >
              <PaymentIcon fontSize="large" />
            </IconButton>
            <IconButton
              aria-label="Make Payment"
              size="medium"
              title="Make Payment"
              classes={{ label: classes.iconButtonLabel }}
              color="primary"
              onClick={() => setOpenManualPayment(true)}
            >
              <AddCircleOutlineIcon fontSize="large" />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <TableContainer>
        <Table
          className={showWriteOff ? {} : classes.table}
          aria-label="collapsible table"
          style={{ fontSize: 14 }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Current Balance</TableCell>
              <TableCell align="center">Balance Due</TableCell>
              <TableCell align="center">Last Bill Amount</TableCell>
              <TableCell align="center">Due Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accountBalance && (
              <TableRow style={{ cursor: "pointer" }}>
                <TableCell align="center">
                  {currency(accountBalance.get("current_balance"))}
                </TableCell>
                <TableCell align="center">
                  {currency(accountBalance.get("balance_due"))}
                </TableCell>
                <TableCell align="center">
                  {currency(accountBalance.get("last_bill_amount"))}
                </TableCell>
                <TableCell align="center">
                  {moment
                    .utc(accountBalance.get("due_date"))
                    .format("YYYY-MM-DD")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        onClose={() => closePaymentDialog()}
        aria-labelledby="customized-dialog-title"
        open={openManualPayment}
      >
        <div className={classes.paymentRoot}>
          <Card className={classes.createNoteContainer} variant="outlined">
            <div className={`${classes.menuBar} ${noselect}`}>
              <span>Manual Payment</span>
              <CloseIcon onClick={() => closePaymentDialog()} />
            </div>
            <FormControl className={classes.paymentForm}>
              <TextField
                label={`Enter payment amount`}
                placeholder="0.00"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                type="money"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.paymentText}
              />
              
              {Boolean(creditCardFee) && 
              <span style={{ color: "red", marginTop:"10px" }}>
                {creditCardFee}% processing fee will apply to credit card payments
              </span>}
            </FormControl>
            <div
              className={classes.optionContainer}
              style={{
                borderTop: "2px solid #ddd",
              }}
            >
              <Button
                color="secondary"
                varian="raised"
                onClick={async () => await closePaymentDialog()}
              >
                Cancel
              </Button>
              <Button
                className={
                  validateInput() ? classes.enableButton : classes.disableButton
                }
                color="primary"
                varian="raised"
                onClick={async () => (
                  await closePaymentDialog(),
                  await onApplyAddFunds(memberID, "amount", amount).then(() => {
                    onFetchMemberAccountBalance(memberID);
                  })
                )}
              >
                Save
              </Button>
            </div>
          </Card>
        </div>
      </Dialog>
      {
        showWriteOff &&
        <div style={{
          marginLeft: "30px",
          marginRight: "30px",
          marginBottom: "10px",
        }}>
          <List>
            <ListItem divider>
              <ListItemText primary="Write Off: " />
              ${writeOffAmount}
            </ListItem>
          </List>
        </div>
      }
      {isCheckPayment && <OneTimeCardPaymentICheck
        memberID={memberID}
        market={market}
        userID={userID}
        open={openOnetimePayment}
        onClose={closeOnetimePaymentDialog}
        onMakeOneTimePayment={onMakeOneTimePaymentICheck}
        onFetchMemberAccountBalance={onFetchMemberAccountBalance}
        creditCardFee={creditCardFee}
      />}
      {isChasePayment && <OneTimeCardPayment
        memberID={memberID}
        market={market}
        userID={userID}
        open={openOnetimePayment}
        onClose={closeOnetimePaymentDialog}
        onMakeOneTimePayment={onMakeOneTimePayment}
        onFetchMemberAccountBalance={onFetchMemberAccountBalance}
        creditCardFee={creditCardFee}
      />}
      {isStripePayment && <OneTimeCardPaymentStripe
        memberID={memberID}
        market={market}
        userID={userID}
        open={openOnetimePayment}
        onClose={closeOnetimePaymentDialog}
        onMakeOneTimePayment={onMakeOneTimePaymentStripe}
        onFetchMemberAccountBalance={onFetchMemberAccountBalance}
        creditCardFee={creditCardFee}
      />}
    </Card>
  );
};

AccountBalance.propTypes = {
  accountBalance: PropTypes.object,
  onFetchMemberAccountBalance: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => selectAccountFlagsContainer();

const mapDispatchToProps = (dispatch) => ({
  onFetchMemberAccountBalance: (memberID) =>
    dispatch(fetchMemberAccountBalance(memberID)),
  onFetchAccountBalanceTransactions: (params) =>
    dispatch(fetchAccountBalanceTransactions(params)),
  dispatch,
});

const ConnectedAccountBalance = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountBalance);

export default ConnectedAccountBalance;
