import { makeStyles, styled } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "25px",
    marginBottom: "25px",
    border: "1px solid lightgrey",
    borderRadius: 0,
    "& .MuiListItem-root": {
      padding: 0,
    },
    "& .MuiTableCell-root": {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
    },
  },
  table: {
    "margin-bottom": 20,
  },
  refundRoot: {
    width: "300px",
  },
  paymentRoot: {
    width: "300px",
  },
  paymentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  paymentText: {
    padding: "10px 20px 20px 0px",
    margin: "0px 10px",
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    pointerEvents: "none",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
}));
