import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  List,
  ListItem,
  Grid,
} from "@material-ui/core";
import CSAMemberTable from "./CSAMemberTable";
import AddMeterDialog from "./AddMeterDialog";
import {
  fetchProductOffers,
  findMeter,
  submitEnrollment,
  listCSAMembers,
} from "../../actions/csa";

const CSAView = ({ selected_csa, doFetchProductOffers, doFindMeter, doSubmitEnrollment, doListCSAMembers }) => {
  const parentCSA = selected_csa.csa;
  const userInfo = selected_csa.csa_user;
  const [openAddMeterDialog, setOpenAddMeterDialog] = useState(false);

  const submitMemberEnrollment = async (props) => {
    const enrollment = doSubmitEnrollment({ ...props, userInfo, csaInfo: parentCSA });
    doListCSAMembers({ csa_id: parentCSA.id, email: userInfo.email });
  }
  return <>
    <div>
      <h5>Owner CSA</h5>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <List style={{ display: "flex", flexDirection: "column" }}>
            <ListItem>
              <label style={{ paddingRight: "1em" }}>Name:</label>
              {`${userInfo.first_name} ${userInfo.last_name}`}
            </ListItem>
            <ListItem>
              <label style={{ paddingRight: "1em" }}>Member ID:</label>
              <a href={`/members/${parentCSA.member_id}/details`}>{parentCSA.member_id}</a>
            </ListItem>
            <ListItem>
              <label style={{ paddingRight: "1em" }}>Company Name:</label>
              {parentCSA.company_name}
            </ListItem>
            <ListItem>
              <label style={{ paddingRight: "1em" }}>Profile Name:</label>
              {parentCSA.profile_name}
            </ListItem>
          </List>
        </Grid>
        <Grid item lg={4}>
          <List style={{ display: "flex", flexDirection: "column" }}>
            <ListItem>
              <label style={{ paddingRight: "1em" }}>Email:</label>
              {userInfo.email}
            </ListItem>
            <ListItem>
              <label style={{ paddingRight: "1em" }}>Phone:</label>
              {userInfo.phone}
            </ListItem>
          </List>
        </Grid>
      </Grid>

    </div >
    <div>
      <Button style={{ float: "right" }} onClick={() => setOpenAddMeterDialog(true)} color="primary" variant="contained">Add Member</Button>
      {openAddMeterDialog && <AddMeterDialog
        open={openAddMeterDialog}
        closeDialog={() => setOpenAddMeterDialog(false)}
        parentCSA={parentCSA}
        doFetchProductOffers={doFetchProductOffers}
        userInfo={userInfo}
        doFindMeter={doFindMeter}
        submitMemberEnrollment={submitMemberEnrollment}
      />}
      <h5>CSA Members</h5>
      <CSAMemberTable
        selectedCsa={selected_csa}
        doListCSAMembers={doListCSAMembers}
        csaMembers={selected_csa.csa_members}
      />
    </div>
  </>
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  selected_csa: state.csa.selected_csa,
});

const mapDispatchToProps = (dispatch) => ({
  doFetchProductOffers: (params) => {
    return dispatch(fetchProductOffers(params));
  },
  doFindMeter: (params) => {
    return dispatch(findMeter(params));
  },
  doSubmitEnrollment: (params) => {
    dispatch(submitEnrollment(params));
  },
  doListCSAMembers: (params) => {
    dispatch(listCSAMembers(params));
  },
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CSAView);
