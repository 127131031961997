import React from "react";
import CSAView from "../components/CSA/CSAView";
import { connect } from "react-redux";
import StatusOverlay from "../components/StatusOverlay";

const MemberCSAContainer = (props) => {
  return <>
    <CSAView {...props} />
    < StatusOverlay />
  </>;
}

const mapStateToProps = (state) => ({
  member: state.selectedMember,
  // Populated as part of the member screen load using doSetSelectedCSA.
  selected_csa: state.csa.selected_csa,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberCSAContainer);