import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Switch from "react-switch";
import { useStyles } from "./styles";

const InputSwitch = ({ offLabel, onLabel, checked, onChange, disabled }) => {
  const [value, setValue] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  return (
    <>
      <span
        color={!value ? "black" : "grey"}
        style={{ fontWeight: !value && "bold" }}
      >
        {offLabel}
      </span>
      <span className={classes.switch}>
        <Switch
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={45}
          onColor="#15bf20"
          offColor="#e30e0e"
          checked={value}
          onChange={onChange}
          disabled={disabled}
        />
      </span>
      <span
        color={value ? "black" : "grey"}
        style={{ fontWeight: value && "bold" }}
      >
        {onLabel}
      </span>
    </>
  );
};

InputSwitch.propTypes = {
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputSwitch;
